import { AppRouteConfig } from '@/models/AppRouter';

import ClientBrandSettings from '../views/ClientBrandSettings.vue';

const routeConfig: AppRouteConfig = {
  path: 'client-brand-settings',
  component: ClientBrandSettings,
  meta: {
    title: 'Настройки клиентов и брендов',
    permission: 'admin',
    order: 4,
    footer: {
      linksOnRight: true,
    },
  },
};

export default routeConfig;
