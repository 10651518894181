















































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api';

import { DBreadcrumbs } from '@shared/druid-kit';

import useRouter from '@/packages/hooks/useRouter';
import useRoute from '@/packages/hooks/useRoute';

import notify from '@/services/notify';
import AppJsonError from '@/services/errorHandler/AppJsonError';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import useMeta from '@/composables/meta/useMeta';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useProviderTokensStore } from '../store';

export default defineComponent({
  components: {
    DBreadcrumbs,
    AppView,
  },
  setup(props, context) {
    const router = useRouter(context);
    const route = useRoute(context);

    const providerTokensStore = useProviderTokensStore();

    const errorMessage = ref('');
    const isLoading = ref(false);

    const breadcrumbs = computed(() => [
      {
        label: 'Доступы к площадкам',
        path: '/provider-tokens',
      },
      {
        label: 'Обработка токена',
        path: '/provider-tokens/callback?',
      },
    ]);

    useMeta({ title: 'Обработка токена' });

    const sendToken = async () => {
      const { state, code } = route.value.query;

      if (!state || !code) {
        errorMessage.value = 'Неверные параметры запроса. Обратитесь в поддержку.';

        return;
      }

      isLoading.value = true;

      try {
        await providerTokensStore.dispatch('CREATE_TOKEN', {
          code: code as string,
          tokenProviderId: state as string,
        });

        notify.push({
          text: 'Новый токен успешно добавлен',
          position: ['bottom', 'center'],
          status: 'positive',
        });

        router.push('/provider-tokens');
      } catch (e) {
        if (!isError(e)) return;

        if (isAxiosError(e) && e.response?.data && typeof e.response.data === 'string') {
          errorMessage.value = e.response.data;
        } else {
          errorMessage.value = 'Произошла ошибка. Попробуйте еще раз либо обратитесь в <a href="mailto:help@danbo.digital" target="_blank">поддержку</a>.';

          throw new AppJsonError({ error: e, options: { showingModal: false } });
        }
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      // facebook fix
      if (window.location.hash === '#_=_') {
        window.history.replaceState(null, '', window.location.href.split('#')[0]);
      }

      sendToken();
    });

    return {
      errorMessage,
      isLoading,
      breadcrumbs,
    };
  },
});
