

































import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api';

import { DTableCellSpacer } from '@shared/druid-kit';

import MetricsTableRow, { MetricCell } from '@/components/_newDesign/common/MetricsTable/MetricsTableRow.vue';
import MetricsTablePeriodCell from '@/components/_newDesign/common/MetricsTable/cells/MetricsTablePeriodCell.vue';

import BrandListItemTableDescCell from '@/components/_newDesign/Brands/BrandList/BrandListItemTable/cells/BrandListItemTableDescCell.vue';
import BrandListItemTableApprovedCell from '@/components/_newDesign/Brands/BrandList/BrandListItemTable/cells/BrandListItemTableApprovedCell.vue';

import { useCampaignApprovedStatus } from '@/composables/campaign';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  components: {
    DTableCellSpacer,
    MetricsTableRow,
    BrandListItemTableDescCell,
    BrandListItemTableApprovedCell,
    MetricsTablePeriodCell,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    metricCells: { type: Array as PropType<MetricCell[]>, default: () => [] },
  },
  setup(props) {
    const { campaign, metricCells } = toRefs(props);

    const {
      campaignIsApproved,
      toggleCampaignApprovedStatusIsLoading,
      toggleCampaignApprovedStatus,
    } = useCampaignApprovedStatus({ campaign });

    const campaignIsActive = computed(() => campaign.value.isActive);

    const progressProps = computed(() => ({
      color: campaignIsActive.value ? 'primary' : 'grey darken-4',
    }));

    const coloredMetricCells = metricCells.value.map((metricCell) => ({
      ...metricCell,
      progressProps: progressProps.value,
    }));

    return {
      campaignIsApproved,
      campaignIsActive,
      toggleCampaignApprovedStatusIsLoading,
      progressProps,
      coloredMetricCells,
      toggleCampaignApprovedStatus,
    };
  },
});
