import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-smart-module';
import createPersistedState from 'vuex-persistedstate';

import rootStore from '@/store/root';

Vue.use(Vuex);

export default createStore(rootStore, {
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      key: 'd1-digital-vuex-v1',
      paths: [
        'ui.redesignNoticeIsShown',
        'ui.redesignWorkflowNoticeIsShown',
        'navigation.ui.sideBarMinimaized',
      ],
    }),
  ],
});
