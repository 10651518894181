


































import Vue, { VueConstructor, PropType } from 'vue';

import Handsontable from 'handsontable/base';

import { DTooltip } from '@shared/druid-kit';
import { HnTableCellMixin } from '@/packages/hn-table/src/mixins';

import { CampaignEditorPlacement } from '@/models/Campaign';

type HnTableCheckboxValue = boolean | null;

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'HnTableCheckboxCell',
  components: {
    DTooltip,
  },
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    campaignPlacements: { type: Array as PropType<CampaignEditorPlacement[]>, required: true },
    tooltipText: { type: [String, Array] as PropType<string | [unknown, string][]>, default: '' },
  },
  data() {
    return {
      value: null as HnTableCheckboxValue | null,
      placement: null as CampaignEditorPlacement | null,
    };
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set() {
        this.onChange();
      },
    },
    cellPropertiesRow(): number | undefined {
      return this.cellProperties?.row;
    },
    tooltipTextComputed(): string {
      if (typeof this.tooltipText === 'string') return this.tooltipText;

      const foundText = this.tooltipText.find(([value]) => value === this.currentValue);

      return foundText ? foundText[1] : '';
    },
  },
  watch: {
    hotInstance: {
      handler(newValue, oldValue) {
        oldValue?.removeHook('afterChange', this.afterChange);
        oldValue?.removeHook('beforeKeyDown', this.beforeKeyDown);

        newValue?.addHook('afterChange', this.afterChange);
        newValue?.addHook('beforeKeyDown', this.beforeKeyDown);
      },
      immediate: true,
    },
    campaignPlacements: {
      handler() {
        this.initPlacement();
      },
      immediate: true,
    },
    async cellPropertiesRow() {
      await this.initPlacement();
    },
  },
  beforeDestroy() {
    this.hotInstance?.removeHook('beforeKeyDown', this.beforeKeyDown);
  },
  methods: {
    async initPlacement() {
      if (typeof this.cellPropertiesRow !== 'number') return;

      await this.$nextTick();

      const campaignPlacement = this.campaignPlacements[this.cellPropertiesRow];

      this.placement = campaignPlacement;
    },
    afterChange() {
      this.initPlacement();
    },
    onChange() {
      if (!this.hotInstance) return;

      this.hotInstance.setDataAtCell(this.cellRow, this.col, !this.value);
    },
    beforeKeyDown(e: KeyboardEvent) {
      if (!this.hotInstance || e.keyCode !== Handsontable.helper.KEY_CODES.ENTER) return;

      const selected = this.hotInstance.getSelected() || [];

      const selectedRow = selected[0][0];
      const selectedCol = selected[0][1];

      if (selectedRow === this.cellRow && selectedCol === this.col) {
        this.onChange();
      }
    },
  },
});
