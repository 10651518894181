/* eslint-disable max-classes-per-file */

import Vue from 'vue';
import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, Context, createMapper, createComposable } from 'vuex-smart-module';

import { Campaign } from '@/models/Campaign';

import { CampaignsApi } from '@/api';

import campaignStore from '../campaign';

interface CampaignEditorCategoriesData {
  typeId: Campaign['typeId'];
  categoriesIds: Campaign['categoriesIds'];
}

class ModuleState {
  typeId: string | null = null;

  categoriesIds: string[] = [];

  dataUpdate: Partial<CampaignEditorCategoriesData> | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  store!: Store<unknown>;

  campaign!: Context<typeof campaignStore>;

  $init(store: Store<unknown>) {
    this.store = store;

    this.campaign = campaignStore.context(store);
  }

  get dataUpdate() {
    return this.state.dataUpdate;
  }

  get currentData() {
    const { currentCampaign } = this.campaign.getters;

    return {
      typeId: this.getters.dataUpdate?.typeId || currentCampaign?.typeId || '',
      categoriesIds: this.getters.dataUpdate?.categoriesIds || currentCampaign?.categoriesIds || [],
    };
  }

  get hasBeenChanged() {
    return !!this.getters.dataUpdate;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_DATA_UPDATE(payload: CampaignEditorCategoriesData) {
    this.state.dataUpdate = payload;
  }

  RESET_CHANGES() {
    this.state.dataUpdate = null;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  store!: Store<unknown>;

  campaign!: Context<typeof campaignStore>;

  $init(store: Store<unknown>) {
    this.store = store;

    this.campaign = campaignStore.context(store);
  }

  CHANGE_DATA(payload: Partial<CampaignEditorCategoriesData>) {
    if (!this.getters.currentData) return;

    const newData = { ...this.getters.currentData, ...payload };

    this.commit('SET_DATA_UPDATE', newData);
  }

  async SAVE(payload: { campaignId: string }) {
    if (!this.getters.hasBeenChanged) return;

    await CampaignsApi.updateCampaignCategories({
      campaignId: payload.campaignId,
      data: {
        campaignTypeId: this.getters.currentData.typeId,
        campaignCategoriesIds: this.getters.currentData.categoriesIds,
      },
    });

    await this.campaign.dispatch('UPDATE_CAMPAIGN', this.getters.currentData);

    const { campaign } = this.campaign.getters;

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Campaign Editor',
      event_action: 'Edit Category',
      event_label: 'Save',
      d1d_client_id: campaign?.clientId,
      d1d_client_name: campaign?.clientName,
      d1d_brand_id: campaign?.brandId,
      d1d_brand_name: campaign?.brandName,
      d1d_campaign_id: campaign?.id,
      d1d_campaign_name: campaign?.name,
    });

    this.commit('RESET_CHANGES');
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const campaignEditorCategoriesStoreMapper = createMapper(module);

export const useCampaignEditorCategoriesStore = createComposable(module);

export default module;
