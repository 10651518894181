




































import Vue from 'vue';
import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { Brand } from '@/models/Brand';

import { useClientStore } from '@/store/client';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    brand: { type: Object as PropType<Brand>, required: true },
    value: { type: Boolean, default: false },
    count: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      brand,
      value,
      loading,
    } = toRefs(props);

    const clientStore = useClientStore();

    const client = computed(() => clientStore.getters.client);

    const onRootClick = () => {
      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Campaign List',
        event_action: 'Unroll finished',
        event_label: `${client.value?.name} | ${brand.value.name}`,
        d1d_client_id: client.value?.id,
        d1d_client_name: client.value?.name,
        d1d_brand_id: brand.value.id,
        d1d_brand_name: brand.value.name,
      });

      if (loading.value) return;

      context.emit('input', !value.value);
    };

    return {
      onRootClick,
    };
  },
});
