

















































import { defineComponent, PropType, computed, toRefs } from '@vue/composition-api';

import moment from 'moment';

import { DTableCell } from '@shared/druid-kit';

import { Campaign, CampaignPlacement } from '@/models/Campaign';

import MetricsTableSimple from '@/components/_newDesign/common/MetricsTable/MetricsTableSimple.vue';

import CampaignMetrics from '@/components/_newDesign/Campaigns/CampaignMetrics/CampaignMetrics.vue';
import CampaignChart from '@/components/_newDesign/Campaigns/CampaignChart.vue';
import CampaignTabs from '@/components/_newDesign/Campaigns/CampaignTabs.vue';

import CampaignDateFilterBtn from '@/components/_newDesign/Campaigns/buttons/CampaignDateFilterBtn.vue';

export default defineComponent({
  components: {
    DTableCell,
    MetricsTableSimple,
    CampaignMetrics,
    CampaignChart,
    CampaignTabs,
    CampaignDateFilterBtn,
  },
  props: {
    danboId: { type: String, required: true },
    campaign: { type: Object as PropType<Campaign>, required: true },
    campaignPlacement: { type: Object as PropType<CampaignPlacement>, required: true },
  },
  setup(props) {
    const { campaign, campaignPlacement } = toRefs(props);

    const campaignPlacementLastUpdate = computed(() => moment(campaignPlacement.value.marker.updatedAt)
      .format('DD.MM.YYYY HH:mm'));

    const metricsData = computed(() => ({
      metricsTotal: campaignPlacement.value.marker.metricsTotal,
      metricsPlan: campaignPlacement.value.planMetrics,
      startDate: campaign.value.startDate,
      endDate: campaign.value.endDate,
    }));

    const tabs = computed(() => {
      const { sources } = campaignPlacement.value.marker;

      return [
        {
          name: 'Отображаемые',
          type: 'shown',
          items: sources.filter((source) => source.isActive && source.dataProviderType.id !== 'Analytics'),
        },
        {
          name: 'Площадки',
          type: 'placements',
          items: sources.filter((source) => source.dataProviderType.id === 'Placement'),
        },
        {
          name: 'Адсервинги',
          type: 'adservings',
          items: sources.filter((source) => source.dataProviderType.id === 'AdServing'),
        },
        {
          name: 'Аналитика',
          type: 'analytics',
          items: sources.filter((source) => source.dataProviderType.id === 'Analytics'),
        },
      ].map((tab) => ({
        ...tab,
        items: tab.items.sort((a, b) => a.dataProviderName.toLowerCase().localeCompare(b.dataProviderName.toLowerCase())),
      })).filter(({ items }) => items.length);
    });

    return {
      metricsData,
      campaignPlacementLastUpdate,
      tabs,
    };
  },
});
