import mitt from 'mitt';

const placementFlightsTableEmitter = mitt<{
  addFlightPlan: undefined;
  deleteFlightPlan: {
    row: number;
  };
}>();

export type PlacementFlightsTableEmitter = typeof placementFlightsTableEmitter;

export default placementFlightsTableEmitter;
