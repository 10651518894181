








































import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTooltip,
} from '@shared/druid-kit';

import numatter from '@/packages/numatter';

export default defineComponent({
  components: {
    DTableCell,
    DTooltip,
  },
  props: {
    metric: { type: Object as PropType<{ name: string; plan: number; fact: number }>, required: true },
    progressProps: { type: Object as PropType<{ color: string }>, default: () => ({}) },
    total: { type: Boolean, default: false },
    planInsteadProgress: { type: Boolean, default: false },
    onlyFact: { type: Boolean, default: false },
    disabledTooltip: { type: Boolean, default: false },
  },
  setup(props) {
    const { metric } = toRefs(props);

    const formattedPlan = computed(() => numatter.format(metric.value.plan, { preset: metric.value.name }));
    const formattedFact = computed(() => numatter.format(metric.value.fact, { preset: metric.value.name }));

    const percent = computed(() => (metric.value.fact / metric.value.plan) * 100);

    return {
      formattedPlan,
      formattedFact,
      percent,
    };
  },
});
