













import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api';

import NotifyListItem from './NotifyListItem.vue';

import { NotifyItem, NotifyItemPosition } from '../types';

import { NOTIFY_ITEM_POSITION_DEFAULT } from '../constants';

export default defineComponent({
  components: { NotifyListItem },
  props: {
    position: {
      type: Array as unknown as PropType<NotifyItemPosition>,
      default: () => NOTIFY_ITEM_POSITION_DEFAULT,
    },
    notifications: {
      type: Array as PropType<NotifyItem[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { position } = toRefs(props);

    const positionClasses = computed(() => {
      const normalizedPosition = position.value;

      return [
        `notify-list-by-position--y-${normalizedPosition[0]}`,
        `notify-list-by-position--x-${normalizedPosition[1]}`,
      ];
    });

    return { positionClasses };
  },
});
