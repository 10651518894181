import { inject, watch, ComputedRef, isRef } from '@vue/composition-api';

import { MetaInfo } from 'vue-meta';

export default (payload: MetaInfo | ComputedRef<MetaInfo>): void => {
  const changeMeta = inject<(payload: MetaInfo) => void>('changeMeta');

  if (!changeMeta) return;

  if (isRef(payload)) {
    watch((payload), (val) => {
      if (val) changeMeta(val);
    }, { immediate: true });
  } else {
    changeMeta(payload);
  }
};
