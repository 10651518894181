<template>
  <div class="markups-catalog-loader">
    <v-skeleton-loader
      class="markups-catalog-loader__header"
      type="heading"
    />
    <TableLoader
      class="markups-catalog-loader__table"
      :columns="5"
      :rows="4"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

export default defineComponent({
  components: {
    TableLoader,
  },
});
</script>

<style lang="scss" scoped>
.markups-catalog-loader {
  background-color: map-get($grey, 'lighten-5');

  &__header {
    display: flex;
    align-items: center;
    width: 70%;
    height: 80px;
    padding: 12px 24px;

    ::v-deep .v-skeleton-loader__heading {
      background-color: map-get($grey, 'lighten-1') !important;
    }
  }

  &__table {
    padding: 24px;
  }
}
</style>
