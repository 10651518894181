



































import { defineComponent, computed, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';

import UserActionsCatalogHeader from '../../components/UserActions/UserActionsCatalog/UserActionsCatalogHeader.vue';
import UserActionsTable from '../../components/UserActions/UserActionsCatalog/UserActionsTable/UserActionsTable.vue';

import { useUserActionsCatalogStore } from '../../store/userActions/userActionsCatalog';

export default defineComponent({
  components: {
    AppView,
    UserActionsCatalogHeader,
    UserActionsTable,
  },
  setup() {
    const { updateIsLoading } = useLayoutLoadingStatus();

    const userActionsCatalogStore = useUserActionsCatalogStore();

    const userActions = computed(() => userActionsCatalogStore.getters.userActions);
    const userActionsIsLoading = computed(() => userActionsCatalogStore.getters.isLoading);
    const userActionsFilters = computed(() => userActionsCatalogStore.getters.filters);
    const userActionsHaveBeenFiltered = computed(() => userActionsCatalogStore.getters.userActionsHaveBeenFiltered);

    const fetch = async () => {
      await userActionsCatalogStore.actions.FETCH_USER_ACTIONS();
    };

    watch(userActionsFilters, () => {
      if (!userActionsHaveBeenFiltered.value) return;

      fetch();
    });

    const init = async () => {
      updateIsLoading(true);
      userActionsCatalogStore.actions.RESET_FILTERS();

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    init();

    return {
      userActions,
      userActionsIsLoading,
      userActionsHaveBeenFiltered,
    };
  },
});
