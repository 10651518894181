









































import { defineComponent } from '@vue/composition-api';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import ProviderSettingsTableRow from './ProviderSettingsTableRow.vue';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
    ProviderSettingsTableRow,
  },
  props: {
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup(props, context) {
    const headers = [
      {
        text: '',
        value: 'status',
      },
      {
        text: 'Название',
        value: 'name',
      },
      {
        text: 'Клиент',
        value: 'client',
      },
      {
        text: 'Площадка',
        value: 'provider',
      },
      {
        text: 'Доступ',
        value: 'token',
      },
      {
        text: '',
        value: 'actions',
      },
    ].map((header) => ({
      ...header,
      class: 'caption',
      sortable: false,
    }));

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (isIntersecting) context.emit('load-next-page');
    };

    return {
      headers,
      onIntersect,
    };
  },
});
