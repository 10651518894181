/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import categoriesCatalog from './categories/categoriesCatalog';
import categoryDetails from './categories/categoryDetails';

const module = new Module({
  modules: {
    categoriesCatalog,
    categoryDetails,
  },
});

export const useCategoriesCatalogStore = createComposable(module);

export default module;
