




















































































import { defineComponent, PropType, toRefs, ref, computed } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import AppView from '@/components/_newDesign/common/AppView.vue';

import GoalsTable from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorGoals/GoalsTable/GoalsTable.vue';
import GoalsAddModal from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorGoals/modals/GoalsAddModal.vue';
import CampaignEditorTableLoader from '@/components/_newDesign/Campaigns/CampaignEditor/loaders/CampaignEditorTableLoader.vue';

import { Campaign } from '@/models/Campaign';

import { useCampaignEditorGoalsStore } from '@/store/campaignEditor/campaignEditorGoals';

import notify from '@/services/notify';
import modally from '@/services/modally';

export default defineComponent({
  components: {
    DBtnInline,
    AppView,
    GoalsTable,
    GoalsAddModal,
    CampaignEditorTableLoader,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props) {
    const {
      campaign,
    } = toRefs(props);

    const campaignEditorGoalsStore = useCampaignEditorGoalsStore();

    const isLoading = ref(false);

    const modalsMap = ref({
      add: false,
    });

    const goals = computed(() => campaignEditorGoalsStore.getters.goals);

    const fetch = async () => {
      isLoading.value = true;

      try {
        await campaignEditorGoalsStore.dispatch('FETCH_GOALS', { campaignId: campaign.value.id });
      } finally {
        isLoading.value = false;
      }
    };

    const refresh = async () => {
      const result = await modally.show({
        type: 'confirm',
        status: 'warning',
        title: 'При обновлении списка целей изменения будут утеряны!',
      });

      if (!result) return;

      campaignEditorGoalsStore.commit('RESET_CHANGES');

      fetch();
    };

    const openModal = (modalSlug: keyof typeof modalsMap.value) => {
      modalsMap.value[modalSlug] = true;
    };

    const openAddModal = () => {
      openModal('add');
    };

    const onAddModalAdded = () => notify.push({
      text: 'Цели добавлены',
      position: ['bottom', 'center'],
      status: 'positive',
    });

    fetch();

    return {
      isLoading,
      modalsMap,
      goals,
      refresh,
      openAddModal,
      onAddModalAdded,
    };
  },
});
