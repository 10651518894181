/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import {
  PermissionsApi,
} from '@/api';

import { Permission } from '@/models/Permission';

class ModuleState {
  isLoading = false;

  permission: Permission | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get permission() {
    return this.state.permission;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_PERMISSION(payload: Permission) {
    this.state.permission = payload;
  }

  RESET() {
    const defaultState = new ModuleState();

    Object.keys(defaultState).forEach((key) => {
      this.state[key] = defaultState[key];
    });
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_PERMISSION(permissionId: string) {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await PermissionsApi.fetchPermission(permissionId);

      this.mutations.SET_PERMISSION(res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const usePermissionDetailsStore = createComposable(module);

export default module;
