


































































import { defineComponent, toRefs, ref, computed, watch, ComponentInstance } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import AppJsonError from '@/services/errorHandler/AppJsonError';

import { Group } from '@/models/Group';

import { PartialWithout } from '@/utils/type';
import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { useGroupsCatalogStore } from '../../../store/groups/groupsCatalog';

import GroupsForm from '../forms/GroupsForm.vue';

export default defineComponent({
  components: {
    AppModal,
    GroupsForm,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const groupsCatalogStore = useGroupsCatalogStore();

    const groupFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const groupFormData = ref<PartialWithout<Group, 'id'> | null>(null);
    const groupFormIsValid = ref(false);

    const groupFormResetValidation = async () => {
      if (!groupFormRef.value) return;

      groupFormRef.value.resetValidation();
    };

    const newGroup = computed(() => {
      if (!groupFormData.value || !groupFormIsValid.value) return null;

      return groupFormData.value;
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => !newGroup.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!newGroup.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await groupsCatalogStore.actions.CREATE_GROUP(newGroup.value);

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const messages = Object.values(error.response?.data?.errors || {})
            .reduce<string[]>((acc, val) => {
              if (Array.isArray(val)) {
                acc.push(...val);
              } else if (typeof val === 'string') {
                acc.push(val);
              }

              return acc;
            }, []);

          saveError.value = {
            title: 'Произошла ошибка',
            messages: messages.length ? messages : ['Обратитесь к администратору'],
          };
        }

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        saving.value = false;
      }
    };

    watch(value, async (val) => {
      if (!val) return;

      groupFormData.value = null;

      groupFormResetValidation();
    }, { immediate: true });

    return {
      groupFormRef,
      groupFormData,
      groupFormIsValid,
      saving,
      saveBtnIsDisabled,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
