











import { defineComponent, toRefs, ref, shallowRef, onMounted, PropType } from '@vue/composition-api';

import lodash from 'lodash';
import moment from 'moment';

import {
  Chart,
  LineController,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Legend,
  Tooltip,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import 'chartjs-adapter-moment';

import numatter from '@/packages/numatter';

import hasOwnProperty from '@/utils/hasOwnProperty';

Chart.register({
  LineController,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Legend,
  Tooltip,
  ChartDataLabels,
});

export default defineComponent({
  props: {
    metrics: { type: Array as PropType<Record<string, string | number>[]>, required: true },
    visibleMetricNames: { type: Array as PropType<string[]>, required: true },
  },
  setup(props) {
    const { metrics, visibleMetricNames } = toRefs(props);

    const metricsParams = {
      Impressions: {
        color: '#5A00BD',
        format: '0,0[.]00 a',
        infoPosition: 'left',
      },
      Clicks: {
        color: '#4ED05D',
        format: '0,0[.]00 a',
        infoPosition: 'right',
      },
      CR: {
        color: '#307FFF',
        format: '0[.]00{%}',
        infoPosition: 'right',
      },
    };

    const chartInstance = shallowRef<Chart | null>(null);

    const chartCanvasEl = ref<HTMLCanvasElement | null>(null);

    const createChart = () => {
      const chartCanvasElContext = chartCanvasEl.value?.getContext('2d');

      if (!chartCanvasElContext) return;

      chartInstance.value = new Chart(chartCanvasElContext, {
        type: 'line',
        data: {
          datasets: visibleMetricNames.value.map((name, idx) => ({
            label: name,
            yAxisID: `y${idx}`,
            data: [],
            fill: false,
            backgroundColor: metricsParams[name].color,
            pointBackgroundColor: '#FFFFFF',
            pointHoverBackgroundColor: metricsParams[name].color,
            borderColor: metricsParams[name].color,
            borderWidth: 2,
          })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 5,
              hoverRadius: 5,
              backgroundColor: '#FFFFFF',
              borderWidth: 2,
              hoverBorderWidth: 4,
            },
          },
          scales: {
            x: {
              type: 'time',
              grid: {
                display: false,
              },
              time: {
                displayFormats: {
                  day: 'DD MMM',
                },
                tooltipFormat: 'dddd, DD.MM.YYYY',
                minUnit: 'day',
              },
              ticks: {
                autoSkipPadding: 0,
                padding: 0,
              },
            },
            ...(visibleMetricNames.value.reduce((acc, name, idx) => {
              acc[`y${idx}`] = {
                type: 'linear',
                position: metricsParams[name].infoPosition,
                beginAtZero: true,
                title: {
                  display: true,
                  text: name,
                },
                ticks: {
                  maxTicksLimit: 5,
                  callback(value: string | number) {
                    return numatter.format(value, metricsParams[name].format);
                  },
                },
              };

              return acc;
            }, {})),
          },
          plugins: {
            legend: {
              display: true,
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              enabled: false,
              intersect: true,
              mode: 'index',
              callbacks: {
                label(tooltipContext) {
                  const formatedMetrics = visibleMetricNames.value.map((name) => ([name, metricsParams[name].format]));
                  const [name, format] = formatedMetrics[tooltipContext.datasetIndex];

                  if (!(tooltipContext.raw && typeof tooltipContext.raw === 'object' && hasOwnProperty(tooltipContext.raw, 'y') && typeof tooltipContext.raw.y === 'number')) return '';

                  const value = numatter.format(tooltipContext.raw.y, format);

                  return `${name}: ${value}`;
                },
              },
              external({ tooltip }) {
                if (!chartInstance.value) return;

                let tooltipEl = document.getElementById('campaign-chart__tooltip');

                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');

                  tooltipEl.id = 'campaign-chart__tooltip';

                  chartInstance.value.canvas.parentNode?.appendChild(tooltipEl);
                }

                if (tooltip.opacity === 0) {
                  tooltipEl.style.opacity = '0';

                  return;
                }

                tooltipEl.className = '';

                if (tooltip.yAlign) {
                  tooltipEl.classList.add(tooltip.yAlign);
                }

                if (tooltip.xAlign) {
                  tooltipEl.classList.add(tooltip.xAlign);
                }

                if (tooltip.body) {
                  const titleLines = tooltip.title || [];
                  const bodyLines = tooltip.body.map((bodyItem) => bodyItem.lines);

                  const createHead = () => titleLines.map((title) => `<div class="campaign-chart__tooltip-title subtitle">${title}</div>`).join('');

                  const createBody = () => bodyLines.map((body, i) => {
                    const colors = tooltip.labelColors[i];

                    const style = `
                    background: ${colors.borderColor};
                    border-color: ${colors.borderColor};
                    border-width: 2px;
                  `;

                    return `
                    <div class="campaign-chart__tooltip-body d-flex align-center body-2">
                      <div class="campaign-chart__tooltip-key" style="${style}"></div>
                      ${body}
                    </div>
                  `;
                  }).join('');

                  const innerHtml = `
                  <div class="campaign-chart__tooltip-content">
                    <div>${createHead()}</div>
                    <div>${createBody()}</div>
                  </div>
                `;

                  tooltipEl.innerHTML = innerHtml;
                }

                const positionY = chartInstance.value.canvas.offsetTop;
                const positionX = chartInstance.value.canvas.offsetLeft;

                let left = positionX + tooltip.caretX;

                switch (tooltip.xAlign.toLowerCase()) {
                  case 'left':
                    left = positionX + tooltip.caretX;

                    break;
                  case 'right':
                    left = positionX + tooltip.caretX - tooltipEl.offsetWidth;

                    break;
                  case 'center':
                    left = positionX + tooltip.caretX - tooltipEl.offsetWidth / 2;

                    break;
                    // no default
                }

                let top = positionY + tooltip.caretY;

                switch (tooltip.yAlign.toLowerCase()) {
                  case 'top':
                    top = positionY + tooltip.caretY;

                    break;
                  case 'bottom':
                    top = positionY + tooltip.caretY - tooltipEl.offsetHeight;

                    break;
                  case 'center':
                    top = positionY + tooltip.caretY - tooltipEl.offsetHeight / 2;

                    break;
                    // no default
                }

                tooltipEl.style.opacity = '1';
                tooltipEl.style.left = `${left}px`;
                tooltipEl.style.top = `${top}px`;
              },
            },
          },
        },
      });
    };

    const updateChart = () => {
      const currentChartInstance = chartInstance.value;

      if (!currentChartInstance) return;

      const dateMetricsList = lodash.sortBy(metrics.value, (metric) => metric.date);

      currentChartInstance.data.datasets.forEach((dataset, idx) => {
        currentChartInstance.data.datasets[idx].data.length = 0;
      });

      dateMetricsList.forEach((dateMetrics) => {
        const mDate = moment(dateMetrics.date).toDate();

        visibleMetricNames.value.forEach((metricName, idx) => {
          currentChartInstance.data.datasets[idx].data.push({
            x: mDate as unknown as number,
            y: dateMetrics.metrics[metricName] || 0,
          });
        });
      });

      currentChartInstance.update();
    };

    onMounted(() => {
      createChart();
      updateChart();
    });

    return {
      chartCanvasEl,
    };
  },
});
