import { AxiosResponse } from 'axios';

import { Campaign, CampaignCategory, CampaignPlacement, CampaignsByBrand } from '@/models/Campaign';
import { AdObject } from '@/models/AdObject';

import httpClient from '../httpClient';

export interface FetchCampaignPayload {
  campaignId: string;
  params?: {
    from?: string;
    to?: string;
  };
}

export interface FetchCampaignInfoPayload {
  campaignId: string;
}

interface FetchCampaignResponse {
  campaign: Campaign;
  categories: CampaignCategory[];
}

interface FetchCampaignsByBrandPayload {
  brandId: string;
  params?: {
    searchQuery?: string;
    getFinished?: boolean;
  };
}

export interface FetchPlacementPayload {
  campaignId: string;
  danboId: string;
  params?: {
    from?: string;
    to?: string;
  };
}

export interface FetchAdObjectPayload {
  campaignId: string;
  danboId: string;
  connectorId: string;
  params?: {
    from?: string;
    to?: string;
  };
}

interface FetchAdObjectResponse {
  adObjects: AdObject[];
  displayableMetrics: string[];
  planMetrics: Record<string, number>;
  source: {
    id: string;
    updatedAt: string;
    dataProviderId: string;
    dataProviderName: string;
    dataProviderType: {
      id: string;
    };
    metricsTotal: Record<string, number>;
    metrics: {
      date: string;
      metrics: Record<string, number>;
    }[];
    groups: unknown[]; // TODO: Описать тип
    isActive: boolean;
  };
}

export interface FetchAdObjectWithLevelPayload {
  campaignId: string;
  danboId: string;
  connectorId: string;
  level: string;
  type: string;
  onSiteId: string;
  params?: {
    from?: string;
    to?: string;
  };
}

interface FetchAdObjectWithLevelResponse {
  adObjects: AdObject[];
  displayableMetrics: string[];
  parentAdObject: AdObject;
  parentAdObjectId: {
    name: string;
    type: string;
    onSiteId: string;
    parent: {
      name: string;
    };
  };
}

interface UpdateCampaignSettingsPayload {
  campaignId: string;
  data: {
    campaignNameManual: string;
    brandIdManual: string;
    conversionRateCalculationMode: string;
    dataProviderId?: string | null;
    includeAC: boolean;
    includeVAT: boolean;
    mainGoal?: {
      id: string;
      mainGoal: boolean;
      name: string;
      providerId: string;
      providerName: string;
      providerSettingsId: string;
      providerSettingsName: string;
    };
  };
}

interface UpdateCampaignCategoriesPayload {
  campaignId: string;
  data: {
    campaignTypeId: string;
    campaignCategoriesIds: string[];
  };
}

interface UpdateCampaignCategoriesOrderPayload {
  campaignId: string;
  data: {
    CategoriesPlacements: {
      CategoryId: string;
      PlacementsIds: string[];
    }[];
  };
}

export interface SetCampaignCategoriesPayload {
  campaignId: string;
  data: {
    campaignTypeId: string;
    clientCategoriesIds: string[];
    brandCategoriesIds: string[];
    campaignCategoriesIds: string[];
  };
}

export default class CampaignsApi {
  public static async fetchCampaign(payload: FetchCampaignPayload): Promise<AxiosResponse<FetchCampaignResponse>> {
    const { campaignId, params } = payload;

    const res = await httpClient.get<FetchCampaignResponse>(`/api/campaigns/${campaignId}`, { params });

    return res;
  }

  public static async fetchCampaignInfo(payload: FetchCampaignInfoPayload): Promise<AxiosResponse<Campaign>> {
    const { campaignId } = payload;

    const res = await httpClient.get<Campaign>(`/api/campaigns/${campaignId}/info`);

    return res;
  }

  public static async fetchCampaignsByBrand(payload: FetchCampaignsByBrandPayload): Promise<AxiosResponse<CampaignsByBrand>> {
    const { brandId, params } = payload;

    const res = await httpClient.get<CampaignsByBrand>(`/api/campaigns/by-brand/${brandId}`, { params });

    return res;
  }

  public static async fetchPlacement(payload: FetchPlacementPayload): Promise<AxiosResponse<CampaignPlacement>> {
    const { campaignId, danboId, params } = payload;

    const res = await httpClient.get<CampaignPlacement>(`/api/campaigns/${campaignId}/${danboId}`, { params });

    return res;
  }

  public static async fetchAdObject(payload: FetchAdObjectPayload): Promise<AxiosResponse<FetchAdObjectResponse>> {
    const {
      campaignId,
      danboId,
      connectorId,
      params,
    } = payload;

    const res = await httpClient.get<FetchAdObjectResponse>(`/api/campaigns/${campaignId}/${danboId}/${connectorId}`, { params });

    return res;
  }

  public static async fetchAdObjectWithLevel(payload: FetchAdObjectWithLevelPayload): Promise<AxiosResponse<FetchAdObjectWithLevelResponse>> {
    const {
      campaignId,
      danboId,
      connectorId,
      level,
      type,
      onSiteId,
      params,
    } = payload;

    const res = await httpClient.get<FetchAdObjectWithLevelResponse>(`/api/campaigns/${campaignId}/${danboId}/${connectorId}/${level}/${type}/${onSiteId}`, { params });

    return res;
  }

  public static async updateCampaignSettings(payload: UpdateCampaignSettingsPayload): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaigns/${campaignId}/updatesettings`, data);

    return res;
  }

  public static async updateCampaignCategories(payload: UpdateCampaignCategoriesPayload): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaigns/${campaignId}/updatecategories`, data);

    return res;
  }

  public static async updateCampaignApproved(payload: { campaignId: string; data: boolean }): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaigns/${campaignId}/approve`, `${data}`);

    return res;
  }

  public static async updateCampaignCategoriesOrder(payload: UpdateCampaignCategoriesOrderPayload): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaigns/${campaignId}/update-order`, data);

    return res;
  }

  public static async updateCampaignActiveStatus(payload: { campaignId: string; data: boolean }): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaigns/${campaignId}/setIsActive`, `${data}`);

    return res;
  }

  public static async setCampaignCategories(payload: SetCampaignCategoriesPayload): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaigns/${campaignId}/set-categories`, data);

    return res;
  }

  public static async removeCampaignManualSettings(payload: { campaignId: string; data: Record<string, never> }): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaigns/${campaignId}/manual-remove`, data);

    return res;
  }

  public static async validateDanboId(danboId: string): Promise<AxiosResponse<{ isValid: boolean }>> {
    const res = await httpClient.get<{ isValid: boolean }>(`/api/campaigns/validate/${danboId}`);

    return res;
  }
}
