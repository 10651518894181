/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import {
  GroupsApi,
} from '@/api';

import { Group } from '@/models/Group';

class ModuleState {
  isLoading = false;

  group: Group | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get group() {
    return this.state.group;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_GROUP(payload: Group) {
    this.state.group = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_GROUP(groupId: string) {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await GroupsApi.fetchGroup(groupId);

      this.mutations.SET_GROUP(res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useGroupsDetailsStore = createComposable(module);

export default module;
