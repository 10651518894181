import { AxiosResponse } from 'axios';

import { UserAction } from '@/models/User';

import httpClient from '../httpClient';

export interface FetchUserActionsPayload {
  params: {
    from: string;
    to: string;
    filter: string;
    page: number;
  };
}

export interface FetchUserActionsResponse {
  list: UserAction[];
  totalRows: number;
}

export default class UserActionsApi {
  public static async fetchUserActions(payload: FetchUserActionsPayload): Promise<AxiosResponse<FetchUserActionsResponse>> {
    const res = await httpClient.get<FetchUserActionsResponse>('/api/useractions', payload);

    return res;
  }
}
