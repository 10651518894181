import { ref, provide, watch, SetupContext, Ref } from '@vue/composition-api';

import { MetaInfo } from 'vue-meta';

import useRoute from '@/packages/hooks/useRoute';

interface UseConfigMetaReturn {
  meta: Ref<MetaInfo>;
}

export default (context: SetupContext): UseConfigMetaReturn => {
  const route = useRoute(context);

  const meta = ref<MetaInfo>({});

  const changeMeta = (payload: MetaInfo) => {
    meta.value = payload;
  };

  provide('changeMeta', changeMeta);

  watch(route, (val) => {
    if (val.meta?.title) {
      changeMeta({ title: val.meta.title });
    }
  });

  return {
    meta,
  };
};
