






























import { defineComponent, toRefs, ref, computed, watch, PropType } from '@vue/composition-api';

import { orderBy } from 'lodash';

import { DTreeView } from '@shared/druid-kit';

import { TaxonomyClientCategory } from '@/models/Taxonomy';

export default defineComponent({
  components: {
    DTreeView,
  },
  props: {
    categories: { type: Array as PropType<TaxonomyClientCategory[]>, default: () => [] },
    value: { type: Array as PropType<string[]>, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
      categories,
      loading,
    } = toRefs(props);

    const sortedCategories = ref<TaxonomyClientCategory[]>([]);

    const selectedCategories = computed({
      get() {
        return value.value;
      },
      set(val: string[]) {
        context.emit('input', val);
      },
    });

    const sortFilter = ref<'alphabet' | 'checked'>('checked');

    const sortMethod = computed(() => {
      const methods: Record<typeof sortFilter.value, (list: TaxonomyClientCategory[]) => TaxonomyClientCategory[]> = {
        alphabet(list) {
          return orderBy(list, 'name', 'asc');
        },
        checked(list) {
          const preSortedList = methods.alphabet(list);

          return orderBy(preSortedList, (category) => {
            const isSelected = category.children.some((child) => value.value.includes(child.id));

            return isSelected ? 1 : 0;
          }, 'desc');
        },
      };

      return methods[sortFilter.value];
    });

    const sortCategories = (list: TaxonomyClientCategory[]) => (sortMethod.value ? sortMethod.value(list) : list);

    watch(sortFilter, () => {
      sortedCategories.value = sortCategories(categories.value);
    });

    watch(loading, (val) => {
      if (!val) {
        sortedCategories.value = sortCategories(categories.value);
      }
    });

    return {
      sortFilter,
      selectedCategories,
      sortedCategories,
    };
  },
});
