






import { defineComponent, computed, watch, onMounted } from '@vue/composition-api';

import { MetaInfo } from 'vue-meta';

import useRoute from '@/packages/hooks/useRoute';

import { useRootStore } from '@/store/root';
import { useAuthStore } from '@/store/auth';

import useConfigMeta from '@/composables/meta/useConfigMeta';

import createRgbVarsForThemes from '@/utils/createRgbVarsForThemes';

export default defineComponent({
  metaInfo() {
    return this.meta as MetaInfo;
  },
  setup(props, context) {
    const route = useRoute(context);

    const rootStore = useRootStore();
    const authStore = useAuthStore();

    const { meta } = useConfigMeta(context);

    const userSignedIn = computed(() => authStore.getters.userSignedIn);

    const layout = computed(() => {
      const defaultLayout = 'default';

      return `${route.value.meta.layout || defaultLayout}-layout`;
    });

    watch(userSignedIn, async (val) => {
      if (!val) return;

      await rootStore.dispatch('INIT');
    }, { immediate: true });

    onMounted(() => {
      if (context?.parent?.$vuetify) createRgbVarsForThemes(context.parent.$vuetify.theme?.themes);
    });

    return {
      meta,
      layout,
    };
  },
});
