/* eslint-disable max-classes-per-file */

import { Store } from 'vuex';
import { Getters, Mutations, Actions, Context, Module, createMapper, createComposable } from 'vuex-smart-module';

import modally from '@/services/modally';

import campaignStore from './campaign';

import campaignEditorPlacementsStore from './campaignEditor/campaignEditorPlacements';
import campaignEditorGoalsStore from './campaignEditor/campaignEditorGoals';
import campaignEditorCategoriesStore from './campaignEditor/campaignEditorCategories';
import campaignEditorSettingsStore from './campaignEditor/campaignEditorSettings';
import campaignEditorPlacementDetailsStore from './campaignEditor/campaignEditorPlacementDetails';

interface CampaignEditors {
  placements: Context<typeof campaignEditorPlacementsStore>;
  goals: Context<typeof campaignEditorGoalsStore>;
  categories: Context<typeof campaignEditorCategoriesStore>;
  settings: Context<typeof campaignEditorSettingsStore>;
}

const initEditors = (store: Store<unknown>): CampaignEditors => ({
  placements: campaignEditorPlacementsStore.context(store),
  goals: campaignEditorGoalsStore.context(store),
  categories: campaignEditorCategoriesStore.context(store),
  settings: campaignEditorSettingsStore.context(store),
});

class ModuleState {
  isSaving = false;

  isCanceling = false;
}

class ModuleGetters extends Getters<ModuleState> {
  store!: Store<unknown>;

  campaign!: Context<typeof campaignStore>;

  editors!: CampaignEditors;

  $init(store: Store<unknown>) {
    this.store = store;

    this.campaign = campaignStore.context(store);

    this.editors = initEditors(store);
  }

  get hasBeenChanged() {
    const editorsHaveBeenChanged = Object.values(this.editors).some((editor) => editor.getters.hasBeenChanged);

    return !!this.campaign.getters.campaignHasBeenChanged || editorsHaveBeenChanged;
  }

  get isSaving() {
    return this.state.isSaving;
  }

  get isCanceling() {
    return this.state.isCanceling;
  }

  get error() {
    const editorWithError = Object.values(this.editors).find((editor) => editor.getters.error);

    return editorWithError?.getters?.error;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_SAVINGS(value: boolean) {
    this.state.isSaving = value;
  }

  SET_IS_CANCELING(value: boolean) {
    this.state.isCanceling = value;
  }

  RESET() {
    const defaultState = new ModuleState();

    Object.keys(defaultState).forEach((key) => {
      this.state[key] = defaultState[key];
    });
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  store!: Store<unknown>;

  campaign!: Context<typeof campaignStore>;

  editors!: CampaignEditors;

  $init(store: Store<unknown>) {
    this.store = store;

    this.campaign = campaignStore.context(store);

    this.editors = initEditors(store);
  }

  async FETCH(payload: { campaignId: string }) {
    const { campaignId } = payload;

    await this.campaign.dispatch('FETCH_CAMPAIGN', { campaignId });
  }

  SHOW_ERROR() {
    const { error } = this.getters;

    if (!error) return;

    modally.show({ title: error.title, content: error.message, status: 'error' });
  }

  async SAVE(payload?: { options?: { withConfirm: boolean } }) {
    const { campaign } = this.campaign.getters;
    const { hasBeenChanged } = this.getters;

    if (!campaign || !hasBeenChanged) return;

    const defaultOptions = {
      withConfirm: true,
    };

    const options = payload?.options ? { ...defaultOptions, ...payload.options } : defaultOptions;

    this.commit('SET_IS_SAVINGS', true);

    try {
      await this.campaign.dispatch('SAVE_CAMPAIGN');

      // TODO: Тут не используется Promise.all потому что при одновременном сохранении плана и размещений, некоторые измененные значения размещений не сохраняются
      // eslint-disable-next-line no-restricted-syntax
      for (const editor of Object.values(this.editors)) {
        // eslint-disable-next-line no-await-in-loop
        await editor.dispatch('SAVE', { campaignId: campaign.id, options });

        editor.commit('RESET_CHANGES');
      }
    } finally {
      this.dispatch('SHOW_ERROR');

      this.commit('SET_IS_SAVINGS', false);
    }
  }

  RESET_EDITORS_CHANGES() {
    Object.values(this.editors).forEach((editor) => editor.commit('RESET_CHANGES'));
  }
}

const module = new Module({
  modules: {
    placements: campaignEditorPlacementsStore,
    goals: campaignEditorGoalsStore,
    categories: campaignEditorCategoriesStore,
    settings: campaignEditorSettingsStore,
    placementDetails: campaignEditorPlacementDetailsStore,
  },
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const campaignEditorStoreMapper = createMapper(module);

export const useCampaignEditorStore = createComposable(module);

export default module;
