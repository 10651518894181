


































































import { defineComponent, toRefs, ref, PropType, watch, computed } from '@vue/composition-api';

import VueDraggable from 'vuedraggable';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { Placement } from '@/models/Placement';
import { CampaignCategory } from '@/models/Campaign';

import { useCampaignStore } from '@/store/campaign';

export default defineComponent({
  components: {
    VueDraggable,
    AppModal,
  },
  props: {
    value: { type: Boolean, default: false },
    campaignCategory: { type: Object as PropType<CampaignCategory>, required: true },
  },
  setup(props, context) {
    const {
      value,
      campaignCategory,
    } = toRefs(props);

    const campaignStore = useCampaignStore();

    const isLoading = ref(false);

    const placements = ref<Placement[]>([]);

    const placementsOrderHasBeenChanged = computed(() => !placements.value.some((placement, idx) => {
      const currentCampaignPlacement = campaignCategory.value.placements[idx];

      return placement.id !== currentCampaignPlacement?.id;
    }));

    watch(value, () => {
      placements.value = campaignCategory.value.placements;
    }, { immediate: true });

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (isLoading.value) return;

      if (placementsOrderHasBeenChanged.value) return close();

      isLoading.value = true;

      await campaignStore.dispatch('UPDATE_CAMPAIGN_PLACEMENTS_ORDER', {
        ...campaignCategory.value,
        placements: placements.value,
      });

      isLoading.value = false;

      close();
    };

    return {
      isLoading,
      placements,
      placementsOrderHasBeenChanged,
      save,
    };
  },
});
