












import { defineComponent, inject, ref, PropType, Ref } from '@vue/composition-api';

import BrandListItem from '@/components/_newDesign/Brands/BrandList/BrandListItem.vue';

import { Brand } from '@/models/Brand';

export default defineComponent({
  components: {
    BrandListItem,
  },
  props: {
    brands: { type: Array as PropType<Brand[]>, required: true },
  },
  setup() {
    const queryFilters = inject<Ref<{ brandId?: string }>>('queryFilters', ref({}));

    return {
      queryFilters,
    };
  },
});
