






























































import { defineComponent, toRefs, ref, computed, watch, PropType } from '@vue/composition-api';

import { UnapprovedCampaign } from '@/models/UnapprovedPlacements';

import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

import UnapprovedPlacementsTable from '../UnapprovedPlacementsTable/UnapprovedPlacementsTable.vue';

import { useUnapprovedPlacementsStore } from '../../store';

export default defineComponent({
  components: {
    TableLoader,
    UnapprovedPlacementsTable,
  },
  props: {
    value: { type: Boolean, default: false },
    campaign: { type: Object as PropType<UnapprovedCampaign>, required: true },
  },
  setup(props, context) {
    const { value, campaign } = toRefs(props);

    const unapprovedPlacementsStore = useUnapprovedPlacementsStore();

    const isOpen = ref(false);
    const isLoading = ref(false);

    const placements = computed(() => unapprovedPlacementsStore.getters.getCampaignPlacements(campaign.value.id));
    const campaignEditorPath = computed(() => `/campaigns/${campaign.value.id}/edit`);
    const isShown = computed(() => isOpen.value && !isLoading.value);

    const fetch = async () => {
      isLoading.value = true;

      try {
        await unapprovedPlacementsStore.dispatch('FETCH_PLACEMENTS_BY_CAMPAIGN', campaign.value.id);
      } finally {
        isLoading.value = false;
      }
    };

    watch(value, (val: boolean) => {
      if (isOpen.value === val) return;

      fetch();

      isOpen.value = val;
    }, { immediate: true });

    watch(isOpen, (val: boolean) => {
      if (value.value === val) return;

      context.emit('input', val);
    });

    const open = async () => {
      if (!isOpen.value) return;

      await fetch();
    };

    const toggle = () => {
      if (isLoading.value) return;

      isOpen.value = !isOpen.value;

      open();
    };

    return {
      placements,
      campaignEditorPath,
      isShown,
      isOpen,
      isLoading,
      toggle,
    };
  },
});
