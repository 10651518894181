

















































































































import { defineComponent, toRefs, ref, computed, watch, PropType } from '@vue/composition-api';

import { DSelect } from '@shared/druid-kit';

import { JsonEditor } from '@/packages/json-editor/src';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { GoalsSettingsApi } from '@/api';

import { Campaign } from '@/models/Campaign';
import { GoalDataProvider } from '@/models/Goal';
import { ProviderSettingsLight } from '@/models/ProviderSettings';

import { useCampaignEditorGoalsStore } from '@/store/campaignEditor/campaignEditorGoals';

export default defineComponent({
  components: {
    DSelect,
    JsonEditor,
    AppModal,
  },
  props: {
    value: { type: Boolean, default: false },
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props, context) {
    const {
      value,
      campaign,
    } = toRefs(props);

    const campaignEditorGoalsStore = useCampaignEditorGoalsStore();

    const isLoading = ref(false);
    const schemaIsLoading = ref(false);

    const currentDataProvider = ref<GoalDataProvider | null>(null);
    const currentDataProviderSettings = ref<ProviderSettingsLight | null>(null);

    const currentSettings = ref<{ goals: string[] } | null>(null);
    const currentSettingsErrors = ref<string[]>([]);

    const schema = ref<Record<string, unknown> | null>(null);
    const schemaErrors = ref<string[]>([]);

    const dataProviders = computed(() => campaignEditorGoalsStore.getters.dataProviders);

    const dataProviderSettings = computed(() => {
      if (!currentDataProvider.value) return [];

      return campaignEditorGoalsStore
        .getters
        .dataProviderSettings
        .filter(({ providerId }) => providerId === currentDataProvider.value?.id);
    });

    const saveBtnIsDisabled = computed(() => schemaIsLoading.value || !!currentSettingsErrors.value.length || !currentSettings.value?.goals.length);

    const fetch = async () => {
      isLoading.value = true;

      try {
        await Promise.all([
          campaignEditorGoalsStore.dispatch('FETCH_DATA_PROVIDERS'),
          campaignEditorGoalsStore.dispatch('FETCH_DATA_PROVIDER_SETTINGS', { campaignId: campaign.value.id }),
        ]);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchSchema = async (payload?: string) => {
      if (!currentDataProvider.value || !currentDataProviderSettings.value) return;

      schemaIsLoading.value = true;

      schemaErrors.value = [];
      currentSettings.value = null;

      try {
        const res = await GoalsSettingsApi.fetchSchema({
          campaignId: campaign.value.id,
          providerId: currentDataProvider.value.id,
          providerSettingsId: currentDataProviderSettings.value.id,
          settings: payload || '',
        });

        schema.value = res.data.schema ? JSON.parse(res.data.schema) : null;
        schemaErrors.value = res.data.errors;
      } finally {
        schemaIsLoading.value = false;
      }
    };

    const onCurrnetSettigsAutoPostBack = (payload: Record<string, unknown>) => {
      if (schemaIsLoading.value) return;

      fetchSchema(JSON.stringify(payload));
    };

    const onCurrentSettingsValidate = (payload: string[]) => {
      currentSettingsErrors.value = payload;
    };

    watch(value, (val) => {
      if (val) {
        fetch();
      } else {
        currentDataProvider.value = null;
      }
    });

    watch(currentDataProvider, () => {
      currentDataProviderSettings.value = null;
    });

    watch(currentDataProviderSettings, (val) => {
      if (val) {
        fetchSchema();
      } else {
        schema.value = null;
        schemaErrors.value = [];
        currentSettings.value = null;
      }
    });

    const close = () => {
      context.emit('input', false);
    };

    const save = () => {
      if (!currentDataProvider.value || !currentDataProviderSettings.value || !currentSettings.value) return;

      const providerId = currentDataProvider.value.id;
      const providerName = currentDataProvider.value.name;
      const providerSettingsId = currentDataProviderSettings.value.id;
      const providerSettingsName = currentDataProviderSettings.value.name;

      const goals = currentSettings.value.goals.map((settingGoalAsString) => {
        const rawSettingGoal = JSON.parse(settingGoalAsString) as { Id: string; Name: string };

        const settingGoal = Object.entries(rawSettingGoal).reduce((newObj, [key, val]) => {
          // настройки могут прийти с ключами в разном регистре
          newObj[key.toLowerCase()] = val;

          return newObj;
        }, {} as { id: string; name: string; });

        const goal = {
          ...settingGoal,
          providerId,
          providerName,
          providerSettingsId,
          providerSettingsName,
          mainGoal: false,
        };

        return goal;
      });

      campaignEditorGoalsStore.dispatch('CHANGE_GOALS', goals);

      context.emit('added', goals);

      close();
    };

    return {
      isLoading,
      schemaIsLoading,
      dataProviders,
      currentDataProvider,
      dataProviderSettings,
      currentDataProviderSettings,
      schema,
      schemaErrors,
      currentSettings,
      currentSettingsErrors,
      saveBtnIsDisabled,
      onCurrnetSettigsAutoPostBack,
      onCurrentSettingsValidate,
      save,
    };
  },
});
