import { AxiosResponse } from 'axios';

import { PartialWithout } from '@/utils/type';

import { DataProvidersSettings, DataProvidersSettingsItem, DataProvidersSettingsToken } from '@/models/DataProvidersSettings';

import { Client } from '@/models/Client';

import { Provider } from '@/models/ProviderSettings';

import httpClient from '../httpClient';

interface FetchDataProvidersSettingsPayload {
  params?: {
    filter: string;
    page: string | number;
    failedOnly: boolean;
  };
}

interface FetchDataProvidersSettingsResponse {
  list: DataProvidersSettings[];
  totalRows: number;
}

interface FetchTokensPayload {
  params?: {
    id: string;
  };
}

interface FetchStatisticsCollectionDepthsPayload {
  params?: {
    id: string;
  };
}

interface GatherStatisticsPayload {
  settingsId: string;
}

export default class DataProvidersSettingsApi {
  public static async fetchDataProvidersSettings(payload: FetchDataProvidersSettingsPayload): Promise<AxiosResponse<FetchDataProvidersSettingsResponse>> {
    const res = await httpClient.get<FetchDataProvidersSettingsResponse>('/api/dataproviderssettings', payload);

    return res;
  }

  public static async fetchProviderStatus(providerId: string): Promise<AxiosResponse<boolean>> {
    const res = await httpClient.get<boolean>(`/api/dataproviderssettings/status/${providerId}`);

    return res;
  }

  public static async fetchProviders(): Promise<AxiosResponse<Provider[]>> {
    const res = await httpClient.get<Provider[]>('/api/dataproviderssettings/providers');

    return res;
  }

  public static async fetchProvider(providerId: string): Promise<AxiosResponse<DataProvidersSettingsItem>> {
    const res = await httpClient.get<DataProvidersSettingsItem>(`/api/dataproviderssettings/${providerId}`);

    return res;
  }

  public static async fetchClients(): Promise<AxiosResponse<Client[]>> {
    const res = await httpClient.get<Client[]>('/api/dataproviderssettings/clients');

    return res;
  }

  public static async fetchTokens(payload: FetchTokensPayload): Promise<AxiosResponse<DataProvidersSettingsToken[]>> {
    const res = await httpClient.get<DataProvidersSettingsToken[]>('/api/dataproviderssettings/tokens', payload);

    return res;
  }

  public static async fetchStatisticsCollectionDepths(payload: FetchStatisticsCollectionDepthsPayload): Promise<AxiosResponse<string[]>> {
    const res = await httpClient.get<string[]>('/api/dataproviderssettings/statisticsCollectionDepths', payload);

    return res;
  }

  public static async gatherStatistics(payload: GatherStatisticsPayload): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/dataproviderssettings/gather', payload);

    return res;
  }

  public static async setProviderSettings(payload: PartialWithout<DataProvidersSettingsItem, 'id'>): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/dataproviderssettings', payload);

    return res;
  }

  public static async deleteProviderSettings(providerId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.delete<''>(`/api/dataproviderssettings/${providerId}`);

    return res;
  }
}
