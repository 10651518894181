









































import { defineComponent, inject, computed } from '@vue/composition-api';

import store from '@/store';
import navigationUnapprovedPlacements from '@/store/navigation/navigationUnapprovedPlacements';

const navigationUnapprovedPlacementsContext = navigationUnapprovedPlacements.context(store);

export default defineComponent({
  setup() {
    const unapprovedPlacementsCount = computed(() => navigationUnapprovedPlacementsContext.getters.unapprovedPlacementsCount);

    const isActive = inject('appSideBarMenuItemIsActive', false);

    const limit = 1000;
    const width = 56;
    const height = 24;

    const widthMax = width;
    const widthMin = 26;

    const charWidth = width / 3;

    const text = computed(() => (unapprovedPlacementsCount.value >= limit ? '999+' : unapprovedPlacementsCount.value.toString()));

    const rectWidth = computed(() => {
      const val = [
        widthMin,
        36,
        46,
        widthMax,
      ][text.value.length - 1] || text.value.length * charWidth;

      if (val > widthMax) return widthMax;

      if (val < widthMin) return widthMin;

      return val - 2;
    });

    const rectHeight = height;
    const rectX = computed(() => ((width - rectWidth.value) / 2));
    const rectRX = rectHeight / 2;
    const rectRY = rectHeight / 2;

    return {
      isActive,
      text,
      width,
      height,
      rectWidth,
      rectHeight,
      rectX,
      rectRX,
      rectRY,
    };
  },
});
