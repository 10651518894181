import { AppRouteConfig } from '@/models/AppRouter';

import Markups from '../views/Markups.vue';

import MarkupsCatalog from '../views/MarkupsCatalog.vue';
import MarkupsCatalogLoader from '../components/MarkupsCatalog/MarkupsCatalogLoader.vue';

import MarkupDetails from '../views/MarkupDetails.vue';
import MarkupDetailsLoader from '../components/MarkupDetails/MarkupDetailsLoader.vue';

const routes: AppRouteConfig[] = [
  {
    path: '/markups',
    component: Markups,
    meta: {
      group: 'main',
      title: 'Наценки',
      icon: 'mdi-currency-rub',
      order: 3,
      permission: 'markups_edit',
    },
    children: [
      {
        name: 'markups',
        path: '',
        meta: {
          loader: MarkupsCatalogLoader,
          header: {
            sticky: true,
          },
        },
        component: MarkupsCatalog,
      },
      {
        name: 'markups-by-placement',
        path: 'placement/:placementId',
        meta: {
          loader: MarkupsCatalogLoader,
        },
        props: true,
        component: MarkupsCatalog,
      },
      {
        name: 'markup',
        path: ':markupId',
        meta: {
          loader: MarkupDetailsLoader,
        },
        props: true,
        component: MarkupDetails,
      },
    ],
  },
];

export default routes;
