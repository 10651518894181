

























import { defineComponent, toRefs, computed } from '@vue/composition-api';
import { Route, NavigationGuardNext } from 'vue-router';

import useRouter from '@/packages/hooks/useRouter';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignEditorHeader from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorHeader.vue';
import CampaignEditorContent from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorContent.vue';
import CampaignEditorFooter from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorFooter.vue';

import useMeta from '@/composables/meta/useMeta';
import { useLayoutLoadingStatus } from '@/composables/layout';

import { useCampaignEditorStore } from '@/store/campaignEditor';
import { useCampaignStore } from '@/store/campaign';

import modally from '@/services/modally';

export default defineComponent({
  components: {
    AppView,
    CampaignEditorHeader,
    CampaignEditorContent,
    CampaignEditorFooter,
  },
  async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
    next(async (vm) => {
      await vm.$store.dispatch('campaignEditor/RESET_EDITORS_CHANGES');
    });
  },
  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
    const customNext = async () => {
      await this.$store.dispatch('campaignEditor/RESET_EDITORS_CHANGES');

      next();
    };

    if (!this.campaignHasBeenChanged) return customNext();

    if (this.isSaving) {
      const result = await modally.show({
        type: 'confirm',
        status: 'warning',
        title: 'Идет сохранение. Выйти из редактирования?',
      });

      if (!result) return;

      customNext();
    } else if (!this.isCanceling) {
      await modally.show({
        status: 'warning',
        title: 'Есть несохраненные данные',
        content: 'Изменения в настройках кампании будут сброшены',
        buttons: [
          {
            props: {
              text: 'Выйти без сохранения',
              color: 'warning',
              outlined: true,
            },
            action: customNext,
          },
          {
            props: {
              text: 'Сохранить и выйти',
              color: 'primary',
              outlined: true,
            },
            action: async () => {
              await this.save({ withConfirm: false });

              customNext();
            },
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary-gradient',
            },
            action: () => { /* empty */ },
          },
        ],
      });
    } else {
      customNext();
    }
  },
  props: {
    campaignId: { type: String, required: true },
  },
  setup(props, context) {
    const { campaignId } = toRefs(props);

    const router = useRouter(context);

    const campaignStore = useCampaignStore();
    const campaignEditorStore = useCampaignEditorStore();

    const {
      isLoading,
      updateIsLoading,
    } = useLayoutLoadingStatus();

    const isSaving = computed(() => campaignEditorStore.getters.isSaving);
    const isCanceling = computed(() => campaignEditorStore.getters.isCanceling);

    const campaign = computed(() => campaignStore.getters.campaign);
    const campaignPath = computed(() => `/campaigns/${campaign.value?.id}`);
    const campaignHasBeenChanged = computed(() => campaignEditorStore.getters.hasBeenChanged);

    const pageMeta = computed(() => ({
      title: campaign.value
        ? `${campaign.value.brandName} • ${campaign.value.name} • Настройки кампании`
        : 'Настройки кампании',
    }));

    useMeta(pageMeta);

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await campaignEditorStore.dispatch('FETCH', { campaignId: campaignId.value });
      } finally {
        updateIsLoading(false);
      }
    };

    const save = async (options: { withConfirm: boolean }) => {
      await campaignEditorStore.dispatch('SAVE', { options });
    };

    const cancel = async () => {
      const redirectCallback = () => {
        campaignEditorStore.commit('SET_IS_CANCELING', false);
      };

      const redirect = () => {
        router.push(campaignPath.value, redirectCallback, redirectCallback);
      };

      campaignEditorStore.commit('SET_IS_CANCELING', true);

      if (isSaving.value || !campaignHasBeenChanged.value) return redirect();

      const result = await modally.show<boolean>({
        status: 'warning',
        title: 'Есть несохраненные данные',
        content: 'Изменения в настройках кампании будут сброшены',
        buttons: [
          {
            props: {
              text: 'Выйти без сохранения',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary-gradient',
            },
            action: () => false,
          },
        ],
      });

      if (!result) {
        campaignEditorStore.commit('SET_IS_CANCELING', false);

        return;
      }

      redirect();
    };

    fetch();

    return {
      isLoading,
      isSaving,
      isCanceling,
      campaign,
      campaignHasBeenChanged,
      save,
      cancel,
    };
  },
});
