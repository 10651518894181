























































import Vue from 'vue';
import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTableCellSpacer,
} from '@shared/druid-kit';

import MetricsTableRow from '@/components/_newDesign/common/MetricsTable/MetricsTableRow.vue';

import { Campaign, CampaignCategory } from '@/models/Campaign';

export default defineComponent({
  components: {
    DTableCell,
    DTableCellSpacer,
    MetricsTableRow,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    campaignCategory: { type: Object as PropType<CampaignCategory>, required: true },
    metricCells: { type: Array as PropType<{ metric: { name: string }; planInsteadProgress: boolean; onlyFact: boolean }[]>, default: () => [] },
    expanded: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      campaign,
      campaignCategory,
      expanded,
    } = toRefs(props);

    const activePlacements = computed(() => campaignCategory.value.placements.filter((placement) => placement.state.code !== 'finished'));

    const activePlacementsCount = computed(() => activePlacements.value.length);

    const categoryPath = computed(() => `/campaigns/${campaign.value.id}/category/${campaignCategory.value.id}`);

    const openCategoryPlacementsOrderModal = () => {
      context.emit('open-modal', { slug: 'placementsOrder', props: { campaignCategory: campaignCategory.value } });
    };

    const onFirstCellClick = () => {
      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Campaign',
        event_action: 'Unroll service type',
        event_label: campaignCategory.value.name,
        campaign_id: campaign.value.id,
        d1d_client_id: campaign.value.clientId,
        d1d_client_name: campaign.value.clientName,
        d1d_brand_id: campaign.value.brandId,
        d1d_brand_name: campaign.value.brandName,
        d1d_campaign_id: campaign.value.id,
        d1d_campaign_name: campaign.value.name,
      });

      context.emit('expand', !expanded.value);
    };

    return {
      activePlacementsCount,
      categoryPath,
      openCategoryPlacementsOrderModal,
      onFirstCellClick,
    };
  },
});
