import { AxiosResponse } from 'axios';

import { UserLight, UserClientsBrands } from '@/models/User';

import httpClient from '../httpClient';

interface FetchUsersPayload {
  page?: number;
  limit?: number;
  filter?: string;
}

interface FetchUsersResponse {
  list: UserLight[];
  totalRows: number;
}

export interface SetExistingUsersPayload {
  emails: string[];
  roles: string[];
  groups: string[];
  clients: UserClientsBrands[];
}

interface SetExistingUsersResponse {
  addedUsers: unknown[]; // TODO: Описать тип
  notFoundEmails: string[];
}

export interface SetUserPayload {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  groups: string[];
  clients: UserClientsBrands[];
}

export interface UpdateUserPayload {
  userId: string;
  data: SetUserPayload;
}

export default class UsersApi {
  public static async fetchUsers(payload: FetchUsersPayload): Promise<AxiosResponse<FetchUsersResponse>> {
    const res = await httpClient.post<FetchUsersResponse>('/api/users/find', payload);

    return res;
  }

  public static async setUser(payload: SetUserPayload): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/users', payload);

    return res;
  }

  public static async setExistingUsers(payload: SetExistingUsersPayload): Promise<AxiosResponse<SetExistingUsersResponse>> {
    const res = await httpClient.post<SetExistingUsersResponse>('/api/users/addExistingUsers', payload);

    return res;
  }

  public static async updateUser(payload: UpdateUserPayload): Promise<AxiosResponse<''>> {
    const { userId, data } = payload;

    const res = await httpClient.put<''>(`/api/users/${userId}`, data);

    return res;
  }

  public static async fetchUser(userId: string): Promise<AxiosResponse<UserLight>> {
    const res = await httpClient.get<UserLight>(`/api/users/${userId}`);

    return res;
  }

  public static async deleteUser(userId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.delete<''>(`/api/users/${userId}`);

    return res;
  }
}
