














import { defineComponent, PropType, toRefs, ref, computed, nextTick } from '@vue/composition-api';

import goTo from 'vuetify/es5/services/goto';

import { cloneDeep } from 'lodash';
import moment from 'moment';
import Handsontable from 'handsontable';

import { HnTable } from '@/packages/hn-table/src/components';
import { HnTableColumn } from '@/packages/hn-table/src/models';

import { FlightPlan } from '@/models/Flight';
import { MetricTypeLight } from '@/models/MetricType';
import { CampaignEditorPlacementDetails } from '@/models/Campaign';

import store from '@/store';
import metricTypesStore from '@/store/reference/metricTypes';

import FlightsTableNumberCell from './cells/FlightsTableNumberCell.vue';
import FlightsTableDeleteCell from './cells/FlightsTableDeleteCell.vue';

import placementFlightsTableEmitter from './placementFlightsTableEmitter';

const metricTypesStoreContext = metricTypesStore.context(store);

let H_TABLE_INSTANCE: Handsontable | null = null;

export default defineComponent({
  components: {
    HnTable,
  },
  props: {
    currentPlacement: { type: Object as PropType<CampaignEditorPlacementDetails>, required: true },
    placementPlanMetrics: { type: Array as PropType<MetricTypeLight[]>, required: true },
    placementHasBeenChanged: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      currentPlacement,
      placementPlanMetrics,
    } = toRefs(props);

    const placementFlights = computed(() => currentPlacement.value.flights);

    const maxFlightPlanDate = computed(() => {
      const flightPlansEndDates = placementFlights.value.map((flightPlan) => moment(flightPlan.endDate));

      return moment.utc(moment.max(flightPlansEndDates)).format('YYYY-MM-DDTHH:mm:ss[Z]');
    });

    const getHnTableSourceData = () => {
      const localHnTableInstance = H_TABLE_INSTANCE;

      const sourceData = cloneDeep(localHnTableInstance?.getSourceData()) as FlightPlan[];

      return sourceData;
    };

    const generatePlacementPlanUpdate = (payload: Partial<CampaignEditorPlacementDetails>) => {
      if (payload.flights) return { ...currentPlacement.value, ...payload };

      const flights = getHnTableSourceData();

      return { ...currentPlacement.value, ...payload, flights };
    };

    const change = (payload: CampaignEditorPlacementDetails) => {
      context.emit('change', payload);
    };

    const addFlightPlan = async () => {
      const flightPlan = {
        startDate: moment.utc(maxFlightPlanDate.value)
          .endOf('month')
          .add(1, 'day')
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        endDate: moment.utc(maxFlightPlanDate.value)
          .endOf('month')
          .add(1, 'month')
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        vat: currentPlacement.value.vat,
        planMetrics: {},
      };

      const sourceData = getHnTableSourceData();
      const flights = [...sourceData, flightPlan];
      const newPlacementPlan = generatePlacementPlanUpdate({ flights, getPlanFrom1C: false });

      change(newPlacementPlan);

      await nextTick();

      goTo(document.documentElement.scrollHeight);
    };

    const deleteFlightPlan = (row: number) => {
      if (placementFlights.value.length < 2) return;

      const sourceData = getHnTableSourceData();
      const flights = sourceData.filter((flightPlan, idx) => idx !== row);
      const newPlacementPlan = generatePlacementPlanUpdate({ flights, getPlanFrom1C: false });

      change(newPlacementPlan);
    };

    const afterChange = (changes: Handsontable.CellChange[], source: Handsontable.ChangeSource) => {
      if (source === 'loadData') return;

      const flights = getHnTableSourceData();
      const newPlacementPlan = generatePlacementPlanUpdate({ flights, getPlanFrom1C: false });

      change(newPlacementPlan);
    };

    const hnTableColumns = computed<HnTableColumn[]>(() => [
      new HnTableColumn({
        title: '№',
        data: '__row_number__',
        settings: {
          width: 54,
          readOnly: true,
        },
        rendererComponent: FlightsTableNumberCell,
      }),
      new HnTableColumn({
        title: 'Старт',
        data: 'startDate',
        header: {
          className: 'htRight',
        },
        settings: {
          type: 'date',
          width: 110,
          validator: { $lte: 'endDate' },
        },
        props: {
          textAlign: 'right',
        },
      }),
      new HnTableColumn({
        title: '',
        data: '__empty__',
        settings: {
          type: 'stub',
          width: 30,
        },
        props: {
          text: '—',
        },
      }),
      new HnTableColumn({
        title: 'Конец',
        data: 'endDate',
        header: {
          className: 'htLeft',
        },
        settings: {
          type: 'date',
          width: 110,
          validator: { $gte: 'startDate' },
        },
        props: {
          textAlign: 'left',
        },
      }),
      new HnTableColumn({
        title: 'НДС',
        data: 'vat',
        header: {
          className: 'htRight',
        },
        settings: {
          type: 'numeric',
          width: 54,
          numericFormat: {
            pattern: '0{%}',
          },
        },
      }),
      ...placementPlanMetrics.value.map((planMetric) => {
        const metricTypeAccuracy = metricTypesStoreContext.getters.getMetricTypeProp(planMetric.name, 'accuracy') || 0;

        return new HnTableColumn({
          title: planMetric.displayName,
          data: `planMetrics.${planMetric.name}`,
          header: {
            className: 'htRight',
          },
          settings: {
            type: 'numeric',
            width: 120,
            className: 'htRight',
            placeholder: '—',
            allowInvalid: false,
            allowEmpty: true,
            numericFormat: {
              pattern: {
                mantissa: metricTypeAccuracy,
              },
            },
          },
        });
      }),
      new HnTableColumn({
        title: '',
        data: '',
        settings: {
          width: 64,
          readOnly: true,
        },
        props: {
          emitter: placementFlightsTableEmitter,
          placementPlanFlightsCount: placementFlights.value.length,
        },
        rendererComponent: FlightsTableDeleteCell,
      }),
    ]);

    const hnTableSettings = ref({
      afterChange,
    });

    const onHnTableInitialized = (hotInstance: Handsontable) => {
      H_TABLE_INSTANCE = hotInstance;
    };

    placementFlightsTableEmitter.on('addFlightPlan', () => {
      addFlightPlan();
    });

    placementFlightsTableEmitter.on('deleteFlightPlan', ({ row }) => {
      deleteFlightPlan(row);
    });

    return {
      placementFlights,
      hnTableColumns,
      hnTableSettings,
      onHnTableInitialized,
    };
  },
});
