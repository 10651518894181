import { AxiosResponse } from 'axios';

import {
  CampaignPlan,
  CampaignSettings,
  CampaignPlanMetrics,
  CampaignPlanToBackend,
  CampaignEditorPlacement,
  CampaignEditorPlacementDetails,
} from '@/models/Campaign';

import { PlacementPlan, PlacementPlanToBackend } from '@/models/Placement';

import httpClient from '../httpClient';

interface UpdatePlacementsPayload {
  campaignId: string;
  data: { placements: CampaignEditorPlacement[] }; // TODO: нужно изменить структуру тела запроса
}

interface fetchPlacementPayload {
  campaignId: string;
  placementDanboId: string;
  params?: {
    use1CPlan : boolean;
  };
}

interface UpdatePlacementPayload {
  campaignId: string;
  data: { placement: CampaignEditorPlacementDetails };
}

export default class CampaignEditorApi {
  public static async fetchCampaignPlan(campaignId: string): Promise<AxiosResponse<CampaignPlan>> {
    const res = await httpClient.get<CampaignPlan>(`/api/campaigneditor/${campaignId}/plan`);

    return res;
  }

  public static async fetchCampaignSettings(campaignId: string): Promise<AxiosResponse<CampaignSettings>> {
    const res = await httpClient.get<CampaignSettings>(`/api/campaigneditor/${campaignId}/settings`);

    return res;
  }

  public static async fetchPlacements(campaignId: string): Promise<AxiosResponse<CampaignEditorPlacement[]>> {
    const res = await httpClient.get<CampaignEditorPlacement[]>(`/api/campaign-editor/${campaignId}/placements`);

    return res;
  }

  public static async fetchPlacement(payload: fetchPlacementPayload): Promise<AxiosResponse<CampaignEditorPlacementDetails>> {
    const { campaignId, placementDanboId, params } = payload;

    const res = await httpClient.get<CampaignEditorPlacementDetails>(`/api/campaign-editor/${campaignId}/placements/${placementDanboId}`, { params });

    return res;
  }

  public static async fetchCampaignPlanMetrics(): Promise<AxiosResponse<CampaignPlanMetrics>> {
    const res = await httpClient.get<CampaignPlanMetrics>('/api/campaigneditor/planmetrics');

    return res;
  }

  public static async fetchPlacementPlanFrom1c(payload: { campaignId: string; placementDanboId: string}): Promise<AxiosResponse<PlacementPlan>> {
    const { campaignId, placementDanboId } = payload;

    const res = await httpClient.get<PlacementPlan>(`/api/campaigneditor/${campaignId}/plan/${placementDanboId}/from1cPlacements`);

    return res;
  }

  public static async saveCampaignPlan(payload: { campaignId: string; data: CampaignPlanToBackend }): Promise<AxiosResponse<CampaignPlan>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<CampaignPlan>(`/api/campaigneditor/${campaignId}/updatePlacementPlans`, data);

    return res;
  }

  public static async savePlacementPlan(payload: { campaignId: string; data: PlacementPlanToBackend }): Promise<AxiosResponse<PlacementPlan>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<PlacementPlan>(`/api/campaigneditor/${campaignId}/updatePlacementPlanByFlights`, data);

    return res;
  }

  public static async updatePlacements(payload: UpdatePlacementsPayload): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaign-editor/${campaignId}/placements`, data);

    return res;
  }

  public static async updatePlacement(payload: UpdatePlacementPayload): Promise<AxiosResponse<''>> {
    const { campaignId, data } = payload;

    const res = await httpClient.post<''>(`/api/campaign-editor/${campaignId}/placement`, data);

    return res;
  }

  public static async uploadFlightsPlan(payload: { campaignId: string; data: FormData }): Promise<AxiosResponse<CampaignPlan>> {
    const { campaignId, data } = payload;
    const res = await httpClient.post<CampaignPlan>(`/api/campaigneditor/${campaignId}/UploadFlightsPlan`, data);

    return res;
  }

  public static async uploadPlacementPlan(payload: { campaignId: string; data: FormData }): Promise<AxiosResponse<CampaignPlan>> {
    const { campaignId, data } = payload;
    const res = await httpClient.post<CampaignPlan>(`/api/campaigneditor/${campaignId}/UploadPlacementPlan`, data);

    return res;
  }
}
