

































import { defineComponent, computed, PropType, toRefs } from '@vue/composition-api';

import moment from 'moment';
import _ from 'lodash';

import CampaignMetrics from '@/components/_newDesign/Campaigns/CampaignMetrics/CampaignMetrics.vue';
import CampaignChart from '@/components/_newDesign/Campaigns/CampaignChart.vue';
import CampaignPlacementsTable from '@/components/_newDesign/Campaigns/CampaignPlacementsTable/CampaignPlacementsTable.vue';

import CampaignDateFilterBtn from '@/components/_newDesign/Campaigns/buttons/CampaignDateFilterBtn.vue';

import { Campaign, CampaignCategory } from '@/models/Campaign';

export default defineComponent({
  components: {
    CampaignMetrics,
    CampaignChart,
    CampaignPlacementsTable,
    CampaignDateFilterBtn,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    category: { type: Object as PropType<CampaignCategory>, required: true },
  },
  setup(props) {
    const {
      campaign,
      category,
    } = toRefs(props);

    const metricsData = computed(() => ({
      ...category.value,
      startDate: campaign.value.startDate,
      endDate: campaign.value.endDate,
    }));

    const categoryPlacementsLastUpdate = computed(() => {
      if (category.value) {
        const lastModifiedItem = _(category.value.placements)
          .filter((placement) => !!placement.metricsUpdatedAt)
          .maxBy((placement) => moment(placement.metricsUpdatedAt));

        if (lastModifiedItem && lastModifiedItem.metricsUpdatedAt) {
          return moment(lastModifiedItem.metricsUpdatedAt).format(
            'DD.MM.YYYY HH:mm',
          );
        }
      }

      return '';
    });

    return {
      categoryPlacementsLastUpdate,
      metricsData,
    };
  },
});
