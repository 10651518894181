

















import { defineComponent, PropType } from '@vue/composition-api';

import { DTable } from '@shared/druid-kit';

import { Category } from '@/models/Category';

import CategoriesCatalogTableRow from './CategoriesCatalogTableRow.vue';

export default defineComponent({
  components: {
    DTable,
    CategoriesCatalogTableRow,
  },
  props: {
    categories: { type: Array as PropType<Category[]>, default: () => [] },
  },
  setup() {
    const headers = ['Название', 'Порядок', 'Отображаемые метрики', '']
      .map((text) => ({
        text,
        class: 'caption',
        sortable: false,
      }));

    return {
      headers,
    };
  },
});
