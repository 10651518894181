


























import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { MarkupLight } from '@/models/Markup';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    markup: { type: Object as PropType<MarkupLight>, required: true },
  },
  setup(props) {
    const { markup } = toRefs(props);

    const pathToMarkup = computed(() => `/markups/${markup.value.id}`);

    return {
      pathToMarkup,
    };
  },
});
