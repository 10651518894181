

























import { defineComponent, toRefs, ref, computed, ComponentInstance } from '@vue/composition-api';

import { Category } from '@/models/Category';

import useRouter from '@/packages/hooks/useRouter';

import { useLayoutLoadingStatus } from '@/composables/layout';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useCategoriesCatalogStore } from '../../store/categories/categoriesCatalog';
import { useCategoryDetailsStore } from '../../store/categories/categoryDetails';

import CategoryDetailsHeader from '../../components/Categories/CategoryDetails/CategoryDetailsHeader.vue';
import CategoriesItemForm from '../../components/Categories/forms/CategoriesItemForm.vue';
import CategoryDetailsFooter from '../../components/Categories/CategoryDetails/CategoryDetailsFooter.vue';

export default defineComponent({
  components: {
    AppView,
    CategoryDetailsHeader,
    CategoriesItemForm,
    CategoryDetailsFooter,
  },
  props: {
    categoryId: { type: String, required: true },
  },
  setup(props, context) {
    const { categoryId } = toRefs(props);

    const categoriesCatalogStore = useCategoriesCatalogStore();
    const categoryDetailsStore = useCategoryDetailsStore();

    const router = useRouter(context);

    const { updateIsLoading } = useLayoutLoadingStatus();

    const categoryFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const categoryFormData = ref<Category | null>(null);
    const categoryFormIsValid = ref(false);

    const category = computed(() => categoryDetailsStore.getters.category);
    const categoryIsLoading = computed(() => categoryDetailsStore.getters.isLoading);

    const newCategory = computed(() => {
      if (!categoryFormData.value || !categoryFormIsValid.value) return null;

      return categoryFormData.value;
    });

    const saving = ref(false);
    const categoryHasBeenChanged = computed(() => !newCategory.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const cancel = () => {
      router.push('./');
    };

    const save = async () => {
      if (!newCategory.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await categoriesCatalogStore.actions.UPDATE_CATEGORY({ category: newCategory.value });

        cancel();
      } finally {
        saving.value = false;
      }
    };

    const init = async () => {
      if (!categoryId.value) return;

      updateIsLoading(true);

      try {
        await categoryDetailsStore.actions.FETCH_CATEGORY(categoryId.value);

        categoryFormData.value = category.value;
      } finally {
        updateIsLoading(false);
      }
    };

    init();

    return {
      categoryFormRef,
      categoryFormData,
      categoryFormIsValid,
      category,
      categoryHasBeenChanged,
      saving,
      closeBtnIsDisabled,
      categoryIsLoading,
      saveError,
      save,
      cancel,
    };
  },
});
