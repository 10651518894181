














































































































import { defineComponent, toRefs, ref, computed } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import AppView from '@/components/_newDesign/common/AppView.vue';

import campaignEditorPlacementsEmitter from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorPlacements/campaignEditorPlacementsEmitter';

import CampaignPlacementsTable from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorPlacements/CampaignPlacementsTable/CampaignPlacementsTable.vue';
import CampaignEditorTableLoader from '@/components/_newDesign/Campaigns/CampaignEditor/loaders/CampaignEditorTableLoader.vue';
import CampaignPlacementsMetricsModal from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorPlacements/modals/CampaignPlacementsMetricsModal.vue';
import CampaignPlacementsMarkupsModal from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorPlacements/modals/CampaignPlacementsMarkupsModal.vue';

import { CampaignEditorPlacement } from '@/models/Campaign';

import { useCampaignStore } from '@/store/campaign';

import { useCampaignEditorPlacementsStore } from '@/store/campaignEditor/campaignEditorPlacements';

import useModals from '@/composables/useModals';

// eslint-disable-next-line no-shadow
enum ColumnPresetSlugs {
  Settings = 'settings',
  Targetings = 'targetings',
  PlanMetrics = 'planMetrics',
  FactMetrics = 'factMetrics',
}

type ColumnPresetSlug = (typeof ColumnPresetSlugs)[keyof typeof ColumnPresetSlugs];

export default defineComponent({
  components: {
    DBtnInline,
    AppView,
    CampaignPlacementsTable,
    CampaignEditorTableLoader,
    CampaignPlacementsMetricsModal,
    CampaignPlacementsMarkupsModal,
  },
  props: {
    campaignId: { type: String, required: true },
  },
  setup(props) {
    const { campaignId } = toRefs(props);

    const campaignStore = useCampaignStore();
    const campaignEditorPlacementsStore = useCampaignEditorPlacementsStore();

    const isLoading = ref(false);

    const {
      modalsMap,
      openModal,
    } = useModals(['metrics', 'markups']);

    const currentColumnsPresetsSlugs = ref<ColumnPresetSlug[]>([
      ColumnPresetSlugs.Settings,
    ]);

    const onChangeCurrentColumnsPresetsSlugs = (val: ColumnPresetSlug[]) => {
      currentColumnsPresetsSlugs.value = val;
    };

    const campaign = computed(() => campaignStore.getters.campaign);
    const placementsHasBeenChanged = computed(() => campaignEditorPlacementsStore.getters.hasBeenChanged);
    const visiblePlacements = computed(() => campaignEditorPlacementsStore.getters.visiblePlacements);
    const hiddenPlacements = computed(() => campaignEditorPlacementsStore.getters.hiddenPlacements);
    const placementsFilter = computed(() => campaignEditorPlacementsStore.getters.filters.filter);
    const visibleCampaignPlanMetrics = computed(() => campaignEditorPlacementsStore.getters.visiblePlanMetrics);
    const filledPlanMetrics = computed(() => campaignEditorPlacementsStore.getters.filledPlanMetrics);

    const metricsBtnIsShown = computed(() => currentColumnsPresetsSlugs.value.includes(ColumnPresetSlugs.PlanMetrics));

    const placementsIsHidden = computed({
      get() {
        return campaignEditorPlacementsStore.getters.filters.hidden;
      },
      set(val: boolean) {
        campaignEditorPlacementsStore.dispatch('UPDATE_FILTERS', { hidden: val });
      },
    });

    const placements = computed(() => {
      const visibilityPlacements = placementsIsHidden.value ? hiddenPlacements.value : visiblePlacements.value;

      const filteredPlacements = placementsFilter.value
        ? visibilityPlacements.filter(({ danboId }) => danboId.includes(placementsFilter.value))
        : visibilityPlacements;

      return filteredPlacements.map((placement) => ({
        ...placement,
        targeting: {
          ...placement.targeting,
          interests: Array.isArray(placement.targeting.interests) // TODO: убрать после фикса типа данных на беке
            ? placement.targeting.interests[0]
            : placement.targeting.interests,
        },
      }));
    });

    const columnsPresets = computed(() => [
      {
        label: 'Настройки',
        slug: ColumnPresetSlugs.Settings,
      },
      {
        label: 'Taргетинги',
        slug: ColumnPresetSlugs.Targetings,
      },
      {
        label: 'Плановые показатели',
        slug: ColumnPresetSlugs.PlanMetrics,
      },
      {
        label: 'Фактические показатели',
        slug: ColumnPresetSlugs.FactMetrics,
      },
    ]);

    const init = async () => {
      isLoading.value = true;

      try {
        await Promise.all([
          campaignEditorPlacementsStore.dispatch('FETCH', campaignId.value),
          campaignEditorPlacementsStore.dispatch('FETCH_CATALOGS'),
          campaignEditorPlacementsStore.dispatch('FETCH_CATEGORIES'),
        ]);
      } finally {
        isLoading.value = false;
      }
    };

    const refetchPlacements = async () => {
      isLoading.value = true;

      try {
        await campaignEditorPlacementsStore.dispatch('FETCH', campaignId.value);
      } finally {
        isLoading.value = false;
      }
    };

    const openMetricsModal = () => {
      openModal({
        slug: 'metrics',
        props: {
          visiblePlanMetrics: visibleCampaignPlanMetrics.value,
          filledPlanMetrics: filledPlanMetrics.value,
        },
      });
    };

    campaignEditorPlacementsEmitter.on('openMarkupsModal', (placement) => {
      openModal({ slug: 'markups', props: placement });
    });

    campaignEditorPlacementsEmitter.on('closeMarkupsModal', ({ markupsIsChanged }) => {
      if (!markupsIsChanged) return;

      refetchPlacements();
    });

    const onChangePlacementsTable = (payload: CampaignEditorPlacement[]) => {
      const normalizedPayload = payload.map((placement) => ({
        ...placement,
        targeting: {
          ...placement.targeting,
          interests: Array.isArray(placement.targeting.interests) // TODO: убрать после фикса типа данных на беке
            ? placement.targeting.interests
            : [placement.targeting.interests],
        },
      }));

      campaignEditorPlacementsStore.dispatch('PUSH_TO_CHANGED_PLACEMENTS', normalizedPayload);
    };

    init();

    return {
      isLoading,
      modalsMap,
      placementsIsHidden,
      placements,
      campaign,
      placementsHasBeenChanged,
      visibleCampaignPlanMetrics,
      metricsBtnIsShown,
      openMetricsModal,
      onChangePlacementsTable,
      //
      currentColumnsPresetsSlugs,
      columnsPresets,
      onChangeCurrentColumnsPresetsSlugs,
    };
  },
});
