/* eslint-disable max-classes-per-file */
import Vue from 'vue';

import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { SearchApi } from '@/api';
import { SearchResult } from '@/api/modules/search.api';

import { SearchCategory, SearchFilters } from '../models';

class ModuleState {
  isShown = false;

  isLoading = false;

  query = '';

  queryLimit = {
    min: 3,
    max: 300,
  };

  filters: SearchFilters = {
    status: 'all',
  };

  result: SearchResult['result'] | null = null;

  hasBeenByDanboId = false;
}

class ModuleGetters extends Getters<ModuleState> {
  get isShown() {
    return this.state.isShown;
  }

  get isLoading() {
    return this.state.isLoading;
  }

  get query() {
    return this.state.query;
  }

  get queryLimit() {
    return this.state.queryLimit;
  }

  get filters() {
    return this.state.filters;
  }

  get result() {
    return this.state.result;
  }

  get resultIsExist() {
    return !!this.getters.result;
  }

  get resultIsEmpty() {
    if (!this.getters.result) return true;

    return Object.values(this.getters.result).every((list: unknown[]) => !list.length);
  }

  get resultContainsOnlyOneItem() {
    if (!this.getters.result) return false;

    return Object.values(this.getters.result).flat(2).length === 1;
  }

  get categories() {
    const categoriesMap: SearchCategory = {
      clients: {
        title: 'Клиенты',
        items: this.getters.result?.clients?.map((client) => ({
          name: client.name,
          to: `/clients/${client.id}/`,
        })) || [],
      },
      brands: {
        title: 'Бренды',
        items: this.getters.result?.brands?.map((brand) => ({
          name: brand.name,
          to: `/clients/${brand.clientId}/?brandId=${brand.id}`,
        })) || [],
      },
      campaigns: {
        title: 'Кампании',
        items: this.getters.result?.campaigns?.map((campaign) => {
          const iconsMap = {
            paused: 'mdi-pause',
            active: '$d1-play',
            finished: campaign.isActive ? 'mdi-check' : 'mdi-archive',
          };

          const item = {
            name: campaign.name,
            to: `/campaigns/${campaign.id}/`,
            icon: iconsMap[campaign.state.code],
            breadcrumbs: [
              {
                label: campaign.clientName,
                to: `/clients/${campaign.clientId}`,
              },
              {
                label: campaign.brandName,
                to: `/clients/${campaign.clientId}/?brandId=${campaign.brandId}`,
              },
            ],
            approved: campaign.approved,
          };

          return item;
        }) || [],
      },
      placements: {
        title: 'Размещения',
        items: this.getters.result?.placements?.map((placement) => {
          const iconsMap = {
            default: '$d1-play',
            paused: 'mdi-timer-sand',
            running: '$d1-play',
            finished: 'mdi-check',
          };

          const item = {
            id: placement.danboId,
            name: placement.name,
            to: Vue.acl.check('view_sub_danboids')
              ? `/campaigns/${placement.campaignId}/placements/${placement.danboId}`
              : `/campaigns/${placement.campaignId}/?danboId=${placement.danboId}`,
            icon: iconsMap[placement.state.code],
            breadcrumbs: [
              {
                label: placement.clientName,
                to: `/clients/${placement.clientId}`,
              },
              {
                label: placement.brandName,
                to: `/clients/${placement.clientId}/?brandId=${placement.brandId}`,
              },
              {
                label: placement.campaignName,
                to: `/campaigns/${placement.campaignId}/`,
              },
            ],
            approved: placement.approved,
          };

          return item;
        }) || [],
      },
    };

    return Object.values(categoriesMap).filter((category) => category.items.length);
  }

  get hasBeenByDanboId() {
    return this.state.hasBeenByDanboId;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_SHOWN(value: boolean) {
    this.state.isShown = value;
  }

  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_QUERY(value: string) {
    this.state.query = value;
  }

  SET_FILTERS(payload: SearchFilters) {
    this.state.filters = payload;
  }

  SET_RESULT(payload: SearchResult) {
    this.state.result = payload.result;
    this.state.hasBeenByDanboId = payload.isDanboResult;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  UPDATE_IS_SHOWN(value: boolean) {
    this.commit('SET_IS_SHOWN', value);
  }

  SHOW_SEARCH() {
    this.dispatch('UPDATE_IS_SHOWN', true);
  }

  HIDE_SEARCH() {
    this.dispatch('UPDATE_IS_SHOWN', false);
  }

  TOOGLE_SEARCH() {
    if (this.getters.isShown) return this.dispatch('HIDE_SEARCH');

    return this.dispatch('SHOW_SEARCH');
  }

  UPDATE_QUERY(value: string) {
    this.commit('SET_QUERY', value);
  }

  UPDATE_FILTERS(payload: Partial<SearchFilters>) {
    const newFilters = {
      ...this.getters.filters,
      ...payload,
    };

    this.commit('SET_FILTERS', newFilters);
  }

  async SEARCH() {
    const normalizedQuery = this.getters.query.slice(0, this.getters.queryLimit.max);

    const payload = {
      keyWord: normalizedQuery,
      state: this.getters.filters.status,
    };

    this.commit('SET_IS_LOADING', true);

    const res = await SearchApi.search(payload);

    this.commit('SET_RESULT', res.data);
    this.commit('SET_IS_LOADING', false);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const searchStoreMapper = createMapper(module);

export const useSearchStore = createComposable(module);

export default module;
