import { AxiosResponse } from 'axios';

import { PartialWithout } from '@/utils/type';

import { DataProvider, DataProviderSite, DataProviderType } from '@/models/DataProvider';

import httpClient from '../httpClient';

export default class DataProvidersApi {
  public static async fetchDataProviders(): Promise<AxiosResponse<DataProvider[]>> {
    const res = await httpClient.get<DataProvider[]>('/api/dataproviders');

    return res;
  }

  public static async fetchSites(): Promise<AxiosResponse<DataProviderSite[]>> {
    const res = await httpClient.get<DataProviderSite[]>('/api/dataproviders/sites');

    return res;
  }

  public static async fetchTypes(): Promise<AxiosResponse<DataProviderType[]>> {
    const res = await httpClient.get<DataProviderType[]>('/api/dataproviders/types');

    return res;
  }

  public static async fetchDataProvider(dataProviderId: string): Promise<AxiosResponse<DataProvider>> {
    const res = await httpClient.get<DataProvider>(`/api/dataproviders/${dataProviderId}`);

    return res;
  }

  public static async setDataProvider(payload: PartialWithout<DataProvider, 'id'>): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/dataproviders/', payload);

    return res;
  }

  public static async deleteDataProvider(dataProviderId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.delete<''>(`/api/dataproviders/${dataProviderId}`);

    return res;
  }
}
