


























































import { defineComponent, PropType, ref, computed, toRefs } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import useModals from '@/composables/useModals';

import CampaignPlanMetricsModal from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorPlacements/modals/CampaignPlacementsMetricsModal.vue';

import { useCampaignEditorPlacementsStore } from '@/store/campaignEditor/campaignEditorPlacements';
import { useCampaignEditorPlacementDetailsStore } from '@/store/campaignEditor/campaignEditorPlacementDetails';

import { CampaignEditorPlacementDetails } from '@/models/Campaign';

import PlacementFlightsTable from './PlacementFlightsTable/PlacementFlightsTable.vue';

import placementFlightsTableEmitter from './PlacementFlightsTable/placementFlightsTableEmitter';

export default defineComponent({
  components: {
    DBtnInline,
    PlacementFlightsTable,
    CampaignPlanMetricsModal,
  },
  props: {
    placement: { type: Object as PropType<CampaignEditorPlacementDetails>, required: true },
    currentPlacement: { type: Object as PropType<CampaignEditorPlacementDetails>, required: true },
  },
  setup(props) {
    const { placement } = toRefs(props);

    const campaignEditorPlacementsStore = useCampaignEditorPlacementsStore();
    const campaignEditorPlacementDetailsStore = useCampaignEditorPlacementDetailsStore();

    const isLoading = ref(false);

    const {
      modalsMap,
      openModal,
    } = useModals(['metrics']);

    const planHasBeenChanged = computed(() => campaignEditorPlacementDetailsStore.getters.placementHasBeenChanged);
    const campaignPlanMetrics = computed(() => campaignEditorPlacementsStore.getters.campaignPlanMetrics);
    const filledPlanMetrics = computed(() => campaignPlanMetrics.value.filter(({ name }) => placement.value?.planTotalMetrics?.[name]));

    const visiblePlacementPlanMetrics = computed(() => {
      const planMetrics = [...filledPlanMetrics.value];

      campaignEditorPlacementsStore.getters.selectedPlanMetrics.forEach((planMetric) => {
        const planMetricAlreadyExists = planMetrics.some(({ name }) => name === planMetric.name);

        if (planMetricAlreadyExists) return;

        planMetrics.push(planMetric);
      });

      const planMetricsWeightsMap = campaignPlanMetrics.value.reduce((map, planMetric, idx) => {
        map[planMetric.name] = idx;

        return map;
      }, {});

      return planMetrics.sort((planMetricA, planMetricB) => {
        const planMetricAWeight = planMetricsWeightsMap[planMetricA.name];
        const planMetricBWeight = planMetricsWeightsMap[planMetricB.name];

        return planMetricAWeight - planMetricBWeight;
      });
    });

    const openMetricsModal = () => {
      openModal({
        slug: 'metrics',
        props: {
          visiblePlanMetrics: visiblePlacementPlanMetrics.value,
          filledPlanMetrics: filledPlanMetrics.value,
        },
      });
    };

    const addPlacementPlanFlight = () => {
      placementFlightsTableEmitter.emit('addFlightPlan');
    };

    const onPlacementFlightsTableChange = (payload: Partial<CampaignEditorPlacementDetails>) => {
      campaignEditorPlacementDetailsStore.dispatch('CHANGE_PLACEMENT_FORM', payload);
    };

    return {
      isLoading,
      modalsMap,
      planHasBeenChanged,
      visiblePlacementPlanMetrics,
      openMetricsModal,
      addPlacementPlanFlight,
      onPlacementFlightsTableChange,
      filledPlanMetrics,
      campaignPlanMetrics,
    };
  },
});
