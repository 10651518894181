















































































import Vue from 'vue';
import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import {
  DBreadcrumbs,
  DTooltip,
} from '@shared/druid-kit';

import { useCampaignApprovedStatus } from '@/composables/campaign';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  components: {
    DBreadcrumbs,
    DTooltip,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props) {
    const { campaign } = toRefs(props);

    const {
      campaignIsApproved,
      toggleCampaignApprovedStatusIsLoading,
      toggleCampaignApprovedStatus,
    } = useCampaignApprovedStatus({ campaign, isDetails: true });

    const breadcrumbs = computed(() => [
      {
        label: campaign.value.brandName,
        path: `/clients/${campaign.value.clientId}/?brandId=${campaign.value.brandId}`,
      },
      {
        label: campaign.value.name,
        path: `/campaigns/${campaign.value.id}`,
      },
    ]);

    const campaignEditorPath = computed(() => `/campaigns/${campaign.value.id}/edit`);

    const onCampaignEditorBtnClick = () => {
      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Campaign',
        event_action: 'Click to Campaign Editor',
        event_label: campaign.value.id,
        d1d_client_id: campaign.value.clientId,
        d1d_client_name: campaign.value.clientName,
        d1d_brand_id: campaign.value.brandId,
        d1d_brand_name: campaign.value.brandName,
        d1d_campaign_id: campaign.value.id,
        d1d_campaign_name: campaign.value.name,
      });
    };

    return {
      campaignIsApproved,
      toggleCampaignApprovedStatusIsLoading,
      breadcrumbs,
      campaignEditorPath,
      toggleCampaignApprovedStatus,
      onCampaignEditorBtnClick,
    };
  },
});
