









































import { defineComponent, toRefs, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    settingsHasBeenChanged: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    resetIsDisabled: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      settingsHasBeenChanged,
      resetIsDisabled,
      saving,
    } = toRefs(props);

    const saveBtnDisabled = computed(() => !saving.value && settingsHasBeenChanged.value);
    const closeBtnDisabled = computed(() => saving.value);
    const resetBtnIsDisabled = computed(() => !saving.value && resetIsDisabled.value);

    const reset = () => {
      context.emit('reset');
    };

    const save = () => {
      context.emit('save');
    };

    const cancel = () => {
      context.emit('cancel');
    };

    return {
      saveBtnDisabled,
      closeBtnDisabled,
      resetBtnIsDisabled,
      save,
      reset,
      cancel,
    };
  },
});
