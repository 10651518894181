




























































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { Permission } from '@/models/Permission';

export default defineComponent({
  props: {
    value: { type: Object as PropType<Permission | null>, default: null },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabledFields: { type: Array as PropType<string[]>, default: () => [] },
  },
  setup(props, context) {
    const {
      value,
      loading,
      disabledFields,
    } = toRefs(props);

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);

    const isValid = ref(false);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updatePermission = (payload: Partial<Permission>) => {
      const newPermission = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newPermission);
    };

    // // // // //

    const idRules = ref([
      (val: string) => !!val,
    ]);

    const idIsLoading = computed(() => loading.value);
    const idIsDisabled = computed(() => idIsLoading.value || disabledFields.value.includes('id'));

    const currentId = computed({
      get() {
        return value.value?.id || '';
      },
      set(val: string) {
        updatePermission({ id: val });
      },
    });

    // // // // //

    const nameRules = ref([
      (val: string) => !!val,
    ]);

    const nameIsLoading = computed(() => loading.value);
    const nameIsDisabled = computed(() => nameIsLoading.value || disabledFields.value.includes('name'));

    const currentName = computed({
      get() {
        return value.value?.name || '';
      },
      set(val: string) {
        updatePermission({ name: val });
      },
    });

    // // // // //

    const scopeIsLoading = computed(() => loading.value);
    const scopeIsDisabled = computed(() => scopeIsLoading.value || disabledFields.value.includes('scope'));

    const currentScope = computed({
      get() {
        return value.value?.scope || '';
      },
      set(val: string) {
        updatePermission({ scope: val });
      },
    });

    // // // // //

    const init = async () => {
      await Promise.all([
      ]);
    };

    init();

    return {
      rootRef,
      isValid,
      resetValidation,
      //
      currentId,
      idRules,
      idIsLoading,
      idIsDisabled,
      //
      currentName,
      nameRules,
      nameIsLoading,
      nameIsDisabled,
      //
      currentScope,
      scopeIsLoading,
      scopeIsDisabled,
    };
  },
});
