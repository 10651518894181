






















import { defineComponent, computed, watch } from '@vue/composition-api';

import useRoute from '@/packages/hooks/useRoute';

import SearchHeader from './SearchHeader.vue';
import SearchResult from './SearchResult.vue';

import { useSearchStore } from '../store';

export default defineComponent({
  components: {
    SearchHeader,
    SearchResult,
  },
  setup(props, context) {
    const route = useRoute(context);

    const searchStore = useSearchStore();

    const isShown = computed({
      get() {
        return searchStore.getters.isShown;
      },
      set(val: boolean) {
        searchStore.dispatch('UPDATE_IS_SHOWN', val);
      },
    });

    watch(route, () => {
      isShown.value = false;
    });

    const onOverlayClick = () => {
      isShown.value = false;
    };

    return {
      isShown,
      onOverlayClick,
    };
  },
});
