






















import Vue, { VueConstructor } from 'vue';

import { HnTableCellMixin } from '@/packages/hn-table/src/mixins';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'PlacementsTableSourceCell',
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    //
  },
  data() {
    return {
      value: null as boolean | null,
    };
  },
});
