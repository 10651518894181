import { registerModule } from 'vuex-smart-module';

import ModuleInit from '@/models/ModuleInit';

import store from './store';

const moduleInit: ModuleInit = (ctx) => {
  registerModule(ctx.store, ['search'], 'search', store);
};

export default moduleInit;
