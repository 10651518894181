<template>
  <div class="table-loader">
    <div
      v-if="!withoutHead"
      class="table-loader__head"
    >
      <div
        v-for="num in columns"
        :key="num"
        class="table-loader__head-cell"
      >
        <v-skeleton-loader
          type="text"
          class="table-loader__head-cell-content"
        />
      </div>
    </div>
    <div
      v-for="rowNum in rows"
      :key="rowNum"
      class="table-loader__body"
    >
      <v-skeleton-loader
        v-for="columnNum in columns"
        :key="columnNum"
        type="text"
        class="table-loader__row-cell"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    withoutHead: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Number,
      default: 6,
    },
    rows: {
      type: Number,
      default: 2,
    },
  },
});
</script>

<style lang="scss" scoped>
.table-loader {
  &__head,
  &__body {
    display: flex;
  }

  &__head-cell,
  &__row-cell {
    width: 100%;
    margin-right: 24px;
  }

  &__head-cell-content {
    width: 75%;

    ::v-deep .v-skeleton-loader__text {
      background: map-get($grey, 'lighten-1') !important;
    }
  }

  ::v-deep .v-skeleton-loader__text {
    margin-bottom: 32px;
  }
}
</style>
