/* eslint-disable max-classes-per-file */
import Vue from 'vue';
import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { ProviderSettingsApi, DataProvidersSettingsApi } from '@/api';

import { SetProviderSettingsPayload, ProviderSettingsResponse } from '@/api/modules/providerSettings.api';

import { ProviderSettings, Provider, ProviderSettingsToken } from '@/models/ProviderSettings';
import { Client } from '@/models/Client';

export interface ProviderSettingsFilters {
  page: number;
  query: string;
  failedOnly: boolean;
}

const initFilters = () => ({
  page: 1,
  query: '',
  failedOnly: false,
});

class ModuleState {
  providersSettings: ProviderSettings[] = [];

  selectedProviderSettings: ProviderSettingsResponse | null = null;

  clients: Client[] = [];

  clientsIsLoading = false;

  providers: Provider[] = [];

  providersIsLoading = false;

  tokens: ProviderSettingsToken[] = [];

  tokensIsLoading = false;

  statisticsCollection: string[] = [];

  statisticsCollectionIsLoading = false;

  filters: ProviderSettingsFilters = initFilters();

  filtersChanged = false;

  modalIsShow = false;

  saveIsLoading = false;
}

class ModuleGetters extends Getters<ModuleState> {
  get providersSettings() {
    return this.state.providersSettings;
  }

  get selectedProviderSettings() {
    return this.state.selectedProviderSettings;
  }

  get clients() {
    return this.state.clients;
  }

  get clientsIsLoading() {
    return this.state.clientsIsLoading;
  }

  get providers() {
    return this.state.providers;
  }

  get providersIsLoading() {
    return this.state.providersIsLoading;
  }

  get tokens() {
    return this.state.tokens;
  }

  get tokensIsLoading() {
    return this.state.tokensIsLoading;
  }

  get statisticsCollection() {
    return this.state.statisticsCollection;
  }

  get statisticsCollectionIsLoading() {
    return this.state.statisticsCollectionIsLoading;
  }

  get filters() {
    return this.state.filters;
  }

  get modalIsShow() {
    return this.state.modalIsShow;
  }

  get saveIsLoading() {
    return this.state.saveIsLoading;
  }

  get filtersChanged() {
    return this.state.filtersChanged;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_PROVIDERS_SETTINGS(payload: ProviderSettings[]) {
    this.state.providersSettings = payload;
  }

  ADD_PROVIDERS_SETTINGS(payload: ProviderSettings[]) {
    this.state.providersSettings = [...this.state.providersSettings, ...payload];
  }

  REMOVE_PROVIDER_SETTINGS(payload: string) {
    this.state.providersSettings = this.state.providersSettings.filter(({ id }) => id !== payload);
  }

  SET_SELECTED_PROVIDER_SETTINGS(payload: ProviderSettingsResponse) {
    this.state.selectedProviderSettings = payload;
  }

  RESET_SELECTED_PROVIDER_SETTINGS() {
    this.state.selectedProviderSettings = null;
  }

  SET_PROVIDERS(payload: Provider[]) {
    this.state.providers = payload;
  }

  SET_PROVIDERS_IS_LOADING(payload: boolean) {
    this.state.providersIsLoading = payload;
  }

  SET_CLIENTS(payload: Client[]) {
    this.state.clients = payload;
  }

  SET_CLIENTS_IS_LOADING(payload: boolean) {
    this.state.clientsIsLoading = payload;
  }

  SET_TOKENS(payload: ProviderSettingsToken[]) {
    this.state.tokens = payload;
  }

  SET_TOKENS_IS_LOADING(payload: boolean) {
    this.state.tokensIsLoading = payload;
  }

  SET_STATISTICS_COLLECTION(payload: string[]) {
    this.state.statisticsCollection = payload;
  }

  SET_STATISTIC_COLLECTION_IS_LOADING(payload: boolean) {
    this.state.statisticsCollectionIsLoading = payload;
  }

  SET_FILTERS(payload: ProviderSettingsFilters) {
    this.state.filters = payload;
  }

  SET_FILTERS_CHANGED(payload: boolean) {
    this.state.filtersChanged = payload;
  }

  SET_MODAL_IS_SHOW(payload: boolean) {
    this.state.modalIsShow = payload;
  }

  SET_SAVE_IS_LOADING(payload: boolean) {
    this.state.saveIsLoading = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_PROVIDERS_SETTINGS_LIST() {
    const { page, failedOnly, query } = this.getters.filters;

    const res = await ProviderSettingsApi.fetchProviderSettings({
      params: { page, failedOnly, filter: query },
    });

    if (page === 1) {
      this.commit('SET_PROVIDERS_SETTINGS', res.data.list);
    } else {
      this.commit('ADD_PROVIDERS_SETTINGS', res.data.list);
    }
  }

  async FETCH_PROVIDER_SETTINGS(payload: string) {
    const res = await ProviderSettingsApi.fetchProvider(payload);

    this.commit('SET_SELECTED_PROVIDER_SETTINGS', res.data);
  }

  CREATE_PROVIDER_SETTINGS() {
    this.commit('SET_MODAL_IS_SHOW', true);
  }

  async EDIT_PROVIDER_SETTINGS(payload: string) {
    await this.dispatch('FETCH_PROVIDER_SETTINGS', payload);

    this.commit('SET_MODAL_IS_SHOW', true);
  }

  RESET_EDIT_PROVIDER_SETTINGS() {
    this.commit('SET_MODAL_IS_SHOW', false);
    this.commit('RESET_SELECTED_PROVIDER_SETTINGS');
  }

  async DELETE_PROVIDER_SETTINGS(payload: ProviderSettings) {
    await ProviderSettingsApi.deleteProvider(payload.id);

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Provider Settings',
      event_action: 'Delete Settings',
      event_label: `${payload.name} | ${payload.provider}`,
      d1d_client_id: payload.clientId,
      d1d_client_name: payload.client,
    });

    this.commit('REMOVE_PROVIDER_SETTINGS', payload.id);
  }

  async FETCH_CLIENTS() {
    this.commit('SET_CLIENTS_IS_LOADING', true);

    try {
      const res = await ProviderSettingsApi.fetchClients();

      this.commit('SET_CLIENTS', res.data);
    } finally {
      this.commit('SET_CLIENTS_IS_LOADING', false);
    }
  }

  async FETCH_PROVIDERS() {
    this.commit('SET_PROVIDERS_IS_LOADING', true);

    try {
      const res = await ProviderSettingsApi.fetchProviders();

      this.commit('SET_PROVIDERS', res.data);
    } finally {
      this.commit('SET_PROVIDERS_IS_LOADING', false);
    }
  }

  async FETCH_TOKENS(providerId: string) {
    this.commit('SET_TOKENS_IS_LOADING', true);

    try {
      const res = await ProviderSettingsApi.fetchTokens({ params: { id: providerId } });

      this.commit('SET_TOKENS', res.data);
    } finally {
      this.commit('SET_TOKENS_IS_LOADING', false);
    }
  }

  async FETCH_STATISTICS_COLLECTION(connectorId: string) {
    this.commit('SET_STATISTIC_COLLECTION_IS_LOADING', true);

    try {
      const res = await DataProvidersSettingsApi.fetchStatisticsCollectionDepths({ params: { id: connectorId } });

      this.commit('SET_STATISTICS_COLLECTION', res.data);
    } finally {
      this.commit('SET_STATISTIC_COLLECTION_IS_LOADING', false);
    }
  }

  async SAVE_PROVIDER_SETTINGS(payload: SetProviderSettingsPayload) {
    this.commit('SET_SAVE_IS_LOADING', true);

    try {
      await ProviderSettingsApi.setProvider(payload);

      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Provider Settings',
        event_action: 'Create Settings',
        event_label: `${payload.name} | ${payload.dataProviderName}`,
        d1d_client_id: payload.clientId,
        d1d_client_name: payload.clientName,
      });

      this.dispatch('RESET_EDIT_PROVIDER_SETTINGS');
      this.dispatch('RESET_FILTERS');

      await this.dispatch('FETCH_PROVIDERS_SETTINGS_LIST');
    } finally {
      this.commit('SET_SAVE_IS_LOADING', false);
    }
  }

  UPDATE_FILTERS(filters: Partial<ProviderSettingsFilters>) {
    const page = filters.page || 1;

    const newFilters = {
      ...this.getters.filters,
      ...filters,
      page,
    };

    this.commit('SET_FILTERS', newFilters);
    this.commit('SET_FILTERS_CHANGED', true);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
    this.commit('SET_FILTERS_CHANGED', false);
  }

  NEXT_PAGE() {
    this.dispatch('UPDATE_FILTERS', { page: this.getters.filters.page + 1 });
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const providerSettingsStoreMapper = createMapper(module);

export const useProviderSettingsStore = createComposable(module);

export default module;
