import { Route, RawLocation } from 'vue-router';

import Campaigns from '@/views/Campaigns.vue';

import CampaignDetails from '@/views/Campaigns/CampaignDetails.vue';
import CampaignCategory from '@/views/Campaigns/CampaignCategory.vue';
import CampaignPlacement from '@/views/Campaigns/CampaignPlacement.vue';
import CampaignConnector from '@/views/Campaigns/CampaignConnector.vue';
import CampaignAdObject from '@/views/Campaigns/CampaignAdObject.vue';

import CampaignEditor from '@/views/Campaigns/CampaignEditor.vue';
import CampaignEditorPlacements from '@/views/Campaigns/CampaignEditor/CampaignEditorPlacements.vue';

import CampaignEditorGoals from '@/views/Campaigns/CampaignEditor/CampaignEditorGoals.vue';
import CampaignEditorCategories from '@/views/Campaigns/CampaignEditor/CampaignEditorCategories.vue';
import CampaignEditorSettings from '@/views/Campaigns/CampaignEditor/CampaignEditorSettings.vue';

import CampaignPlacements from '@/views/Campaigns/CampaignPlacements.vue';
import CampaignPlacementEditor from '@/views/Campaigns/CampaignPlacements/CampaignPlacementEditor.vue';

import CampaignPageLoader from '@/components/_newDesign/Campaigns/CampaignPageLoader.vue';
import CampaignEditorPageLoader from '@/components/_newDesign/Campaigns/CampaignEditor/loaders/CampaignEditorPageLoader.vue';
import CampaignPlacementEditorLoader from '@/components/_newDesign/Campaigns/CampaignPlacementEditor/CampaignPlacementEditorLoader.vue';
import CampaignEditorSettingsPageLoader from '@/components/_newDesign/Campaigns/CampaignEditor/loaders/CampaignEditorSettingsPageLoader.vue';
import CampaignEditorCategoriesPageLoader from '@/components/_newDesign/Campaigns/CampaignEditor/loaders/CampaignEditorCategoriesPageLoader.vue';

import { AppRouteConfig } from '@/models/AppRouter';

const campaignCatalogRoutes: AppRouteConfig[] = [
  {
    name: 'campaigns',
    path: '',
    redirect: '/',
  },
];

const campaignDetailsRoutes: AppRouteConfig[] = [
  {
    name: 'campaign',
    path: ':campaignId',
    component: CampaignDetails,
    meta: {
      loader: CampaignPageLoader,
    },
    props: true,
  },
];

const campaignCategoryRoutes: AppRouteConfig[] = [
  {
    name: 'campaign-category',
    path: ':campaignId/category/:categoryId',
    component: CampaignCategory,
    meta: {
      loader: CampaignPageLoader,
    },
    props: true,
  },
];

const campaignPlacementRoutes: AppRouteConfig[] = [
  {
    name: 'campaign_danboid',
    path: ':campaignId/danboid/:danboId',
    redirect(to: Route): RawLocation {
      const {
        start,
        end,
        ...otherQuery
      } = to.query;

      return {
        name: 'campaign-placement',
        query: {
          from: start,
          to: end,
          ...otherQuery,
        },
      };
    },
  },
  {
    name: 'campaign-placement',
    path: ':campaignId/placements/:danboId',
    component: CampaignPlacement,
    meta: {
      permission: 'view_sub_danboids',
      loader: CampaignPageLoader,
    },
    props: true,
  },
];

const campaignConnectorRoutes: AppRouteConfig[] = [
  {
    name: 'campaign-connector',
    path: ':campaignId/placements/:danboId/connectors/:connectorId',
    component: CampaignConnector,
    meta: {
      permission: 'view_sub_danboids',
      loader: CampaignPageLoader,
    },
    props: true,
  },
];

const campaignAdObjectRoutes: AppRouteConfig[] = [
  {
    name: 'campaign-ad-object',
    path: ':campaignId/placements/:danboId/connectors/:connectorId/:level/:type/:onSiteId',
    component: CampaignAdObject,
    meta: {
      permission: 'view_sub_danboids',
      loader: CampaignPageLoader,
    },
    props: true,
  },
];

const campaignEditorRoutes: AppRouteConfig[] = [
  {
    name: 'campaign-editor',
    path: ':campaignId/edit',
    redirect: {
      name: 'campaign-editor-placements',
    },
    component: CampaignEditor,
    meta: {
      permission: 'plan_edit',
    },
    props: true,
    children: [
      {
        name: 'campaign-editor-placements',
        path: 'placements',
        component: CampaignEditorPlacements,
        props: true,
        meta: {
          permission: 'plan_edit',
          loader: CampaignEditorPageLoader,
          footer: {
            linksOnRight: true,
          },
        },
      },
      {
        name: 'campaign-editor-goals',
        path: 'goals',
        component: CampaignEditorGoals,
        props: true,
        meta: {
          loader: CampaignEditorPageLoader,
          footer: {
            linksOnRight: true,
          },
        },
      },
      {
        name: 'campaign-editor-categories',
        path: 'categories',
        component: CampaignEditorCategories,
        props: true,
        meta: {
          loader: CampaignEditorCategoriesPageLoader,
          footer: {
            linksOnRight: true,
          },
        },
      },
      {
        name: 'campaign-editor-settings',
        path: 'settings',
        component: CampaignEditorSettings,
        props: true,
        meta: {
          loader: CampaignEditorSettingsPageLoader,
          footer: {
            linksOnRight: true,
          },
        },
      },
    ],
  },
];

const campaignPlacementsRoutes: AppRouteConfig[] = [
  {
    name: 'campaign-placements',
    path: ':campaignId/placements',
    component: CampaignPlacements,
    children: [
      {
        name: 'campaign-placement-editor',
        path: ':placementDanboId/edit',
        component: CampaignPlacementEditor,
        props: true,
        meta: {
          permission: 'plan_edit',
          loader: CampaignPlacementEditorLoader,
          footer: {
            linksOnRight: true,
          },
        },
      },
    ],
  },
];

const routeConfig: AppRouteConfig = {
  path: '/campaigns',
  component: Campaigns,
  meta: {
    permission: 'any',
  },
  children: [
    ...campaignCatalogRoutes,
    ...campaignDetailsRoutes,
    ...campaignCategoryRoutes,
    ...campaignPlacementRoutes,
    ...campaignConnectorRoutes,
    ...campaignAdObjectRoutes,
    ...campaignEditorRoutes,
    ...campaignPlacementsRoutes,
  ],
};

export default routeConfig;
