



























































































import { defineComponent, ref, computed } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import { TaxonomyBrandCategory } from '@/models/Taxonomy';

import store from '@/store';
import { useCampaignEditorCategoriesStore } from '@/store/campaignEditor/campaignEditorCategories';
import referenceStore from '@/store/reference';

const referenceStoreContext = referenceStore.context(store);

export default defineComponent({
  components: {
    DBtnInline,
  },
  setup() {
    const campaignEditorCategoriesStore = useCampaignEditorCategoriesStore();

    const expandedRootItemIds = ref<string[]>([]);

    const categories = computed(() => referenceStoreContext.getters.brandCategories);

    const campaignCategoriesIds = computed({
      get() {
        return campaignEditorCategoriesStore.getters.currentData.categoriesIds;
      },
      set(val: string[]) {
        campaignEditorCategoriesStore.dispatch('CHANGE_DATA', { categoriesIds: val });
      },
    });

    const campaignSelectedCategories = computed(() => {
      const selectedCategories: TaxonomyBrandCategory[] = [];

      const getSelectedCategories = (items: TaxonomyBrandCategory[]) => {
        items.forEach((item) => {
          if (campaignCategoriesIds.value.includes(item.id)) selectedCategories.push(item);

          if (item.children && item.children.length) getSelectedCategories(item.children);
        });
      };

      getSelectedCategories(categories.value);

      return selectedCategories;
    });

    const categoryGroups = computed(() => {
      const firstChars = [...new Set(categories.value.map((category) => category.title[0]))];

      const groups = firstChars.map((char) => {
        const title = char;
        const items = categories.value.filter((category) => category.title[0] === char);

        return {
          title,
          items,
        };
      });

      return groups;
    });

    const rootItemIsOpen = (rootItemId: string) => expandedRootItemIds.value.includes(rootItemId);

    const toggleExpandRootItem = (rootItemId: string) => {
      const foundIdx = expandedRootItemIds.value.findIndex((categoryId) => categoryId === rootItemId);

      if (foundIdx === -1) {
        expandedRootItemIds.value.push(rootItemId);
      } else {
        expandedRootItemIds.value.splice(foundIdx, 1);
      }
    };

    const onChipClickClose = (category: TaxonomyBrandCategory) => {
      campaignCategoriesIds.value = campaignCategoriesIds.value.filter((categoryId) => categoryId !== category.id);
    };

    return {
      categories,
      categoryGroups,
      campaignCategoriesIds,
      campaignSelectedCategories,
      rootItemIsOpen,
      toggleExpandRootItem,
      onChipClickClose,
    };
  },
});
