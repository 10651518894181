<template>
  <div class="campaign-editor-placement-loader">
    <v-skeleton-loader
      type="heading"
      class="campaign-editor-placement-loader__header"
    />
    <v-skeleton-loader
      type="text"
      class="campaign-editor-placement-loader__subheader"
    />
    <div class="campaign-editor-placement-loader__settings">
      <div class="campaign-editor-placement-loader__settings-list-wrapper">
        <ListLoader
          without-title
          class="campaign-editor-placement-loader__settings-list-content"
        />
      </div>
      <div class="campaign-editor-placement-loader__settings-list-wrapper">
        <ListLoader
          without-title
          class="campaign-editor-placement-loader__settings-list-content"
        />
      </div>
    </div>
    <div class="campaign-editor-placement-loader__table">
      <v-skeleton-loader
        type="text"
        class="campaign-editor-placement-loader__table-title"
      />
      <TableLoader :rows="3" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import ListLoader from '@/components/_newDesign/common/loaders/ListLoader.vue';
import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

export default defineComponent({
  components: {
    ListLoader,
    TableLoader,
  },
});
</script>

<style lang="scss" scoped>
.campaign-editor-placement-loader {
  background-color: map-get($grey, 'lighten-5');

  &__header {
    display: flex;
    align-items: center;
    width: 70%;
    height: 80px;
    padding: 12px 24px;

    ::v-deep .v-skeleton-loader__heading {
      background: map-get($grey, 'lighten-1') !important;
    }
  }

  &__subheader {
    width: 26%;
    padding: 12px 24px;
  }

  &__settings {
    display: flex;
    padding: 54px 24px 28px 24px;
    background-color: map-get($shades, 'white');
  }

  &__settings-list-wrapper {
    width: 50%;
  }

  &__settings-list-content {
    width: 70%;
  }

  &__table {
    padding: 24px;
  }

  &__table-title {
    width: 16%;

    ::v-deep .v-skeleton-loader__text {
      margin-bottom: 32px !important;
      background: map-get($grey, 'lighten-1') !important;
    }
  }
}
</style>
