import { ref, Ref } from '@vue/composition-api';

interface useModalsReturn {
  modalsMap: Ref<{
    [slug: string]: {
      value: boolean;
      props?: Record<string, unknown> | undefined;
    };
  }>;
  openModal: (payload: string | { slug: string; props?: Record<string, unknown> }) => void;
}

export default (modals: string[]): useModalsReturn => {
  type ModalSlug = typeof modals[number];

  type Modal = Record<ModalSlug, { value: boolean; props?: Record<string, unknown> }>;

  const modalsMap = ref(modals.reduce((acc, slug) => {
    acc[slug] = {
      value: false,
    };

    return acc;
  }, {} as Modal));

  const openModal = (payload: ModalSlug | { slug: ModalSlug; props?: Record<string, unknown> }) => {
    if (typeof payload !== 'string') {
      modalsMap.value[payload.slug].props = payload.props;
      modalsMap.value[payload.slug].value = true;
    } else {
      modalsMap.value[payload].value = true;
    }
  };

  return {
    modalsMap,
    openModal,
  };
};
