

















import { defineComponent, toRefs, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { Permission } from '@/models/Permission';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    permission: { type: Object as PropType<Permission>, required: true },
  },
  setup(props, context) {
    const {
      permission,
    } = toRefs(props);

    const openPermission = () => {
      context.emit('open-modal', {
        slug: 'item',
        props: {
          title: permission.value.name,
          permissionId: permission.value.id,
        },
      });
    };

    return {
      openPermission,
    };
  },
});
