var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DTableCell',{staticClass:"info-cell pr-0",attrs:{"to":_vm.placementPath}},[_c('div',{staticClass:"d-flex py-2"},[_c('DTooltip',{attrs:{"center":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"flex-shrink-0 align-self-start mt-1 mr-3",attrs:{"size":"16"},domProps:{"textContent":_vm._s(_vm.placementStatus.icon)}},'v-icon',attrs,false),on))]}}])},[_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.placementStatus.text)}})]),_vm._v(" "),_c('DTooltip',{attrs:{"max-width":"380","bottom":"","interactive":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"flex-grow-1 text-truncate"},'div',attrs,false),on),[_c('div',{staticClass:"info-cell__title"},[_c('span',{staticClass:"info-cell__site-name subtitle-2",class:{
                'info-cell__site-name--link': _vm.userCanViewSubDanboiIds,
              },domProps:{"textContent":_vm._s(_vm.placement.siteName)}}),_vm._v(" "),_c('span',{staticClass:"info-cell__danbo-id body-2 grey--text text--darken-1",domProps:{"textContent":_vm._s(_vm.placement.danboId)}})]),_vm._v(" "),_c('div',{staticClass:"caption grey--text text--darken-3 text-truncate"},[_vm._v("\n            "+_vm._s(_vm.placementInfo)+"\n          ")])])]}}])},[_vm._v(" "),_c('div',[(_vm.placement.metricsDataProviders.length)?_c('div',{staticClass:"mb-2"},[_c('div',[_vm._v("\n            Источники данных:\n          ")]),_vm._v(" "),_vm._l((_vm.placement.metricsDataProviders),function(metricDataProvider){return _c('div',{key:metricDataProvider,domProps:{"textContent":_vm._s(metricDataProvider)}})})],2):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n          "+_vm._s(_vm.placementInfo)+"\n        ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }