




import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import { DBreadcrumbs } from '@shared/druid-kit';

import { Campaign, CampaignCategory } from '@/models/Campaign';

export default defineComponent({
  components: {
    DBreadcrumbs,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    campaignCategory: { type: Object as PropType<CampaignCategory | null>, default: () => null },
    danboId: { type: String, required: true },
    connectorId: { type: String, required: true },
    connectorName: { type: String, required: true },
    level: { type: String, required: true },
    type: { type: String, required: true },
    onSiteId: { type: String, required: true },
  },
  setup(props) {
    const {
      campaign,
      campaignCategory,
      danboId,
      connectorId,
      connectorName,
      level,
      type,
      onSiteId,
    } = toRefs(props);

    const breadcrumbs = computed(() => [
      {
        label: campaign.value.brandName,
        path: `/clients/${campaign.value.clientId}/?brandId=${campaign.value.brandId}`,
      },
      {
        label: campaign.value.name,
        path: `/campaigns/${campaign.value.id}`,
      },
      {
        label: danboId.value,
        path: `/campaigns/${campaign.value.id}/placements/${danboId.value}`,
      },
      {
        label: connectorId.value,
        path: `/campaigns/${campaign.value.id}/placements/${danboId.value}/connectors/${connectorId.value}`,
      },
      {
        label: connectorName.value || '—',
        path: `/campaigns/${campaign.value.id}/placements/${danboId.value}/connectors/${connectorId.value}/${level.value}/${type.value}/${onSiteId.value}`,
      },
    ]);

    if (campaignCategory.value) {
      breadcrumbs.value.splice(2, 0, {
        label: campaignCategory.value.name,
        path: `/campaigns/${campaign.value.id}/category/${campaignCategory.value.id}`,
      });
    }

    return { breadcrumbs };
  },
});
