import { ref, computed, Ref, ComputedRef } from '@vue/composition-api';

import { Campaign } from '@/models/Campaign';

import { useClientStore } from '@/store/client';
import { useCampaignStore } from '@/store/campaign';

interface UseCampaignApprovedStatusProps {
  campaign: Ref<Campaign>;
  isDetails?: boolean | Ref<boolean>;
}

interface UseCampaignApprovedStatusReturn {
  campaignIsApproved: ComputedRef<boolean>;
  toggleCampaignApprovedStatusIsLoading: Ref<boolean>;
  toggleCampaignApprovedStatus: () => Promise<void>;
}

export default (props: UseCampaignApprovedStatusProps): UseCampaignApprovedStatusReturn => {
  const {
    campaign,
    isDetails,
  } = props;

  const clientStore = useClientStore();
  const campaignStore = useCampaignStore();

  const toggleCampaignApprovedStatusIsLoading = ref(false);

  const campaignIsApproved = computed(() => campaign.value.approved);

  const toggleCampaignApprovedStatus = async () => {
    if (toggleCampaignApprovedStatusIsLoading.value) return;

    toggleCampaignApprovedStatusIsLoading.value = true;

    const actionValue = !campaignIsApproved.value;

    if (isDetails) {
      await campaignStore.dispatch('CAMPAIGN_TOGGLE_APPROVED_STATUS', actionValue);
    } else {
      await clientStore.dispatch('CAMPAIGN_TOGGLE_APPROVED_STATUS', {
        campaign: campaign.value,
        value: actionValue,
      });
    }

    toggleCampaignApprovedStatusIsLoading.value = false;
  };

  return {
    campaignIsApproved,
    toggleCampaignApprovedStatusIsLoading,
    toggleCampaignApprovedStatus,
  };
};
