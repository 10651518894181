



























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    percent: { type: [String, Number], default: 0 },
    withProgress: { type: Boolean, default: true },
  },
});
