import { AxiosResponse } from 'axios';

import { CampaignType } from '@/models/Campaign';
import { TaxonomyClientCategory, TaxonomyBrandCategory } from '@/models/Taxonomy';

import httpClient from '../httpClient';

export default class TaxonomyApi {
  public static async fetchClientCategories(): Promise<AxiosResponse<TaxonomyClientCategory[]>> {
    const res = await httpClient.get<TaxonomyClientCategory[]>('/api/taxonomy/client-categories');

    return res;
  }

  public static async fetchBrandCategories(): Promise<AxiosResponse<TaxonomyBrandCategory[]>> {
    const res = await httpClient.get<TaxonomyBrandCategory[]>('/api/taxonomy/brand-categories');

    return res;
  }

  public static async fetchCampaignTypes(): Promise<AxiosResponse<CampaignType[]>> {
    const res = await httpClient.get<CampaignType[]>('/api/taxonomy/campaign-types');

    return res;
  }
}
