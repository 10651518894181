





















import { defineComponent, PropType } from '@vue/composition-api';

import { DTableCellSpacer } from '@shared/druid-kit';

import MetricsTableRow, { MetricCell } from '@/components/_newDesign/common/MetricsTable/MetricsTableRow.vue';

import InfoCell from '@/components/_newDesign/Campaigns/CampaignPlacementsTable/cells/InfoCell.vue';
import MarkupCell from '@/components/_newDesign/Campaigns/CampaignPlacementsTable/cells/MarkupCell.vue';
import TargetingCell from '@/components/_newDesign/Campaigns/CampaignPlacementsTable/cells/TargetingCell.vue';
import TypeCell from '@/components/_newDesign/Campaigns/CampaignPlacementsTable/cells/TypeCell.vue';

import { Placement } from '@/models/Placement';

export default defineComponent({
  components: {
    DTableCellSpacer,
    MetricsTableRow,
    InfoCell,
    MarkupCell,
    TargetingCell,
    TypeCell,
  },
  props: {
    placement: { type: Object as PropType<Placement>, required: true },
    metricCells: { type: Array as PropType<MetricCell[]>, default: () => [] },
  },
});
