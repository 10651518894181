





























import { defineComponent, toRefs, PropType, computed } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { Category } from '@/models/Category';

import { useCategoryDetailsStore } from '../../../store/categories/categoryDetails';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    rule: { type: Object as PropType<Category['rules'][number]>, required: true },
    index: { type: Number, default: 0 },
  },
  setup(props, context) {
    const {
      rule,
      index,
    } = toRefs(props);

    const categoryDetailsStore = useCategoryDetailsStore();

    const normalizedServiceTypes = computed(() => categoryDetailsStore.getters.normalizedServiceTypes);

    const serviceTypeName = computed(() => {
      const serviceType = normalizedServiceTypes.value.find(({ id }) => id === rule.value.serviceTypeId);

      return serviceType?.name || 'Все сервисы';
    });

    const deleteRule = () => {
      context.emit('delete-rule', index.value);
    };

    return {
      serviceTypeName,
      deleteRule,
    };
  },
});
