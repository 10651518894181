<template>
  <AppView class="d-flex justify-center align-center">
    <div>
      <div class="d-flex justify-center align-center mb-4">
        <v-icon
          class="mr-2"
          color="grey darken-3"
          size="48"
        >
          mdi-boom-gate
        </v-icon>
        <div class="text-h5 grey--text text--darken-3">
          Нет доступа
        </div>
      </div>
      <div class="mb-4 body-2 text-center grey--text text--darken-3">
        Эта страница не доступна для вашей учетной записи.<br>
        Обратитесь <a
          href="mailto:help@danbo.digital"
          target="_blank"
        >в поддержку</a> за доступом, или подождите немного, если ранее его запрашивали.
      </div>
      <div class="d-flex justify-center">
        <v-btn
          color="primary-gradient"
          rounded
          to="/"
        >
          Перейти на стартовую страницу
        </v-btn>
      </div>
    </div>
  </AppView>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

export default defineComponent({
  components: {
    AppView,
  },
});
</script>
