

































































import { defineComponent, toRefs, ref, computed, watch, ComponentInstance } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import notify from '@/services/notify';

import AppJsonError from '@/services/errorHandler/AppJsonError';

import { DataProvidersSettingsItem } from '@/models/DataProvidersSettings';

import { PartialWithout } from '@/utils/type';
import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { useDataProvidersSettingsCatalogStore } from '../../../store/dataProvidersSettings/dataProvidersSettingsCatalog';

import DataProvidersSettingsForm from '../forms/DataProvidersSettingsForm.vue';

export default defineComponent({
  components: {
    AppModal,
    DataProvidersSettingsForm,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const dataProvidersSettingsCatalogStore = useDataProvidersSettingsCatalogStore();

    const dataProviderSettingsFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const dataProviderSettingsFormData = ref<PartialWithout<DataProvidersSettingsItem, 'id'> | null>(null);
    const dataProviderSettingsFormIsValid = ref(false);

    const dataProviderSettingsFormResetValidation = async () => {
      if (!dataProviderSettingsFormRef.value) return;

      dataProviderSettingsFormRef.value.resetValidation();
    };

    const newDataProviderSettings = computed(() => {
      if (!dataProviderSettingsFormData.value || !dataProviderSettingsFormIsValid.value) return null;

      return dataProviderSettingsFormData.value;
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => !newDataProviderSettings.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!newDataProviderSettings.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await dataProvidersSettingsCatalogStore.actions.CREATE_PROVIDER_SETTINGS(newDataProviderSettings.value);

        notify.push({
          text: 'Новая настройка добавлена',
          status: 'positive',
          position: ['bottom', 'center'],
        });

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const messages = Object.values(error.response?.data?.errors || {})
            .reduce<string[]>((acc, val) => {
              if (Array.isArray(val)) {
                acc.push(...val);
              } else if (typeof val === 'string') {
                acc.push(val);
              }

              return acc;
            }, []);

          saveError.value = {
            title: 'Произошла ошибка',
            messages: messages.length ? messages : ['Обратитесь к администратору'],
          };
        }

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        saving.value = false;
      }
    };

    watch(value, async (val) => {
      if (!val) return;

      dataProviderSettingsFormData.value = null;

      dataProviderSettingsFormResetValidation();
    }, { immediate: true });

    return {
      dataProviderSettingsFormRef,
      dataProviderSettingsFormData,
      dataProviderSettingsFormIsValid,
      saving,
      saveBtnIsDisabled,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
