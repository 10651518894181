




















import { defineComponent, toRefs, provide, computed, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignDetailsHeader from '@/components/_newDesign/Campaigns/CampaignDetails/CampaignDetailsHeader.vue';
import CampaignDetailsContent from '@/components/_newDesign/Campaigns/CampaignDetails/CampaignDetailsContent.vue';
import CampaignDetailsCategoriesEditModal from '@/components/_newDesign/Campaigns/CampaignDetails/modals/CampaignDetailsCategoriesEditModal.vue';

import useQueryFilters from '@/composables/useQueryFilters';
import useModals from '@/composables/useModals';
import useMeta from '@/composables/meta/useMeta';
import { useCampaignStore } from '@/store/campaign';
import { useLayoutLoadingStatus } from '@/composables/layout';
import { useCampaignDetails } from '@/composables/campaign';

export default defineComponent({
  components: {
    AppView,
    CampaignDetailsHeader,
    CampaignDetailsContent,
    CampaignDetailsCategoriesEditModal,
  },
  props: {
    campaignId: { type: String, required: true },
  },
  setup(props, context) {
    const { campaignId } = toRefs(props);

    const campaignStore = useCampaignStore();

    const {
      queryFilters,
      updateQueryFilters,
    } = useQueryFilters({
      filters: {
        from: undefined,
        to: undefined,
      },
    }, context);

    provide('queryFilters', queryFilters);
    provide('updateQueryFilters', updateQueryFilters);

    const {
      modalsMap,
      openModal,
    } = useModals(['categories']);

    const {
      isLoading,
      updateIsLoading,
    } = useLayoutLoadingStatus();

    const {
      campaign,
      campaignCategories,
      fetchCampaign,
      campaignPageTitle,
    } = useCampaignDetails({
      campaignId,
      filters: queryFilters,
    });

    const pageMeta = computed(() => ({ title: campaignPageTitle.value }));

    const client = computed(() => campaignStore.getters.client);
    const clientBrand = computed(() => campaignStore.getters.clientBrand);
    const categoriesIsEdit = computed(() => !client?.value?.categoriesIds.length || !clientBrand?.value?.categoriesIds.length || !campaign.value?.categoriesIds.length || !campaign.value?.typeId);

    useMeta(pageMeta);

    const fetchClient = async (clientId: string) => {
      await campaignStore.dispatch('FETCH_CLIENT', clientId);
    };

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await fetchCampaign();

        if (!campaign.value || !context.root.$acl.check('set_client_categories')) return;

        await fetchClient(campaign.value.clientId);

        if (categoriesIsEdit.value) {
          openModal({
            slug: 'categories',
            props: { campaignId: campaign.value?.id },
          });
        }
      } finally {
        updateIsLoading(false);
      }
    };

    watch(queryFilters, () => fetch());

    fetch();

    return {
      modalsMap,
      isLoading,
      campaign,
      campaignCategories,
    };
  },
});
