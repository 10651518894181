





































import { defineComponent, PropType } from '@vue/composition-api';

import { Campaign, CampaignEditorPlacementDetails } from '@/models/Campaign';

import PlacementEditorInfo from './PlacementEditorInfo.vue';
import PlacementEditorFlights from './PlacementEditorFlights/PlacementEditorFlights.vue';

export default defineComponent({
  components: {
    PlacementEditorInfo,
    PlacementEditorFlights,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    placement: { type: Object as PropType<CampaignEditorPlacementDetails>, required: true },
    currentPlacement: { type: Object as PropType<CampaignEditorPlacementDetails>, required: true },
  },
});
