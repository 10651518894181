import { AxiosResponse } from 'axios';

import httpClient from '../httpClient';

export default class FactUploadApi {
  // TODO: Описать типы
  public static async uploadFile(payload: FormData): Promise<AxiosResponse<unknown>> {
    const res = await httpClient.post<unknown>('/api/factupload/fileupload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res;
  }
}
