import { AxiosResponse } from 'axios';

import { MetricType } from '@/models/MetricType';

import httpClient from '../httpClient';

export default class ReferenceApi {
  public static async fetchMetricTypes(): Promise<AxiosResponse<MetricType[]>> {
    const res = await httpClient.get<MetricType[]>('/api/reference/metric-types', { cache: true });

    return res;
  }
}
