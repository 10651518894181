















import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import moment from 'moment';

import { DTableCell } from '@shared/druid-kit';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  components: {
    DTableCell,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    progressProps: { type: Object as PropType<{ color: string }>, default: () => ({}) },
  },
  setup(props) {
    const { campaign } = toRefs(props);

    const formattedStartDate = computed(() => moment.utc(campaign.value.startDate).format('DD.MM'));
    const formattedEndDate = computed(() => moment.utc(campaign.value.endDate).format('DD.MM.YYYY'));

    const percent = computed(() => {
      const start = moment(campaign.value.startDate).startOf('day');
      const end = moment(campaign.value.endDate).endOf('day');
      const now = moment();

      const total = end.valueOf() - start.valueOf();
      const elapsed = now.valueOf() - start.valueOf();

      return elapsed > total ? 100 : (elapsed / total) * 100;
    });

    return {
      formattedStartDate,
      formattedEndDate,
      percent,
    };
  },
});
