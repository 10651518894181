import { computed, Ref, ComputedRef } from '@vue/composition-api';

import { Client } from '@/models/Client';

interface UseClientPathsProps {
  client: Ref<Client>;
}

interface UseClientPathsReturn {
  path: ComputedRef<string>;
  unapprovedPlacementsPath: ComputedRef<string>;
}

export default (props: UseClientPathsProps): UseClientPathsReturn => {
  const { client } = props;

  const path = computed(() => `/clients/${client.value.id}`);

  const unapprovedPlacementsPath = computed(() => `/placements?s=${client.value.name}`);

  return {
    path,
    unapprovedPlacementsPath,
  };
};
