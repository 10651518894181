

























import { defineComponent } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

export default defineComponent({
  components: {
    DBtnInline,
  },
});
