/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';
import { UnapprovedPlacementsApi } from '@/api';

class ModuleState {
  unapprovedPlacementsCount = 0;
}

class ModuleGetters extends Getters<ModuleState> {
  get unapprovedPlacementsCount() {
    return this.state.unapprovedPlacementsCount;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_UNAPPROVED_PLACEMENTS_COUNT(count: number) {
    this.state.unapprovedPlacementsCount = count;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_UNAPPROVED_PLACEMENTS_COUNT() {
    const res = await UnapprovedPlacementsApi.fetchCountV2();

    this.commit('SET_UNAPPROVED_PLACEMENTS_COUNT', res.data);
  }

  async CHANGE_UNAPPROVED_PLACEMENTS_COUNT(count: number) {
    this.commit('SET_UNAPPROVED_PLACEMENTS_COUNT', count);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useNavigationUnapprovedPlacementsStore = createComposable(module);
export const navigationUnapprovedPlacementsStoreMapper = createMapper(module);

export default module;
