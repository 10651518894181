





































import { defineComponent, toRefs, computed } from '@vue/composition-api';

import { debounce } from 'lodash';

import { DBtnInline } from '@shared/druid-kit';

import { useMarkupsStore } from '../../store';

export default defineComponent({
  components: {
    DBtnInline,
  },
  props: {
    placementId: { type: String, default: '' },
    byPlacement: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      placementId,
      byPlacement,
    } = toRefs(props);

    const markupsStore = useMarkupsStore();

    const placement = computed(() => markupsStore.getters.placement);

    const title = computed(() => (byPlacement.value ? `Наценки ${placement.value?.siteName} (${placement.value?.danboId})` : 'Наценки'));

    const query = computed({
      get() {
        return markupsStore.getters.filters.query;
      },
      set: debounce((val: string) => {
        markupsStore.dispatch('UPDATE_FILTERS', { query: val });
      }, 300),
    });

    const openAddModal = () => {
      context.emit('open-modal', {
        slug: 'add',
        props: { placementId: placementId.value },
      });
    };

    return {
      title,
      query,
      openAddModal,
    };
  },
});
