/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { MetricsCheckApi } from '@/api';

import {
  MetricsCheckTotal,
  MetricsCheckDataProvider,
  MetricsCheckDataProviderMarkup,
} from '@/models/MetricsCheck';

import AppJsonError from '@/services/errorHandler/AppJsonError';

import isError from '@/utils/isError';

class ModuleState {
  total: MetricsCheckTotal | null = null;

  totalByDataProvider: MetricsCheckDataProvider[] = [];

  rawTotalByDataProvider: MetricsCheckDataProvider[] = [];

  errors: string[] = [];

  serachQuery = '';

  isLoading = false;
}

class ModuleGetters extends Getters<ModuleState> {
  get total() {
    return this.state.total;
  }

  get totalByDataProvider() {
    return this.state.totalByDataProvider;
  }

  get rawTotalByDataProvider() {
    return this.state.rawTotalByDataProvider;
  }

  get errors() {
    return this.state.errors;
  }

  get searchQuery() {
    return this.state.serachQuery;
  }

  get isLoading() {
    return this.state.isLoading;
  }

  get markupByDataProvider() {
    return this.getters.totalByDataProvider
      .reduce((acc: Record<string, MetricsCheckDataProviderMarkup['markups']>, dataProvider) => {
        if (dataProvider.markup && 'markups' in dataProvider.markup) {
          acc[dataProvider.dataProviderName] = dataProvider.markup.markups;
        }

        return acc;
      }, {});
  }

  get metricsNames() {
    const totalMetricsNames = this.getters.total?.metrics ? Object.keys(this.getters.total.metrics) : [];

    const allDataProvidersMetricsNames = [...this.getters.totalByDataProvider, ...this.getters.rawTotalByDataProvider]
      .reduce((acc: string[], dataProvider) => {
        acc = [...acc, ...Object.keys(dataProvider.metrics)];

        return acc;
      }, []);

    return [...new Set<string>([...totalMetricsNames, ...allDataProvidersMetricsNames])];
  }

  get dataProvidersNames() {
    const allDataProvidersNames = [...this.getters.totalByDataProvider, ...this.getters.rawTotalByDataProvider]
      .reduce((acc: string[], dataProvider) => {
        acc = [...acc, dataProvider.dataProviderName];

        return acc;
      }, []);

    return [...new Set<string>(allDataProvidersNames)];
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_TOTAL(payload: MetricsCheckTotal) {
    this.state.total = payload;
  }

  SET_TOTAL_BY_DATA_PROVIDER(payload: MetricsCheckDataProvider[]) {
    this.state.totalByDataProvider = payload;
  }

  SET_RAW_TOTAL_BY_DATA_PROVIDER(payload: MetricsCheckDataProvider[]) {
    this.state.rawTotalByDataProvider = payload;
  }

  SET_ERRORS(payload: string[]) {
    this.state.errors = payload;
  }

  RESET() {
    this.state.total = null;
    this.state.totalByDataProvider = [];
    this.state.rawTotalByDataProvider = [];
    this.state.errors = [];
  }

  SET_SEARCH_QUERY(payload: string) {
    this.state.serachQuery = payload;
  }

  SET_IS_LOADING(payload: boolean) {
    this.state.isLoading = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_TOTAL(danboId: string) {
    this.commit('SET_IS_LOADING', true);

    this.commit('RESET');

    try {
      const res = await MetricsCheckApi.fetchTotal(danboId);

      if (res.data.succeseded) {
        if (res.data?.total) {
          this.commit('SET_TOTAL', res.data.total);
        }

        this.commit('SET_TOTAL_BY_DATA_PROVIDER', res.data.totalByDataProvider);
        this.commit('SET_RAW_TOTAL_BY_DATA_PROVIDER', res.data.rawTotalByDataProvider);
      } else {
        this.commit('SET_ERRORS', res.data.errors);
      }
    } catch (error) {
      if (!isError(error)) return;

      this.commit('SET_ERRORS', ['Произошла непредвиденная ошибка']);

      throw new AppJsonError({ error, options: { showingModal: false } });
    } finally {
      this.commit('SET_IS_LOADING', false);
      this.commit('SET_SEARCH_QUERY', danboId);
    }
  }

  UPDATE_SEARCH_QUERY(payload: string) {
    this.commit('SET_SEARCH_QUERY', payload);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const metricsCheckStoreMapper = createMapper(module);

export const useMetricsCheckStore = createComposable(module);

export default module;
