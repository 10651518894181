



































import { defineComponent, computed, toRefs, PropType } from '@vue/composition-api';

import { DTableCellSpacer } from '@shared/druid-kit';

import MetricsTable from '@/components/_newDesign/common/MetricsTable/MetricsTable.vue';
import MetricsTableRow from '@/components/_newDesign/common/MetricsTable/MetricsTableRow.vue';

interface Item {
  metricsTotal: Record<string, number>
}

export default defineComponent({
  components: {
    DTableCellSpacer,
    MetricsTable,
    MetricsTableRow,
  },
  props: {
    items: { type: Array as PropType<Item[]>, default: () => [] },
  },
  setup(props) {
    const { items } = toRefs(props);

    const actualDisplayableMetricsNames = computed(() => {
      const allMetricsTotalNames = items.value
        .reduce((acc: string[], { metricsTotal }) => {
          acc = [...acc, ...Object.keys(metricsTotal)];

          return acc;
        }, []);

      return [...new Set<string>(allMetricsTotalNames)];
    });

    const metricCells = computed(() => actualDisplayableMetricsNames.value
      .map((name) => ({
        metric: { name },
        onlyFact: true,
        disabledTooltip: true,
      })));

    const headers = computed(() => [
      {
        class: '',
        text: '',
        value: 'name',
        sortable: false,
      },
      ...metricCells.value.map((metricCell) => ({
        class: 'caption',
        text: metricCell.metric.name,
        value: `metricsTotal.${metricCell.metric.name}`,
        sortable: false,
      })),
      {
        text: '',
        value: '',
        sortable: false,
      },
    ]);

    return {
      metricCells,
      headers,
    };
  },
});
