














































































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { DAutocomplete } from '@shared/druid-kit';

import AppClientsTreeView from '@/components/_newDesign/common/inputs/AppClientsTreeView.vue';

import { UserClientsBrands } from '@/models/User';

import { useUsersDetailsStore } from '../../../store/users/userDetails';
import { useRolesCatalogStore } from '../../../store/roles/rolesCatalog';

import { UsersFormData } from '../../../models/users';

export default defineComponent({
  components: {
    DAutocomplete,
    AppClientsTreeView,
  },
  props: {
    value: { type: Object as PropType<UsersFormData | null>, default: null },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    existUsers: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
      loading,
    } = toRefs(props);

    const userDetailsStore = useUsersDetailsStore();
    const rolesCatalogStore = useRolesCatalogStore();

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const clientsTreeViewRef = ref<ComponentInstance & { collapseTree(): void } | null>(null);

    const isValid = ref(false);

    const isEdit = computed(() => !!value.value?.id);
    const groupsClientsBrands = computed(() => userDetailsStore.getters.groupsClientsBrands);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updateForm = (payload: Partial<UsersFormData>) => {
      const newForm = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newForm);
    };

    const collapseClientsTree = () => {
      if (!clientsTreeViewRef.value) return;

      clientsTreeViewRef.value.collapseTree();
    };

    // // // // //

    const firstNameRules = ref([
      (val: string) => !!val,
    ]);

    const firstNameIsLoading = computed(() => loading.value);
    const firstNameIsDisabled = computed(() => firstNameIsLoading.value || isEdit.value);

    const currentFirstName = computed({
      get() {
        return value.value?.firstName || '';
      },
      set(val: string) {
        updateForm({ firstName: val });
      },
    });

    // // // // //

    const lastNameRules = ref([
      (val: string) => !!val,
    ]);

    const lastNameIsLoading = computed(() => loading.value);
    const lastNameIsDisabled = computed(() => lastNameIsLoading.value || isEdit.value);

    const currentLastName = computed({
      get() {
        return value.value?.lastName || '';
      },
      set(val: string) {
        updateForm({ lastName: val });
      },
    });

    // // // // //

    const emailRules = ref([
      (val: string) => !!val && /\S+@\S+\.\S+/.test(val),
    ]);

    const emailIsLoading = computed(() => loading.value);
    const emailIsDisabled = computed(() => emailIsLoading.value || isEdit.value);

    const currentEmail = computed({
      get() {
        return value.value?.email || '';
      },
      set(val: string) {
        updateForm({ email: val });
      },
    });

    // // // // //

    const existEmailsRules = ref([
      (val: string) => {
        const splitEmails = val.split('\n');

        return splitEmails.length && splitEmails.every((email) => /\S+@\S+\.\S+/.test(email));
      },
    ]);

    const existEmailsIsLoading = computed(() => loading.value);
    const existEmailsIsDisabled = computed(() => emailIsLoading.value);

    const currentExistEmails = computed({
      get() {
        return value.value?.emails ? value.value?.emails.join('\n') : '';
      },
      set(val: string) {
        updateForm({ emails: val.split('\n') });
      },
    });

    // // // // //

    const roles = computed(() => rolesCatalogStore.getters.roles);
    const rolesIsLoadingData = ref(false);

    const rolesIsLoading = computed({
      get() {
        return rolesIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        rolesIsLoadingData.value = val;
      },
    });

    const rolesIsDisabled = computed(() => !roles.value.length || rolesIsLoading.value);

    const currentRoles = computed({
      get() {
        return value.value?.roles || [];
      },
      set(val: string[]) {
        updateForm({ roles: val });
      },
    });

    const fetchRoles = async () => {
      rolesIsLoading.value = true;

      try {
        await rolesCatalogStore.actions.FETCH_ROLES();
      } finally {
        rolesIsLoading.value = false;
      }
    };

    // // // // //

    const groups = computed(() => userDetailsStore.getters.groups);
    const groupsIsLoadingData = ref(false);

    const groupsIsLoading = computed({
      get() {
        return groupsIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        groupsIsLoadingData.value = val;
      },
    });

    const groupsIsDisabled = computed(() => !groups.value.length || groupsIsLoading.value);

    const currentGroups = computed({
      get() {
        return value.value?.groups || [];
      },
      set(val: string[]) {
        updateForm({ groups: val });
        userDetailsStore.actions.FETCH_GROUPS_CLIENTS_BRANDS(val);
      },
    });

    const fetchGroups = async () => {
      groupsIsLoading.value = true;

      try {
        await userDetailsStore.actions.FETCH_GROUPS();
      } finally {
        groupsIsLoading.value = false;
      }
    };

    // // // // //

    const currentClients = computed({
      get() {
        return value.value?.clients || [];
      },
      set(val: UserClientsBrands[]) {
        updateForm({ clients: val });
      },
    });

    // // // // //

    const init = async () => {
      await Promise.all([
        fetchRoles(),
        fetchGroups(),
      ]);
    };

    init();

    return {
      rootRef,
      clientsTreeViewRef,
      isValid,
      resetValidation,
      collapseClientsTree,
      //
      currentExistEmails,
      existEmailsRules,
      existEmailsIsLoading,
      existEmailsIsDisabled,
      //
      currentFirstName,
      firstNameRules,
      firstNameIsLoading,
      firstNameIsDisabled,
      //
      currentLastName,
      lastNameRules,
      lastNameIsLoading,
      lastNameIsDisabled,
      //
      currentEmail,
      emailRules,
      emailIsLoading,
      emailIsDisabled,
      //
      roles,
      currentRoles,
      rolesIsLoading,
      rolesIsDisabled,
      //
      groups,
      currentGroups,
      groupsClientsBrands,
      groupsIsLoading,
      groupsIsDisabled,
      //
      currentClients,
      //
    };
  },
});
