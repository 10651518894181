




























import { defineComponent, toRefs, computed } from '@vue/composition-api';

import ModallyWrapper from './ModallyModalWrapper.vue';
import ModallyHeader from './ModallyModalHeader.vue';
import ModallyBody from './ModallyModalBody.vue';
import ModallyFooter from './ModallyModalFooter.vue';

import { ModallyModalButton } from '../types';

export default defineComponent({
  components: {
    ModallyWrapper,
    ModallyHeader,
    ModallyBody,
    ModallyFooter,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    title: {
      type: [String, Number],
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, context) {
    const { status } = toRefs(props);

    const statusBtnColor = computed(() => {
      const statusBtnColors = {
        neutral: 'accent',
        positive: 'success',
        warning: 'warning',
        error: 'error',
      };

      return statusBtnColors[status.value] || 'primary';
    });

    const onBtnClick = (button: ModallyModalButton<unknown>, index: number) => {
      context.emit('on-btn-click', { button, index });
    };

    return {
      statusBtnColor,
      onBtnClick,
    };
  },
});
