














































































import { defineComponent, toRefs, ref, computed, watch } from '@vue/composition-api';

import moment from 'moment';

import { DDatePickerTextField } from '@shared/druid-kit';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import notify from '@/services/notify';
import AppJsonError from '@/services/errorHandler/AppJsonError';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { useMarkupDetailsStore } from '../../../../store/markupDetails';

export default defineComponent({
  components: {
    AppModal,
    DDatePickerTextField,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const markupDetailsStore = useMarkupDetailsStore();

    const maxMarkupPeriodStartDate = computed(() => markupDetailsStore.getters.maxMarkupPeriodStartDate);

    const currentStartDate = ref(moment(maxMarkupPeriodStartDate.value).add(1, 'day').format('YYYY-MM-DD'));

    const currentMultiplier = ref(1);

    const newMarkupPeriod = computed(() => {
      if (!currentStartDate.value || !currentMultiplier.value) return null;

      return {
        startDate: moment.utc(currentStartDate.value).toISOString(),
        multiplier: currentMultiplier.value,
      };
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => saving.value || !newMarkupPeriod.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!newMarkupPeriod.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await markupDetailsStore.dispatch('ADD_MARKUP_PERIOD', newMarkupPeriod.value);

        notify.push({
          text: 'Наценка добавлена',
          position: ['bottom', 'center'],
          status: 'positive',
        });

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const message = error.response?.data?.message;

          saveError.value = {
            title: 'Произошла ошибка',
            messages: [message],
          };
        } else {
          saveError.value = {
            title: 'Произошла ошибка. Обратитесь к администратору',
            messages: [],
          };

          throw new AppJsonError({ error, options: { showingModal: false } });
        }
      } finally {
        saving.value = false;
      }
    };

    watch(value, (val) => {
      if (!val) return;

      currentStartDate.value = moment(maxMarkupPeriodStartDate.value).add(1, 'day').format('YYYY-MM-DD');
      currentMultiplier.value = 1;
    });

    return {
      currentStartDate,
      currentMultiplier,
      saveBtnIsDisabled,
      saveError,
      save,
    };
  },
});
