



























































import { defineComponent, computed, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';
import useModals from '@/composables/useModals';

import DataProvidersSettingsCatalogHeader from '../../components/DataProvidersSettings/DataProvidersSettingsCatalog/DataProvidersSettingsCatalogHeader.vue';
import DataProvidersSettingsTable from '../../components/DataProvidersSettings/DataProvidersSettingsCatalog/DataProvidersSettingsTable/DataProvidersSettingsTable.vue';

import DataProvidersSettingsAddModal from '../../components/DataProvidersSettings/modals/DataProvidersSettingsAddModal.vue';
import DataProvidersSettingsItemModal from '../../components/DataProvidersSettings/modals/DataProvidersSettingsItemModal.vue';

import { useDataProvidersSettingsCatalogStore } from '../../store/dataProvidersSettings/dataProvidersSettingsCatalog';

export default defineComponent({
  components: {
    AppView,
    DataProvidersSettingsCatalogHeader,
    DataProvidersSettingsTable,
    DataProvidersSettingsAddModal,
    DataProvidersSettingsItemModal,
  },
  setup() {
    const { updateIsLoading } = useLayoutLoadingStatus();

    const {
      modalsMap,
      openModal,
    } = useModals(['add', 'item']);

    const dataProvidersSettingsCatalogStore = useDataProvidersSettingsCatalogStore();

    const providersSettings = computed(() => dataProvidersSettingsCatalogStore.getters.providersSettings);
    const providersSettingsFilters = computed(() => dataProvidersSettingsCatalogStore.getters.filters);
    const providersSettingsFiltersChanged = computed(() => dataProvidersSettingsCatalogStore.getters.filtersChanged);
    const providersSettingsIsLoading = computed(() => dataProvidersSettingsCatalogStore.getters.isLoading);

    const fetch = async () => {
      await dataProvidersSettingsCatalogStore.actions.FETCH_PROVIDERS_SETTINGS();
    };

    const init = async () => {
      updateIsLoading(true);
      dataProvidersSettingsCatalogStore.actions.RESET_FILTERS();

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    watch(providersSettingsFilters, () => {
      if (!providersSettingsFiltersChanged.value) return;

      fetch();
    });

    init();

    return {
      modalsMap,
      providersSettings,
      providersSettingsFiltersChanged,
      providersSettingsIsLoading,
      openModal,
    };
  },
});
