




















import Vue, { VueConstructor, PropType } from 'vue';

import { HnTableCellMixin } from '@/packages/hn-table/src/mixins';

import { CampaignEditorPlacement } from '@/models/Campaign';

import { CampaignEditorPlacementsEmitter } from '../../campaignEditorPlacementsEmitter';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'PlacementsTableMarkupsCell',
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    campaignPlacements: { type: Array as PropType<CampaignEditorPlacement[]>, required: true },
    emitter: { type: Object as PropType<CampaignEditorPlacementsEmitter>, required: true },
  },
  data() {
    return {
      placement: null as CampaignEditorPlacement | null,
    };
  },
  computed: {
    cellPropertiesRow(): number | undefined {
      return this.cellProperties?.row;
    },
  },
  watch: {
    hotInstance: {
      handler(newValue, oldValue) {
        oldValue?.removeHook('afterChange', this.afterChange);

        newValue?.addHook('afterChange', this.afterChange);
      },
      immediate: true,
    },
    campaignPlacements: {
      handler() {
        this.initPlacement();
      },
      immediate: true,
    },
    async cellPropertiesRow() {
      await this.initPlacement();
    },
  },
  beforeDestroy() {
    this.hotInstance?.removeHook('afterChange', this.afterChange);
  },
  methods: {
    async initPlacement() {
      if (typeof this.cellPropertiesRow !== 'number') return;

      await this.$nextTick();

      const campaignPlacement = this.campaignPlacements[this.cellPropertiesRow];

      this.placement = campaignPlacement;
    },
    afterChange() {
      this.initPlacement();
    },
    openModal() {
      if (!this.placement) return;

      this.emitter.emit('openMarkupsModal', { placement: this.placement });
    },
  },
});
