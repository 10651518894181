/* eslint-disable max-classes-per-file */

import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { oidcToRefs, useOidc } from '@shared/vue-oidc';

import { User } from '@/models/User';

import { AccountsApi } from '@/api';

class ModuleState {
  user: User | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  store!: Store<unknown>;

  $init(store: Store<unknown>) {
    this.store = store;
  }

  get userSignedIn(): boolean {
    const oidc = useOidc();

    const { oidcIsAuthenticated } = oidcToRefs(oidc);

    return oidcIsAuthenticated.value || false;
  }

  get user() {
    return this.state.user;
  }

  get userName() {
    return this.getters.user?.name || '';
  }

  get userInitials() {
    const names = this.getters.userName.split(' ');
    const first = names[0] ? names[0][0] : '';
    const last = names[1] ? names[1][0] : '';

    return `${first}${last}` || 'JD';
  }

  get userEmail() {
    return this.getters.user?.email || '';
  }

  get userImage() {
    return this.getters.user?.image || '';
  }

  get userPermissions() {
    return this.getters.user?.permissions || ['any'];
  }

  get userIsDp() {
    return this.getters.user?.isDP || false;
  }

  get userIsBu() {
    return this.getters.user?.isBU || false;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_USER(payload: User) {
    this.state.user = payload;
  }

  RESET() {
    const defaultState = new ModuleState();

    Object.keys(defaultState).forEach((key) => {
      this.state[key] = defaultState[key];
    });
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  store!: Store<unknown>;

  $init(store: Store<unknown>) {
    this.store = store;
  }

  async SIGN_OUT() {
    const oidc = useOidc();

    await oidc.signOutOidc();

    this.commit('RESET');
  }

  async FETCH_USER() {
    const res = await AccountsApi.fetchUser();

    this.commit('SET_USER', res.data);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const authStoreMapper = createMapper(module);

export const useAuthStore = createComposable(module);

export default module;
