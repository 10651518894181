/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import groupsCatalog from './groups/groupsCatalog';
import groupsDetails from './groups/groupsDetails';

const module = new Module({
  modules: {
    groupsCatalog,
    groupsDetails,
  },
});

export const useGroupsStore = createComposable(module);

export default module;
