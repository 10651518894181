var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DTableRow',{staticClass:"data-providers-settings-table-row"},[_c('DTableCell',[_c('DTooltip',{attrs:{"center":"","bottom":"","interactive":"","disabled":!_vm.dataProviderSettings.finishedWithError},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","color":_vm.statusIcon.color}},'v-icon',attrs,false),on),[_vm._v("\n          "+_vm._s(_vm.statusIcon.icon)+"\n        ")])]}}])},[_vm._v(" "),(_vm.dataProviderSettings.statusText)?_c('span',[_vm._v("\n        "+_vm._s(_vm.dataProviderSettings.statusText)+"\n      ")]):_vm._e()])],1),_vm._v(" "),_c('DTableCell',{staticClass:"data-providers-settings-table-row__cell data-providers-settings-table-row__cell--name font-weight-medium",on:{"click":_vm.openItemModal}},[_vm._v("\n    "+_vm._s(_vm.dataProviderSettings.name || '—')+"\n  ")]),_vm._v(" "),_c('DTableCell',[_vm._v("\n    "+_vm._s(_vm.dataProviderSettings.client || '—')+"\n  ")]),_vm._v(" "),_c('DTableCell',[_vm._v("\n    "+_vm._s(_vm.dataProviderSettings.provider || '—')+"\n  ")]),_vm._v(" "),_c('DTableCell',[_vm._v("\n    "+_vm._s(_vm.dataProviderSettings.token || '—')+"\n  ")]),_vm._v(" "),_c('DTableCell',{staticClass:"provider-settings-table-row__actions-cell",attrs:{"align":"right"}},[(_vm.dataProviderSettings.inProgress)?_c('div',{staticClass:"grey--text"},[_vm._v("\n      Данные обновляются...\n    ")]):_c('DTooltip',{attrs:{"center":"","bottom":"","interactive":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var btnHover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"disabled":!_vm.dataProviderSettings.available,"color":btnHover ? 'primary' : '',"icon":"","small":""},on:{"click":_vm.startGatherStatistics}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("\n              mdi-play\n            ")])],1)]}}],null,true)})]}}])},[_vm._v(" "),_c('span',[_vm._v("Отправить запрос на сбор статистики")])])],1),_vm._v(" "),_c('DTableCell',{staticClass:"provider-settings-table-row__actions-cell"},[_c('v-btn',{staticClass:"data-providers-settings-table-row__btn data-providers-settings-table-row__btn--delete",attrs:{"icon":"","small":""},on:{"click":_vm.deleteDataProviderSettings}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("\n        mdi-delete-outline\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }