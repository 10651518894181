































import { defineComponent, PropType, ref } from '@vue/composition-api';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup() {
    const tabs = ref([
      {
        label: 'Размещения',
        to: { name: 'campaign-editor-placements' },
      },
      {
        label: 'Цели',
        to: { name: 'campaign-editor-goals' },
      },
      {
        label: 'Категории',
        to: { name: 'campaign-editor-categories' },
      },
      {
        label: 'Основные настройки',
        to: { name: 'campaign-editor-settings' },
      },
    ]);

    return {
      tabs,
    };
  },
});
