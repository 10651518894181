







































































import Vue, { VueConstructor, PropType } from 'vue';

import {
  DSelect,
  DTooltip,
} from '@shared/druid-kit';

import HnTableCellMixin from '../../mixins/HnTableCellMixin.vue';

import {
  HnTableSelectCellValue,
  HnTableSelectCellItem,
  HnTableSelectCellItems,
} from '../../types';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'HnTableSelectCell',
  components: {
    DSelect,
    DTooltip,
  },
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    items: { type: [Array, Function] as PropType<HnTableSelectCellItems>, default: () => [] },
    hideSelected: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    itemText: { type: String, default: 'text' },
    itemValue: { type: String, default: 'value' },
    tooltip: { type: Object as PropType<{ props: Record<string, unknown>; text: string } | null>, default: () => null },
  },
  data() {
    return {
      value: null as HnTableSelectCellValue | null,
      //
      editorInputFocusActionName: 'activateMenu',
    };
  },
  computed: {
    selectItems(): HnTableSelectCellItem[] {
      if (this.hotInstance?.isDestroyed) return [];

      if (typeof this.items === 'function') {
        return this.items({
          TD: this.TD,
          hotInstance: this.hotInstance,
          cellProperties: this.cellProperties,
          originalValue: this.originalValue,
          value: this.value,
          row: this.row,
          col: this.col,
          prop: this.prop,
        });
      }

      return this.items;
    },
    rendererValue(): string | number {
      const value = this.isRenderer ? this.value : this.originalValue;
      const normalizedValue = Array.isArray(value) ? value : [value];

      const intersectionItems = this.selectItems.filter((item) => normalizedValue.includes(item[this.itemValue]));

      return intersectionItems.map((item) => item[this.itemText]).join(', ') || '—';
    },
    editorValue(): HnTableSelectCellValue {
      if (this.originalValue === '') return null;

      return this.originalValue ?? null;
    },
  },
  watch: {
    // Здесь нужен вотч, т.к. компьютед не всеггда реагирует на изменения, в частности при открытии выпадающего списка мультиселекта
    value(val) {
      if (this.multiple && !Array.isArray(this.value)) {
        this.value = val.split(',').filter((item) => !!item);
      }
    },
  },
  methods: {
    onSelectInput(value: string) {
      this.setValue(value);

      this.finishEditing();
    },
  },
});
