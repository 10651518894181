import { AppRouteConfig } from '@/models/AppRouter';

import Dashboard from '../views/Dashboard.vue';

const routeConfig: AppRouteConfig = {
  name: 'dashboard',
  path: 'dashboard',
  component: Dashboard,
  meta: {
    title: 'Аналитический дашборд',
    permission: 'admin',
    order: 0,
  },
};

export default routeConfig;
