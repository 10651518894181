
























































import { defineComponent, ref, computed, watch } from '@vue/composition-api';

import { useLayoutLoadingStatus } from '@/composables/layout';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useProviderSettingsStore } from '../store';

import ProviderSettingsHeader from '../components/ProviderSettingsHeader.vue';
import ProviderSettingsTable from '../components/ProviderSettingsTable/ProviderSettingsTable.vue';
import ProviderSettingsItemModal from '../components/modals/ProviderSettingsItemModal.vue';

export default defineComponent({
  components: {
    AppView,
    ProviderSettingsHeader,
    ProviderSettingsTable,
    ProviderSettingsItemModal,
  },
  setup() {
    const providerSettingsStore = useProviderSettingsStore();

    const { updateIsLoading } = useLayoutLoadingStatus();

    const isLoading = ref(false);

    const providersSettings = computed(() => providerSettingsStore.getters.providersSettings);
    const providerSettingsFilters = computed(() => providerSettingsStore.getters.filters);
    const providerSettingsFiltersChanged = computed(() => providerSettingsStore.getters.filtersChanged);

    const modalIsShow = computed({
      get() {
        return providerSettingsStore.getters.modalIsShow;
      },
      set(val: boolean) {
        if (!val) providerSettingsStore.dispatch('RESET_EDIT_PROVIDER_SETTINGS');
      },
    });

    const init = async () => {
      updateIsLoading(true);
      providerSettingsStore.dispatch('RESET_FILTERS');

      try {
        await providerSettingsStore.dispatch('FETCH_PROVIDERS_SETTINGS_LIST');
      } finally {
        updateIsLoading(false);
      }
    };

    const fetch = async () => {
      isLoading.value = true;

      try {
        await providerSettingsStore.dispatch('FETCH_PROVIDERS_SETTINGS_LIST');
      } finally {
        isLoading.value = false;
      }
    };

    const loadNextPage = async () => {
      providerSettingsStore.dispatch('NEXT_PAGE');
    };

    watch(providerSettingsFilters, () => {
      if (!providerSettingsFiltersChanged.value) return;

      fetch();
    });

    const createSetting = () => {
      providerSettingsStore.dispatch('CREATE_PROVIDER_SETTINGS');
    };

    init();

    return {
      isLoading,
      loadNextPage,
      providersSettings,
      modalIsShow,
      createSetting,
      providerSettingsFiltersChanged,
    };
  },
});
