import { AxiosResponse } from 'axios';

import { Placement, PlacementManualState } from '@/models/Placement';

import httpClient from '../httpClient';

export default class PlacementsApi {
  public static async fetchPlacementsByCampaign(campaignId: string): Promise<AxiosResponse<Placement[]>> {
    const res = await httpClient.get<Placement[]>(`/api/placements/by-campaign/${campaignId}`);

    return res;
  }

  public static async fetchPlacement(placementId: string): Promise<AxiosResponse<Placement>> {
    const res = await httpClient.get<Placement>(`/api/placements/${placementId}`);

    return res;
  }

  public static async fetchManualStates(): Promise<AxiosResponse<PlacementManualState[]>> {
    const res = await httpClient.get<PlacementManualState[]>('/api/placements/get-manual-states');

    return res;
  }
}
