<template>
  <div class="unapproved-placements-loader rounded-0">
    <div class="unapproved-placements-loader__content">
      <ListLoader class="unapproved-placements-loader__list" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import ListLoader from '@/components/_newDesign/common/loaders/ListLoader.vue';

export default defineComponent({
  components: {
    ListLoader,
  },
});
</script>

<style lang="scss" scoped>

.unapproved-placements-loader {
  background-color: map-get($grey, 'lighten-5');

  &__content {
    padding: 44px 24px;
  }

  &__list {
    width: 16%;
  }
}
</style>
