


































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { Role } from '@/models/Role';

import { PartialWithout } from '@/utils/type';

import RolesItemForm from '../forms/RolesItemForm.vue';

import { useRolesCatalogStore } from '../../../store/roles/rolesCatalog';
import { useRoleDetailsStore } from '../../../store/roles/roleDetails';

export default defineComponent({
  components: {
    AppModal,
    RolesItemForm,
  },
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: '' },
    roleId: { type: String as PropType<string | null>, default: null },
  },
  setup(props, context) {
    const {
      value,
      title,
      roleId,
    } = toRefs(props);

    const rolesCatalogStore = useRolesCatalogStore();
    const roleDetailsStore = useRoleDetailsStore();

    const role = computed(() => roleDetailsStore.getters.role);
    const roleIsLoading = computed(() => roleDetailsStore.getters.isLoading);

    const roleFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const roleFormData = ref<PartialWithout<Role, 'id'> | null>(null);
    const roleFormIsValid = ref(false);

    const roleReset = () => {
      roleDetailsStore.mutations.RESET_ROLE();
    };

    const roleFormResetValidation = async () => {
      if (!roleFormRef.value) return;

      roleFormRef.value.resetValidation();
    };

    const newRole = computed(() => {
      if (!roleFormData.value || !roleFormIsValid.value) return null;

      return roleFormData.value;
    });

    const modalTitle = computed(() => title.value || role.value?.name || 'Роль');

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => !newRole.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!roleId.value || !newRole.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await rolesCatalogStore.actions.UPDATE_ROLE({
          roleId: roleId.value,
          data: newRole.value,
        });

        close();
      } finally {
        saving.value = false;
      }
    };

    const init = async () => {
      if (!roleId.value) return;

      await roleDetailsStore.actions.FETCH_ROLE(roleId.value);

      roleFormData.value = role.value;
    };

    const reset = () => {
      roleFormData.value = null;

      roleReset();
      roleFormResetValidation();
    };

    watch(value, (val) => {
      if (!val) return;

      reset();
      init();
    }, { immediate: true });

    return {
      roleIsLoading,
      roleFormData,
      roleFormIsValid,
      modalTitle,
      saving,
      saveBtnIsDisabled,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
