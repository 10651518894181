import { render, staticRenderFns } from "./MarkupDetailsInfo.vue?vue&type=template&id=28928ab8&scoped=true&"
import script from "./MarkupDetailsInfo.vue?vue&type=script&lang=ts&"
export * from "./MarkupDetailsInfo.vue?vue&type=script&lang=ts&"
import style0 from "./MarkupDetailsInfo.vue?vue&type=style&index=0&id=28928ab8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28928ab8",
  null
  
)

export default component.exports