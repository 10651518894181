/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import permissionsCatalog from './permissions/permissionsCatalog';
import permissionDetails from './permissions/permissionDetails';

const module = new Module({
  modules: {
    permissionsCatalog,
    permissionDetails,
  },
});

export const usePermissionsStore = createComposable(module);

export default module;
