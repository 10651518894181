<template>
  <div class="campaign-page-loader">
    <v-skeleton-loader
      type="heading"
      class="campaign-page-loader__header"
    />

    <div class="campaign-page-loader__statistic">
      <div class="campaign-page-loader__metrics">
        <v-skeleton-loader
          v-for="num in 4"
          :key="num"
          type="text"
          class="campaign-page-loader__metrics-item"
        />
      </div>
      <v-skeleton-loader
        type="image"
        class="campaign-page-loader__chart"
      />
    </div>

    <TableLoader class="campaign-page-loader__table" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

export default defineComponent({
  components: {
    TableLoader,
  },
});
</script>

<style lang="scss" scoped>
.campaign-page-loader {
  background-color: map-get($grey, 'lighten-5');

  &__header {
    display: flex;
    align-items: center;
    width: 70%;
    height: 80px;
    padding: 12px 24px;

    ::v-deep .v-skeleton-loader__heading {
      background: map-get($grey, 'lighten-1') !important;
    }
  }

  &__statistic {
    display: flex;
    padding: 12px 24px 32px 24px;
    background-color: map-get($shades, 'white');
  }

  &__metrics {
    flex-shrink: 0;
    min-width: 306px;
    margin-right: 24px;
  }

  &__metrics-item {
    padding: 28px 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__chart {
    flex-grow: 1;

    ::v-deep .v-skeleton-loader__image {
      height: 100%;
    }
  }

  &__table {
    padding: 40px 24px;
  }
}
</style>
