<template>
  <div class="campaign-editor-categories-loader">
    <ListLoader
      class="campaign-editor-categories-loader__list"
      :rows="1"
    />
    <ListLoader
      class="campaign-editor-categories-loader__list campaign-editor-categories-loader__list--last"
      :rows="1"
    />

    <div class="campaign-editor-categories-loader__items">
      <ListLoader class="campaign-editor-categories-loader__items-list" />
      <ListLoader class="campaign-editor-categories-loader__items-list" />
      <ListLoader class="campaign-editor-categories-loader__items-list" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import ListLoader from '@/components/_newDesign/common/loaders/ListLoader.vue';

export default defineComponent({
  components: { ListLoader },
});
</script>

<style lang="scss" scoped>
.campaign-editor-categories-loader {
  padding: 12px 24px;

  &__list {
    width: 20%;

    &:last-child {
      margin-top: 36px;
    }
  }

  &__items {
    display: flex;
    margin-top: 32px;
  }

  &__items-list {
    width: 33%;
    padding-right: 24px;

    &:last-child {
      padding-right: 0;
    }
  }
}
</style>
