












































import { defineComponent, computed } from '@vue/composition-api';

import { debounce } from 'lodash';

import {
  DBtnInline,
  DSelectInline,
} from '@shared/druid-kit';

import { useDataProvidersSettingsCatalogStore } from '../../../store/dataProvidersSettings/dataProvidersSettingsCatalog';

export default defineComponent({
  components: {
    DBtnInline,
    DSelectInline,
  },
  setup(props, context) {
    const dataProvidersSettingsStore = useDataProvidersSettingsCatalogStore();

    const filterItems = computed(() => [
      { text: 'Все настройки', value: false },
      { text: 'Только проблемные', value: true },
    ]);

    const filter = computed({
      get() {
        return dataProvidersSettingsStore.getters.filters.failedOnly;
      },
      set(val: boolean) {
        dataProvidersSettingsStore.dispatch('UPDATE_FILTERS', { failedOnly: val });
      },
    });

    const query = computed({
      get() {
        return dataProvidersSettingsStore.getters.filters.query;
      },
      set: debounce((val: string) => {
        dataProvidersSettingsStore.dispatch('UPDATE_FILTERS', { query: val });
      }, 300),
    });

    const openAddModal = () => {
      context.emit('open-modal', 'add');
    };

    return {
      filter,
      query,
      openAddModal,
      filterItems,
    };
  },
});
