













import { defineComponent, toRefs, provide, computed, watch, nextTick } from '@vue/composition-api';

import goTo from 'vuetify/es5/services/goto';

import AppView from '@/components/_newDesign/common/AppView.vue';

import ClientDetailsContent from '@/components/_newDesign/Clients/ClientDetails/ClientDetailsContent.vue';

import useQueryFilters from '@/composables/useQueryFilters';
import useMeta from '@/composables/meta/useMeta';
import { useLayoutLoadingStatus } from '@/composables/layout';
import { useClientDetails } from '@/composables/client';

export default defineComponent({
  components: {
    AppView,
    ClientDetailsContent,
  },
  props: {
    clientId: { type: String, required: true },
  },
  setup(props, context) {
    const { clientId } = toRefs(props);

    const { queryFilters } = useQueryFilters({
      filters: {
        brandId: undefined,
      },
    }, context);

    provide('queryFilters', queryFilters);

    const {
      client,
      fetchClient,
    } = useClientDetails({ clientId });

    const {
      isLoading,
      updateIsLoading,
    } = useLayoutLoadingStatus();

    watch(isLoading, async (val) => {
      if (val) return;

      await nextTick();

      if (!queryFilters.value.brandId) return;

      goTo(`#brand-id-${queryFilters.value.brandId}`, { offset: 100 });
    });

    const title = computed(() => `Бренды и кампании ${client.value?.name}`);
    const clientMeta = computed(() => ({ title: client.value?.name || 'Клиент' }));

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await fetchClient();
      } finally {
        updateIsLoading(false);
      }
    };

    useMeta(clientMeta);

    fetch();

    return {
      isLoading,
      title,
      client,
    };
  },
});
