/* eslint-disable max-classes-per-file */
import Vue from 'vue';
import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';
import { isEqual } from 'lodash';

import { TokensApi } from '@/api';

import { Token, TokenProvider } from '@/models/Token';

export interface TokensListFilters {
  page: number;
  query: string;
}

const initFilters = () => ({
  page: 1,
  query: '',
});

class ModuleState {
  tokens: Token[] = [];

  tokenProviders: TokenProvider[] = [];

  selectedTokenProvider: TokenProvider | null = null;

  filters: TokensListFilters = initFilters();
}

class ModuleGetters extends Getters<ModuleState> {
  get tokens() {
    return this.state.tokens;
  }

  get tokenProviders() {
    return this.state.tokenProviders;
  }

  get selectedTokenProvider() {
    return this.state.selectedTokenProvider;
  }

  get filters() {
    return this.state.filters;
  }

  get filtersAreEnabled() {
    const defaultFilters = initFilters();

    return Object.entries(this.getters.filters).some(([key, val]) => !isEqual(val, defaultFilters[key]));
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_TOKENS_LIST(payload: Token[]) {
    this.state.tokens = payload;
  }

  ADD_TOKENS_LIST(payload: Token[]) {
    this.state.tokens = [...this.state.tokens, ...payload];
  }

  REMOVE_TOKEN(tokenId: string) {
    this.state.tokens = this.state.tokens.filter(({ id }) => id !== tokenId);
  }

  SET_TOKEN_PROVIDERS(payload: TokenProvider[]) {
    this.state.tokenProviders = payload;
  }

  SET_SELECTED_TOKEN_PROVIDER(payload: TokenProvider) {
    this.state.selectedTokenProvider = payload;
  }

  SET_FILTERS(payload: TokensListFilters) {
    this.state.filters = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_TOKENS_LIST() {
    const { page, query } = this.getters.filters;

    const res = await TokensApi.fetchTokens({
      params: { page, filter: query },
    });

    if (page === 1) {
      this.commit('SET_TOKENS_LIST', res.data.list);
    } else {
      this.commit('ADD_TOKENS_LIST', res.data.list);
    }
  }

  async FETCH_TOKEN_PROVIDERS() {
    const res = await TokensApi.fetchTokenProviders();

    this.commit('SET_TOKEN_PROVIDERS', res.data);
  }

  async CREATE_TOKEN(payload: { code: string; tokenProviderId: string }) {
    await TokensApi.createToken(payload);

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Tokens',
      event_action: 'Get new token',
      event_label: payload.tokenProviderId,
    });
  }

  async CREATE_CUSTOM_TOKEN(payload: { name: string; tokenJson: string; tokenProviderId: string }) {
    await TokensApi.addToken(payload);

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Tokens',
      event_action: 'Get new token',
      event_label: payload.tokenProviderId,
    });
  }

  async DELETE_TOKEN(payload: { tokenId: string; tokenProviderId: string }) {
    await TokensApi.deleteToken(payload.tokenId);

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Tokens',
      event_action: 'Delete token',
      event_label: payload.tokenProviderId,
    });

    this.commit('REMOVE_TOKEN', payload.tokenId);
  }

  UPDATE_FILTERS(filters: Partial<TokensListFilters>) {
    const page = filters.page || 1;

    const newFilters = {
      ...this.getters.filters,
      ...filters,
      page,
    };

    this.commit('SET_FILTERS', newFilters);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
  }

  NEXT_PAGE() {
    this.dispatch('UPDATE_FILTERS', { page: this.getters.filters.page + 1 });
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const tokensStoreMapper = createMapper(module);

export const useProviderTokensStore = createComposable(module);

export default module;
