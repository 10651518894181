

















































































import { defineComponent, PropType, toRefs, ref, computed } from '@vue/composition-api';

import { DTooltip } from '@shared/druid-kit';

import { useClientPaths, useClientBrands } from '@/composables/client';

import { Client } from '@/models/Client';

import { useClientsStore } from '@/store/clients';

export default defineComponent({
  components: {
    DTooltip,
  },
  props: {
    client: { type: Object as PropType<Client>, required: true },
  },
  setup(props) {
    const { client } = toRefs(props);

    const clientsStore = useClientsStore();

    const { path, unapprovedPlacementsPath } = useClientPaths({ client });
    const { activeBrands, archiveBrands } = useClientBrands({ client });

    const visibleBrandsLimit = ref(4);
    const favoriteStatusIsLoading = ref(false);

    const visibleBrands = computed(() => [
      ...activeBrands.value,
      ...archiveBrands.value,
    ].slice(0, visibleBrandsLimit.value));

    const hiddenBrands = computed(() => activeBrands.value.slice(visibleBrandsLimit.value));

    const hiddenBrandsCount = computed(() => hiddenBrands.value.length);

    const title = computed(() => client.value.name);

    const subtitle = computed(() => {
      const brandNames = visibleBrands.value.map((brand) => brand.name).join(', ');

      return hiddenBrandsCount.value ? `${brandNames} и еще ${hiddenBrandsCount.value}` : brandNames;
    });

    const toggleFavoriteStatus = async () => {
      favoriteStatusIsLoading.value = true;

      try {
        await clientsStore.actions.TOGGLE_CLIENT_IS_FAVORITE(client.value);
      } finally {
        favoriteStatusIsLoading.value = false;
      }
    };

    return {
      path,
      unapprovedPlacementsPath,
      title,
      subtitle,
      toggleFavoriteStatus,
      favoriteStatusIsLoading,
    };
  },
});
