



























import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api';

import {
  DTable,
} from '@shared/druid-kit';

import { Markup } from '@/models/Markup';

import useModals from '@/composables/useModals';

import MarkupPeriodsTableRow from './rows/MarkupPeriodsTableRow.vue';
import MarkupPeriodsTableFooterRow from './rows/MarkupPeriodsTableFooterRow.vue';

import MarkupPeriodsTableAddModal from './modals/MarkupPeriodsTableAddModal.vue';

export default defineComponent({
  components: {
    DTable,
    MarkupPeriodsTableRow,
    MarkupPeriodsTableFooterRow,
    MarkupPeriodsTableAddModal,
  },
  props: {
    markup: { type: Object as PropType<Markup | null>, default: null },
  },
  setup(props) {
    const { markup } = toRefs(props);

    const headers = [
      {
        class: 'caption',
        text: 'Дата начала',
        value: 'startDate',
      },
      {
        class: 'caption',
        text: 'Коэффицент',
        value: 'value',
      },
      {
        class: 'caption',
        text: 'Бюджет без наценки',
        value: 'rawCost',
      },
      {
        class: 'caption',
        text: 'Бюджет с наценкой',
        value: 'cost',
      },
      {
        sortable: false,
      },
    ];

    const { modalsMap, openModal } = useModals(['add']);

    const markupsWithCost = computed(() => markup.value?.markupsWithCost || []);

    const openAddModal = () => {
      openModal({ slug: 'add', props: { markup: markup.value } });
    };

    return {
      headers,
      markupsWithCost,
      modalsMap,
      openAddModal,
    };
  },
});
