/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { MarkupLight } from '@/models/Markup';
import { Placement } from '@/models/Placement';
import { Client } from '@/models/Client';

import {
  MarkupsApi,
  PlacementsApi,
  ClientsApi,
} from '@/api';

import { FetchMarkupsResponse } from '@/api/modules/markups.api';

import markupDetails from './markupDetails';

export interface MarkupsSortFilters {
  by?: 'placementName' | 'clientName' | 'brandName' | 'campaignName' | 'dataProviderName';
  desc?: boolean;
}

export interface MarkupsFilters {
  page: number;
  query: string;
}

const initFilters = () => ({
  page: 1,
  query: '',
});

class ModuleState {
  isShown = false;

  isLoading = false;

  clientsIsLoading = false;

  markups: MarkupLight[] = [];

  markupsTotalCount = 0;

  filters: MarkupsFilters = initFilters();

  sortFilters: MarkupsSortFilters = {};

  markupsHaveBeenFiltered = false;

  placement: Placement | null = null;

  clients: Client[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get clientsIsLoading() {
    return this.state.clientsIsLoading;
  }

  get markups() {
    return this.state.markups;
  }

  get markupsTotalCount() {
    return this.state.markupsTotalCount;
  }

  get filters() {
    return this.state.filters;
  }

  get maxPage() {
    return Math.ceil(this.getters.markupsTotalCount / 50);
  }

  get pageIsFirst() {
    return this.getters.filters.page <= 1;
  }

  get pageIsLast() {
    return this.getters.filters.page >= this.getters.maxPage;
  }

  get nextPage() {
    if (this.getters.pageIsLast) return this.getters.filters.page;

    return this.getters.filters.page + 1;
  }

  get sortFilters() {
    return this.state.sortFilters;
  }

  get markupsHaveBeenFiltered() {
    return this.state.markupsHaveBeenFiltered;
  }

  get fetchParams() {
    return {
      filter: this.getters.filters.query || undefined,
      page: this.getters.filters.page,
      sortBy: this.getters.sortFilters.by,
      sortDesc: this.getters.sortFilters.desc,
    };
  }

  get placement() {
    return this.state.placement;
  }

  get clients() {
    return this.state.clients;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_CLIENTS_IS_LOADING(value: boolean) {
    this.state.clientsIsLoading = value;
  }

  SET_MARKUPS(payload: FetchMarkupsResponse) {
    this.state.markups = payload.list;
    this.state.markupsTotalCount = payload.totalRows;
  }

  SET_PLACEMENT(payload: Placement) {
    this.state.placement = payload;
  }

  ADD_MARKUPS(payload: FetchMarkupsResponse) {
    this.state.markups = [...this.state.markups, ...payload.list];
  }

  SET_CLIENTS(payload: Client[]) {
    this.state.clients = payload;
  }

  SET_FILTERS(payload: MarkupsFilters) {
    this.state.filters = payload;
  }

  SET_SORT_FILTERS(payload: MarkupsSortFilters) {
    this.state.sortFilters = payload;
  }

  SET_MARKUPS_HAS_BEEN_FILTERED(value: boolean) {
    this.state.markupsHaveBeenFiltered = value;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH(payload?: { placementId?: string }) {
    this.commit('SET_IS_LOADING', true);

    const params = {
      placementId: payload?.placementId,
      ...this.getters.fetchParams,
    };

    try {
      const res = await MarkupsApi.fetchMarkups({ params });

      if (this.getters.pageIsFirst) {
        this.commit('SET_MARKUPS', res.data);
      } else {
        this.commit('ADD_MARKUPS', res.data);
      }
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async FETCH_PLACEMENT(payload: { placementId: string }) {
    const res = await PlacementsApi.fetchPlacement(payload.placementId);

    this.commit('SET_PLACEMENT', res.data);
  }

  async FETCH_CLIENTS() {
    this.commit('SET_CLIENTS_IS_LOADING', true);

    try {
      const res = await ClientsApi.fetchClients();

      this.commit('SET_CLIENTS', res.data);
    } finally {
      this.commit('SET_CLIENTS_IS_LOADING', false);
    }
  }

  UPDATE_FILTERS(filters: Partial<MarkupsFilters>) {
    const page = filters.page || 1;

    const newFilters = {
      ...this.getters.filters,
      ...filters,
      page,
    };

    this.commit('SET_FILTERS', newFilters);
    this.commit('SET_MARKUPS_HAS_BEEN_FILTERED', true);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
    this.commit('SET_MARKUPS_HAS_BEEN_FILTERED', false);
  }

  async TO_NEXT_PAGE() {
    await this.dispatch('UPDATE_FILTERS', { page: this.getters.nextPage });
  }

  async UPDATE_SORT_FILTERS(payload: Partial<MarkupsSortFilters>) {
    await this.dispatch('UPDATE_FILTERS', { page: 1 });

    const newPayload = {
      ...this.getters.sortFilters,
      ...payload,
    };

    this.commit('SET_SORT_FILTERS', newPayload);
  }
}

const module = new Module({
  modules: {
    markupDetails,
  },
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const markupsStoreMapper = createMapper(module);

export const useMarkupsStore = createComposable(module);

export default module;
