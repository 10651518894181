import { AxiosResponse } from 'axios';

import { ProviderSettings, ProviderSettingsLight, ProviderSettingsToken, Provider } from '@/models/ProviderSettings';
import { GoalDataProvider } from '@/models/Goal';
import { Client } from '@/models/Client';
//
import httpClient from '../httpClient';

interface FetchProviderSettingsPayload {
  params?: {
    filter: string;
    page: string | number;
    failedOnly: boolean;
  };
}

interface FetchProviderSettingsResponse {
  list: ProviderSettings[];
  totalRows: number;
}

interface FetchTokensPayload {
  params?: {
    id: string;
  };
}

interface FetchSchemaPayload {
  providerId: string;
  tokenId: string | null;
  settings?: string;
}

interface FetchSchemaResponse {
  errors: string[];
  schema: string;
}

export interface SetProviderSettingsPayload {
  id?: string;
  brandIds: string[];
  clientId: string;
  clientName: string;
  connectorSettings: string;
  dataProviderId: string;
  dataProviderName: string;
  name: string;
  statisticsCollectionDepth: string;
  tokenId: string | null;
}

export interface ProviderSettingsResponse {
  brandIds: string[];
  clientId: string;
  connectorSettings: string;
  dataProviderId: string;
  id: string;
  name: string;
  tokenId: string;
  statisticsCollectionDepth: string;
}

export default class ProviderSettingsApi {
  public static async fetchProviderSettings(payload: FetchProviderSettingsPayload): Promise<AxiosResponse<FetchProviderSettingsResponse>> {
    const res = await httpClient.get<FetchProviderSettingsResponse>('/api/providersettings', payload);

    return res;
  }

  public static async fetchProviderSettingsByCampaign(campaignId: string): Promise<AxiosResponse<ProviderSettingsLight[]>> {
    const res = await httpClient.get<ProviderSettingsLight[]>(`/api/providersettings/campaign/${campaignId}`);

    return res;
  }

  public static async fetchProviders(): Promise<AxiosResponse<Provider[]>> {
    const res = await httpClient.get<Provider[]>('/api/providersettings/providers');

    return res;
  }

  public static async fetchProvider(providerId: string): Promise<AxiosResponse<ProviderSettingsResponse>> {
    const res = await httpClient.get<ProviderSettingsResponse>(`/api/providersettings/${providerId}`);

    return res;
  }

  public static async fetchClients(): Promise<AxiosResponse<Client[]>> {
    const res = await httpClient.get<Client[]>('/api/providersettings/clients');

    return res;
  }

  public static async fetchTokens(payload: FetchTokensPayload): Promise<AxiosResponse<ProviderSettingsToken[]>> {
    const res = await httpClient.get<ProviderSettingsToken[]>('/api/providersettings/tokens', payload);

    return res;
  }

  public static async fetchSchema(payload: FetchSchemaPayload): Promise<AxiosResponse<FetchSchemaResponse>> {
    const res = await httpClient.post<FetchSchemaResponse>('/api/providersettings/schema', payload);

    return res;
  }

  public static async fetchGoalProviders(): Promise<AxiosResponse<GoalDataProvider[]>> {
    const res = await httpClient.get<GoalDataProvider[]>('/api/providersettings/goals-providers');

    return res;
  }

  public static async setProvider(payload: SetProviderSettingsPayload): Promise<AxiosResponse<string>> {
    const res = await httpClient.post<string>('/api/providersettings', payload);

    return res;
  }

  public static async deleteProvider(providerId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.delete<''>(`/api/providersettings/${providerId}`);

    return res;
  }
}
