/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import {
  CategoriesApi,
  ServiceTypesApi,
} from '@/api';

import { Category } from '@/models/Category';
import { ServiceType } from '@/models/ServiceType';

interface NormalizedServiceType {
  id: string | null;
  name: string;
  requiresModeration?: false;
}

class ModuleState {
  isLoading = false;

  category: Category | null = null;

  serviceTypes: ServiceType[] = [];

  metrics: string[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get category() {
    return this.state.category;
  }

  get metrics() {
    return this.state.metrics;
  }

  get serviceTypes() {
    return this.state.serviceTypes;
  }

  get normalizedServiceTypes() {
    const normalizeServiceTypes = (serviceTypes: ServiceType[], prefix?: string) => serviceTypes
      .reduce<NormalizedServiceType[]>((acc, serviceType) => {
      const path = prefix ? `${prefix}\\${serviceType.name}` : serviceType.name;

      acc.push({ name: path, id: serviceType.id });

      if (serviceType.children) {
        acc.push(...normalizeServiceTypes(serviceType.children));
      }

      return acc;
    }, []);

    return [
      { id: null, name: 'Все типы сервисов', requiresModeration: false },
      ...normalizeServiceTypes(this.getters.serviceTypes),
    ];
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_CATEGORY(payload: Category) {
    this.state.category = payload;
  }

  SET_METRICS(payload: string[]) {
    this.state.metrics = payload;
  }

  SET_SERVICE_TYPES(payload: ServiceType[]) {
    this.state.serviceTypes = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_CATEGORY(categoryId: string) {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await CategoriesApi.fetchCategory(categoryId);

      this.mutations.SET_CATEGORY(res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async FETCH_METRICS() {
    const res = await CategoriesApi.fetchMetrics();

    this.mutations.SET_METRICS(res.data);
  }

  async FETCH_SERVICE_TYPES() {
    const res = await ServiceTypesApi.fetchServiceTypes();

    this.mutations.SET_SERVICE_TYPES(res.data);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useCategoryDetailsStore = createComposable(module);

export default module;
