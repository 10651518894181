



















import { defineComponent, computed, PropType } from '@vue/composition-api';

import { DTable } from '@shared/druid-kit';

import { Permission } from '@/models/Permission';

import PermissionsTableRow from './PermissionsTableRow.vue';

import { usePermissionsCatalogStore } from '../../../../store/permissions/permissionsCatalog';

export default defineComponent({
  components: {
    DTable,
    PermissionsTableRow,
  },
  props: {
    permissions: { type: Array as PropType<Permission[]>, default: () => [] },
  },
  setup() {
    const permissionsCatalogStore = usePermissionsCatalogStore();

    const headers: {
      class: string;
      text: string;
      sortable: boolean;
    }[] = [
      {
        class: 'caption',
        text: 'Название',
        sortable: false,
      },
      {
        class: 'caption',
        text: 'Идентификатор',
        sortable: false,
      },
      {
        class: 'caption',
        text: 'Область видимости (scope)',
        sortable: false,
      },
    ];

    const permissionsIsLoading = computed(() => permissionsCatalogStore.getters.isLoading);

    return {
      headers,
      permissionsIsLoading,
    };
  },
});
