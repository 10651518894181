























import Vue, { VueConstructor, PropType } from 'vue';

import { HnTableCellMixin } from '@/packages/hn-table/src/mixins';
import numatter, { NumatterFormat } from '@/packages/numatter';

import { CampaignEditorPlacement } from '@/models/Campaign';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'PlacementsTableMetricCell',
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    metricName: { type: String, default: '' },
    campaignPlacements: { type: Array as PropType<CampaignEditorPlacement[]>, required: true },
    format: { type: Object as PropType<NumatterFormat | undefined>, default: undefined },
    planIsShown: { type: Boolean, default: false },
    factIsShown: { type: Boolean, default: false },
  },
  data() {
    return {
      placement: null as CampaignEditorPlacement | null,
      value: 0 as number,
    };
  },
  computed: {
    cellPropertiesRow(): number | undefined {
      return this.cellProperties?.row;
    },
    currentValue(): string {
      return numatter.format(this.value || 0, this.format);
    },
    factValue(): string {
      if (!this.placement) return '—';

      const factTotalMetrics = this.placement.factTotalMetrics || {};

      const totalMetricValue = factTotalMetrics[this.metricName];

      if (typeof totalMetricValue !== 'number') return '—';

      return numatter.format(totalMetricValue, this.format);
    },
  },
  watch: {
    campaignPlacements: {
      handler() {
        this.initPlacement();
      },
      immediate: true,
    },
    async cellPropertiesRow() {
      await this.initPlacement();
    },
  },
  methods: {
    async initPlacement() {
      if (typeof this.cellPropertiesRow !== 'number') return;

      await this.$nextTick();

      const campaignPlacement = this.campaignPlacements[this.cellPropertiesRow];

      this.placement = campaignPlacement;
    },
    afterChange() {
      this.initPlacement();
    },
  },
});
