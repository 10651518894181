


























































import { defineComponent, ref, computed, watch } from '@vue/composition-api';

import useRouter from '@/packages/hooks/useRouter';
import useRoute from '@/packages/hooks/useRoute';

import AppView from '@/components/_newDesign/common/AppView.vue';
import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

import { useMetricsCheckStore } from '../store';

import MetricsCheckTable from '../components/MetricsCheckTable.vue';

export default defineComponent({
  components: {
    AppView,
    TableLoader,
    MetricsCheckTable,
  },
  setup(props, context) {
    const router = useRouter(context);
    const route = useRoute(context);

    const metricsCheckStore = useMetricsCheckStore();

    const searchParamsChanged = ref(false);

    const searchQuery = computed({
      get() {
        return metricsCheckStore.getters.searchQuery;
      },
      set(val: string) {
        metricsCheckStore.dispatch('UPDATE_SEARCH_QUERY', val);
      },
    });

    const totalByDataProvider = computed(() => metricsCheckStore.getters.totalByDataProvider);
    const rawTotalByDataProvider = computed(() => metricsCheckStore.getters.rawTotalByDataProvider);

    const errors = computed(() => metricsCheckStore.getters.errors);

    const isLoading = computed(() => metricsCheckStore.getters.isLoading);

    const danboId = computed(() => route.value.query.danboId);
    const searchIsDisabled = computed(() => danboId.value === searchQuery.value || !searchQuery.value);
    const tableIsShown = computed(() => totalByDataProvider.value.length && rawTotalByDataProvider.value.length);

    const search = () => {
      if (searchIsDisabled.value) return;

      router.push({ query: { danboId: searchQuery.value } });
      searchParamsChanged.value = true;
    };

    watch(route, async () => {
      if (!danboId.value || typeof danboId.value !== 'string') return;

      metricsCheckStore.dispatch('FETCH_TOTAL', danboId.value);
    }, { immediate: true });

    return {
      searchQuery,
      isLoading,
      tableIsShown,
      searchIsDisabled,
      search,
      errors,
      searchParamsChanged,
    };
  },
});
