


















































import { defineComponent, PropType, computed, toRefs } from '@vue/composition-api';

import moment from 'moment';

import { DTableCell } from '@shared/druid-kit';

import { Campaign, CampaignAdObject } from '@/models/Campaign';

import MetricsTableSimple from '@/components/_newDesign/common/MetricsTable/MetricsTableSimple.vue';

import CampaignMetrics from '@/components/_newDesign/Campaigns/CampaignMetrics/CampaignMetrics.vue';
import CampaignChart from '@/components/_newDesign/Campaigns/CampaignChart.vue';
import CampaignTabs from '@/components/_newDesign/Campaigns/CampaignTabs.vue';

import CampaignDateFilterBtn from '@/components/_newDesign/Campaigns/buttons/CampaignDateFilterBtn.vue';

export default defineComponent({
  components: {
    DTableCell,
    MetricsTableSimple,
    CampaignMetrics,
    CampaignChart,
    CampaignDateFilterBtn,
    CampaignTabs,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    danboId: { type: String, required: true },
    connectorId: { type: String, required: true },
    adObject: { type: Object as PropType<CampaignAdObject>, required: true },
  },
  setup(props) {
    const { campaign, adObject } = toRefs(props);

    const adObjectLastUpdate = computed(() => moment(adObject.value.parentAdObject.updatedAt)
      .format('DD.MM.YYYY HH:mm'));

    const metricsData = computed(() => ({
      metricsTotal: adObject.value.parentAdObject.metricsTotal,
      startDate: campaign.value.startDate,
      endDate: campaign.value.endDate,
    }));

    const tabs = computed(() => {
      const { adObjects } = adObject.value;

      const uniqTypes = [...new Set<string>(adObjects.map(({ type }) => type))];

      return uniqTypes.map((type) => ({
        type,
        name: type,
        items: adObjects.filter((addObject) => addObject.type === type),
      }));
    });

    return {
      metricsData,
      adObjectLastUpdate,
      tabs,
    };
  },
});
