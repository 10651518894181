























import { defineComponent, computed, ref, watch } from '@vue/composition-api';

import useRouter from '@/packages/hooks/useRouter';
import useRoute from '@/packages/hooks/useRoute';

import { useNavigationUnapprovedPlacementsStore } from '@/store/navigation/navigationUnapprovedPlacements';

import { useUnapprovedPlacementsStore } from '../store';

export default defineComponent({
  setup(props, context) {
    const router = useRouter(context);
    const route = useRoute(context);

    const navigationUnapprovedPlacementsStore = useNavigationUnapprovedPlacementsStore();
    const unapprovedPlacementsStore = useUnapprovedPlacementsStore();

    const count = computed(() => navigationUnapprovedPlacementsStore.getters.unapprovedPlacementsCount);
    const isLoading = computed(() => unapprovedPlacementsStore.getters.isLoading);

    const searchQuery = computed(() => route.value.query.search);

    const query = ref(searchQuery.value || '');

    const searchIsDisabled = computed(() => searchQuery.value === query.value);

    watch(searchQuery, async (val) => {
      if (typeof val !== 'string') return;

      unapprovedPlacementsStore.actions.UPDATE_FILTERS({ query: val });
    }, { immediate: true });

    const search = () => {
      if (searchIsDisabled.value) return;

      router.push({ query: { search: query.value } });
    };

    return {
      count,
      query,
      isLoading,
      search,
    };
  },
});
