




















import { defineComponent, toRefs, computed, provide, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignConnectorHeader from '@/components/_newDesign/Campaigns/CampaignConnector/CampaignConnectorHeader.vue';
import CampaignConnectorContent from '@/components/_newDesign/Campaigns/CampaignConnector/CampaignConnectorContent.vue';

import useQueryFilters from '@/composables/useQueryFilters';
import { useLayoutLoadingStatus } from '@/composables/layout';
import { useCampaignDetails } from '@/composables/campaign';
import useMeta from '@/composables/meta/useMeta';

import { useCampaignStore } from '@/store/campaign';

export default defineComponent({
  components: {
    CampaignConnectorHeader,
    CampaignConnectorContent,
    AppView,
  },
  props: {
    campaignId: { type: String, required: true },
    danboId: { type: String, required: true },
    connectorId: { type: String, required: true },
  },
  setup(props, context) {
    const { campaignId, danboId, connectorId } = toRefs(props);

    const campaignStore = useCampaignStore();

    const {
      queryFilters,
      updateQueryFilters,
    } = useQueryFilters({
      filters: {
        from: undefined,
        to: undefined,
      },
    }, context);

    provide('queryFilters', queryFilters);
    provide('updateQueryFilters', updateQueryFilters);

    const {
      updateIsLoading,
      isLoading,
    } = useLayoutLoadingStatus();

    const {
      campaign,
      campaignCategories,
      campaignPageTitle,
      fetchCampaign,
    } = useCampaignDetails({ campaignId });

    const campaignConnector = computed(() => campaignStore.getters.campaignConnector);

    const campaignCategory = computed(() => campaignCategories.value
      .filter(({ placements }) => placements.find((placement) => placement.danboId === danboId.value))[0] ?? null);

    const pageMeta = computed(() => ({ title: campaignPageTitle.value }));

    useMeta(pageMeta);

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await Promise.all([
          fetchCampaign(),
          campaignStore.dispatch('FETCH_CAMPAIGN_CONNECTOR', {
            campaignId: campaignId.value,
            danboId: danboId.value,
            connectorId: connectorId.value,
            params: queryFilters.value,
          }),
        ]);
      } finally {
        updateIsLoading(false);
      }
    };

    watch(queryFilters, () => fetch());

    fetch();

    return {
      campaign,
      campaignCategory,
      campaignConnector,
      isLoading,
    };
  },
});
