// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { modallyRootShow } from './ModallyRoot.vue';

import { ModallyModalOptions, ModallyShowResult } from './types';

export default {
  async show<T>(payload: ModallyModalOptions<T>): Promise<ModallyShowResult<T>> {
    return new Promise<ModallyShowResult<T>>((resolve) => {
      modallyRootShow(payload, resolve);
    });
  },
};
