




















import { defineComponent, ref, computed, ComponentInstance } from '@vue/composition-api';

import { ClientsApi } from '@/api';

import { ClientRenamePayload } from '@/api/modules/clients.api';

import useRouter from '@/packages/hooks/useRouter';

import AppView from '@/components/_newDesign/common/AppView.vue';

import ClientBrandSettingsForm from '../components/ClientBrandSettings/forms/ClientBrandSettingsForm.vue';
import ClientBrandSettingsFooter from '../components/ClientBrandSettings/ClientBrandSettingsFooter.vue';

export default defineComponent({
  components: {
    AppView,
    //
    ClientBrandSettingsForm,
    ClientBrandSettingsFooter,
  },
  setup(props, context) {
    const router = useRouter(context);

    const settingsFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const settingsFormData = ref<ClientRenamePayload | null>(null);
    const settingsFormIsValid = ref(false);

    const newSettings = computed(() => {
      if (!settingsFormData.value || !settingsFormIsValid.value) return null;

      return settingsFormData.value;
    });

    const saving = ref(false);
    const settingsHasBeenChanged = computed(() => !newSettings.value);
    const closeBtnIsDisabled = computed(() => saving.value);
    const resetIsDisabled = computed(() => !newSettings.value?.clientId || !newSettings.value?.brandId);

    // // // // //

    const cancel = () => {
      router.push('/clients');
    };

    const save = async () => {
      if (!newSettings.value) return;

      saving.value = true;

      try {
        await ClientsApi.clientRename(newSettings.value);

        cancel();
      } finally {
        saving.value = false;
      }
    };

    const resetManualSettings = async () => {
      if (!newSettings.value) return;

      saving.value = true;

      try {
        await ClientsApi.removeClientManualSettings({
          clientId: newSettings.value.clientId,
          brandId: newSettings.value.brandId,
        });

        cancel();
      } finally {
        saving.value = false;
      }
    };

    return {
      settingsFormRef,
      settingsFormData,
      settingsFormIsValid,
      settingsHasBeenChanged,
      saving,
      closeBtnIsDisabled,
      save,
      cancel,
      resetManualSettings,
      resetIsDisabled,
    };
  },
});
