/* eslint-disable max-classes-per-file */

import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

class ModuleState {
  headerMinHeight = 80;

  sideBarWidth = 224;

  sideBarMinWidth = 64;

  sideBarMinimaized = false;

  footerMinHeight = 60;
}

class ModuleGetters extends Getters<ModuleState> {
  get headerMinHeight() {
    return this.state.headerMinHeight;
  }

  get sideBarWidth() {
    return this.state.sideBarWidth;
  }

  get sideBarMinWidth() {
    return this.state.sideBarMinWidth;
  }

  get sideBarMinimaized() {
    return this.state.sideBarMinimaized;
  }

  get footerMinHeight() {
    return this.state.footerMinHeight;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_SIDE_BAR_MINIMAIZED(value: boolean) {
    this.state.sideBarMinimaized = value;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  CHANGE_SIDE_BAR_MINIMAIZED(value: boolean) {
    this.commit('SET_SIDE_BAR_MINIMAIZED', value);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const navigationUiStoreMapper = createMapper(module);

export default module;
