import mitt from 'mitt';

import { CampaignEditorPlacement } from '@/models/Campaign';

const campaignEditorPlacementsEmitter = mitt<{
  openMarkupsModal: { placement: CampaignEditorPlacement };
  closeMarkupsModal: { markupsIsChanged: boolean };
}>();

export type CampaignEditorPlacementsEmitter = typeof campaignEditorPlacementsEmitter;

export default campaignEditorPlacementsEmitter;
