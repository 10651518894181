import Vue from 'vue';

import * as Sentry from '@sentry/browser';

import router from '@/router';

import modally from '@/services/modally';

import hasOwnProperty from '@/utils/hasOwnProperty';

import { normalizeError } from './utils';

import { AppModalData, AppErrorOptions } from './types';

import AppCustomError from './AppCustomError';

const show = (modalData: AppModalData): void => {
  const { title, messages } = modalData;

  modally.show({
    title,
    content: messages.join('\n'),
    status: 'error',
  });
};

const handle = (payload: unknown, options?: AppErrorOptions): void => {
  const normalizedError = normalizeError(payload, options);

  switch (normalizedError?.modalData?.status) {
    case 401:
      router.push('/logout');

      break;
    // no default
  }

  if (normalizedError?.options?.showingModal && normalizedError.modalData) {
    show(normalizedError.modalData);
  }

  if (normalizedError?.options?.sendingSentry) {
    if (normalizedError.error && normalizedError.data) {
      Sentry.captureException(new AppCustomError(normalizedError.error), {
        extra: normalizedError.data,
      });
    } else {
      Sentry.captureException(normalizedError.error);
    }
  }
};

const init = (): void => {
  const parseError = (payload: unknown) => {
    let error: Record<string, unknown> | null = null;

    if (payload
      && typeof payload === 'object'
      && hasOwnProperty(payload, 'message')
      && typeof payload.message === 'string'
    ) {
      try {
        error = JSON.parse(payload.message);
      } catch (e) {
        error = payload;
      }
    }

    return error ?? payload;
  };

  window.addEventListener('unhandledrejection', (event) => {
    event.promise.catch((error) => {
      handle(parseError(error));
    });
  });

  Vue.config.errorHandler = (e) => {
    const parsedError = parseError(e);

    handle(parsedError, { sendingSentry: true });

    // eslint-disable-next-line no-console
    console.error(e);
  };
};

export default { init };
