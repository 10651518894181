









































import Vue from 'vue';
import { defineComponent, toRefs, computed, PropType, inject, ref, Ref } from '@vue/composition-api';

import moment from 'moment';

import { DBtnInline } from '@shared/druid-kit';

import downloadFile from '@/utils/downloadFile';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  components: {
    DBtnInline,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props) {
    const { campaign } = toRefs(props);

    const reports = computed(() => {
      const list = [
        {
          slug: 'clientPlacementReportByPeriod',
          label: 'план-факт',
          permissions: 'get_client_placement_report_by_period',
          gtm: {
            eventLabel: 'Period',
          },
        },
        {
          slug: 'generalReport',
          label: 'за период',
          permissions: 'get_campaign_report_by_period',
          gtm: {
            eventLabel: 'Client Period',
          },
        },
        {
          slug: 'report',
          label: 'по дням',
          permissions: 'get_campaign_report',
          gtm: {
            eventLabel: 'Daily',
          },
        },
        {
          slug: 'amnetCampaignReport',
          label: 'Amnet',
          permissions: 'get_amnet_campaign_report',
          gtm: {
            eventLabel: 'AmnetCampaignReport',
          },
        },
      ];

      return list.filter((report) => Vue.acl.check(report.permissions));
    });

    const createReportPath = (reportSlug: string) => `/api/campaigns/${campaign.value.id}/${reportSlug}`;

    const queryFilters = inject<Ref<{ from?: string; to?: string }>>('queryFilters', ref({}));

    const downloadReport = async (report: typeof reports.value[number]) => {
      const path = createReportPath(report.slug);

      const params = {
        periodFrom: queryFilters.value.from || moment(campaign.value.startDate).format('YYYY-MM-DD'),
        periodTo: queryFilters.value.to || moment(campaign.value.endDate).format('YYYY-MM-DD'),
      };

      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Campaign',
        event_action: 'Download Report',
        event_label: report.gtm.eventLabel,
        d1d_client_id: campaign.value.clientId,
        d1d_client_name: campaign.value.clientName,
        d1d_brand_id: campaign.value.brandId,
        d1d_brand_name: campaign.value.brandName,
        d1d_campaign_id: campaign.value.id,
        d1d_campaign_name: campaign.value.name,
      });

      await downloadFile({ url: path, params });
    };

    const selectReport = (report: typeof reports.value[number]) => {
      downloadReport(report);
    };

    return {
      reports,
      selectReport,
    };
  },
});
