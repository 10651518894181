import Vue from 'vue';

import 'bootstrap';

import { findLast } from 'lodash';

// Порядок импорта configuration, store и router не менять!
import configuration from '@/configuration/';
import store from '@/store/';
import router from '@/router';

// plugins
import vueOidc from '@/plugins/vueOidc';
import compositionApi from '@/plugins/compositionApi';
import vueMeta from '@/plugins/vueMeta';
import sentry from '@/plugins/sentry';
import httpClient from '@/plugins/httpClient';
import vuetify from '@/plugins/vuetify';
import gtm from '@/plugins/gtm';
import acl from '@/plugins/acl';
import layouts from '@/plugins/layouts';
import device from '@/plugins/device';
import portalVue from '@/plugins/portalVue';
import numatter from '@/plugins/numatter';
import errorObserver from '@/plugins/errorObserver';

import '@/plugins/select2'; // Загрузка select2

import Context from '@/models/Context';

import App from './components/App.vue';

// modules
import searchModule from './modules/search';
import unapprovedPlacements from './modules/unapprovedPlacements';
import providerTokensModule from './modules/providerTokens';
import providerSetingsModule from './modules/providerSettings';
import markupsModule from './modules/markups';
import factUploadModule from './modules/factUpload';
import metricsCheckModule from './modules/metricsCheck';
import accessControlModule from './modules/accessControl';
import administrationModule from './modules/administration';

import notifyService from './services/notify/notify.service';

const context: Context = { configuration, store, router };

// init modules
unapprovedPlacements(context);
searchModule(context);
providerTokensModule(context);
providerSetingsModule(context);
markupsModule(context);
factUploadModule(context);
metricsCheckModule(context);
accessControlModule(context);
administrationModule(context);

// init services
notifyService(context);

// init plugins
vueOidc(context);
vueMeta(context);
compositionApi(context);
sentry(context);
httpClient(context);
acl(context);
gtm(context);
layouts(context);
device(context);
portalVue(context);
numatter(context);
errorObserver(context);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  const userIsSignedIn = store.getters['auth/userSignedIn'];
  const user = store.getters['auth/user'];

  // Необходимо, чтобы дождаться загрузки пользователя с его правами
  // Иначе произойдет редирект на /access-denied
  if (userIsSignedIn && !user && !to.path.includes('auth')) {
    return next({ path: '/auth', query: { source_url: to.fullPath }, replace: true });
  }

  const lastTo = findLast(to.matched, (m) => !!m.meta.permission);

  if (typeof lastTo !== 'undefined') {
    const redirectTo = to.meta.redirectTo || lastTo.meta.redirectTo || { path: '/access-denied' };

    if (!router.app.$acl.check(lastTo.meta.permission)) {
      return next(redirectTo);
    }
  }

  next();
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
