











































import { defineComponent, computed, PropType } from '@vue/composition-api';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { Group } from '@/models/Group';

import GroupsTableRow from './GroupsTableRow.vue';

import { useGroupsCatalogStore } from '../../../../store/groups/groupsCatalog';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
    GroupsTableRow,
  },
  props: {
    groups: { type: Array as PropType<Group[]>, default: () => [] },
  },
  setup() {
    const groupsCatalogStore = useGroupsCatalogStore();

    const headers = ['Название', 'Описание', '']
      .map((text) => ({
        text,
        class: 'caption',
        sortable: false,
      }));

    const groupsIsLoading = computed(() => groupsCatalogStore.getters.isLoading);

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (isIntersecting) groupsCatalogStore.actions.NEXT_PAGE();
    };

    return {
      headers,
      groupsIsLoading,
      onIntersect,
    };
  },
});
