import { AppRouteConfig } from '@/models/AppRouter';

import Users from '../views/Users.vue';
import UsersCatalog from '../views/Users/UsersCatalog.vue';
import PageCatalogLoader from '../../../components/_newDesign/common/loaders/PageCatalogLoader.vue';

const routeConfig: AppRouteConfig = {
  path: 'users',
  component: Users,
  meta: {
    title: 'Пользователи',
    permission: 'admin',
    order: 0,
  },
  children: [
    {
      name: 'users-catalog',
      path: '',
      component: UsersCatalog,
      meta: {
        title: 'Пользователи',
        loader: PageCatalogLoader,
        header: {
          sticky: true,
        },
      },
    },
  ],
};

export default routeConfig;
