









import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const icon = computed(() => (props.value ? 'mdi-chevron-right' : 'mdi-chevron-left'));

    const onClick = () => ctx.emit('input', !props.value);

    return {
      icon,
      onClick,
    };
  },
});
