import { ref, computed, Ref, ComputedRef } from '@vue/composition-api';

import { Client } from '@/models/Client';

import { useClientStore } from '@/store/client';

interface UseClientDetailsProps {
  clientId: Ref<string>;
}

interface UseClientDetailsReturn {
  client: ComputedRef<Client | null>;
  clientIsLoading: Ref<boolean>;
  fetchClient: () => Promise<void>;
}

export default (props: UseClientDetailsProps): UseClientDetailsReturn => {
  const { clientId } = props;

  const clientStore = useClientStore();

  const client = computed(() => clientStore.getters.client);

  const clientIsLoading = ref(false);

  const fetchClient = async () => {
    clientIsLoading.value = true;

    await clientStore.dispatch('FETCH_CLIENT', clientId.value);

    clientIsLoading.value = false;
  };

  return {
    client,
    clientIsLoading,
    fetchClient,
  };
};
