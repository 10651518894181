





































import { defineComponent, computed } from '@vue/composition-api';

import { debounce } from 'lodash';

import { DBtnInline } from '@shared/druid-kit';

import { useUsersCatalogStore } from '../../../store/users/usersCatalog';

export default defineComponent({
  components: {
    DBtnInline,
  },
  setup(props, context) {
    const usersCatalogStore = useUsersCatalogStore();

    const query = computed({
      get() {
        return usersCatalogStore.getters.filters.query;
      },
      set: debounce((val: string) => {
        usersCatalogStore.dispatch('UPDATE_FILTERS', { query: val });
      }, 300),
    });

    const openAccessRightsModal = () => {
      context.emit('open-modal', 'accessRights');
    };

    return {
      query,
      openAccessRightsModal,
    };
  },
});
