import { AxiosResponse } from 'axios';

import { Client } from '@/models/Client';
import { Brand } from '@/models/Brand';

import httpClient from '../httpClient';

export interface FetchClientsV2Payload {
  page: number;
  limit: number;
  state: 'active' | 'archive' | 'all';
}

interface FetchClientsPayload {
  params?: {
    searchQuery?: string;
  };
}

export interface ClientRenamePayload {
  clientId: string;
  clientNameManual: string;
  brandId: string;
  brandNameManual: string;
  clientCategoriesIds: string[];
  brandCategoriesIds: string[];
}

interface FetchClientsV2Response {
  list: Client[];
  totalRows: number;
}

export default class ClientsApi {
  public static async fetchClientsV2(payload: FetchClientsV2Payload): Promise<AxiosResponse<FetchClientsV2Response>> {
    const res = await httpClient.get<FetchClientsV2Response>('/api/v2/clients', { params: payload });

    return res;
  }

  public static async fetchClients(payload?: FetchClientsPayload): Promise<AxiosResponse<Client[]>> {
    const res = await httpClient.get<Client[]>('/api/clients', payload);

    return res;
  }

  public static async fetchClient(clientId: string): Promise<AxiosResponse<Client>> {
    const res = await httpClient.get<Client>(`/api/clients/${clientId}`);

    return res;
  }

  public static async fetchClientBrands(clientId: string): Promise<AxiosResponse<Brand[]>> {
    const res = await httpClient.get<Brand[]>(`/api/clients/${clientId}/brands`);

    return res;
  }

  public static async clientRename(payload: ClientRenamePayload): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/clients/rename', payload);

    return res;
  }

  public static async removeClientManualSettings(payload: { clientId: string; brandId: string }): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/clients/manual-remove', payload);

    return res;
  }
}
