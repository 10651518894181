








































import { defineComponent } from '@vue/composition-api';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import ProviderTokensListTableRow from './ProviderTokensListTableRow.vue';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
    ProviderTokensListTableRow,
  },
  props: {
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    height: { type: Number, default: 0 },
  },
  setup(props, context) {
    const headers = [
      {
        text: 'Площадка',
        value: 'providerName',
      },
      {
        text: 'Название',
        value: 'name',
      },
      {
        text: 'Создание',
        value: 'createdOn',
      },
      {
        text: 'Окончание',
        value: 'expirationDate',
      },
      {
        text: '',
        value: 'actions',
      },
    ].map((header) => ({
      ...header,
      class: 'caption',
      sortable: false,
    }));

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (isIntersecting) context.emit('load-next-page');
    };

    return {
      headers,
      onIntersect,
    };
  },
});
