import { ref, watch, SetupContext, Ref } from '@vue/composition-api';

import { debounce, isEqual } from 'lodash';

import useRouter from '@/packages/hooks/useRouter';
import useRoute from '@/packages/hooks/useRoute';

type Query = { [key: string]: string | (string | null)[] };

type Filters = { [key: string]: string | (string | null | undefined)[] | undefined };

interface UseQueryFilterProps {
  filters: Filters;
}

interface UseQueryFilterReturn {
  queryFilters: Ref<Filters>;
  setQueryFilters: (value: { [key: string]: string | undefined }) => void;
  updateQueryFilters: (value: { [key: string]: string | undefined }) => void;
}

const createFiltersState = (initFilters: Filters, routeQuery: Query) => {
  const state = Object.entries(initFilters).reduce<Filters>((filtersStateMap, [slug, value]) => {
    value = routeQuery[slug] || value;

    if (!value) return filtersStateMap;

    filtersStateMap[slug] = value;

    return filtersStateMap;
  }, {});

  return state;
};

const createQueryFilters = (initFilters: Filters) => {
  const query = Object.entries(initFilters).reduce<Query>((queryMap, [slug, value]) => {
    const checkQueryEntry = (val: string | null | undefined) => [null, undefined, ''].includes(val);

    const queryEntryIsEmpty = Array.isArray(value) ? value.every((val) => checkQueryEntry(val)) : checkQueryEntry(value);

    if (queryEntryIsEmpty) return queryMap;

    queryMap[slug] = value as string | (string | null)[];

    return queryMap;
  }, {});

  return query;
};

export default (props: UseQueryFilterProps, context: SetupContext): UseQueryFilterReturn => {
  const { filters } = props;

  const router = useRouter(context);
  const route = useRoute(context);

  const filtersState = ref(createFiltersState(filters, route.value.query));

  const queryFilters = ref(createQueryFilters(filtersState.value));

  const setQueryFilters = (payload: Filters) => {
    filtersState.value = payload;
  };

  const updateQueryFilters = (payload: Filters) => {
    const newValue = { ...filtersState.value, ...payload };

    filtersState.value = newValue;
  };

  const setQueryFiltersViaFiltersState = debounce((payload: Filters) => {
    const newQueryFilters = createQueryFilters(payload);

    const oldValueIsEqualNewValue = isEqual(queryFilters.value, newQueryFilters);

    if (oldValueIsEqualNewValue) return;

    queryFilters.value = newQueryFilters;
  }, 30);

  const routeReplace = (payload: Query) => {
    const oldValueIsEqualNewValue = isEqual(route.value.query, payload);

    if (oldValueIsEqualNewValue) return;

    router.replace({ query: payload });
  };

  watch(route, () => {
    updateQueryFilters(route.value.query);
  });

  watch(filtersState, (newValue) => {
    setQueryFiltersViaFiltersState(newValue);
  });

  watch(queryFilters, (newValue) => {
    routeReplace(newValue);
  });

  return {
    queryFilters,
    setQueryFilters,
    updateQueryFilters,
  };
};
