import { render, staticRenderFns } from "./ModallyModalFooter.vue?vue&type=template&id=26b8c4ae&scoped=true&"
import script from "./ModallyModalFooter.vue?vue&type=script&lang=ts&"
export * from "./ModallyModalFooter.vue?vue&type=script&lang=ts&"
import style0 from "./ModallyModalFooter.vue?vue&type=style&index=0&id=26b8c4ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26b8c4ae",
  null
  
)

export default component.exports