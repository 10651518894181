import Vue from 'vue';

import { upperFirst, camelCase } from 'lodash';

import PluginInit from '@/models/PluginInit';

const pluginInit: PluginInit = () => {
  const requiredFiles = require.context('@/layouts', false, /\.vue$/);

  requiredFiles.keys().forEach((filePath) => {
    const layoutNamePostfix = 'Layout';

    const fileName = (filePath.split('/').pop() || '').replace(/\.\w+$/, '');
    const componentName = upperFirst(camelCase(fileName));
    const layoutName = componentName.endsWith(layoutNamePostfix) ? componentName : `${componentName}${layoutNamePostfix}`;

    const componentConfig = requiredFiles(filePath);
    const layout = componentConfig.default || componentConfig;

    Vue.component(layoutName, layout);
  });
};

export default pluginInit;
