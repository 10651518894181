import axios, { AxiosAdapter, AxiosRequestConfig, AxiosResponse, AxiosInterceptorManager } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

const axiosInstance = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { enabledByDefault: false }),
});

export const setBaseUrl = (path: string): void => {
  axiosInstance.defaults.baseURL = path;
};

export const setRequestInterceptor: AxiosInterceptorManager<AxiosRequestConfig>['use'] = (...args) => axiosInstance.interceptors.request.use(...args);

export const setResponseInterceptor: AxiosInterceptorManager<AxiosResponse>['use'] = (...args) => axiosInstance.interceptors.response.use(...args);

export default axiosInstance;
