/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import { DataProvidersApi } from '@/api';

import { DataProvider } from '@/models/DataProvider';

import { PartialWithout } from '@/utils/type';

class ModuleState {
  dataProviders: DataProvider[] = [];

  isLoading = false;
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get dataProviders() {
    return this.state.dataProviders;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_DATA_PROVIDERS(payload: DataProvider[]) {
    this.state.dataProviders = payload;
  }

  REMOVE_PROVIDER_SETTINGS(dataProviderId: string) {
    this.state.dataProviders = this.state.dataProviders.filter((group) => group.id !== dataProviderId);
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_DATA_PROVIDERS() {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await DataProvidersApi.fetchDataProviders();

      this.commit('SET_DATA_PROVIDERS', res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async CREATE_DATA_PROVIDER(payload: PartialWithout<DataProvider, 'id'>) {
    await DataProvidersApi.setDataProvider(payload);

    this.dispatch('FETCH_DATA_PROVIDERS');
  }

  async UPDATE_DATA_PROVIDER(payload: DataProvider) {
    await DataProvidersApi.setDataProvider(payload);

    this.dispatch('FETCH_DATA_PROVIDERS');
  }

  async DELETE_DATA_PROVIDER(dataProviderId: string) {
    await DataProvidersApi.deleteDataProvider(dataProviderId);

    this.commit('REMOVE_PROVIDER_SETTINGS', dataProviderId);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useDataProvidersCatalogStore = createComposable(module);

export default module;
