
































import { defineComponent, toRefs, ref, computed } from '@vue/composition-api';
import { Route, NavigationGuardNext } from 'vue-router';

import useRouter from '@/packages/hooks/useRouter';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignPlacementEditorHeader from '@/components/_newDesign/Campaigns/CampaignPlacementEditor/CampaignPlacementEditorHeader.vue';
import CampaignPlacementEditorContent from '@/components/_newDesign/Campaigns/CampaignPlacementEditor/CampaignPlacementEditorContent.vue';
import CampaignPlacementEditorFooter from '@/components/_newDesign/Campaigns/CampaignPlacementEditor/CampaignPlacementEditorFooter.vue';

import useMeta from '@/composables/meta/useMeta';
import { useLayoutLoadingStatus } from '@/composables/layout';

import { useNavigationStore } from '@/store/navigation';
import { useCampaignStore } from '@/store/campaign';
import { useCampaignEditorPlacementsStore } from '@/store/campaignEditor/campaignEditorPlacements';
import { useCampaignEditorPlacementDetailsStore } from '@/store/campaignEditor/campaignEditorPlacementDetails';

import modally from '@/services/modally';

export default defineComponent({
  components: {
    AppView,
    CampaignPlacementEditorHeader,
    CampaignPlacementEditorContent,
    CampaignPlacementEditorFooter,
  },
  async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
    next(async (vm) => {
      await vm.$store.dispatch('campaignEditor/RESET_EDITORS_CHANGES');
    });
  },
  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
    const customNext = async () => {
      await this.$store.dispatch('campaignEditor/RESET_EDITORS_CHANGES');

      next();
    };

    if (!this.placementHasBeenChanged) return customNext();

    if (this.isSaving) {
      const result = await modally.show({
        type: 'confirm',
        status: 'warning',
        title: 'Идет сохранение. Выйти из редактирования?',
      });

      if (!result) return;

      customNext();
    } else if (!this.isCanceling) {
      await modally.show({
        status: 'warning',
        title: 'Есть несохраненные данные',
        content: `Изменения в размещении ${this.placement?.siteName} будут сброшены`,
        buttons: [
          {
            props: {
              text: 'Выйти без сохранения',
              color: 'warning',
              outlined: true,
            },
            action: customNext,
          },
          {
            props: {
              text: 'Сохранить и выйти',
              color: 'primary',
              outlined: true,
            },
            action: async () => {
              await this.save();

              customNext();
            },
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary-gradient',
            },
            action: () => { /* empty */ },
          },
        ],
      });
    } else {
      customNext();
    }
  },

  props: {
    campaignId: { type: String, required: true },
    placementDanboId: { type: String, required: true },
  },
  setup(props, context) {
    const {
      campaignId,
      placementDanboId,
    } = toRefs(props);

    const router = useRouter(context);

    const navigationStore = useNavigationStore();
    const campaignStore = useCampaignStore();
    const campaignEditorPlacementsStore = useCampaignEditorPlacementsStore();
    const campaignEditorPlacementDetailsStore = useCampaignEditorPlacementDetailsStore();

    const {
      isLoading,
      updateIsLoading,
    } = useLayoutLoadingStatus();

    const isSaving = ref(false);
    const isCanceling = ref(false);

    const campaign = computed(() => campaignStore.getters.campaign);
    const placement = computed(() => campaignEditorPlacementDetailsStore.getters.placement);
    const currentPlacement = computed(() => campaignEditorPlacementDetailsStore.getters.currentPlacement);

    const pageMeta = computed(() => ({
      title: placement.value ? `${placement.value.siteName} • Настройки размещения` : 'Настройки размещения',
    }));

    useMeta(pageMeta);

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await Promise.all([
          campaignStore.dispatch('FETCH_CAMPAIGN', { campaignId: campaignId.value }),
          campaignEditorPlacementsStore.dispatch('FETCH_CAMPAIGN_PLAN_METRICS', campaignId.value),
          campaignEditorPlacementDetailsStore.dispatch('FETCH_PLACEMENT', {
            campaignId: campaignId.value,
            placementDanboId: placementDanboId.value,
          }),
        ]);
      } finally {
        updateIsLoading(false);
      }
    };

    const placementHasBeenChanged = computed(() => campaignEditorPlacementDetailsStore.getters.placementHasBeenChanged);
    const campaignEditorPath = computed(() => `/campaigns/${campaign.value?.id}/edit`);

    const prevRoutePath = computed(() => {
      const defaultPath = campaignEditorPath.value;

      const prevRoute = navigationStore.getters.getPrevRoute({
        name: [
          /campaign.+/i,
          /campaign-editor.+/i,
        ],
      });

      return prevRoute?.path || defaultPath;
    });

    const cancel = async () => {
      const redirectCallback = () => {
        isCanceling.value = false;
      };

      const redirect = () => {
        router.push(prevRoutePath.value, redirectCallback, redirectCallback);
      };

      isCanceling.value = true;

      if (isSaving.value || !placementHasBeenChanged.value) return redirect();

      const result = await modally.show({
        status: 'warning',
        title: 'Есть несохраненные данные',
        content: `Изменения в размещении ${placement.value?.siteName} будут сброшены`,
        buttons: [
          {
            props: {
              text: 'Выйти без сохранения',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary-gradient',
            },
            action: () => false,
          },
        ],
      });

      if (!result) {
        isCanceling.value = false;

        return;
      }

      redirect();
    };

    const save = async () => {
      if (!placementHasBeenChanged.value) return;

      isSaving.value = true;

      try {
        await campaignEditorPlacementDetailsStore.dispatch('SAVE_PLACEMENT', { campaignId: campaignId.value });

        cancel();
      } finally {
        isSaving.value = false;
      }
    };

    const showPlacement = async () => {
      updateIsLoading(true);

      try {
        await campaignEditorPlacementDetailsStore.dispatch('SHOW_PLACEMENT', {
          campaignId: campaignId.value,
          placementDanboId: placementDanboId.value,
        });
      } finally {
        updateIsLoading(false);
      }
    };

    fetch();

    return {
      isLoading,
      isSaving,
      isCanceling,
      campaign,
      placement,
      currentPlacement,
      save,
      showPlacement,
      cancel,
      placementHasBeenChanged,
    };
  },
});
