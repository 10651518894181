

























import { defineComponent, computed } from '@vue/composition-api';

import { debounce } from 'lodash';

import { DDatePeriodPickerInline } from '@shared/druid-kit';

import { useUserActionsCatalogStore } from '../../../store/userActions/userActionsCatalog';

export default defineComponent({
  components: {
    DDatePeriodPickerInline,
  },
  setup() {
    const userActionsCatalogStore = useUserActionsCatalogStore();

    const dates = computed({
      get() {
        return {
          from: userActionsCatalogStore.getters.filters.from,
          to: userActionsCatalogStore.getters.filters.to,
        };
      },
      set(val: { from: string, to: string }) {
        userActionsCatalogStore.dispatch('UPDATE_FILTERS', val);
      },
    });

    const query = computed({
      get() {
        return userActionsCatalogStore.getters.filters.filter;
      },
      set: debounce((val: string) => {
        userActionsCatalogStore.dispatch('UPDATE_FILTERS', { filter: val });
      }, 300),
    });

    const dateFiltersPresets = ['yesterday', 'last-7-days', 'last-month', 'last-30-days'];

    return {
      query,
      dates,
      dateFiltersPresets,
    };
  },
});
