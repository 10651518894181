





































import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import { SearchResultListItem } from '../../models';

import { useSearchStore } from '../../store';

export default defineComponent({
  props: {
    item: { type: Object as PropType<SearchResultListItem>, required: true },
  },
  setup(props) {
    const { item } = toRefs(props);

    const searchStore = useSearchStore();

    const query = computed(() => searchStore.getters.query);

    const name = computed(() => {
      const regExp = new RegExp(query.value, 'ig');

      return item.value.name.replace(regExp, '<span class="search-list-item__match">$&</span>');
    });

    return {
      name,
    };
  },
});
