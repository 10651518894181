
































import { defineComponent, computed } from '@vue/composition-api';

import AppSideBarMenuItem from '@/components/_newDesign/App/AppSideBar/AppSideBarMenuItem.vue';

import store from '@/store';
import { useAuthStore } from '@/store/auth';
import navigationUiStore from '@/store/navigation/navigationUi';

const navigationUiStoreContext = navigationUiStore.context(store);

export default defineComponent({
  components: {
    AppSideBarMenuItem,
  },
  props: {
    sideBarIsShown: { type: Boolean, default: false },
  },
  setup() {
    const authStore = useAuthStore();

    const user = computed(() => authStore.getters.user);
    const headerMinHeight = computed(() => navigationUiStoreContext.getters.headerMinHeight);

    const iconOptions = {
      name: '$d1-project-logo',
      size: 48,
      custom: true,
    };

    return {
      user,
      headerMinHeight,
      iconOptions,
    };
  },
});
