import { AxiosResponse } from 'axios';

import { User } from '@/models/User';

import httpClient from '../httpClient';

export default class AccountsApi {
  public static async fetchUser(): Promise<AxiosResponse<User>> {
    const res = await httpClient.get<User>('/api/v2/accounts/me');

    return res;
  }
}
