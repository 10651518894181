































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { Route, NavigationGuardNext } from 'vue-router';

import modally from '@/services/modally';

import useElHeight from '@/composables/useElHeight';

import { MaintenanceApi } from '@/api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import AppClientsTreeView from '@/components/_newDesign/common/inputs/AppClientsTreeView.vue';

interface MaintenanceTask {
  title: string;
  descr: string[];
  isLoading: boolean;
  action: (task: MaintenanceTask) => Promise<void> | void;
  error: {
    title: string;
    messages: string[];
  } | null;
}

export default defineComponent({
  components: {
    AppView,
    AppClientsTreeView,
  },
  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
    if (!this.tasksInProgress) return next();

    const result = await modally.show({
      type: 'confirm',
      status: 'warning',
      title: 'Задачи в процессе выполнения',
      content: 'Вы уверены, что хотите уйти со страницы?',
    });

    if (!result) return;

    next();
  },
  setup() {
    const {
      el: clientsTreeViewFieldEl,
      elHeight: clientsTreeViewFieldElHeight,
    } = useElHeight();

    const selectedClients = ref([]);

    const tasks = ref<MaintenanceTask[]>([
      {
        title: 'Обновление метрик кампаний',
        descr: [
          'Обновить метрики всех кампаний',
        ],
        isLoading: false,
        async action() {
          await MaintenanceApi.updateCampaignMetrics(selectedClients.value);
        },
        error: null,
      },
      {
        title: 'Обновление провайдеров данных в размещениях',
        descr: [
          'Обновить название и приоритет провайдеров данных в размещениях.',
          'Обновляются метрики в размещениях и кампаниях',
        ],
        isLoading: false,
        async action() {
          await MaintenanceApi.updatePlacementMetricDataproviders(selectedClients.value);
        },
        error: null,
      },
      {
        title: 'Пересчитать метрики',
        descr: [
          'Пересчитываются метрики по всем источникам данных.',
          'Обновляются метрики в размещениях и кампаниях ',
        ],
        isLoading: false,
        async action() {
          await MaintenanceApi.recalculatePlacementMetrics(selectedClients.value);
        },
        error: null,
      },
      {
        title: 'Обновить наценки',
        descr: [
          'Обновить связи наценок и размещений без пересчета сумм',
        ],
        isLoading: false,
        async action() {
          await MaintenanceApi.updatePlacementMarkups(selectedClients.value);
        },
        error: null,
      },
      {
        title: 'Обновить флайты',
        descr: [
          'Временный функционал. Запустить нормализацию флайтов',
        ],
        isLoading: false,
        async action() {
          await MaintenanceApi.recalculatePlacementFlights(selectedClients.value);
        },
        error: null,
      },
    ]);

    const tasksInProgress = computed(() => tasks.value.some((task) => task.isLoading));

    const startTask = async (task: MaintenanceTask) => {
      task.isLoading = true;
      task.error = null;

      try {
        await task.action(task);
      } catch (e) {
        task.error = {
          title: 'Произошла ошибка',
          messages: [],
        };
      } finally {
        task.isLoading = false;
      }
    };

    const closeTaskError = (task: MaintenanceTask) => {
      task.error = null;
    };

    return {
      clientsTreeViewFieldEl,
      clientsTreeViewFieldElHeight,
      selectedClients,
      tasks,
      tasksInProgress,
      startTask,
      closeTaskError,
    };
  },
});
