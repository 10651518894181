import { registerModule } from 'vuex-smart-module';
import ServiceInit from '@/models/ServiceInit';

import notify from './store/notify.store';

const serviceInit: ServiceInit = (ctx) => {
  registerModule(ctx.store, ['notify'], 'notify', notify);
};

export default serviceInit;
