/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import {
  GroupsApi,
} from '@/api';

import { SetGroupPayload, UpdateGroupPayload } from '@/api/modules/groups.api';

import { Group } from '@/models/Group';

export interface GroupsCatalogFilters {
  query: string;
  page: number;
  limit: number;
}

const initFilters = () => ({
  page: 1,
  query: '',
  limit: 50,
});

class ModuleState {
  filters: GroupsCatalogFilters = initFilters();

  filtersChanged = false;

  isLoading = false;

  groups: Group[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get groups() {
    return this.state.groups;
  }

  get filters() {
    return this.state.filters;
  }

  get filtersChanged() {
    return this.state.filtersChanged;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_GROUPS(payload: Group[]) {
    this.state.groups = payload;
  }

  ADD_GROUPS(payload: Group[]) {
    this.state.groups = [...this.state.groups, ...payload];
  }

  REMOVE_GROUP(groupId: string) {
    this.state.groups = this.state.groups.filter((group) => group.id !== groupId);
  }

  SET_FILTERS(payload: GroupsCatalogFilters) {
    this.state.filters = payload;
  }

  SET_FILTERS_CHANGED(payload: boolean) {
    this.state.filtersChanged = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_GROUPS() {
    const { page, limit, query } = this.getters.filters;

    this.commit('SET_IS_LOADING', true);

    try {
      const res = await GroupsApi.fetchGroups({ page, limit, filter: query });

      if (page === 1) {
        this.commit('SET_GROUPS', res.data.list);
      } else {
        this.commit('ADD_GROUPS', res.data.list);
      }
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async CREATE_GROUP(payload: SetGroupPayload) {
    await GroupsApi.setGroup(payload);

    await this.dispatch('FETCH_GROUPS');
  }

  async UPDATE_GROUP(payload: UpdateGroupPayload) {
    await GroupsApi.updateGroup(payload);

    await this.dispatch('FETCH_GROUPS');
  }

  UPDATE_FILTERS(filters: Partial<GroupsCatalogFilters>) {
    const page = filters.page || 1;

    const newFilters = {
      ...this.getters.filters,
      ...filters,
      page,
    };

    this.commit('SET_FILTERS', newFilters);
    this.commit('SET_FILTERS_CHANGED', true);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
    this.commit('SET_FILTERS_CHANGED', false);
  }

  async DELETE_GROUP(groupId: string) {
    await GroupsApi.deleteGroup(groupId);

    this.commit('REMOVE_GROUP', groupId);
  }

  NEXT_PAGE() {
    this.dispatch('UPDATE_FILTERS', {
      page: this.getters.filters.page + 1,
    });
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useGroupsCatalogStore = createComposable(module);

export default module;
