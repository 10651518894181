


















import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import { DBreadcrumbs } from '@shared/druid-kit';

import { Campaign, CampaignPlacement, CampaignCategory } from '@/models/Campaign';

export default defineComponent({
  components: {
    DBreadcrumbs,
  },
  props: {
    danboId: { type: String, required: true },
    campaign: { type: Object as PropType<Campaign>, required: true },
    campaignPlacement: { type: Object as PropType<CampaignPlacement>, required: true },
    campaignCategory: { type: Object as PropType<CampaignCategory | null>, default: () => null },
  },
  setup(props) {
    const {
      danboId,
      campaign,
      campaignPlacement,
      campaignCategory,
    } = toRefs(props);

    const breadcrumbs = computed(() => [
      {
        label: campaign.value.brandName,
        path: `/clients/${campaign.value.clientId}/?brandId=${campaign.value.brandId}`,
      },
      {
        label: campaign.value.name,
        path: `/campaigns/${campaign.value.id}`,
      },
      ...(campaignCategory.value ? [
        {
          label: campaignCategory.value.name,
          path: `/campaigns/${campaign.value.id}/category/${campaignCategory.value.id}`,
        },
      ] : []),
      {
        label: campaignPlacement.value.marker.name,
        path: `/campaigns/${campaign.value.id}/placements/${campaignPlacement.value.marker.id}`,
      },
    ]);

    const placementEditorPath = computed(() => `/campaigns/${campaign.value.id}/placements/${danboId.value}/edit`);

    return {
      breadcrumbs,
      placementEditorPath,
    };
  },
});
