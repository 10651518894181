



























































































import { defineComponent, toRefs, ref, PropType, computed } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
  DTooltip,
} from '@shared/druid-kit';

import modally from '@/services/modally';

import { DataProvidersSettings } from '@/models/DataProvidersSettings';

import { useDataProvidersSettingsCatalogStore } from '../../../../store/dataProvidersSettings/dataProvidersSettingsCatalog';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
    DTooltip,
  },
  props: {
    dataProviderSettings: { type: Object as PropType<DataProvidersSettings>, required: true },
  },
  setup(props, context) {
    const {
      dataProviderSettings,
    } = toRefs(props);

    const dataProvidersSettingsCatalogStore = useDataProvidersSettingsCatalogStore();

    const openItemModal = () => {
      context.emit('open-modal', { slug: 'item', props: { dataProviderSettingsId: dataProviderSettings.value.id } });
    };

    const deletion = ref(false);

    const statusIcon = computed(() => {
      const icons = {
        finishedWithError: {
          icon: 'mdi-close-circle',
          color: 'error',
        },
        available: {
          icon: 'mdi-checkbox-marked-circle',
          color: 'success',
        },
        inProgress: {
          icon: 'mdi-timer-sand',
          color: 'grey',
        },
        default: {
          icon: 'mdi-help-circle-outline',
          color: 'grey',

        },
      };

      const iconKey = Object.keys(icons).find((key) => !!dataProviderSettings.value[key]) ?? 'default';

      return icons[iconKey];
    });

    const startGatherStatistics = () => {
      dataProvidersSettingsCatalogStore.actions.START_GATHER_STATISTICS(dataProviderSettings.value);
    };

    const deleteDataProviderSettings = async () => {
      const result = await modally.show({
        title: 'Удалить настройку?',
        content: `Настройка ${dataProviderSettings.value.name} будет удалена.`,
        buttons: [
          {
            props: {
              text: 'Удалить',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary',
            },
            action: () => false,
          },
        ],
      });

      if (!result) return;

      deletion.value = true;

      try {
        await dataProvidersSettingsCatalogStore.actions.DELETE_PROVIDER_SETTINGS(dataProviderSettings.value.id);
      } finally {
        deletion.value = false;
      }
    };

    return {
      openItemModal,
      deletion,
      deleteDataProviderSettings,
      startGatherStatistics,
      statusIcon,
    };
  },
});
