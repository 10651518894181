














































































import { defineComponent, toRefs, ref, computed, watch, PropType } from '@vue/composition-api';

import moment from 'moment';

import { DDatePickerTextField } from '@shared/druid-kit';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import notify from '@/services/notify';
import AppJsonError from '@/services/errorHandler/AppJsonError';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { useCampaignEditorPlacementDetailsStore } from '@/store/campaignEditor/campaignEditorPlacementDetails';

import { Markup } from '@/models/Markup';

export default defineComponent({
  components: {
    AppModal,
    DDatePickerTextField,
  },
  props: {
    value: { type: Boolean, default: false },
    markup: { type: Object as PropType<Markup | null>, default: null },
  },
  setup(props, context) {
    const {
      value,
      markup,
    } = toRefs(props);

    const campaignEditorPlacementsDetailsStore = useCampaignEditorPlacementDetailsStore();

    const title = computed(() => (markup.value ? `Наценка для ${markup.value.dataProviderName}` : 'Наценка'));

    const markupsWithCost = computed(() => markup.value?.markupsWithCost || []);

    const maxMarkupPeriodStartDate = computed(() => {
      const markupPeriodsStartDates = markupsWithCost.value.map((period) => moment(period.startDate));

      return moment.utc(moment.max(markupPeriodsStartDates)).format('YYYY-MM-DDTHH:mm:ss[Z]');
    });

    const currentStartDate = ref(moment(maxMarkupPeriodStartDate.value).add(1, 'day').format('YYYY-MM-DD'));

    const currentMultiplier = ref(1);

    const newMarkupPeriod = computed(() => {
      if (!markup.value || !currentStartDate.value || !currentMultiplier.value) return null;

      return {
        clientId: markup.value.clientId,
        brandId: markup.value.brandId,
        campaignId: markup.value.campaignId,
        placementId: markup.value.placementId,
        dataProviderId: markup.value.dataProviderId,
        startDate: moment.utc(currentStartDate.value).toISOString(),
        value: currentMultiplier.value,
      };
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => saving.value || !newMarkupPeriod.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!markup.value || !newMarkupPeriod.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await campaignEditorPlacementsDetailsStore.dispatch('ADD_MARKUP_PERIOD', {
          markupId: markup.value.id,
          data: newMarkupPeriod.value,
        });

        notify.push({
          text: `Наценка для ${markup.value.dataProviderName} добавлена`,
          position: ['bottom', 'center'],
          status: 'positive',
        });

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const message = error.response?.data?.message;

          saveError.value = {
            title: 'Произошла ошибка',
            messages: [message],
          };
        } else {
          saveError.value = {
            title: 'Произошла ошибка. Обратитесь к администратору',
            messages: [],
          };

          throw new AppJsonError({ error, options: { showingModal: false } });
        }
      } finally {
        saving.value = false;
      }
    };

    watch(value, (val) => {
      if (!val) return;

      currentStartDate.value = moment(maxMarkupPeriodStartDate.value).add(1, 'day').format('YYYY-MM-DD');
      currentMultiplier.value = 1;
    });

    return {
      title,
      currentStartDate,
      currentMultiplier,
      saveBtnIsDisabled,
      saveError,
      save,
    };
  },
});
