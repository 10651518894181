<template>
  <div class="campaign-editor-table-loader">
    <v-skeleton-loader
      v-if="!withoutHeader"
      type="text"
      class="campaign-editor-table-loader__content-title"
    />
    <TableLoader :rows="3" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

export default defineComponent({
  components: { TableLoader },
  props: {
    withoutHeader: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.campaign-editor-table-loader {
  padding: 12px 24px;

  &__content-title {
    width: 16%;

    ::v-deep .v-skeleton-loader__text {
      margin-bottom: 32px !important;
      background: map-get($grey, 'lighten-1') !important;
    }
  }
}
</style>
