





















import { defineComponent, toRefs, provide, computed, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignPlacementHeader from '@/components/_newDesign/Campaigns/CampaignPlacement/CampaignPlacementHeader.vue';
import CampaignPlacementContent from '@/components/_newDesign/Campaigns/CampaignPlacement/CampaignPlacementContent.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';
import useQueryFilters from '@/composables/useQueryFilters';
import { useCampaignDetails } from '@/composables/campaign';
import useMeta from '@/composables/meta/useMeta';

import { useCampaignStore } from '@/store/campaign';

export default defineComponent({
  components: {
    AppView,
    CampaignPlacementHeader,
    CampaignPlacementContent,
  },
  props: {
    campaignId: { type: String, required: true },
    danboId: { type: String, required: true },
  },
  setup(props, context) {
    const { campaignId, danboId } = toRefs(props);

    const campaignStore = useCampaignStore();

    const {
      isLoading,
      updateIsLoading,
    } = useLayoutLoadingStatus();

    const {
      queryFilters,
      updateQueryFilters,
    } = useQueryFilters({
      filters: {
        from: undefined,
        to: undefined,
      },
    }, context);

    const campaignPlacement = computed(() => campaignStore.getters.campaignPlacement);

    provide('queryFilters', queryFilters);
    provide('updateQueryFilters', updateQueryFilters);

    const {
      campaign,
      campaignCategories,
      campaignPageTitle,
      fetchCampaign,
    } = useCampaignDetails({ campaignId });

    const campaignCategory = computed(() => campaignCategories.value
      .filter(({ placements }) => placements.find((placement) => placement.danboId === danboId.value))[0] ?? null);

    const pageMeta = computed(() => ({ title: campaignPageTitle.value }));

    useMeta(pageMeta);

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await Promise.all([
          fetchCampaign(),
          campaignStore.dispatch('FETCH_CAMPAIGN_PLACEMENT', {
            campaignId: campaignId.value,
            danboId: danboId.value,
            params: queryFilters.value,
          }),
        ]);
      } finally {
        updateIsLoading(false);
      }
    };

    watch(queryFilters, () => fetch());

    fetch();

    return {
      campaign,
      campaignPlacement,
      campaignCategory,
      isLoading,
    };
  },
});
