import { render, staticRenderFns } from "./BrandListItemTableRow.vue?vue&type=template&id=e12d86d0&scoped=true&"
import script from "./BrandListItemTableRow.vue?vue&type=script&lang=ts&"
export * from "./BrandListItemTableRow.vue?vue&type=script&lang=ts&"
import style0 from "./BrandListItemTableRow.vue?vue&type=style&index=0&id=e12d86d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e12d86d0",
  null
  
)

export default component.exports