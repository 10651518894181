
































import Vue from 'vue';
import { defineComponent, toRefs, ref, PropType } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import downloadFile from '@/utils/downloadFile';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  components: {
    AppModal,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const { campaign } = toRefs(props);

    const reports = ref([
      {
        slug: 'placements',
        url: `/api/campaigneditor/${campaign.value.id}/reports/plan`,
        name: 'План по размещениям',
        gtm: {
          eventAction: 'Download MediaPlan',
          eventLabel: 'MediaPlan',
        },
      },
      {
        slug: 'flights',
        url: `/api/campaigneditor/${campaign.value.id}/reports/flights-plan`,
        name: 'План с разбивкой по флайтам',
        gtm: {
          eventAction: 'Download Flights',
          eventLabel: 'Flights',
        },
      },
    ] as const);

    type Report = typeof reports.value[number];

    const reportLoadingsMap = ref(reports.value.reduce((map, report) => {
      map[report.slug] = false;

      return map;
    }, {} as Record<Report['slug'], boolean>));

    const close = () => {
      context.emit('input', false);
    };

    const downloadReport = async (report: Report) => {
      reportLoadingsMap.value[report.slug] = true;

      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Campaign Editor',
        event_action: report.gtm.eventAction,
        event_label: report.gtm.eventLabel,
        d1d_client_id: campaign.value.clientId,
        d1d_client_name: campaign.value.clientName,
        d1d_brand_id: campaign.value.brandId,
        d1d_brand_name: campaign.value.brandName,
        d1d_campaign_id: campaign.value.id,
        d1d_campaign_name: campaign.value.name,
      });

      try {
        await downloadFile({ url: report.url });

        close();

      // TODO: Обработать ошибку, показывать модалку
      } finally {
        reportLoadingsMap.value[report.slug] = false;
      }
    };

    return {
      reports,
      reportLoadingsMap,
      close,
      downloadReport,
    };
  },
});
