































































































import Vue, { VueConstructor, PropType } from 'vue';

import { DTooltip } from '@shared/druid-kit';
import { HnTableCellMixin } from '@/packages/hn-table/src/mixins';

import { Campaign, CampaignEditorPlacement } from '@/models/Campaign';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'PlacementsTableInfoCell',
  components: {
    DTooltip,
  },
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    campaignPlacements: { type: Array as PropType<CampaignEditorPlacement[]>, required: true },
  },
  data() {
    return {
      placement: null as CampaignEditorPlacement | null,
      tooltipIsShown: false,
    };
  },
  computed: {
    cellPropertiesRow(): number | undefined {
      return this.cellProperties?.row;
    },
    flightsCount(): number {
      return this.placement?.flightsCount || 0;
    },
    campaignPlacementEditorPath(): string {
      if (!this.placement) return '';

      return `/campaigns/${this.campaign.id}/placements/${this.placement.danboId}/edit`;
    },
    title(): string {
      return this.placement?.siteName ?? '';
    },
    subtitle(): string {
      return [
        this.placement?.adPlace,
        this.placement?.placementType,
      ].filter((text) => text).join(' / ');
    },
    placementIsHidden(): boolean {
      return !!this.placement?.hidden;
    },
    placementComment(): string {
      return this.placement?.comment ?? '';
    },
  },
  watch: {
    hotInstance: {
      handler(newValue, oldValue) {
        oldValue?.removeHook('afterChange', this.afterChange);

        newValue?.addHook('afterChange', this.afterChange);
      },
      immediate: true,
    },
    campaignPlacements: {
      handler() {
        this.initPlacement();
      },
      immediate: true,
    },
    async cellPropertiesRow() {
      await this.initPlacement();
    },
  },
  beforeDestroy() {
    this.hotInstance?.removeHook('afterChange', this.afterChange);
  },
  methods: {
    async initPlacement() {
      if (typeof this.cellPropertiesRow !== 'number') return;

      await this.$nextTick();

      const campaignPlacement = this.campaignPlacements[this.cellPropertiesRow];

      this.placement = campaignPlacement;
    },
    afterChange() {
      this.initPlacement();
    },
    closeTooltip() {
      this.tooltipIsShown = false;
    },
    toggleVisible() {
      if (!this.hotInstance) return;

      this.hotInstance.setDataAtCell(this.cellRow, this.col, !this.value);
    },
    onCampaignPlacementEditorLinkClick() {
      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Placement Editor',
        event_action: 'Open Flights Editor',
        event_label: this.placement?.danboId,
        d1d_client_id: this.campaign.clientId,
        d1d_client_name: this.campaign.clientName,
        d1d_brand_id: this.campaign.brandId,
        d1d_brand_name: this.campaign.brandName,
        d1d_campaign_id: this.campaign.id,
        d1d_campaign_name: this.campaign.name,
      });

      this.closeTooltip();
    },
  },
});
