











import { defineComponent } from '@vue/composition-api';

import { DTableCell } from '@shared/druid-kit';

export default defineComponent({
  components: {
    DTableCell,
  },
  props: {
    type: { type: String, default: '' },
  },
});
