import Handsontable from 'handsontable';

export interface Coords { row: number; col: number }

class HnTableCellClassName {
  private table: Handsontable;

  private coords: Coords;

  constructor({ table, coords }: { table: Handsontable; coords: Coords }) {
    this.table = table;
    this.coords = coords;
  }

  public get(): string {
    const cellMeta = this.table.getCellMeta(this.coords.row, this.coords.col);

    let classList = cellMeta.className || '';

    if (Array.isArray(classList)) {
      classList = classList.join(' ');
    }

    return classList;
  }

  public add(className: string): void {
    const classList = this.get();
    const classListIncludesClassName = classList.includes(className);

    if (classListIncludesClassName) return;

    const newClassList = `${classList} ${className}`;

    this.table.setCellMeta(this.coords.row, this.coords.col, 'className', newClassList);
  }

  public remove(className: string): void {
    const classList = this.get();
    const newClassList = classList.replace(className, '');

    this.table.setCellMeta(this.coords.row, this.coords.col, 'className', newClassList);
  }
}

export { HnTableCellClassName };
export default HnTableCellClassName;
