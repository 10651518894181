/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import dashboard from './dashboard';
import dataProviders from './dataProviders';
import dataProvidersSettings from './dataProvidersSettings';
import categories from './categories';
import userActions from './userActions';

const module = new Module({
  modules: {
    dashboard,
    dataProviders,
    dataProvidersSettings,
    categories,
    userActions,
  },
});

export const useAdministrationStore = createComposable(module);

export default module;
