import { render, staticRenderFns } from "./BrandListItemTableDescCell.vue?vue&type=template&id=1a3df161&scoped=true&"
import script from "./BrandListItemTableDescCell.vue?vue&type=script&lang=ts&"
export * from "./BrandListItemTableDescCell.vue?vue&type=script&lang=ts&"
import style0 from "./BrandListItemTableDescCell.vue?vue&type=style&index=0&id=1a3df161&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a3df161",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
