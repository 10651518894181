import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import PluginInit from '@/models/PluginInit';

import hasOwnProperty from '@/utils/hasOwnProperty';

const pluginInit: PluginInit = () => {
  if (process.env.NODE_ENV !== 'production') return;

  Sentry.init({
    release: Vue.configuration.commitSha,
    dsn: 'https://236fd09dd16946e0ba86e2f2a2cee5cf@sentry.aemedia.ru/2',
    autoSessionTracking: false,
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true }),
    ],
    maxValueLength: 10000,
    beforeSend(event) {
      if (!event.exception?.values || !Array.isArray(event.exception.values)) return event;

      const exceptionValues = event.exception.values
        .reduce<Sentry.Exception[]>((acc, exception) => {
        if (exception.type === 'JSON Error' && typeof exception.value === 'string') {
          let parsedException: Record<string, unknown> | null;

          try {
            parsedException = JSON.parse(exception.value);
          } catch (error) {
            parsedException = null;
          }

          const parsedExceptionError = parsedException?.error;

          acc.push({
            ...exception,
            type: 'Error',
            value: parsedExceptionError
              && typeof parsedExceptionError === 'object'
              && hasOwnProperty(parsedExceptionError, 'message')
              && typeof parsedExceptionError.message === 'string'
              ? parsedExceptionError.message : 'Unknown error',
          });
        } else {
          acc.push(exception);
        }

        return acc;
      }, []);

      return {
        ...event,
        exception: {
          ...event.exception,
          values: exceptionValues,
        },
      };
    },
  });
};

export default pluginInit;
