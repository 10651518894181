































































import { defineComponent, computed } from '@vue/composition-api';

import AppSideBarMenuItem from '@/components/_newDesign/App/AppSideBar/AppSideBarMenuItem.vue';

import { useSearchStore } from '@/modules/search/store';

import { useAuthStore } from '@/store/auth';

type MenuItem = {
  title: string;
  icon: string;
  path: string;
} | {
  title: string;
  icon: string;
  gtmIsActive?: boolean;
  children: MenuItem[],
} | {
  title: string;
  icon: string;
  active?: boolean;
  action: () => void;
};

export default defineComponent({
  components: {
    AppSideBarMenuItem,
  },
  setup() {
    const authStore = useAuthStore();
    const searchStore = useSearchStore();

    const user = computed(() => authStore.getters.user);
    const userName = computed(() => authStore.getters.userName);

    const menu = computed(() => {
      const list: MenuItem[] = [
        {
          title: 'Поиск',
          icon: 'mdi-magnify',
          active: searchStore.getters.isShown,
          action: () => {
            searchStore.dispatch('SHOW_SEARCH');
          },
        },
        {
          title: 'Руководство',
          icon: 'mdi-help-circle-outline',
          path: '/wiki',
        },
        {
          title: userName.value,
          icon: '$d1-user',
          gtmIsActive: false,
          children: [
            {
              title: 'Выход',
              icon: 'mdi-exit-to-app',
              path: '/logout',
            },
          ],
        },
        {
          title: 'Приложения',
          path: '/apps',
          icon: '$d1-apps',
        },
      ];

      return list;
    });

    return {
      user,
      userName,
      menu,
    };
  },
});
