/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import { isEqual } from 'lodash';
import Fuse from 'fuse.js';

import {
  RolesApi,
} from '@/api';

import { SetRolePayload, UpdateRolePayload } from '@/api/modules/roles.api';

import { RoleLight } from '@/models/Role';

export interface RolesCatalogFilters {
  query: string;
}

const initFilters = () => ({
  query: '',
});

class ModuleState {
  filters: RolesCatalogFilters = initFilters();

  isLoading = false;

  roles: RoleLight[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get filters() {
    return this.state.filters;
  }

  get filtersAreEnabled() {
    const defaultFilters = initFilters();

    return Object.entries(this.getters.filters).some(([key, val]) => !isEqual(val, defaultFilters[key]));
  }

  private get fuseByRoles() {
    return new Fuse(this.state.roles, {
      keys: [
        'name',
        'description',
      ],
    });
  }

  get roles() {
    const {
      filters,
      fuseByRoles,
    } = this.getters;

    const { roles } = this.state;

    if (!filters.query) return roles;

    const fuseResult = fuseByRoles.search(filters.query);

    return fuseResult.map(({ item }) => item);
  }

  get rolesHaveBeenFiltered() {
    return this.getters.filtersAreEnabled;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_FILTERS(payload: RolesCatalogFilters) {
    this.state.filters = payload;
  }

  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_ROLES(payload: RoleLight[]) {
    this.state.roles = payload;
  }

  REMOVE_ROLE(roleId: string) {
    this.state.roles = this.state.roles.filter((role) => role.id !== roleId);
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  UPDATE_FILTERS(payload: Partial<RolesCatalogFilters>) {
    const newPayload = {
      ...this.getters.filters,
      ...payload,
    };

    this.commit('SET_FILTERS', newPayload);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
  }

  async FETCH_ROLES() {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await RolesApi.fetchRoles();

      this.mutations.SET_ROLES(res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async CREATE_ROLE(payload: SetRolePayload) {
    await RolesApi.setRole(payload);

    await this.actions.FETCH_ROLES();
  }

  async UPDATE_ROLE(payload: UpdateRolePayload) {
    await RolesApi.updateRole(payload);

    await this.actions.FETCH_ROLES();
  }

  async DELETE_ROLE(roleId: string) {
    await RolesApi.deleteRole(roleId);

    this.mutations.REMOVE_ROLE(roleId);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useRolesCatalogStore = createComposable(module);

export default module;
