




































































































import { defineComponent, computed } from '@vue/composition-api';

import { HISTOGRAM_METRICS } from '../../../../store/dashboard';

// TODO: refactor
export default defineComponent({
  props: {
    metrics: { type: Object, required: true },
  },
  setup() {
    const valueMetrics = computed(() => [...HISTOGRAM_METRICS]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getTableCellValue = (value: any) => value || '-';

    const getFormattedMetric = (metric: number) => (metric * 100).toFixed(2);

    return {
      valueMetrics,
      getTableCellValue,
      getFormattedMetric,
    };
  },
});
