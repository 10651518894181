






import { defineComponent } from '@vue/composition-api';

import { DBreadcrumbs } from '@shared/druid-kit';

export default defineComponent({
  components: {
    DBreadcrumbs,
  },
  setup() {
    const breadcrumbs = [
      {
        label: 'Редактор категорий',
        path: '/administration/categories',
      },
      {
        label: 'Новая категория',
        path: '/administration/categories/new',
      },
    ];

    return {
      breadcrumbs,
    };
  },
});
