import Vue from 'vue';
import _ from 'lodash';

import PluginInit from '@/models/PluginInit';
import Acl from '@/models/Acl';

const pluginInit: PluginInit = ({ store }) => {
  const acl: Acl = {
    check(payload) {
      if (!payload) return true;

      const userPermissions = store.getters['auth/userPermissions'];

      const permissions = (payload.indexOf('|') !== -1) ? payload.split('|') : [payload];

      return _(permissions).find((permission) => {
        const needed = (permission.indexOf('&') !== -1) ? permission.split('&') : permission;

        if (Array.isArray(needed)) return needed.every((need) => userPermissions.indexOf(need) !== -1);

        return (userPermissions.indexOf(needed) !== -1);
      }) !== undefined;
    },
  };

  Vue.acl = acl;
  Vue.prototype.$acl = acl;
};

export default pluginInit;
