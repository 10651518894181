












import { defineComponent } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import FactUploadWorkSteps from '../components/FactUploadWorkSteps.vue';
import FactUploadAfter from '../components/FactUploadAfter.vue';

export default defineComponent({
  components: {
    AppView,
    FactUploadWorkSteps,
    FactUploadAfter,
  },
});
