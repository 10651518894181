


















import Vue from 'vue';
import { defineComponent, PropType } from '@vue/composition-api';

import { Campaign } from '@/models/Campaign';

import MetricsTable from '@/components/_newDesign/common/MetricsTable/MetricsTable.vue';
import BrandListItemTableRow from '@/components/_newDesign/Brands/BrandList/BrandListItemTable/rows/BrandListItemTableRow.vue';

export default defineComponent({
  components: {
    MetricsTable,
    BrandListItemTableRow,
  },
  props: {
    campaigns: { type: Array as PropType<Campaign[]>, required: true },
  },
  setup() {
    const metricCells = [
      {
        metric: {
          name: 'Cost',
        },
      },
      {
        metric: {
          name: 'Impressions',
        },
      },
      {
        metric: {
          name: 'Clicks',
        },
      },
      {
        metric: {
          name: 'CTR',
        },
        planInsteadProgress: true,
      },
    ];

    const headers = [
      {
        class: 'caption pl-10',
        text: 'Кампания',
        value: 'name',
      },
      ...(Vue.acl.check('approve_campaigns') ? [
        {
          sortable: false,
        },
      ] : []),
      {
        class: 'caption pl-0',
        text: 'Период',
        sortable: false,
      },
      ...metricCells.map((metricCell) => ({
        class: 'caption',
        text: metricCell.metric.name,
        value: `metricsTotal.${metricCell.metric.name}`,
        sortable: false,
      })),
    ];

    return {
      headers,
      metricCells,
    };
  },
});
