






import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api';

import { DBreadcrumbs } from '@shared/druid-kit';

import { Category } from '@/models/Category';

export default defineComponent({
  components: {
    DBreadcrumbs,
  },
  props: {
    category: { type: Object as PropType<Category>, default: () => ({}) },
  },
  setup(props) {
    const { category } = toRefs(props);

    const breadcrumbs = computed(() => [
      {
        label: 'Редактор категорий',
        path: '/administration/categories',
      },
      {
        label: category.value?.name || '',
        path: `/administration/categories/${category.value?.id || ''}`,
      },
    ]);

    return {
      breadcrumbs,
    };
  },
});
