import { render, staticRenderFns } from "./AppBottomNavigation.vue?vue&type=template&id=0c00ffa7&scoped=true&"
var script = {}
import style0 from "./AppBottomNavigation.vue?vue&type=style&index=0&id=0c00ffa7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c00ffa7",
  null
  
)

export default component.exports