






































































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    title: { type: String, default: '' },
  },
  setup(props, context) {
    const close = () => {
      context.emit('input', false);
    };

    const slotProps = computed(() => ({
      close,
    }));

    return {
      slotProps,
      close,
    };
  },
});
