/**
 * Plural forms for russian words
 * @param  {Integer} count quantity for word
 * @param  {Array}   words Array of words. Example: ['депутат', 'депутата', 'депутатов'], ['коментарий', 'коментария', 'комментариев']
 * @return {String}        Plural form for word
 */
const plural = (count: number, words: [string, string, string], options?: { withCount: boolean }): string => {
  count = Math.floor(Math.abs(count));

  const cases = [2, 0, 1, 1, 1, 2];

  const wordIdx = (count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)];

  const currentWord = `${words[wordIdx]}`;

  return options?.withCount ? `${count} ${currentWord}` : currentWord;
};

export default plural;
