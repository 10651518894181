









































































import { defineComponent, PropType, toRefs, ref, computed, watch, ComponentInstance } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';
import AppJsonError from '@/services/errorHandler/AppJsonError';

import { SetCampaignCategoriesPayload } from '@/api/modules/campaigns.api';

import useRouter from '@/packages/hooks/useRouter';

import { useCampaignStore } from '@/store/campaign';

import CampaignDetailsCategoriesEditForm from '@/components/_newDesign/Campaigns/CampaignDetails/forms/CampaignDetailsCategoriesEditForm.vue';

export default defineComponent({
  components: {
    AppModal,
    CampaignDetailsCategoriesEditForm,
  },
  props: {
    value: { type: Boolean, default: false },
    campaignId: { type: String as PropType<string | null>, default: null },
  },
  setup(props, context) {
    const {
      value,
      campaignId,
    } = toRefs(props);

    const router = useRouter(context);

    const campaignStore = useCampaignStore();

    const categoriesFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const categoriesFormData = ref<SetCampaignCategoriesPayload['data'] | null>(null);
    const categoriesFormIsValid = ref(false);

    const campaign = computed(() => campaignStore.getters.campaign);
    const client = computed(() => campaignStore.getters.client);
    const clientBrand = computed(() => campaignStore.getters.clientBrand);

    const categoriesFormResetValidation = async () => {
      if (!categoriesFormRef.value) return;

      categoriesFormRef.value.resetValidation();
    };

    const newCategories = computed(() => {
      if (!categoriesFormData.value || !categoriesFormIsValid.value) return null;

      return categoriesFormData.value;
    });

    const saving = ref(false);
    const savingIsDone = ref(false);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const canSaveCategories = computed(() => newCategories.value && newCategories.value.campaignCategoriesIds.length);
    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!campaignId.value || !newCategories.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await campaignStore.dispatch('SET_CAMPAIGN_CATEGORIES', {
          campaignId: campaignId.value,
          data: newCategories.value,
        });

        savingIsDone.value = true;

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const messages = Object.values(error.response?.data?.errors || {})
            .reduce<string[]>((acc, val) => {
              if (Array.isArray(val)) {
                acc.push(...val);
              } else if (typeof val === 'string') {
                acc.push(val);
              }

              return acc;
            }, []);

          saveError.value = {
            title: 'Произошла ошибка',
            messages: messages.length ? messages : ['Обратитесь к администратору'],
          };
        }

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        saving.value = false;
      }
    };

    const init = () => {
      categoriesFormData.value = {
        clientCategoriesIds: client.value?.categoriesIds || [],
        brandCategoriesIds: clientBrand.value?.categoriesIds || [],
        campaignCategoriesIds: campaign.value?.categoriesIds || [],
        campaignTypeId: campaign.value?.typeId || '',
      };
    };

    watch(value, (val) => {
      if (!val && !savingIsDone.value) {
        router.push({
          path: `/clients/${client.value?.id}`,
          query: { brandId: clientBrand.value?.id },
        });

        return;
      }

      categoriesFormData.value = null;

      categoriesFormResetValidation();
      init();
    });

    return {
      categoriesFormRef,
      categoriesFormData,
      categoriesFormIsValid,
      saving,
      canSaveCategories,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
