



















































import { defineComponent, ref, computed } from '@vue/composition-api';

import notify from '@/services/notify';

import useRouter from '@/packages/hooks/useRouter';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { useProviderTokensStore } from '../../store';

export default defineComponent({
  components: {
    AppModal,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const providerTokensStore = useProviderTokensStore();

    const router = useRouter(context);

    const name = ref('');
    const token = ref('');
    const isLoading = ref(false);

    const selectedTokenProvider = computed(() => providerTokensStore.getters.selectedTokenProvider);

    const save = async () => {
      if (!selectedTokenProvider.value) return;

      isLoading.value = true;

      try {
        await providerTokensStore.dispatch('CREATE_CUSTOM_TOKEN', {
          name: name.value,
          tokenJson: token.value,
          tokenProviderId: selectedTokenProvider.value.id,
        });

        notify.push({
          text: `Новый токен для ${selectedTokenProvider.value.name} добавлен`,
          position: ['bottom', 'center'],
          status: 'positive',
        });

        router.push('/provider-tokens');
      } finally {
        isLoading.value = false;
      }
    };

    return {
      name,
      token,
      isLoading,
      save,
    };
  },
});
