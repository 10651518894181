



























import { defineComponent, toRefs, ref, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import modally from '@/services/modally';

import { User } from '@/models/User';

import { useUsersCatalogStore } from '../../../../store/users/usersCatalog';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    user: { type: Object as PropType<User>, required: true },
  },
  setup(props, context) {
    const {
      user,
    } = toRefs(props);

    const usersCatalogStore = useUsersCatalogStore();

    const openItemModal = () => {
      context.emit('open-modal', {
        slug: 'item',
        props: {
          userId: user.value.id,
          title: user.value.name,
        },
      });
    };

    const deletion = ref(false);

    const deleteUser = async () => {
      const result = await modally.show({
        title: 'Удалить пользователя?',
        content: `Пользователь ${user.value.name} будет удален.`,
        buttons: [
          {
            props: {
              text: 'Удалить',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary',
            },
            action: () => false,
          },
        ],
      });

      if (!result) return;

      deletion.value = true;

      try {
        await usersCatalogStore.actions.DELETE_USER(user.value.id);
      } finally {
        deletion.value = false;
      }
    };

    return {
      openItemModal,
      deletion,
      deleteUser,
    };
  },
});
