
































import { defineComponent, PropType, computed } from '@vue/composition-api';

import { UnapprovedCampaign } from '@/models/UnapprovedPlacements';

import { useUnapprovedPlacementsStore } from '../../store';

import UnapprovedPlacementsCampaignListItem from './UnapprovedPlacementsCampaignListItem.vue';

export default defineComponent({
  components: {
    UnapprovedPlacementsCampaignListItem,
  },
  props: {
    campaigns: { type: Array as PropType<UnapprovedCampaign[]>, default: () => [] },
  },
  setup() {
    const unapprovedPlacementsStore = useUnapprovedPlacementsStore();

    const campaignsIsLoading = computed(() => unapprovedPlacementsStore.getters.isLoading);

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (isIntersecting) unapprovedPlacementsStore.dispatch('NEXT_PAGE');
    };

    return {
      campaignsIsLoading,
      onIntersect,
    };
  },
});
