import { AppRouteConfig } from '@/models/AppRouter';

import UnapprovedPlacements from '../views/UnapprovedPlacements.vue';

import UnapprovedPlacementsLoader from '../components/UnapprovedPlacementsLoader.vue';

const routes: AppRouteConfig[] = [
  {
    path: '/unapproved-placements',
    component: UnapprovedPlacements,
    meta: {
      group: 'main',
      title: 'Проверка размещений',
      icon: {
        name: '$d1-placements-check',
        size: 54,
        custom: true,
      },
      permission: 'view_unapproved_campaigns',
      loader: UnapprovedPlacementsLoader,
    },
  },
];

export default routes;
