
















import { defineComponent, computed } from '@vue/composition-api';

import useRoute from '@/packages/hooks/useRoute';

import AppSideBarMenuItem from '@/components/_newDesign/App/AppSideBar/AppSideBarMenuItem.vue';

import store from '@/store';
import navigationCurrentClientStore from '@/store/navigation/navigationCurrentClient';

const navigationCurrentClientContext = navigationCurrentClientStore.context(store);

export default defineComponent({
  components: {
    AppSideBarMenuItem,
  },
  setup(props, context) {
    const route = useRoute(context);

    const clientName = computed(() => navigationCurrentClientContext.getters.currentClientName);
    const clientPath = computed(() => navigationCurrentClientContext.getters.currentClientPath);

    const isShown = computed(() => {
      const approvedRoutes = [
        /\/campaigns\/.+/i,
        /\/clients\/.+/i,
      ];

      const routeIsApproved = approvedRoutes.some((regExp) => regExp.test(route.value.fullPath || ''));

      return routeIsApproved && clientName.value && clientPath.value;
    });

    const isActive = computed(() => route.value.name === 'client-details');

    const iconOptions = {
      name: '$d1-client',
      size: 54,
      custom: true,
    };

    return {
      clientName,
      clientPath,
      isShown,
      isActive,
      iconOptions,
    };
  },
});
