











import Vue, { VueConstructor } from 'vue';

import HnTableCellMixin from '../../mixins/HnTableCellMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'HnTableStubCell',
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    text: { type: [String, Number], default: '' },
  },
});
