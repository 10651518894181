/* eslint-disable max-classes-per-file */

import Vue from 'vue';
import { Route } from 'vue-router';
import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { cloneDeep } from 'lodash';

import navigationUi from '@/store/navigation/navigationUi';
import navigationCurrentClient from '@/store/navigation/navigationCurrentClient';
import navigationUnapprovedPlacements from '@/store/navigation/navigationUnapprovedPlacements';

import { appRouteConfigMetaGroupSlugs, appRouteConfigMetaGroupTitles, appRouteConfigMetaGroupShortTitles, AppRouteConfig } from '@/models/AppRouter';
import { AppMenu, AppMenuGroup, AppMenuRouteConfig } from '@/models/AppMenu';

import { PartialAtLeastOne } from '@/utils/type';

class ModuleState {
  menuGroupSlugs = appRouteConfigMetaGroupSlugs;

  menuGroupTitles = appRouteConfigMetaGroupTitles;

  menuGroupShortTitles = appRouteConfigMetaGroupShortTitles;

  routes: AppRouteConfig[] = [];

  prevRoute: Route | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  get routes() {
    return this.state.routes;
  }

  get prevRoute() {
    return this.state.prevRoute;
  }

  get getPrevRoute() {
    type FilterName = 'name' | 'path';

    type Filters = PartialAtLeastOne<Record<FilterName, string | RegExp | (string | RegExp)[]>>;

    type RegExpFilters = PartialAtLeastOne<Record<FilterName, RegExp[]>>;

    return (filters?: Filters) => {
      const route = this.getters.prevRoute;

      if (!route) return null;

      if (!filters) return route;

      const regExpFilters = Object.entries(filters).reduce((acc, [key, val]) => {
        acc[key] = Array.isArray(val) ? val.map((item) => new RegExp(item)) : new RegExp(val);

        return acc;
      }, {} as RegExpFilters);

      const routeIsApproved = Object.entries(regExpFilters).some(([key, val]) => val.some((regExp) => regExp.test(route[key] || '')));

      if (!routeIsApproved) return null;

      return route;
    };
  }

  get menuGroups() {
    return this.state.menuGroupSlugs.map((slug, idx) => {
      const group: AppMenuGroup = {
        meta: {
          slug,
          order: idx,
        },
        children: [],
      };

      const title = this.state.menuGroupTitles[idx];
      const shortTitle = this.state.menuGroupShortTitles[idx];

      if (title) {
        group.meta.title = title;
      }

      if (shortTitle) {
        group.meta.shortTitle = shortTitle;
      }

      return group;
    });
  }

  get menuRoutes() {
    const generateMenuRoutes = (routes: AppRouteConfig[], path?: string) => {
      const menuRoutes = routes.reduce<AppMenuRouteConfig[]>((list, route) => {
        const routeApproved = Vue.acl.check(route.meta?.permission);

        if (route.path === '' || !routeApproved || (!route.meta?.title && !route.meta?.icon)) return list;

        const menuRoutePath = path ? `${path}/${route.path}/` : route.path;

        const menuRouteChildren = route.children ? generateMenuRoutes(route.children, menuRoutePath) : [];

        const menuRoute = {
          ...route,
          path: menuRoutePath,
          meta: route.meta,
          children: menuRouteChildren,
        };

        list.push(menuRoute);

        return list;
      }, []);

      return menuRoutes.sort((routeA, routeB) => (routeA.meta.order || 0) - (routeB.meta.order || 0));
    };

    return generateMenuRoutes(this.getters.routes);
  }

  get menu() {
    const groups = this.getters.menuRoutes.reduce((menu: AppMenu, route: AppMenuRouteConfig) => {
      const foundGroup = menu.find((group) => group.meta.slug === route.meta.group);

      if (!foundGroup) return menu;

      foundGroup.children.push(route);

      return menu;
    }, [...this.getters.menuGroups]);

    return groups
      .filter((group) => group.children.length)
      .sort((groupA, groupB) => groupA.meta.order - groupB.meta.order);
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  UPDATE_ROUTES(payload: AppRouteConfig[]) {
    this.state.routes = [...this.state.routes, ...cloneDeep([...payload])];
  }

  SET_PREV_ROUTE(payload: Route) {
    this.state.prevRoute = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  ADD_ROUTES(payload: AppRouteConfig[]) {
    this.commit('UPDATE_ROUTES', payload);
  }
}

const module = new Module({
  modules: {
    ui: navigationUi,
    currentClient: navigationCurrentClient,
    unapprovedPlacements: navigationUnapprovedPlacements,
  },
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const navigationStoreMapper = createMapper(module);

export const useNavigationStore = createComposable(module);

export default module;
