






























import { defineComponent, toRefs, ref, computed, PropType } from '@vue/composition-api';

import moment from 'moment';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import numatter from '@/packages/numatter';

import modally from '@/services/modally';
import notify from '@/services/notify';

import { Markup } from '@/models/Markup';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { useCampaignEditorPlacementDetailsStore } from '@/store/campaignEditor/campaignEditorPlacementDetails';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    period: { type: Object as PropType<Markup['markupsWithCost'][number]>, required: true },
    index: { type: Number, required: true },
    markup: { type: Object as PropType<Markup | null>, default: null },
  },
  setup(props) {
    const {
      period,
      index,
      markup,
    } = toRefs(props);

    const campaignEditorPlacementDetailsStore = useCampaignEditorPlacementDetailsStore();

    const markupsWithCost = computed(() => markup.value?.markupsWithCost || []);

    const isOnlyOne = computed(() => markupsWithCost.value.length <= 1);

    const startDate = computed(() => moment.utc(period.value.startDate).format('DD.MM.YYYY'));
    const multiplier = computed(() => numatter.format(period.value.value, '0,0[.]0000'));
    const rawCost = computed(() => numatter.format(period.value.rawCost, '0,0[.]00'));
    const cost = computed(() => numatter.format(period.value.cost, '0,0[.]00'));

    const deletion = ref(false);

    const deletePeriod = async () => {
      const result = await modally.show({
        title: isOnlyOne.value
          ? 'Удалить последний период?'
          : `Удалить период от ${startDate.value}?`,
        content: isOnlyOne.value
          ? 'Наценка будет удалена.\nКоэффицент 1 будет использоваться по умолчанию\nна весь период размещения.'
          : 'Будет применен предыдущий коэффицент.\nЧтобы восстановить период, создайте его заново.',
        buttons: [
          {
            props: {
              text: 'Удалить',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary',
            },
            action: () => false,
          },
        ],
      });

      if (!markup.value || !result) return;

      deletion.value = true;

      try {
        if (isOnlyOne.value) {
          await campaignEditorPlacementDetailsStore.dispatch('DELETE_MARKUP', {
            placementId: markup.value.placementId,
            markupId: markup.value.id,
          });

          notify.push({
            text: `Наценка для ${markup.value.dataProviderName} удалена`,
            position: ['bottom', 'center'],
            status: 'positive',
          });
        } else {
          await campaignEditorPlacementDetailsStore.dispatch('DELETE_MARKUP_PERIOD', {
            markupId: markup.value.id,
            index: index.value,
          });

          notify.push({
            text: `Период наценки для ${markup.value.dataProviderName} удален`,
            position: ['bottom', 'center'],
            status: 'positive',
          });
        }
      } catch (e) {
        if (!isError(e) || !isAxiosError(e)) return;

        const message = e.response?.data?.message;

        if (message) {
          modally.show({ status: 'error', title: 'Ошибка при удалении периода', content: message });
        } else {
          throw e;
        }
      } finally {
        deletion.value = false;
      }
    };

    return {
      startDate,
      multiplier,
      rawCost,
      cost,
      deletion,
      deletePeriod,
    };
  },
});
