/* eslint-disable max-classes-per-file */

import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

class ModuleState {
  isLoading = false;

  redesignWorkflowNoticeIsShown = true;
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get redesignNoticeIsShown() {
    return this.state.redesignWorkflowNoticeIsShown;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(payload: boolean) {
    this.state.isLoading = payload;
  }

  SET_REDESIGN_NOTICE_SHOWN_STATUS(value: boolean) {
    this.state.redesignWorkflowNoticeIsShown = value;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  UPDATE_IS_LOADING(payload: boolean) {
    this.commit('SET_IS_LOADING', payload);
  }

  CHANGE_REDESIGN_NOTICE_SHOWN_STATUS(value: boolean) {
    this.commit('SET_REDESIGN_NOTICE_SHOWN_STATUS', value);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const rootUiStoreMapper = createMapper(module);

export default module;
