import { ref, watch, nextTick, Ref } from '@vue/composition-api';

interface useElHeightReturn {
  el: Ref<HTMLElement | null>;
  elHeight: Ref<number | 'auto'>;
}

export default (): useElHeightReturn => {
  const el = ref<HTMLElement | null>(null);
  const elHeight = ref<number | 'auto'>(0);

  watch(el, async (val) => {
    await nextTick();

    elHeight.value = val?.clientHeight || 'auto';
  });

  return {
    el,
    elHeight,
  };
};
