

















import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import { debounce } from 'lodash';

import useRoute from '@/packages/hooks/useRoute';

import { DBreadcrumbs } from '@shared/druid-kit';

import { Campaign } from '@/models/Campaign';

import { useCampaignEditorPlacementsStore } from '@/store/campaignEditor/campaignEditorPlacements';

export default defineComponent({
  components: {
    DBreadcrumbs,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props, context) {
    const route = useRoute(context);

    const { campaign } = toRefs(props);

    const campaignEditorPlacementsStore = useCampaignEditorPlacementsStore();

    const breadcrumbs = computed(() => [
      {
        label: campaign.value.brandName,
        path: `/clients/${campaign.value.clientId}/?brandId=${campaign.value.brandId}`,
      },
      {
        label: campaign.value.name,
        path: `/campaigns/${campaign.value.id}`,
      },
      {
        label: 'Настройки кампании',
        path: { name: 'campaign-editor' },
      },
    ]);

    const query = computed({
      get() {
        return campaignEditorPlacementsStore.getters.filters.filter;
      },
      set: debounce((val: string) => {
        campaignEditorPlacementsStore.actions.UPDATE_FILTERS({ filter: val });
      }, 300),
    });

    const searchIsShown = computed(() => route.value.name === 'campaign-editor-placements');

    return {
      breadcrumbs,
      query,
      searchIsShown,
    };
  },
});
