










































import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTooltip,
} from '@shared/druid-kit';

import { UnapprovedPlacement } from '@/models/UnapprovedPlacements';

export default defineComponent({
  components: {
    DTableCell,
    DTooltip,
  },
  props: {
    placement: { type: Object as PropType<UnapprovedPlacement>, required: true },
  },
  setup(props) {
    const { placement } = toRefs(props);

    const placementStatus = computed(() => {
      const textMap = {
        running: 'Идет',
        paused: 'На паузе',
        finished: 'Завершено',
      };

      const iconsMap = {
        running: '$d1-play',
        paused: 'mdi-timer-sand',
        finished: 'mdi-check',
      };

      const text = textMap[placement.value.state.code];
      const icon = iconsMap[placement.value.state.code];

      return {
        text,
        icon,
      };
    });

    const placementInfoIsShown = computed(() => !!placement.value.section || !!placement.value.adPlace);
    const placementInfo = computed(() => `${placement.value.section} ${placement.value.adPlace ? '/' : ''} ${placement.value?.adPlace || ''}`);

    return {
      placementStatus,
      placementInfoIsShown,
      placementInfo,
    };
  },
});
