































import Vue from 'vue';
import { defineComponent, PropType, computed, toRefs } from '@vue/composition-api';

import {
  DTableCell,
  DTooltip,
} from '@shared/druid-kit';

import { Placement } from '@/models/Placement';

import { useCampaignStore } from '@/store/campaign';

export default defineComponent({
  components: {
    DTableCell,
    DTooltip,
  },
  props: {
    placement: { type: Object as PropType<Placement>, required: true },
  },
  setup(props) {
    const {
      placement,
    } = toRefs(props);

    const campaignStore = useCampaignStore();

    const campaign = computed(() => campaignStore.getters.campaign);

    const onClick = () => {
      if (!campaign.value || placement.value.hasMarkup) return;

      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Campaign',
        event_action: 'Click to Add Markup',
        event_label: placement.value.danboId,
        d1d_client_id: campaign.value.clientId,
        d1d_client_name: campaign.value.clientName,
        d1d_brand_id: campaign.value.brandId,
        d1d_brand_name: campaign.value.brandName,
        d1d_campaign_id: campaign.value.id,
        d1d_campaign_name: campaign.value.name,
        d1d_danbo_id: placement.value.danboId,
      });
    };

    return {
      onClick,
    };
  },
});
