/* eslint-disable max-classes-per-file */

import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import metricTypeExtensions from '@/data/metricTypes/metricTypeExtensions.json';

import { MetricType, MetricTypeExtension } from '@/models/MetricType';

import { ReferenceApi } from '@/api';

class ModuleState {
  metricTypes: MetricType[] = [];

  metricTypeExtensions: MetricTypeExtension[] = metricTypeExtensions;
}

class ModuleGetters extends Getters<ModuleState> {
  get metricTypes() {
    return this.state.metricTypes;
  }

  get metricTypeExtensions() {
    return this.state.metricTypeExtensions;
  }

  get getMetricType() {
    return (name: string) => {
      const slug = name.toLowerCase();

      const foundMetricType = this.getters.metricTypes.find((metricType) => {
        const metricTypeSlug = metricType.name.toLowerCase();

        return metricTypeSlug === slug;
      });

      return foundMetricType;
    };
  }

  get getMetricTypeExtension() {
    return (name: string) => {
      const slug = name.toLowerCase();

      const foundMetricTypeExtension = this.getters.metricTypeExtensions.find((metricType) => {
        const metricTypeSlug = metricType.name.toLowerCase();

        return slug.includes(metricTypeSlug);
      });

      return foundMetricTypeExtension;
    };
  }

  get getMetricTypeProp() {
    return <T extends keyof MetricType>(name: string, prop: T) => {
      const foundMetricType = this.getters.getMetricType(name);

      return foundMetricType?.[prop];
    };
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_METRIC_TYPES(payload: MetricType[]) {
    this.state.metricTypes = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_METRIC_TYPES() {
    const res = await ReferenceApi.fetchMetricTypes();

    this.commit('SET_METRIC_TYPES', res.data);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useMetricTypesStore = createComposable(module);

export default module;
