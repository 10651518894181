


















































import { defineComponent, computed, ref, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';

import { useProviderTokensStore } from '../store';

import ProviderTokensListHeader from '../components/ProviderTokensList/ProviderTokensListHeader.vue';

import TokensListTable from '../components/ProviderTokensList/ProviderTokensListTable/ProviderTokensListTable.vue';

export default defineComponent({
  components: {
    AppView,
    ProviderTokensListHeader,
    TokensListTable,
  },
  setup() {
    const providerTokensStore = useProviderTokensStore();

    const { updateIsLoading } = useLayoutLoadingStatus();

    const isLoading = ref(false);

    const tokens = computed(() => providerTokensStore.getters.tokens);
    const tokensFilters = computed(() => providerTokensStore.getters.filters);
    const tokensFiltersAreEnabled = computed(() => providerTokensStore.getters.filtersAreEnabled);

    const init = async () => {
      updateIsLoading(true);
      providerTokensStore.dispatch('RESET_FILTERS');

      try {
        await providerTokensStore.dispatch('FETCH_TOKENS_LIST');
      } finally {
        updateIsLoading(false);
      }
    };

    const loadNextPage = () => {
      providerTokensStore.dispatch('NEXT_PAGE');
    };

    const fetch = async () => {
      isLoading.value = true;

      try {
        await providerTokensStore.dispatch('FETCH_TOKENS_LIST');
      } finally {
        isLoading.value = false;
      }
    };

    watch(tokensFilters, () => {
      if (!tokensFiltersAreEnabled.value) return;

      fetch();
    });

    init();

    return {
      isLoading,
      loadNextPage,
      tokens,
      tokensFiltersAreEnabled,
    };
  },
});
