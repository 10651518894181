import Vue from 'vue';
import VueMeta from 'vue-meta';

import PluginInit from '@/models/PluginInit';

const pluginInit: PluginInit = () => {
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
  });
};

export default pluginInit;
