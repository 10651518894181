import { AppRouteConfig } from '@/models/AppRouter';

import DataProviders from '../views/DataProviders.vue';
import DataProvidersCatalog from '../views/DataProviders/DataProvidersCatalog.vue';
import PageCatalogLoader from '../../../components/_newDesign/common/loaders/PageCatalogLoader.vue';

const routeConfig: AppRouteConfig = {
  path: 'data-providers',
  component: DataProviders,
  meta: {
    title: 'Провайдеры данных',
    permission: 'admin',
    order: 0,
  },
  children: [
    {
      name: 'data-providers-catalog',
      path: '',
      component: DataProvidersCatalog,
      meta: {
        title: 'Провайдеры данных',
        loader: PageCatalogLoader,
        header: {
          sticky: true,
        },
      },
    },
  ],
};

export default routeConfig;
