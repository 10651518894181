
















import Vue, { VueConstructor } from 'vue';
import { Ref } from '@vue/composition-api';

import { HnTableChangedCells } from '../types';

interface HnTableCellWrapperInject {
  hotTableChangedCells: Ref<HnTableChangedCells>;
}

export interface HnTableCellWrapperProps {
  prop?: string;
  isRenderer?: boolean;
  isEditor?: boolean;
  isVisible?: boolean;
  //
  style?: Partial<CSSStyleDeclaration>;
  textAlign?: string;
  readonly?: boolean;
  //
  cellRow: number;
  invalid?: boolean;
  //
  isFirstCol?: boolean;
  isLastCol?: boolean;
}

export default (Vue as VueConstructor<Vue & HnTableCellWrapperInject>).extend({
  name: 'HnTableCellWrapper',
  inject: [
    'hotTableChangedCells',
  ],
  props: {
    prop: { type: String, default: '' },
    isRenderer: { type: Boolean, default: false },
    isEditor: { type: Boolean, default: false },
    isVisible: { type: Boolean, default: false },
    //
    textAlign: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    //
    cellRow: { type: Number, default: -1 },
    invalid: { type: Boolean, default: false },
    //
    isFirstCol: { type: Boolean, default: false },
    isLastCol: { type: Boolean, default: false },
  },
  computed: {
    cellType() {
      if (this.isRenderer) return 'renderer';

      if (this.isEditor) return 'editor';

      return '';
    },
    cellHasBeenEdited(): boolean {
      return this.hotTableChangedCells.value.some((cell) => cell.row === this.cellRow && cell.prop === this.prop);
    },
    rootElClasses(): string[] {
      const base = 'hn-table-cell-wrapper';

      const classes = [
        base,
        `${base}${this.cellType ? `-${this.cellType}` : ''}`,
        this.readonly ? `${base}--readonly` : '',
        this.cellHasBeenEdited ? `${base}--edited` : '',
        this.invalid ? `${base}--invalid` : '',
      ];

      if (this.cellType === 'renderer') {
        classes.push(...[
          'body-2',
          this.isFirstCol ? `${base}-renderer--first-col` : '',
          this.isLastCol ? `${base}-renderer--last-col` : '',
        ]);
      }

      if (this.textAlign) {
        classes.push(`${base}--text-${this.textAlign}`);
      }

      return classes;
    },
  },
  methods: {
    stopPropagation(e: Event) {
      e.stopPropagation();
    },
  },
});
