



























































import { defineComponent, computed } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';
import useModals from '@/composables/useModals';

import PermissionsCatalogHeader from '../../components/Permissions/PermissionsCatalog/PermissionsCatalogHeader.vue';
import PermissionsTable from '../../components/Permissions/PermissionsCatalog/PermissionsTable/PermissionsTable.vue';

import PermissionsAddModal from '../../components/Permissions/modals/PermissionsAddModal.vue';
import PermissionsItemModal from '../../components/Permissions/modals/PermissionsItemModal.vue';

import { usePermissionsCatalogStore } from '../../store/permissions/permissionsCatalog';

export default defineComponent({
  components: {
    AppView,
    PermissionsCatalogHeader,
    PermissionsTable,
    PermissionsAddModal,
    PermissionsItemModal,
  },
  setup() {
    const { updateIsLoading } = useLayoutLoadingStatus();

    const {
      modalsMap,
      openModal,
    } = useModals(['add', 'item']);

    const permissionsCatalogStore = usePermissionsCatalogStore();

    const permissions = computed(() => permissionsCatalogStore.getters.permissions);
    const permissionsIsLoading = computed(() => permissionsCatalogStore.getters.isLoading);
    const permissionsHaveBeenFiltered = computed(() => permissionsCatalogStore.getters.permissionsHaveBeenFiltered);

    const fetch = async () => {
      await permissionsCatalogStore.actions.FETCH_PERMISSIONS();
    };

    const init = async () => {
      updateIsLoading(true);
      permissionsCatalogStore.actions.RESET_FILTERS();

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    init();

    return {
      modalsMap,
      permissions,
      permissionsIsLoading,
      permissionsHaveBeenFiltered,
      openModal,
    };
  },
});
