
























































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import {
  DAutocomplete,
  DSelectList,
  DTreeView,
} from '@shared/druid-kit';

import { Role } from '@/models/Role';

import AppUsersAutocomplete from '@/components/_newDesign/common/inputs/AppUsersAutocomplete.vue';

import { usePermissionsCatalogStore } from '../../../store/permissions/permissionsCatalog';
import { useRoleDetailsStore } from '../../../store/roles/roleDetails';

export default defineComponent({
  components: {
    DAutocomplete,
    DSelectList,
    DTreeView,
    AppUsersAutocomplete,
  },
  props: {
    value: { type: Object as PropType<Role | null>, default: null },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
      loading,
    } = toRefs(props);

    const permissionsCatalogStore = usePermissionsCatalogStore();
    const roleDetailsStore = useRoleDetailsStore();

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);

    const isValid = ref(false);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updateRole = (payload: Partial<Role>) => {
      const newRole = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newRole);
    };

    // // // // //

    const nameRules = ref([
      (val: string) => !!val,
    ]);

    const nameIsLoading = computed(() => loading.value);
    const nameIsDisabled = computed(() => nameIsLoading.value);

    const currentName = computed({
      get() {
        return value.value?.name || '';
      },
      set(val: string) {
        updateRole({ name: val });
      },
    });

    // // // // //

    const descrIsLoading = computed(() => loading.value);
    const descrIsDisabled = computed(() => descrIsLoading.value);

    const currentDescr = computed({
      get() {
        return value.value?.description || '';
      },
      set(val: string) {
        updateRole({ description: val });
      },
    });

    // // // // //

    const permissions = computed(() => permissionsCatalogStore.getters.permissions);
    const permissionsIsLoadingData = ref(false);

    const permissionsIsLoading = computed({
      get() {
        return permissionsIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        permissionsIsLoadingData.value = val;
      },
    });

    const permissionsIsDisabled = computed(() => !permissions.value.length || permissionsIsLoading.value);

    const currentPermissions = computed({
      get() {
        return value.value?.permissions || [];
      },
      set(val: string[]) {
        updateRole({ permissions: val });
      },
    });

    const fetchPermissions = async () => {
      permissionsIsLoading.value = true;

      try {
        await permissionsCatalogStore.actions.FETCH_PERMISSIONS();
      } finally {
        permissionsIsLoading.value = false;
      }
    };

    // // // // //

    const serviceTypes = computed(() => roleDetailsStore.getters.serviceTypes);
    const serviceTypesIsLoadingData = ref(false);

    const serviceTypesIsLoading = computed({
      get() {
        return serviceTypesIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        serviceTypesIsLoadingData.value = val;
      },
    });

    const serviceTypesIsDisabled = computed(() => !serviceTypes.value.length || serviceTypesIsLoading.value);

    const currentServiceTypes = computed({
      get() {
        return value.value?.serviceTypes || [];
      },
      set(val: string[]) {
        updateRole({ serviceTypes: val });
      },
    });

    const fetchServiceTypes = async () => {
      serviceTypesIsLoading.value = true;

      try {
        await roleDetailsStore.actions.FETCH_SERVICE_TYPES();
      } finally {
        serviceTypesIsLoading.value = false;
      }
    };

    // // // // //

    const serviceOrganizations = computed(() => roleDetailsStore.getters.serviceOrganizations);
    const serviceOrganizationsIsLoadingData = ref(false);

    const serviceOrganizationsIsLoading = computed({
      get() {
        return serviceOrganizationsIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        serviceOrganizationsIsLoadingData.value = val;
      },
    });

    const serviceOrganizationsIsDisabled = computed(() => !serviceOrganizations.value.length || serviceOrganizationsIsLoading.value);

    const currentServiceOrganizations = computed({
      get() {
        return value.value?.serviceOrganizations || [];
      },
      set(val: string[]) {
        updateRole({ serviceOrganizations: val });
      },
    });

    const fetchServiceOrganizations = async () => {
      serviceOrganizationsIsLoading.value = true;

      try {
        await roleDetailsStore.actions.FETCH_SERVICE_ORGANIZATIONS();
      } finally {
        serviceOrganizationsIsLoading.value = false;
      }
    };

    // // // // //
    const currentUsers = computed({
      get() {
        return value.value?.users || [];
      },
      set(val: string[]) {
        updateRole({ users: val });
      },
    });

    // // // // //

    const init = async () => {
      await Promise.all([
        fetchPermissions(),
        fetchServiceTypes(),
        fetchServiceOrganizations(),
      ]);
    };

    init();

    return {
      rootRef,
      isValid,
      resetValidation,
      //
      currentName,
      nameRules,
      nameIsLoading,
      nameIsDisabled,
      //
      currentDescr,
      descrIsLoading,
      descrIsDisabled,
      //
      permissions,
      currentPermissions,
      permissionsIsLoading,
      permissionsIsDisabled,
      //
      serviceTypes,
      currentServiceTypes,
      serviceTypesIsLoading,
      serviceTypesIsDisabled,
      //
      serviceOrganizations,
      currentServiceOrganizations,
      serviceOrganizationsIsLoading,
      serviceOrganizationsIsDisabled,
      //
      currentUsers,
    };
  },
});
