













































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { DSelectList } from '@shared/druid-kit';

import { Category } from '@/models/Category';

import CategoryRulesTable from '../CategoryRulesTable/CategoryRulesTable.vue';

import { useCategoryDetailsStore } from '../../../store/categories/categoryDetails';

export default defineComponent({
  components: {
    DSelectList,
    CategoryRulesTable,
  },
  props: {
    value: { type: Object as PropType<Category | null>, default: null },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabledFields: { type: Array as PropType<string[]>, default: () => [] },
  },
  setup(props, context) {
    const {
      value,
      loading,
      disabledFields,
    } = toRefs(props);

    const categoryDetailsStore = useCategoryDetailsStore();

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);

    const isValid = ref(false);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updateCategory = (payload: Partial<Category>) => {
      const newCategory = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newCategory);
    };

    // // // // //

    const nameRules = ref([
      (val: string) => !!val,
    ]);

    const nameIsLoading = computed(() => loading.value);
    const nameIsDisabled = computed(() => nameIsLoading.value || disabledFields.value.includes('name'));

    const currentName = computed({
      get() {
        return value.value?.name || '';
      },
      set(val: string) {
        updateCategory({ name: val });
      },
    });

    // // // // //

    const orderIsLoading = computed(() => loading.value);
    const orederIsDisabled = computed(() => orderIsLoading.value || disabledFields.value.includes('order'));

    const currentOrder = computed({
      get() {
        return value.value?.order || 0;
      },
      set(val: number) {
        updateCategory({ order: val });
      },
    });

    // // // // //

    const currentRequiresTargetings = computed({
      get() {
        return value.value?.requiresTargetings || false;
      },
      set(val: boolean) {
        updateCategory({ requiresTargetings: val });
      },
    });

    // // // // //

    const metricsItems = computed(() => categoryDetailsStore
      .getters.metrics.map((metricName) => ({ id: metricName, name: metricName })));

    const metricsIsLoadingData = ref(false);

    const metricsIsLoading = computed({
      get() {
        return metricsIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        metricsIsLoadingData.value = val;
      },
    });

    const metricsIsDisabled = computed(() => !metricsItems.value.length || metricsIsLoading.value);

    const currentMetrics = computed({
      get() {
        return value.value?.displayableMetrics || [];
      },
      set(val: string[]) {
        updateCategory({ displayableMetrics: val });
      },
    });

    const fetchMetrics = async () => {
      metricsIsLoading.value = true;

      try {
        await categoryDetailsStore.actions.FETCH_METRICS();
      } finally {
        metricsIsLoading.value = false;
      }
    };

    // // // // //

    const currentRules = computed({
      get() {
        return value.value?.rules || [];
      },
      set(val: Category['rules']) {
        updateCategory({ rules: val });
      },
    });

    // // // // //

    const currentNotRules = computed({
      get() {
        return value.value?.notRules || [];
      },
      set(val: Category['notRules']) {
        updateCategory({ notRules: val });
      },
    });

    // // // // //

    const serviceTypesIsLoadingData = ref(false);

    const serviceTypesIsLoading = computed({
      get() {
        return serviceTypesIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        serviceTypesIsLoadingData.value = val;
      },
    });

    const fetchServiceTypes = async () => {
      serviceTypesIsLoading.value = true;

      try {
        await categoryDetailsStore.actions.FETCH_SERVICE_TYPES();
      } finally {
        serviceTypesIsLoading.value = false;
      }
    };

    const init = async () => {
      await Promise.all([
        fetchMetrics(),
        fetchServiceTypes(),
      ]);
    };

    init();

    return {
      rootRef,
      isValid,
      resetValidation,
      //
      currentName,
      nameRules,
      nameIsLoading,
      nameIsDisabled,
      //
      currentOrder,
      orderIsLoading,
      orederIsDisabled,
      //
      currentRequiresTargetings,
      //
      currentMetrics,
      metricsItems,
      metricsIsLoading,
      metricsIsDisabled,
      //
      currentRules,
      //
      currentNotRules,
      //
      serviceTypesIsLoading,
      //
    };
  },
});
