/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import { PartialWithout } from '@/utils/type';

import { DataProvidersSettingsApi } from '@/api';

import { DataProvidersSettings, DataProvidersSettingsItem } from '@/models/DataProvidersSettings';

import modally from '@/services/modally';

import isError from '@/utils/isError';
import AppJsonError from '@/services/errorHandler/AppJsonError';

export interface DataProvidersSettingsCatalogFilters {
  query: string;
  page: number;
  failedOnly: boolean;
}

const initFilters = () => ({
  query: '',
  page: 1,
  failedOnly: false,
});

class ModuleState {
  providersSettings: DataProvidersSettings[] = [];

  filters: DataProvidersSettingsCatalogFilters = initFilters();

  filtersChanged = false;

  isLoading = false;
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get providersSettings() {
    return this.state.providersSettings;
  }

  get filters() {
    return this.state.filters;
  }

  get filtersChanged() {
    return this.state.filtersChanged;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_PROVIDERS_SETTINGS(payload: DataProvidersSettings[]) {
    this.state.providersSettings = payload;
  }

  ADD_PROVIDERS_SETTINGS(payload: DataProvidersSettings[]) {
    this.state.providersSettings = [...this.state.providersSettings, ...payload];
  }

  REMOVE_PROVIDER_SETTINGS(groupId: string) {
    this.state.providersSettings = this.state.providersSettings.filter((group) => group.id !== groupId);
  }

  SET_FILTERS(payload: DataProvidersSettingsCatalogFilters) {
    this.state.filters = payload;
  }

  SET_FILTERS_CHANGED(payload: boolean) {
    this.state.filtersChanged = payload;
  }

  CHANGE_PROVIDERS_SETTINGS(payload: DataProvidersSettings) {
    const providerSettingsIdx = this.state.providersSettings.findIndex((providerSettings) => providerSettings.id === payload.id);

    if (providerSettingsIdx === -1) return;

    this.state.providersSettings.splice(providerSettingsIdx, 1, payload);
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_PROVIDERS_SETTINGS() {
    const { page, failedOnly, query } = this.getters.filters;

    this.commit('SET_IS_LOADING', true);

    try {
      const res = await DataProvidersSettingsApi.fetchDataProvidersSettings({
        params: { page, failedOnly, filter: query },
      });

      if (page === 1) {
        this.commit('SET_PROVIDERS_SETTINGS', res.data.list);
      } else {
        this.commit('ADD_PROVIDERS_SETTINGS', res.data.list);
      }
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async DELETE_PROVIDER_SETTINGS(providerSettingsId: string) {
    await DataProvidersSettingsApi.deleteProviderSettings(providerSettingsId);

    this.commit('REMOVE_PROVIDER_SETTINGS', providerSettingsId);
  }

  async CREATE_PROVIDER_SETTINGS(payload: PartialWithout<DataProvidersSettingsItem, 'id'>) {
    await DataProvidersSettingsApi.setProviderSettings(payload);

    await this.dispatch('FETCH_PROVIDERS_SETTINGS');
  }

  async UPDATE_PROVIDER_SETTINGS(payload: PartialWithout<DataProvidersSettingsItem, 'id'>) {
    await DataProvidersSettingsApi.setProviderSettings(payload);

    await this.dispatch('FETCH_PROVIDERS_SETTINGS');
  }

  UPDATE_FILTERS(filters: Partial<DataProvidersSettingsCatalogFilters>) {
    const page = filters.page || 1;

    const newFilters = {
      ...this.getters.filters,
      ...filters,
      page,
    };

    this.commit('SET_FILTERS', newFilters);
    this.commit('SET_FILTERS_CHANGED', true);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
    this.commit('SET_FILTERS_CHANGED', false);
  }

  NEXT_PAGE() {
    this.dispatch('UPDATE_FILTERS', {
      page: this.getters.filters.page + 1,
    });
  }

  async FETCH_GATHER_STATISTICS(providerSettingsId: string) {
    const res = await DataProvidersSettingsApi.fetchProviderStatus(providerSettingsId);

    if (!res.data) {
      modally.show({
        title: 'Операция недоступна!',
        content: 'Сбор данных по этим настройкам еще не закончен.',
        status: 'warning',
      });

      return;
    }

    await DataProvidersSettingsApi.gatherStatistics({ settingsId: providerSettingsId });
  }

  async START_GATHER_STATISTICS(providerSettings: DataProvidersSettings) {
    this.commit('CHANGE_PROVIDERS_SETTINGS', {
      ...providerSettings,
      available: false,
      finishedWithError: false,
      inProgress: true,
    });

    try {
      this.dispatch('FETCH_GATHER_STATISTICS', providerSettings.id);
    } catch (e) {
      this.commit('CHANGE_PROVIDERS_SETTINGS', {
        ...providerSettings,
        available: false,
        inProgress: false,
        finishedWithError: true,
      });

      if (!isError(e)) return;

      modally.show({
        title: e.message || '',
        content: 'Что-то пошло не так! Невозможно запустить сбор данных!',
      });

      throw new AppJsonError({ error: e, options: { showingModal: false } });
    }
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useDataProvidersSettingsCatalogStore = createComposable(module);

export default module;
