








































































import { defineComponent, ref, computed } from '@vue/composition-api';

import { DSelect } from '@shared/druid-kit';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { Category } from '@/models/Category';

import { useCategoryDetailsStore } from '../../../store/categories/categoryDetails';

export default defineComponent({
  components: {
    DSelect,
    AppModal,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const categoryDetailsStore = useCategoryDetailsStore();

    const ruleForm = ref<Category['rules'][number] | null>(null);

    const normalizedServiceTypes = computed(() => categoryDetailsStore.getters.normalizedServiceTypes);

    const initForm = () => ({
      level1: '',
      level2: '',
      device: '',
      serviceTypeId: '',
    });

    ruleForm.value = initForm();

    const close = () => {
      context.emit('input', false);
    };

    const save = () => {
      context.emit('add-rule', ruleForm.value);

      ruleForm.value = initForm();

      close();
    };

    return {
      save,
      ruleForm,
      normalizedServiceTypes,
    };
  },
});
