
















import { defineComponent, PropType, toRefs } from '@vue/composition-api';

import BrandList from '@/components/_newDesign/Brands/BrandList/BrandList.vue';

import { useClientBrands } from '@/composables/client';

import { Client } from '@/models/Client';

export default defineComponent({
  components: {
    BrandList,
  },
  props: {
    client: { type: Object as PropType<Client>, required: true },
  },
  setup(props) {
    const { client } = toRefs(props);

    const {
      activeBrands,
      archiveBrands,
      archiveBrandsCount,
    } = useClientBrands({ client });

    return {
      activeBrands,
      archiveBrands,
      archiveBrandsCount,
    };
  },
});
