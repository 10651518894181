




import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import { DBreadcrumbs } from '@shared/druid-kit';

import { Campaign, CampaignCategory } from '@/models/Campaign';

export default defineComponent({
  components: {
    DBreadcrumbs,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    category: { type: Object as PropType<CampaignCategory>, required: true },
  },
  setup(props) {
    const { campaign, category } = toRefs(props);

    const categoryName = computed(() => category.value?.name || 'Категория');

    const breadcrumbs = computed(() => [
      {
        label: campaign.value.brandName,
        path: `/clients/${campaign.value.clientId}/?brandId=${campaign.value.brandId}`,
      },
      {
        label: campaign.value.name,
        path: `/campaigns/${campaign.value.id}`,
      },
      {
        label: categoryName.value,
        path: `/campaigns/${campaign.value.id}/category/${category.value.id}`,
      },
    ]);

    return { breadcrumbs };
  },
});
