import { AxiosResponse } from 'axios';

import { MetricsCheckTotal, MetricsCheckDataProvider } from '@/models/MetricsCheck';

import httpClient from '../httpClient';

interface FetchTotalResponse {
  succeseded: boolean;
  total?: MetricsCheckTotal;
  totalByDataProvider: MetricsCheckDataProvider[];
  rawTotalByDataProvider: MetricsCheckDataProvider[];
  errors: string[];
}

export default class MetricsCheckApi {
  public static async fetchTotal(danboId: string): Promise<AxiosResponse<FetchTotalResponse>> {
    const res = await httpClient.get<FetchTotalResponse>(`/api/metrics-check/${danboId}/get-total`);

    return res;
  }

  // TODO: Описать типы
  public static async fetchTotalFromConnectors(danboId: string): Promise<AxiosResponse<unknown>> {
    const res = await httpClient.get<unknown>(`/api/metrics-check/${danboId}/get-total-from-connectors`);

    return res;
  }
}
