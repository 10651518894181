

























import { defineComponent, ref, computed, ComponentInstance } from '@vue/composition-api';

import { Category } from '@/models/Category';

import { PartialWithout } from '@/utils/type';

import useRouter from '@/packages/hooks/useRouter';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useCategoriesCatalogStore } from '../../store/categories/categoriesCatalog';

import CategoryNewHeader from '../../components/Categories/CategoryNew/CategoryNewHeader.vue';
import CategoriesItemForm from '../../components/Categories/forms/CategoriesItemForm.vue';
import CategoryNewFooter from '../../components/Categories/CategoryNew/CategoryNewFooter.vue';

export default defineComponent({
  components: {
    AppView,
    CategoryNewHeader,
    CategoriesItemForm,
    CategoryNewFooter,
  },
  setup(props, context) {
    const categoriesCatalogStore = useCategoriesCatalogStore();

    const router = useRouter(context);

    const categoryFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const categoryFormData = ref<PartialWithout<Category, 'id'> | null>(null);
    const categoryFormIsValid = ref(false);

    const newCategory = computed(() => {
      if (!categoryFormData.value || !categoryFormIsValid.value) return null;

      return categoryFormData.value;
    });

    const saving = ref(false);
    const categoryHasBeenChanged = computed(() => !newCategory.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const cancel = () => {
      router.push('./');
    };

    const save = async () => {
      if (!newCategory.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await categoriesCatalogStore.actions.CREATE_CATEGORY(newCategory.value);

        cancel();
      } finally {
        saving.value = false;
      }
    };

    return {
      categoryFormRef,
      categoryFormData,
      categoryFormIsValid,
      saving,
      categoryHasBeenChanged,
      closeBtnIsDisabled,
      saveError,
      save,
      cancel,
    };
  },
});
