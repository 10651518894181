import { NotifyItemPushOptions } from '@/services/notify/types';

import store from '@/store';
import notifyStore from './store/notify.store';

const notifyStoreContext = notifyStore.context(store);

export default {
  push(payload: NotifyItemPushOptions): void {
    notifyStoreContext.dispatch('PUSH_NOTIFICATION', payload);
  },
};
