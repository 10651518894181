






import { defineComponent, PropType } from '@vue/composition-api';

import { UnapprovedCampaign } from '@/models/UnapprovedPlacements';

import UnapprovedPlacementsCampaignList from './UnapprovedPlacementsCampaignList/UnapprovedPlacementsCampaignList.vue';

export default defineComponent({
  components: {
    UnapprovedPlacementsCampaignList,
  },
  props: {
    campaigns: { type: Array as PropType<UnapprovedCampaign[]>, required: true },
  },
});
