
















import { defineComponent, ref, computed } from '@vue/composition-api';

import { ModallyModalOptions, ModallyModalResolve } from './types';

import ModallyModal from './components/ModallyModal.vue';

let modallyRootOnShow: <T>(payload: ModallyModalOptions<T>, resolve: ModallyModalResolve<T>) => void | undefined;

export function modallyRootShow<T>(payload: ModallyModalOptions<T>, resolve: ModallyModalResolve<T>): void {
  if (modallyRootOnShow) modallyRootOnShow(payload, resolve);
}

export default defineComponent({
  setup() {
    const defaultModalConfigs = {
      alert: {
        buttons: [
          {
            props: {
              text: 'Хорошо',
              color: 'primary-gradient',
            },
            action: () => true,
          },
        ],
      },
      confirm: {
        buttons: [
          {
            props: {
              text: 'Подтвердить',
              color: 'primary',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary-gradient',
            },
            action: () => false,
          },
        ],
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const modalOptions = ref<ModallyModalOptions<any> | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const modalResolve = ref<ModallyModalResolve<any> | null>(null);

    const loadingButtonIdx = ref(-1);

    const normalizedButtons = computed(() => {
      if (!modalOptions.value?.buttons) return [];

      const someButtonsIsLoading = loadingButtonIdx.value < modalOptions.value?.buttons.length && loadingButtonIdx.value !== -1;

      return modalOptions.value.buttons.map((button, idx) => {
        const buttonIsLoading = idx === loadingButtonIdx.value;

        return {
          action: button.action,
          props: {
            ...button.props,
            loading: buttonIsLoading,
            disabled: someButtonsIsLoading && !buttonIsLoading,
          },
        };
      });
    });

    modallyRootOnShow = (options, resolve) => {
      const modalType = options.type ?? 'alert';
      const modalButtons = options.buttons ?? defaultModalConfigs[modalType].buttons;

      modalOptions.value = {
        ...options,
        buttons: modalButtons,
        component: ModallyModal,
      };

      modalResolve.value = async (payload) => {
        if (payload && typeof payload !== 'boolean' && payload.button.action) {
          if (payload.index) {
            loadingButtonIdx.value = payload.index;
          }

          try {
            const result = await payload.button.action();

            resolve(result);
          } finally {
            loadingButtonIdx.value = -1;
          }
        } else {
          resolve(payload);
        }

        modalOptions.value = null;
        modalResolve.value = null;
      };
    };

    return {
      modalOptions,
      modalResolve,
      normalizedButtons,
    };
  },
});
