import { AppRouteConfig } from '@/models/AppRouter';

import FactUpload from '../views/FactUpload.vue';

const routes: AppRouteConfig[] = [
  {
    path: '/factupload',
    redirect: '/fact-upload',
  },
  {
    path: '/fact-upload',
    component: FactUpload,
    meta: {
      group: 'main',
      title: 'Загрузка факта',
      icon: 'mdi-upload',
      order: 4,
      permission: 'upload_fact',
    },
    props: true,
  },
];

export default routes;
