






































import { defineComponent } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

export default defineComponent({
  components: {
    AppModal,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const close = () => {
      context.emit('input', false);
    };

    const openAddModal = () => {
      context.emit('open-modal', 'add');

      close();
    };

    const openAddExistModal = () => {
      context.emit('open-modal', 'addExist');

      close();
    };

    return {
      openAddModal,
      openAddExistModal,
    };
  },
});
