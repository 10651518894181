<template>
  <AppView class="d-flex justify-center align-center">
    <div>
      <div class="d-flex justify-center align-center mb-4">
        <v-icon
          class="mr-2"
          color="grey darken-3"
          size="48"
        >
          mdi-file-question-outline
        </v-icon>
        <div class="text-h5 grey--text text--darken-3">
          Страница не найдена
        </div>
      </div>
      <div class="mb-4 body-2 text-center grey--text text--darken-3">
        Возможно, ее адрес был изменен или она была удалена.<br>
        Вернитесь на стартовую страницу или обратитесь <a
          href="mailto:help@danbo.digital"
          target="_blank"
        >в поддержку</a>.
      </div>
      <div class="d-flex justify-center">
        <v-btn
          color="primary-gradient"
          rounded
          to="/"
        >
          Перейти на стартовую страницу
        </v-btn>
      </div>
    </div>
  </AppView>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

export default defineComponent({
  components: {
    AppView,
  },
});
</script>
