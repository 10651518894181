













































































































































import Vue from 'vue';
import { defineComponent, PropType, toRefs, ref, computed, watch } from '@vue/composition-api';

import moment from 'moment';

import {
  DAutocomplete,
  DDatePickerTextField,
} from '@shared/druid-kit';

import useRouter from '@/packages/hooks/useRouter';

import AppJsonError from '@/services/errorHandler/AppJsonError';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import {
  CampaignsApi,
  PlacementsApi,
  DataProvidersApi,
  MarkupsApi,
} from '@/api';

import { Client } from '@/models/Client';
import { Brand } from '@/models/Brand';
import { Campaign } from '@/models/Campaign';
import { Placement } from '@/models/Placement';
import { DataProvider } from '@/models/DataProvider';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { useMarkupsStore } from '../../store';

export default defineComponent({
  components: {
    DAutocomplete,
    DDatePickerTextField,
    AppModal,
  },
  props: {
    value: { type: Boolean, default: false },
    placementId: { type: String as PropType<string | null>, default: null },
  },
  setup(props, context) {
    const {
      value,
      placementId,
    } = toRefs(props);

    const router = useRouter(context);

    const markupsStore = useMarkupsStore();

    const currentClient = ref<Client | null>(null);
    const clients = computed(() => markupsStore.getters.clients);
    const clientsIsLoading = computed(() => markupsStore.getters.clientsIsLoading);
    const placement = computed(() => markupsStore.getters.placement);

    const currentBrand = ref<Brand | null>(null);
    const brands = computed(() => currentClient.value?.brands || []);

    watch(currentClient, () => {
      currentBrand.value = currentClient.value?.brands.find((brand) => brand.id === placement.value?.brandId) ?? null;
    });

    const currentCampaign = ref<Campaign | null>(null);
    const campaigns = ref<Campaign[]>([]);
    const campaignsIsLoading = ref(false);

    const fetchCampaigns = async () => {
      if (!currentBrand.value) {
        campaigns.value = [];

        return;
      }

      campaignsIsLoading.value = true;

      try {
        const res = await CampaignsApi.fetchCampaignsByBrand({ brandId: currentBrand.value.id });

        campaigns.value = res.data.campaigns;
      } finally {
        campaignsIsLoading.value = false;
      }
    };

    watch(currentBrand, async () => {
      await fetchCampaigns();

      currentCampaign.value = campaigns.value.find((campaign) => campaign.id === placement.value?.campaignId) ?? null;
    });

    const currentPlacementId = ref<string | null>(null);
    const placements = ref<Placement[]>([]);
    const placementsIsLoading = ref(false);

    const currentPlacement = computed(() => placements.value.find(({ id }) => id === currentPlacementId.value));

    const placementSelectItems = computed(() => placements.value.map(({ siteName, danboId, id }) => ({
      text: `${siteName} ${danboId}`,
      value: id,
    })));

    const fetchPlacements = async () => {
      if (!currentCampaign.value) {
        placements.value = [];

        return;
      }

      placementsIsLoading.value = true;

      try {
        const res = await PlacementsApi.fetchPlacementsByCampaign(currentCampaign.value.id);

        placements.value = res.data;
      } finally {
        placementsIsLoading.value = false;
      }
    };

    watch(currentCampaign, async () => {
      await fetchPlacements();

      currentPlacementId.value = placements.value.find(({ id }) => id === placement.value?.id)?.id ?? null;
    });

    const currentDataProvider = ref<DataProvider | null>(null);
    const rawDataProviders = ref<DataProvider[]>([]);
    const dataProvidersIsLoading = ref(false);

    const dataProviders = computed(() => {
      const filteredDataProviders = rawDataProviders.value.filter((dataProvider) => dataProvider.type.id === 'Placement');

      return filteredDataProviders.sort((dataProviderA, dataProviderB) => dataProviderA.name.localeCompare(dataProviderB.name));
    });

    const fetchDataProviders = async () => {
      dataProvidersIsLoading.value = true;

      try {
        const res = await DataProvidersApi.fetchDataProviders();

        rawDataProviders.value = res.data;
      } finally {
        dataProvidersIsLoading.value = false;
      }
    };

    const currentDate = ref(moment().format('YYYY-MM-DD'));

    const currentMultiplier = ref(1);

    const newMarkup = computed(() => {
      if (!currentClient.value || !currentBrand.value || !currentCampaign.value || !currentPlacementId.value || !currentDataProvider.value || !currentDate.value || !currentMultiplier.value) return null;

      return {
        clientId: currentClient.value.id,
        brandId: currentBrand.value.id,
        campaignId: currentCampaign.value.id,
        placementId: currentPlacementId.value,
        dataProviderId: currentDataProvider.value.id,
        startDate: moment.utc(currentDate.value).toISOString(),
        value: currentMultiplier.value,
      };
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => saving.value || !newMarkup.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!newMarkup.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        const res = await MarkupsApi.setMarkup(newMarkup.value);

        Vue.gtm.push({
          event: 'user_action',
          event_category: 'Markup',
          event_action: 'Add Markup',
          event_label: `${currentDataProvider.value?.name} | ${currentPlacement.value?.danboId}`,
          d1d_client_id: currentClient.value?.id,
          d1d_client_name: currentClient.value?.name,
          d1d_brand_id: currentBrand.value?.id,
          d1d_brand_name: currentBrand.value?.name,
          d1d_campaign_id: currentCampaign.value?.id,
          d1d_campaign_name: currentCampaign.value?.name,
          d1d_danbo_id: currentPlacement.value?.danboId,
        });

        router.push(`/markups/${res.data.id}`, close, close);
      } catch (error) {
        if (!isError(error)) return;

        const messages = {
          403: 'Недостаточно прав. Обратитесь к администратору',
        };

        if (isAxiosError(error)) {
          const message = error.response?.data?.message || messages[error?.response?.status || ''];

          saveError.value = {
            title: 'Произошла ошибка',
            messages: [message || 'Обратитесь к администратору'],
          };
        }

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        saving.value = false;
      }
    };

    const init = async () => {
      await Promise.all([
        markupsStore.dispatch('FETCH_CLIENTS'),
        fetchDataProviders(),
      ]);

      if (placementId.value) {
        currentClient.value = clients.value.find((client) => client.id === placement.value?.clientId) ?? null;
      }
    };

    watch(value, (val) => {
      if (!val) return;

      currentClient.value = null;
      currentDataProvider.value = null;
      currentDate.value = moment().format('YYYY-MM-DD');
      currentMultiplier.value = 1;

      init();
    });

    return {
      currentClient,
      clients,
      clientsIsLoading,
      //
      currentBrand,
      brands,
      //
      currentCampaign,
      campaigns,
      campaignsIsLoading,
      //
      currentPlacementId,
      placements,
      placementsIsLoading,
      placementSelectItems,
      //
      currentDataProvider,
      dataProviders,
      dataProvidersIsLoading,
      //
      currentDate,
      //
      currentMultiplier,
      //
      saveBtnIsDisabled,
      saveError,
      save,
    };
  },
});
