




























































import { defineComponent, toRefs, ref, PropType, watch, computed } from '@vue/composition-api';

import VueDraggable from 'vuedraggable';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { CampaignCategory } from '@/models/Campaign';

import { useCampaignStore } from '@/store/campaign';

export default defineComponent({
  components: {
    VueDraggable,
    AppModal,
  },
  props: {
    value: { type: Boolean, default: false },
    campaignCategories: { type: Array as PropType<CampaignCategory[]>, default: () => [] },
  },
  setup(props, context) {
    const {
      value,
      campaignCategories,
    } = toRefs(props);

    const campaignStore = useCampaignStore();

    const isLoading = ref(false);

    const categories = ref<CampaignCategory[]>([]);

    const categoriesOrderHasBeenChanged = computed(() => !categories.value.some((category, idx) => {
      const currentCampaignCategory = campaignCategories.value[idx];

      return category.id !== currentCampaignCategory?.id;
    }));

    watch(value, () => {
      categories.value = campaignCategories.value;
    });

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (isLoading.value) return;

      if (categoriesOrderHasBeenChanged.value) return close();

      isLoading.value = true;

      await campaignStore.dispatch('UPDATE_CAMPAIGN_CATEGORIES_ORDER', categories.value);

      isLoading.value = false;

      close();
    };

    return {
      isLoading,
      categories,
      categoriesOrderHasBeenChanged,
      save,
    };
  },
});
