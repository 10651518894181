








































import { defineComponent } from '@vue/composition-api';

import {
  DTableCell,
  DTooltip,
} from '@shared/druid-kit';

export default defineComponent({
  components: {
    DTableCell,
    DTooltip,
  },
  props: {
    approved: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
});
