import { computed, Ref, ComputedRef } from '@vue/composition-api';

import { Campaign, CampaignCategory } from '@/models/Campaign';

import { useCampaignStore } from '@/store/campaign';

interface UseCampaignDetailsProps {
  campaignId: Ref<string>;
  filters?: Ref<{
    from?: string;
    to?: string;
  }>;
}

interface UseCampaignDetailsReturn {
  campaign: ComputedRef<Campaign | null>;
  campaignCategories: ComputedRef<CampaignCategory[]>;
  campaignPageTitle: ComputedRef<string>;
  fetchCampaign: () => Promise<void>;
}

export default (props: UseCampaignDetailsProps): UseCampaignDetailsReturn => {
  const {
    campaignId,
    filters,
  } = props;

  const campaignStore = useCampaignStore();

  const campaign = computed(() => campaignStore.getters.campaign);

  const campaignCategories = computed(() => campaignStore.getters.campaignCategories);

  const campaignPageTitle = computed(() => (campaign.value ? `${campaign.value?.brandName} • ${campaign.value?.name}` : 'Кампания'));

  const fetchCampaign = async () => {
    await campaignStore.dispatch('FETCH_CAMPAIGN', {
      campaignId: campaignId.value,
      filters: filters?.value,
      options: {
        withCategories: true,
      },
    });
  };

  return {
    campaign,
    campaignCategories,
    campaignPageTitle,
    fetchCampaign,
  };
};
