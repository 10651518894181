




import { defineComponent, computed } from '@vue/composition-api';

import useRoute from '@/packages/hooks/useRoute';

export default defineComponent({
  setup(props, context) {
    const route = useRoute(context);

    const routerViewProps = computed(() => ({
      key: route.value.params.campaignId,
    }));

    return {
      routerViewProps,
    };
  },
});
