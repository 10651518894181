import { AxiosResponse } from 'axios';

import {
  Targeting,
  TargetingGender,
  TargetingRegion,
  TargetingCappingPeriod,
  TargetingTransmittance,
  TargetingIncomeGroup,
} from '@/models/Targeting';

import httpClient from '../httpClient';

export default class TargetingsApi {
  public static async fetchTargetingsByCampaign(campaignId: string): Promise<AxiosResponse<Targeting[]>> {
    const res = await httpClient.get<Targeting[]>(`/api/targetings/by-campaign/${campaignId}`);

    return res;
  }

  public static async fetchGenders(): Promise<AxiosResponse<TargetingGender[]>> {
    const res = await httpClient.get<TargetingGender[]>('/api/targetings/genders');

    return res;
  }

  public static async fetchRegions(): Promise<AxiosResponse<TargetingRegion[]>> {
    const res = await httpClient.get<TargetingRegion[]>('/api/targetings/regions');

    return res;
  }

  public static async fetchCappingPeriods(): Promise<AxiosResponse<TargetingCappingPeriod[]>> {
    const res = await httpClient.get<TargetingCappingPeriod[]>('/api/targetings/capping-periods');

    return res;
  }

  public static async fetchTransmittances(): Promise<AxiosResponse<TargetingTransmittance[]>> {
    const res = await httpClient.get<TargetingTransmittance[]>('/api/targetings/transmittances');

    return res;
  }

  public static async fetchIncomeGroups(): Promise<AxiosResponse<TargetingIncomeGroup[]>> {
    const res = await httpClient.get<TargetingIncomeGroup[]>('/api/targetings/income-groups');

    return res;
  }
}
