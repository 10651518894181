























































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api';

import { DAutocomplete } from '@shared/druid-kit';

import { JsonEditor } from '@/packages/json-editor/src';

import notify from '@/services/notify';
import AppJsonError from '@/services/errorHandler/AppJsonError';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import plural from '@/utils/plural';
import isError from '@/utils/isError';

import { Provider, ProviderSettingsToken } from '@/models/ProviderSettings';
import { Client } from '@/models/Client';

import { ProviderSettingsApi } from '@/api';

import { useProviderSettingsStore } from '../../store';

export default defineComponent({
  components: {
    DAutocomplete,
    JsonEditor,
    AppModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const providerSettingsStore = useProviderSettingsStore();

    const name = ref('');
    const client = ref<Client | null>(null);
    const brands = ref<string[] | []>([]);
    const provider = ref<Provider | null>(null);
    const token = ref<ProviderSettingsToken | null>(null);
    const statisticsCollectionDepth = ref('');

    const selectedProviderSettings = computed(() => providerSettingsStore.getters.selectedProviderSettings);
    const saveIsLoading = computed(() => providerSettingsStore.getters.saveIsLoading);

    const clients = computed(() => providerSettingsStore.getters.clients);
    const clientsIsLoading = computed(() => providerSettingsStore.getters.clientsIsLoading);

    const providers = computed(() => providerSettingsStore.getters.providers);
    const providersIsLoading = computed(() => providerSettingsStore.getters.providersIsLoading);

    const tokens = computed(() => providerSettingsStore.getters.tokens);
    const tokensIsLoading = computed(() => providerSettingsStore.getters.tokensIsLoading);

    const statisticsCollection = computed(() => providerSettingsStore.getters.statisticsCollection);
    const statisticsCollectionIsLoading = computed(() => providerSettingsStore.getters.statisticsCollectionIsLoading);

    const isEdit = computed(() => !!selectedProviderSettings.value?.id);

    const settingSchema = ref<Record<string, unknown> | null>(null);
    const settingSchemaErrorMessages = ref<string[]>([]);
    const settingSchemaValidateErrors = ref<string[]>([]);
    const settingSchemaLoadedWithError = ref(false);

    const connectorSettings = ref<unknown>(null);
    const connectorSettingsLoading = ref(false);

    const title = computed(() => providerSettingsStore.getters.selectedProviderSettings?.name ?? 'Новая настройка');
    const brandsOtherText = computed(() => plural(brands.value?.length - 1, ['другой', 'других', 'других'], { withCount: true }));
    const clientBrands = computed(() => client.value?.brands ?? []);

    const tokensLabel = computed(() => {
      if (provider.value && !provider.value?.isTokenRequired) return 'Без доступа';

      return 'Доступ';
    });

    const tokensIsDisabled = computed(() => isEdit.value || !provider.value || !provider.value?.isTokenRequired);

    const connectorSettingsIsEmpty = computed(() => !isEdit.value && !connectorSettingsLoading.value && !settingSchema.value && !settingSchemaLoadedWithError.value);

    const saveIsDisabled = computed(() => {
      if (connectorSettingsLoading.value || settingSchemaValidateErrors.value.length) return true;

      return (!provider.value || provider.value.isTokenRequired) && (!token.value || !client.value);
    });

    const onError = (messages: string[]) => {
      settingSchemaLoadedWithError.value = true;

      settingSchemaErrorMessages.value = messages;
    };

    const loadSettingSchema = async () => {
      if (!provider.value) return;

      connectorSettingsLoading.value = true;

      settingSchemaLoadedWithError.value = false;
      settingSchemaErrorMessages.value = [];

      const jsonReplacer = (key: unknown, value: unknown) => {
        if (value === null) return undefined;

        return value;
      };

      try {
        const { data: { errors, schema } } = await ProviderSettingsApi.fetchSchema({
          providerId: provider.value.id,
          tokenId: token.value?.id ?? null,
          settings: JSON.stringify(connectorSettings.value, jsonReplacer, ' '),
        });

        if (errors.length) {
          onError(errors);

          return;
        }

        settingSchema.value = JSON.parse(schema);

        connectorSettingsLoading.value = false;
      } catch (error) {
        if (!isError(error)) return;

        onError(['Произошла ошибка. Обратитесь к администратору.']);

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        connectorSettingsLoading.value = false;
      }
    };

    watch(provider, async (val) => {
      if (val && !isEdit.value) {
        token.value = null;
        statisticsCollectionDepth.value = '';
        connectorSettings.value = null;
        settingSchema.value = null;

        if (provider.value?.isTokenRequired) {
          await providerSettingsStore.dispatch('FETCH_TOKENS', val.id);
        } else {
          await loadSettingSchema();
        }

        await providerSettingsStore.dispatch('FETCH_STATISTICS_COLLECTION', val.connectorId);
      }
    });

    watch(token, (val) => {
      if (!isEdit.value) {
        settingSchema.value = null;
        connectorSettings.value = null;
      }

      if (val) {
        loadSettingSchema();
      }
    });

    const onAutoPostBack = (payload: unknown) => {
      connectorSettings.value = payload;

      if (connectorSettingsLoading.value) return;

      loadSettingSchema();
    };

    const fetch = async () => {
      await Promise.all([
        providerSettingsStore.dispatch('FETCH_CLIENTS'),
        providerSettingsStore.dispatch('FETCH_PROVIDERS'),
      ]);

      if (!selectedProviderSettings.value) return;

      name.value = selectedProviderSettings.value.name;
      client.value = clients.value.find(({ id }) => id === selectedProviderSettings.value?.clientId) ?? null;
      brands.value = selectedProviderSettings.value.brandIds;

      provider.value = providers.value.find(({ id }) => id === selectedProviderSettings.value?.dataProviderId) ?? null;

      if (!provider.value) return;

      if (provider.value?.isTokenRequired) {
        await providerSettingsStore.dispatch('FETCH_TOKENS', provider.value.id);
      } else {
        await loadSettingSchema();
      }

      await providerSettingsStore.dispatch('FETCH_STATISTICS_COLLECTION', provider.value.connectorId);

      token.value = tokens.value.find(({ id }) => id === selectedProviderSettings.value?.tokenId) ?? null;

      statisticsCollectionDepth.value = statisticsCollection.value
        .find((statistic) => statistic === selectedProviderSettings.value?.statisticsCollectionDepth) ?? '';

      connectorSettings.value = selectedProviderSettings.value?.connectorSettings
        ? JSON.parse(selectedProviderSettings.value.connectorSettings)
        : null;
    };

    const save = async () => {
      if (!provider.value || !client.value) return;

      const payload = {
        ...(selectedProviderSettings.value && { id: selectedProviderSettings.value.id }),
        name: name.value,
        clientId: client.value.id,
        clientName: client.value.name,
        brandIds: brands.value,
        dataProviderId: provider.value.id,
        dataProviderName: provider.value.name,
        tokenId: token.value?.id ?? null,
        statisticsCollectionDepth: statisticsCollectionDepth.value,
        connectorSettings: JSON.stringify(connectorSettings.value) ?? '{}',
      };

      const successText = `Настройка ${name.value} успешно ${isEdit.value ? 'обновлена' : 'добавлена'}`;

      await providerSettingsStore.dispatch('SAVE_PROVIDER_SETTINGS', payload);

      notify.push({ text: successText, position: ['bottom', 'center'], status: 'positive' });
    };

    const onSettingSchemaValidate = (payload: string[]) => {
      settingSchemaValidateErrors.value = payload;
    };

    fetch();

    return {
      name,
      client,
      brands,
      provider,
      token,
      statisticsCollectionDepth,
      //
      clients,
      providers,
      tokens,
      statisticsCollection,
      //
      clientsIsLoading,
      providersIsLoading,
      tokensIsLoading,
      statisticsCollectionIsLoading,
      //
      settingSchema,
      connectorSettings,
      loadSettingSchema,
      connectorSettingsLoading,
      onAutoPostBack,
      onSettingSchemaValidate,
      settingSchemaLoadedWithError,
      settingSchemaErrorMessages,
      //
      title,
      brandsOtherText,
      clientBrands,
      tokensLabel,
      tokensIsDisabled,
      connectorSettingsIsEmpty,
      saveIsDisabled,
      saveIsLoading,
      isEdit,
      //
      save,
    };
  },
});
