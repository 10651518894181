/* eslint-disable max-classes-per-file */

import Vue from 'vue';
import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, Context, createMapper, createComposable } from 'vuex-smart-module';

import { Goal, GoalDataProvider } from '@/models/Goal';
import { ProviderSettingsLight } from '@/models/ProviderSettings';

import {
  GoalsSettingsApi,
  ProviderSettingsApi,
} from '@/api';

import campaignStore from '../campaign';

export const CONVERSION_RATE_MODE_GOAL_SUM = 'GoalsSum';
export const CONVERSION_RATE_MODE_MAIN_GOAL = 'MainGoal';

export const DEFAULT_CONVERSION_RATE_MODE = CONVERSION_RATE_MODE_GOAL_SUM;

class ModuleState {
  goals: Goal[] = [];

  initGoalsCount = 0;

  goalsUpdate: Goal[] = [];

  changedGoals: Goal[] = [];

  dataProviders: GoalDataProvider[] = [];

  dataProviderSettings: ProviderSettingsLight[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get goals() {
    return this.state.goals;
  }

  get changedGoals() {
    return this.state.changedGoals;
  }

  get dataProviders() {
    return this.state.dataProviders;
  }

  get dataProviderSettings() {
    return this.state.dataProviderSettings;
  }

  get hasBeenChanged() {
    if (this.state.initGoalsCount !== this.getters.goals.length) return true;

    return !!this.getters.changedGoals.length;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_GOALS(payload: Goal[]) {
    this.state.goals = payload;
    this.state.initGoalsCount = payload.length;
  }

  SET_CHANGED_GOALS(payload: Goal[]) {
    this.state.changedGoals = payload;
  }

  SET_DATA_PROVIDERS(payload: GoalDataProvider[]) {
    this.state.dataProviders = payload;
  }

  SET_DATA_PROVIDER_SETTINGS(payload: ProviderSettingsLight[]) {
    this.state.dataProviderSettings = payload;
  }

  DELETE_GOAL(payload: Goal) {
    this.state.goals = this.state.goals.filter(({ id, providerId, providerSettingsId }) => (
      id !== payload.id
      || providerId !== payload.providerId
      || providerSettingsId !== payload.providerSettingsId
    ));
  }

  RESET_CHANGES() {
    this.state.changedGoals = [];
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  store!: Store<unknown>;

  campaign!: Context<typeof campaignStore>;

  $init(store: Store<unknown>) {
    this.store = store;

    this.campaign = campaignStore.context(store);
  }

  async FETCH_GOALS(payload: { campaignId: string }) {
    const { campaignId } = payload;

    const res = await GoalsSettingsApi.fetchGoalsSettings({ params: { campaignId } });

    this.commit('SET_GOALS', res.data);
  }

  async FETCH_DATA_PROVIDERS() {
    const res = await ProviderSettingsApi.fetchGoalProviders();

    this.commit('SET_DATA_PROVIDERS', res.data);
  }

  async FETCH_DATA_PROVIDER_SETTINGS(payload: { campaignId: string }) {
    const { campaignId } = payload;

    const res = await ProviderSettingsApi.fetchProviderSettingsByCampaign(campaignId);

    this.commit('SET_DATA_PROVIDER_SETTINGS', res.data);
  }

  CHANGE_GOALS(payload: Goal[]) {
    const findGoalIdx = (goals: Goal[], goal: Goal) => goals.findIndex(({ id, providerId, providerSettingsId }) => (
      id === goal.id
      && providerId === goal.providerId
      && providerSettingsId === goal.providerSettingsId
    ));

    const newGoals = [...this.state.goals];
    const newChangedGoals = [...this.state.changedGoals];

    payload.forEach((newGoal) => {
      const foundIdxInGoals = findGoalIdx(newGoals, newGoal);
      const foundIdxInChangedGoals = findGoalIdx(newChangedGoals, newGoal);

      // update goals
      if (foundIdxInGoals === -1) {
        newGoals.unshift(newGoal);
      } else {
        const oldGoal = newGoals[foundIdxInGoals];

        newGoals.splice(foundIdxInGoals, 1, {
          ...newGoals[foundIdxInGoals],
          ...newGoal,
          mainGoal: oldGoal.mainGoal,
        });
      }

      // update changed goals
      if (foundIdxInChangedGoals === -1) {
        newChangedGoals.unshift(newGoal);
      } else {
        newChangedGoals.splice(foundIdxInChangedGoals, 1, {
          ...newChangedGoals[foundIdxInChangedGoals],
          ...newGoal,
        });
      }
    });

    this.commit('SET_GOALS', newGoals);
    this.commit('SET_CHANGED_GOALS', newChangedGoals);

    const { campaign } = this.campaign.getters;

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Campaign Editor',
      event_action: 'Edit Goals',
      event_label: 'Add new',
      d1d_client_id: campaign?.clientId,
      d1d_client_name: campaign?.clientName,
      d1d_brand_id: campaign?.brandId,
      d1d_brand_name: campaign?.brandName,
      d1d_campaign_id: campaign?.id,
      d1d_campaign_name: campaign?.name,
    });
  }

  DELETE_GOAL(payload: Goal) {
    this.commit('DELETE_GOAL', payload);
  }

  async SAVE(payload: { campaignId: string }) {
    if (!this.getters.hasBeenChanged) return;

    await GoalsSettingsApi.setGoalsSettings({
      CampaignId: payload.campaignId,
      Goals: this.getters.goals,
    });

    const { campaign } = this.campaign.getters;

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Campaign Editor',
      event_action: 'Edit Goals',
      event_label: 'Save',
      d1d_client_id: campaign?.clientId,
      d1d_client_name: campaign?.clientName,
      d1d_brand_id: campaign?.brandId,
      d1d_brand_name: campaign?.brandName,
      d1d_campaign_id: campaign?.id,
      d1d_campaign_name: campaign?.name,
    });

    await this.dispatch('FETCH_GOALS', payload);

    this.commit('RESET_CHANGES');
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const campaignEditorGoalsStoreMapper = createMapper(module);

export const useCampaignEditorGoalsStore = createComposable(module);

export default module;
