<template>
  <div class="campaign-details-loader">
    <v-skeleton-loader
      type="heading"
      class="campaign-details-loader__header"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({});
</script>

<style lang="scss" scoped>
.campaign-details-loader {
  background-color: map-get($grey, 'lighten-5');

  &__header {
    display: flex;
    align-items: center;
    width: 70%;
    height: 80px;
    padding: 12px 24px;
    margin-bottom: 58px;

    ::v-deep .v-skeleton-loader__heading {
      background: map-get($grey, 'lighten-1') !important;
    }
  }
}
</style>
