
































import { defineComponent, ref, computed, watch } from '@vue/composition-api';

import { debounce } from 'lodash';

import { DSelectInline } from '@shared/druid-kit';

import { useSearchStore } from '../store';

import { SearchFilters } from '../models';

export default defineComponent({
  components: {
    DSelectInline,
  },
  setup() {
    const searchStore = useSearchStore();

    const statusItems = ref([
      { text: 'Активные сейчас', value: 'active' },
      { text: 'Архивные', value: 'archive' },
      { text: 'Все', value: 'all' },
    ]);

    const searchIsLoading = computed(() => searchStore.getters.isLoading);
    const searchHasBeenByDanboId = computed(() => searchStore.getters.hasBeenByDanboId);

    const queryLimit = computed(() => searchStore.getters.queryLimit);

    const query = computed({
      get() {
        return searchStore.getters.query;
      },
      set: debounce((val: string) => {
        searchStore.dispatch('UPDATE_QUERY', val);
      }, 120),
    });

    const status = computed({
      get() {
        return searchStore.getters.filters.status;
      },
      set(val: SearchFilters['status']) {
        searchStore.dispatch('UPDATE_FILTERS', { status: val });
      },
    });

    const searchIsDisabled = computed(() => query.value.length < queryLimit.value.min);

    const search = () => {
      if (searchIsDisabled.value) return;

      searchStore.dispatch('SEARCH');
    };

    watch(status, () => {
      search();
    });

    return {
      searchIsLoading,
      searchHasBeenByDanboId,
      query,
      status,
      statusItems,
      searchIsDisabled,
      search,
    };
  },
});
