























































































import { defineComponent, PropType, computed } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import { Campaign } from '@/models/Campaign';

import useRoute from '@/packages/hooks/useRoute';

import useModals from '@/composables/useModals';

import { useCampaignEditorStore } from '@/store/campaignEditor';

import CampaignPlacementsReportsModal from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorPlacements/modals/CampaignPlacementsReportsModal.vue';
import CampaignPlacementsUploadFilesModal from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorPlacements/modals/CampaignPlacementsUploadFilesModal.vue';

export default defineComponent({
  components: {
    DBtnInline,
    CampaignPlacementsReportsModal,
    CampaignPlacementsUploadFilesModal,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props, context) {
    const route = useRoute(context);

    const campaignEditorStore = useCampaignEditorStore();

    const {
      modalsMap,
      openModal,
    } = useModals(['reports', 'uploadFiles']);

    const isSaving = computed(() => campaignEditorStore.getters.isSaving);

    const campaignHasBeenChanged = computed(() => campaignEditorStore.getters.hasBeenChanged);

    const saveBtnDisabled = computed(() => !isSaving.value && !campaignEditorStore.getters.hasBeenChanged);

    const filesButttonsIsShown = computed(() => ['campaign-editor-plan', 'campaign-editor-placements'].includes(route.value.name || ''));

    const save = () => {
      context.emit('save');
    };

    const cancel = () => {
      context.emit('cancel');
    };

    return {
      modalsMap,
      openModal,
      isSaving,
      campaignHasBeenChanged,
      saveBtnDisabled,
      filesButttonsIsShown,
      save,
      cancel,
    };
  },
});
