



































import Vue, { VueConstructor } from 'vue';

import moment from 'moment';
import Handsontable from 'handsontable/base';

import { DDatePicker } from '@shared/druid-kit';

import HnTableCellMixin from '../../mixins/HnTableCellMixin.vue';

type HnTableDateCellValue = string | null;

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'HnTableDateCell',
  components: {
    DDatePicker,
  },
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    format: { type: String, default: 'YYYY-MM-DDTHH:mm:ss[Z]' },
  },
  data() {
    return {
      value: null as HnTableDateCellValue | null,
      textFieldFormat: 'DD.MM.YYYY',
      datePickerFormat: 'YYYY-MM-DD',
    };
  },
  computed: {
    rendererValue(): string {
      const momentDate = moment(this.value);

      const valueIsValidDate = momentDate.isValid();

      if (!valueIsValidDate) return '—';

      return moment.utc(this.value).format('DD.MM.YYYY');
    },
    editorValue(): HnTableDateCellValue {
      const momentDate = moment(this.originalValue);

      const valueIsValidDate = momentDate.isValid();

      if (!valueIsValidDate) return null;

      return moment.utc(this.originalValue).format(this.datePickerFormat);
    },
    textFieldValue(): HnTableDateCellValue {
      if (!this.editorValue) return null;

      return moment.utc(this.editorValue).format(this.textFieldFormat);
    },
    datePickerValue(): HnTableDateCellValue {
      if (!this.editorValue) return null;

      return moment.utc(this.editorValue).format(this.datePickerFormat);
    },
  },
  methods: {
    getDate(payload: { value: string | null, format: string }) {
      const momentDate = moment(payload.value, payload.format);

      const date = momentDate.format(this.format);
      const isValid = momentDate.isValid();

      return {
        date,
        isValid,
      };
    },
    onTextFieldChange(value: string) {
      const { date, isValid } = this.getDate({ value, format: this.textFieldFormat });

      if (!isValid) return this.finishEditing();

      this.setValue(date);

      this.finishEditing();
    },
    onTextFieldKeyDown(e: KeyboardEvent) {
      switch (e.keyCode) {
        case Handsontable.helper.KEY_CODES.ESCAPE:
          this.finishEditing();

          break;
        case Handsontable.helper.KEY_CODES.ENTER:
          break;

        default:
          e.stopPropagation();
      }
    },
    onDatePickerChange(value: string) {
      const { date, isValid } = this.getDate({ value, format: this.datePickerFormat });

      if (!isValid) return this.finishEditing();

      this.setValue(date);

      this.finishEditing();
    },
  },
});
