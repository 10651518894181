



























import Vue from 'vue';

import { JSONEditor } from '@json-editor/json-editor';

interface JSONEditorInstance {
  root: {
    path: string;
  };
  editors: {
    options: Record<string, unknown>;
  };
  ready: boolean;
  watch: (key: string, callback: () => void) => void;
  on: (key: string, callback: () => void) => void;
  enable: () => void;
  getValue: () => Record<string, unknown>;
  setValue: (value: unknown) => void;
  validate: () => unknown[];
  disable: () => void;
  destroy: () => void;
}

interface JSONSchema {
  type?: 'string' | 'number' | 'integer' | 'boolean' | 'object' | 'array';
  title?: string;
  format?: string;
  definitions?: {
    [key: string]: JSONSchema;
  };
  properties?: {
    [key: string]: JSONSchema;
  };
}

export default Vue.extend({
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Object, default: null },
    options: { type: Object, default: () => ({ ajax: false }) },
    schema: { type: Object, default: null },
    autopostback: { type: Boolean, default: false },
    enabled: { type: Boolean, default: true },
    redesign: { type: Boolean, default: false }, // TODO: выпилить после редизайна
    editorHeight: { type: String, default: 'auto' },
  },
  data() {
    return {
      jsonEditorInstance: null as JSONEditorInstance | null,
      inputValue: null as Record<string, unknown> | null,
      isAutoPostBack: false,
      rootStyle: {
        height: '0',
      },
      editorStyle: {
        left: '0',
        top: '0',
        width: '0',
        height: this.editorHeight,
      },
    };
  },
  computed: {
    schemaComputed() {
      if (!this.schema) return null;

      const schema: JSONSchema = { ...this.schema };

      if (schema.properties) {
        Object.values(schema.properties)
          .filter((input) => !input.type && !input.format)
          .forEach((input) => {
            input.format = 'select2';
          });
      }

      return schema;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val === this.inputValue || val === null || val === undefined) return;

        this.setValue(val);
      },
      deep: true,
    },
    schema() {
      this.attachEditor();
    },
    enabled(val) {
      this.setEnabled(val);
    },
    inputValue(val) {
      this.$emit('change', val);
    },
  },
  beforeDestroy() {
    this.destroyEditor();
  },
  methods: {
    onMutateRootEl(mutations: MutationRecord[]) {
      mutations.forEach((mutation) => {
        const clientRect = (mutation.target as HTMLElement).getBoundingClientRect();

        this.editorStyle.left = `${clientRect.left}px`;
        this.editorStyle.top = `${clientRect.top}px`;
        this.editorStyle.width = `${clientRect.width}px`;
      });
    },
    onMutateEditorEl(mutations: MutationRecord[]) {
      mutations.forEach((mutation) => {
        const clientRect = (mutation.target as HTMLElement).getBoundingClientRect();

        this.rootStyle.height = `${clientRect.height}px`;
      });
    },
    attachEditor() {
      this.destroyEditor();

      if (!this.schemaComputed) return;

      const options = {
        theme: 'bootstrap4',
        iconlib: 'fontawesome4',
        required_by_default: true,
        disable_collapse: true,
        disable_edit_json: true,
        disable_properties: true,
        disable_array_reorder: true,
        disable_array_delete_last_row: true,
        show_errors: 'interaction',
        startval: this.value,
        ...this.options,
        schema: this.schemaComputed,
      };

      this.jsonEditorInstance = new JSONEditor(this.$refs.jsonEditorEl, options);

      const { jsonEditorInstance } = this;

      if (!jsonEditorInstance) return;

      if (this.autopostback) {
        Object.keys(jsonEditorInstance.editors).forEach((key) => {
          const editor = jsonEditorInstance.editors[key];

          if (!editor) return;

          if (editor.options && (editor.options.autopostback === true || editor.options.autopostback === 'true')) {
            jsonEditorInstance.watch(key, this.onAutoPostBack);
          }
        });
      }

      jsonEditorInstance.on('ready', () => {
        jsonEditorInstance.on('change', this.onChange);
      });
    },
    attached() {
      return this.jsonEditorInstance && this.jsonEditorInstance.ready;
    },
    destroyEditor() {
      if (!this.jsonEditorInstance) return;

      this.jsonEditorInstance.destroy();

      this.jsonEditorInstance = null;
    },
    setEnabled(value: boolean) {
      if (!this.jsonEditorInstance || !this.attached()) return;

      if (value) {
        this.jsonEditorInstance.enable();
      } else {
        this.jsonEditorInstance.disable();
      }
    },
    getValue() {
      if (!this.jsonEditorInstance || !this.attached()) return null;

      return this.jsonEditorInstance.getValue();
    },
    setValue(value: Record<string, unknown>) {
      if (!this.jsonEditorInstance || !this.attached()) return;

      this.jsonEditorInstance.setValue(value);
    },
    validate() {
      if (!this.jsonEditorInstance || !this.attached()) return [];

      return this.jsonEditorInstance.validate();
    },
    onAutoPostBack() {
      this.isAutoPostBack = true;
    },
    onChange() {
      this.$emit('validate', this.validate());

      this.inputValue = this.getValue();

      if (this.isAutoPostBack) {
        this.$emit('autopostback', this.inputValue);

        this.isAutoPostBack = false;
      }
    },
  },
});
