
















































import { defineComponent, computed } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';

import CategoriesCatalogHeader from '../../components/Categories/CategoriesCatalog/CategoriesCatalogHeader.vue';
import CategoriesCatalogTable from '../../components/Categories/CategoriesCatalog/CategoriesCatalogTable/CategoriesCatalogTable.vue';

import { useCategoriesCatalogStore } from '../../store/categories/categoriesCatalog';

export default defineComponent({
  components: {
    AppView,
    CategoriesCatalogHeader,
    CategoriesCatalogTable,
  },
  setup() {
    const { updateIsLoading } = useLayoutLoadingStatus();

    const categoriesCatalogStore = useCategoriesCatalogStore();

    const categories = computed(() => categoriesCatalogStore.getters.categories);
    const categoriesIsLoading = computed(() => categoriesCatalogStore.getters.isLoading);

    const fetch = async () => {
      await categoriesCatalogStore.actions.FETCH_CATEGORIES();
    };

    const init = async () => {
      updateIsLoading(true);

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    init();

    return {
      categories,
      categoriesIsLoading,
    };
  },
});
