










import Vue, { VueConstructor } from 'vue';

import { HnTableCellMixin } from '@/packages/hn-table/src/mixins';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'PlanTableNumberCell',
  mixins: [
    HnTableCellMixin,
  ],
  computed: {
    rowNumber(): number {
      if (!this.row) return 1;

      return this.row + 1;
    },
  },
});
