import { AxiosResponse } from 'axios';

import httpClient from '../httpClient';

interface FetchUserSettingsResponse {
  favouriteClientIds: string[];
}

export default class UserSettingsApi {
  public static async fetchUserSettings(): Promise<AxiosResponse<FetchUserSettingsResponse>> {
    const res = await httpClient.get<FetchUserSettingsResponse>('/api/v2/user-settings');

    return res;
  }

  public static async addFavoriteClient(clientId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/v2/user-settings/favourite-clients', `'${clientId}'`);

    return res;
  }

  public static async deleteFavoriteClient(clientId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.delete<''>('/api/v2/user-settings/favourite-clients', {
      data: `'${clientId}'`,
    });

    return res;
  }
}
