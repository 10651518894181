import { AxiosResponse } from 'axios';

import { Group, GroupClientWithBrands } from '@/models/Group';

import { PartialWithout } from '@/utils/type';

import httpClient from '../httpClient';

interface FetchGroupsPayload {
  page?: number;
  limit?: number;
  filter?: string;
}

interface FetchGroupsResponse {
  list: Group[];
  totalRows: number;
}

export type SetGroupPayload = PartialWithout<Group, 'id'>;

export interface UpdateGroupPayload {
  groupId: string;
  data: SetGroupPayload;
}

export default class GroupsApi {
  public static async fetchGroups(payload: FetchGroupsPayload): Promise<AxiosResponse<FetchGroupsResponse>> {
    const res = await httpClient.post<FetchGroupsResponse>('/api/groups/find', payload);

    return res;
  }

  public static async fetchGroup(groupId: string): Promise<AxiosResponse<Group>> {
    const res = await httpClient.get<Group>(`/api/groups/${groupId}`);

    return res;
  }

  public static async fetchClientsWithBrands(payload: string[]): Promise<AxiosResponse<GroupClientWithBrands[]>> {
    const res = await httpClient.post<GroupClientWithBrands[]>('/api/groups/clientswithbrands', payload);

    return res;
  }

  public static async setGroup(payload: SetGroupPayload): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/groups', payload);

    return res;
  }

  public static async updateGroup(payload: UpdateGroupPayload): Promise<AxiosResponse<''>> {
    const { groupId, data } = payload;

    const res = await httpClient.put<''>(`/api/groups/${groupId}`, data);

    return res;
  }

  public static async deleteGroup(groupId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.delete<''>(`/api/groups/${groupId}`);

    return res;
  }
}
