import { AppRouteConfig } from '@/models/AppRouter';

import Groups from '../views/Groups.vue';
import GroupsCatalog from '../views/Groups/GroupsCatalog.vue';
import PageCatalogLoader from '../../../components/_newDesign/common/loaders/PageCatalogLoader.vue';

const routeConfig: AppRouteConfig = {
  path: 'groups',
  component: Groups,
  meta: {
    title: 'Группы',
    permission: 'admin',
    order: 1,
  },
  children: [
    {
      name: 'groups-catalog',
      path: '',
      component: GroupsCatalog,
      meta: {
        title: 'Группы',
        loader: PageCatalogLoader,
        header: {
          sticky: true,
        },
      },
    },
  ],
};

export default routeConfig;
