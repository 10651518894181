import numbro from 'numbro';
import numbroRuLanguage from 'numbro/languages/ru-RU';
import { pickBy, omit } from 'lodash';

numbro.registerLanguage(numbroRuLanguage);
numbro.setLanguage('ru-RU');

export interface NumatterFormatOptions extends numbro.Format {
  prefix?: string;
  postfix?: string;
}

export type NumatterPreset = {
  name: string;
  options: NumatterFormatOptions;
};

interface NumatterInputFormatOptions extends NumatterFormatOptions {
  preset?: string;
}

export type NumatterFormat = string | NumatterInputFormatOptions;

type NumatterValue = string | number;

//
//
// // // Utils
//

const getFilledFormatOptions = (inputFormatOptions: NumatterInputFormatOptions): Partial<NumatterInputFormatOptions> => pickBy(inputFormatOptions, (value) => value !== null && value !== undefined);

//
// // //
//
//

const presetsMap = new Map<string, NumatterFormatOptions>();

const addPreset = (preset: NumatterPreset): void => {
  const presetName = preset.name;
  const presetOptions = preset.options;

  presetsMap.set(presetName, presetOptions);
};

const normalizeFormatOptionsForNumbro = (inputFormatOptions: NumatterInputFormatOptions): numbro.Format => {
  const filledFormatOptions = getFilledFormatOptions(inputFormatOptions);

  return omit(filledFormatOptions, [
    'preset',
    'prefix',
    'postfix',
    'spaceSeparated',
  ]);
};

const numberFormatter = (inputValue: NumatterValue, inputFormat?: NumatterFormat) => {
  const defaultFormatOptions = {
    mantissa: 2,
    optionalMantissa: true,
    thousandSeparated: true,
  };

  if (!inputFormat) return numbro(inputValue).format(defaultFormatOptions);

  if (typeof inputFormat === 'string') return numbro(inputValue).format(inputFormat);

  const formatOptions = {
    ...defaultFormatOptions,
    ...inputFormat,
  };

  const numbroFormatOptions = normalizeFormatOptionsForNumbro(formatOptions);

  return numbro(inputValue).format(numbroFormatOptions);
};

const format = (inputValue: NumatterValue, inputFormat?: NumatterFormat): string => {
  if (!inputFormat || typeof inputFormat === 'string') return numberFormatter(inputValue, inputFormat);

  const presetFormatOptions = inputFormat.preset ? presetsMap.get(inputFormat.preset) : {};

  const formatOptions = {
    ...presetFormatOptions,
    ...inputFormat,
  };

  const prefix = formatOptions.prefix || '';
  const postfix = formatOptions.postfix || '';
  const separated = formatOptions.spaceSeparated ? ' ' : '';

  const formattedPrefix = prefix ? `${prefix}${separated}` : '';
  const formattedPostfix = postfix ? `${separated}${postfix}` : '';

  const formattedValue = numberFormatter(inputValue, formatOptions);

  return `${formattedPrefix}${formattedValue}${formattedPostfix}`;
};

export default {
  get presets(): NumatterPreset[] {
    return [...presetsMap].map(([name, options]) => ({ name, options }));
  },
  addPreset,
  format,
  clearEmptyFormatOptions: getFilledFormatOptions,
  reset(): void {
    presetsMap.clear();
  },
};
