import { AppRouteConfig } from '@/models/AppRouter';

import ProviderSettings from '../views/ProviderSettings.vue';
import ProviderSettingsPageLoader from '../components/ProviderSettingsPageLoader.vue';

const routes: AppRouteConfig[] = [
  {
    path: '/provider-settings',
    component: ProviderSettings,
    meta: {
      group: 'main',
      title: 'Настройки площадок',
      icon: 'mdi-layers',
      order: 2,
      permission: 'provider-settings_edit',
      loader: ProviderSettingsPageLoader,
      header: {
        sticky: true,
      },
    },
    props: true,
  },
];

export default routes;
