
































import Vue from 'vue';
import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTooltip,
} from '@shared/druid-kit';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  components: {
    DTableCell,
    DTooltip,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props) {
    const { campaign } = toRefs(props);

    const status = computed(() => {
      const textMap = {
        active: 'Кампания идет',
        paused: 'Кампания на паузе',
        finished: 'Кампания завершена',
      };

      const iconsMap = {
        active: '$d1-play',
        paused: 'mdi-pause',
        finished: campaign.value.isActive ? 'mdi-check' : 'mdi-archive',
      };

      const text = textMap[campaign.value.state.code];
      const icon = iconsMap[campaign.value.state.code];

      return {
        text,
        icon,
      };
    });

    const path = computed(() => `/campaigns/${campaign.value.id}`);

    const onRootClick = () => {
      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Campaign List',
        event_action: 'Open Campaign',
        event_label: campaign.value.id,
        d1d_client_id: campaign.value.clientId,
        d1d_client_name: campaign.value.clientName,
        d1d_brand_id: campaign.value.brandId,
        d1d_brand_name: campaign.value.brandName,
        d1d_campaign_id: campaign.value.id,
        d1d_campaign_name: campaign.value.name,
      });
    };

    return {
      status,
      path,
      onRootClick,
    };
  },
});
