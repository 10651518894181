import { AppRouteConfig } from '@/models/AppRouter';

import Categories from '../views/Categories.vue';
import CategoriesCatalog from '../views/Categories/CategoriesCatalog.vue';
import CategoryNew from '../views/Categories/CategoryNew.vue';
import CategoryDetails from '../views/Categories/CategoryDetails.vue';

import CategoryDetailsLoader from '../components/Categories/CategoryDetails/CategoryDetailsLoader.vue';

import PageCatalogLoader from '../../../components/_newDesign/common/loaders/PageCatalogLoader.vue';

const routeConfig: AppRouteConfig = {
  path: 'categories',
  component: Categories,
  meta: {
    title: 'Редактор категорий',
    permission: 'admin',
    order: 3,
  },
  children: [
    {
      name: 'categories-catalog',
      path: '',
      component: CategoriesCatalog,
      meta: {
        title: 'Редактор категорий',
        loader: PageCatalogLoader,
        header: {
          sticky: true,
        },
      },
    },
    {
      name: 'category-new',
      path: 'new',
      component: CategoryNew,
      meta: {
        title: 'Новая категория',
        footer: {
          linksOnRight: true,
        },
      },
    },
    {
      name: 'category-details',
      path: ':categoryId',
      component: CategoryDetails,
      props: true,
      meta: {
        title: 'Редактирование категории',
        loader: CategoryDetailsLoader,
        footer: {
          linksOnRight: true,
        },
      },
    },
  ],
};

export default routeConfig;
