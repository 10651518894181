/* eslint-disable @typescript-eslint/ban-ts-comment */

export default (instances: Readonly<Readonly<{ id: string; name: string }>[]>): void => {
  if (process.env.NODE_ENV !== 'production') return;

  instances.forEach((instance) => {
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);

      // eslint-disable-next-line eqeqeq
      const dl = l != 'dataLayer' ? `&l=${l}` : '';

      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;

      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    }(window, document, 'script', instance.name, instance.id));
  });
};
