/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import {
  UsersApi,
} from '@/api';

import { UserLight } from '@/models/User';

import { SetUserPayload, UpdateUserPayload, SetExistingUsersPayload } from '@/api/modules/users.api';

export interface UsersCatalogFilters {
  query: string;
  page: number;
  limit: number;
}

const initFilters = () => ({
  page: 1,
  query: '',
  limit: 50,
});

class ModuleState {
  filters: UsersCatalogFilters = initFilters();

  filtersChanged = false;

  isLoading = false;

  users: UserLight[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get users() {
    return this.state.users;
  }

  get filters() {
    return this.state.filters;
  }

  get filtersChanged() {
    return this.state.filtersChanged;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_USERS(payload: UserLight[]) {
    this.state.users = payload;
  }

  ADD_USERS(payload: UserLight[]) {
    this.state.users = [...this.state.users, ...payload];
  }

  REMOVE_USER(userId: string) {
    this.state.users = this.state.users.filter((user) => user.id !== userId);
  }

  SET_FILTERS(payload: UsersCatalogFilters) {
    this.state.filters = payload;
  }

  SET_FILTERS_CHANGED(payload: boolean) {
    this.state.filtersChanged = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_USERS() {
    const { page, query, limit } = this.getters.filters;

    this.commit('SET_IS_LOADING', true);

    try {
      const res = await UsersApi.fetchUsers({ page, filter: query, limit });

      if (page === 1) {
        this.commit('SET_USERS', res.data.list);
      } else {
        this.commit('ADD_USERS', res.data.list);
      }
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async CREATE_USER(payload: SetUserPayload) {
    await UsersApi.setUser(payload);

    await this.dispatch('FETCH_USERS');
  }

  async UPDATE_USER(payload: UpdateUserPayload) {
    await UsersApi.updateUser(payload);

    await this.dispatch('FETCH_USERS');
  }

  async ADD_EXISTING_USERS(payload: SetExistingUsersPayload) {
    await UsersApi.setExistingUsers(payload);

    await this.dispatch('FETCH_USERS');
  }

  UPDATE_FILTERS(filters: Partial<UsersCatalogFilters>) {
    const page = filters.page || 1;

    const newFilters = {
      ...this.getters.filters,
      ...filters,
      page,
    };

    this.commit('SET_FILTERS', newFilters);
    this.commit('SET_FILTERS_CHANGED', true);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
    this.commit('SET_FILTERS_CHANGED', false);
  }

  async DELETE_USER(userId: string) {
    await UsersApi.deleteUser(userId);

    this.commit('REMOVE_USER', userId);
  }

  NEXT_PAGE() {
    this.dispatch('UPDATE_FILTERS', {
      page: this.getters.filters.page + 1,
    });
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useUsersCatalogStore = createComposable(module);

export default module;
