


























import { defineComponent, toRefs, ref, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import modally from '@/services/modally';

import { DataProvider } from '@/models/DataProvider';

import { useDataProvidersCatalogStore } from '../../../../store/dataProviders/dataProvidersCatalog';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    dataProvider: { type: Object as PropType<DataProvider>, required: true },
  },
  setup(props, context) {
    const {
      dataProvider,
    } = toRefs(props);

    const dataProvidersCatalogStore = useDataProvidersCatalogStore();

    const openItemModal = () => {
      context.emit('open-modal', {
        slug: 'item',
        props: {
          dataProviderId: dataProvider.value.id,
          title: dataProvider.value.name,
        },
      });
    };

    const deletion = ref(false);

    const deleteDataProvider = async () => {
      const result = await modally.show({
        title: 'Удалить провайдера?',
        content: `Провайдер ${dataProvider.value.name} будет удалён.`,
        buttons: [
          {
            props: {
              text: 'Удалить',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary',
            },
            action: () => false,
          },
        ],
      });

      if (!result) return;

      deletion.value = true;

      try {
        await dataProvidersCatalogStore.actions.DELETE_DATA_PROVIDER(dataProvider.value.id);
      } finally {
        deletion.value = false;
      }
    };

    return {
      openItemModal,
      deletion,
      deleteDataProvider,
    };
  },
});
