import { AxiosError } from 'axios';

import hasOwnProperty from '@/utils/hasOwnProperty';

import {
  AppModalData,
  AppErrorOptions,
  AppResponseMessagesCheckItem,
  AppNormalizeResponse,
} from './types';

const responseMessagesChecks: AppResponseMessagesCheckItem[] = [
  {
    check: (response) => response?.data && (typeof response.data === 'string' || Array.isArray(response.data)),
    getMessages: (response) => response?.data,
  },
  {
    check: (response) => response?.status === 400 && !!response?.data.detail,
    getMessages: (response) => response?.data.detail,
  },
  {
    check: (response) => response?.status === 400 && !response.data.errors,
    getMessages: (response) => response?.data.title,
  },
  {
    check: (response) => response?.status === 400 && !!response.data.errors,
    getMessages: (response) => Object.values(response?.data.errors)
      .reduce((acc: string[], errorMessages: unknown) => {
        if (Array.isArray(errorMessages)) {
          acc = [...acc, ...errorMessages];
        }

        return acc;
      }, []),
  },
];

const supportLink = '<a href="mailto:help@danbo.digital" target="_blank">help@danbo.digital</a>';

const normalizeBaseError = (error: Record<string, unknown>): AppModalData => {
  const title = error?.name && typeof error.name === 'string' ? error.name : 'Ошибка';

  const defaultMessage = `Произошла непредвиденная ошибка.\nОбратитесь в поддержку ${supportLink}.`;

  return {
    title,
    messages: [error?.message && typeof error.message === 'string' ? error.message : defaultMessage],
    status: undefined,
  };
};

const normalizeResponsedError = (error: Record<string, unknown>): AppModalData => {
  const errorResponse: AxiosError['response'] = hasOwnProperty(error, 'response') ? error.response as AxiosError['response'] : undefined;

  const errorStatus: number | undefined = errorResponse?.status;

  const title: string = errorResponse?.data.title ?? `Ошибка ${errorStatus ?? ''}`;

  const statusesTranslations: Record<string | number, string> = {
    400: 'Неверный запрос.',
    403: 'Отказанно в доступе.',
    404: 'Не найдено.',
    500: 'Произошла ошибка на стороне сервера.',
    default: 'Произошла непредвиденная ошибка.',
  };

  const statusTranslation = (errorStatus && statusesTranslations[errorStatus]) ?? statusesTranslations.default;

  const responseMessagesCheckItem: AppResponseMessagesCheckItem | undefined = responseMessagesChecks.find(({ check }) => check(errorResponse));
  const responseMessages: string | string[] = responseMessagesCheckItem ? responseMessagesCheckItem.getMessages(errorResponse) : '';

  const defaultMessage = `${statusTranslation}\nОбратитесь в поддержку ${supportLink}.`;
  const messages: string | string[] = responseMessages?.length ? responseMessages : defaultMessage;

  return {
    title,
    messages: Array.isArray(messages) ? messages : [messages],
    status: errorStatus,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const normalizeError = (payload: any, options?: AppErrorOptions): AppNormalizeResponse => {
  const payloadError = payload instanceof Error || !payload.error ? payload : payload.error;

  const modalData: AppModalData = payloadError?.response
    ? normalizeResponsedError(payloadError)
    : normalizeBaseError(payloadError);

  const defaultOptions = { showingModal: true, sendingSentry: false };
  const mergedOptions = { ...defaultOptions, ...payload.options, ...options };

  return {
    error: payloadError,
    data: payload.data,
    options: mergedOptions,
    modalData,
  };
};

export { normalizeError, normalizeResponsedError, normalizeBaseError };
