import { AppRouteConfig } from '@/models/AppRouter';

import Administration from '../views/Administration.vue';

import userActions from './userActions';

import dashboard from './dashboard';
import dataProviders from './dataProviders';
import dataProvidersSettings from './dataProvidersSettings';
import maintenance from './maintenance';
import categories from './categories';
import clientBrandSettings from './clientBrandSettings';

const routes: AppRouteConfig[] = [
  {
    path: '/administration',
    name: 'administration',
    component: Administration,
    meta: {
      group: 'admin',
      title: 'Ещё',
      icon: 'mdi-cogs',
      order: 2,
      permission: 'admin',
    },
    children: [
      {
        path: 'benchmarks',
        redirect: '/administration/dashboard',
      },
      dashboard,
      dataProviders,
      dataProvidersSettings,
      maintenance,
      categories,
      clientBrandSettings,
      userActions,
    ],
  },
];

export default routes;
