















import { defineComponent, computed } from '@vue/composition-api';

import { DSelect } from '@shared/druid-kit';

import store from '@/store';
import { useCampaignEditorCategoriesStore } from '@/store/campaignEditor/campaignEditorCategories';
import referenceStore from '@/store/reference';

const referenceStoreContext = referenceStore.context(store);

export default defineComponent({
  components: {
    DSelect,
  },
  setup() {
    const campaignEditorCategoriesStore = useCampaignEditorCategoriesStore();

    const campaignTypes = computed(() => referenceStoreContext.getters.campaignTypes);

    const campaignTypeId = computed({
      get() {
        return campaignEditorCategoriesStore.getters.currentData.typeId;
      },
      set(val: string) {
        campaignEditorCategoriesStore.dispatch('CHANGE_DATA', { typeId: val });
      },
    });

    return {
      campaignTypes,
      campaignTypeId,
    };
  },
});
