











































import { defineComponent, computed, PropType } from '@vue/composition-api';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { DataProvidersSettings } from '@/models/DataProvidersSettings';

import DataProvidersSettingsTableRow from './DataProvidersSettingsTableRow.vue';

import { useDataProvidersSettingsCatalogStore } from '../../../../store/dataProvidersSettings/dataProvidersSettingsCatalog';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
    DataProvidersSettingsTableRow,
  },
  props: {
    dataProvidersSettings: { type: Array as PropType<DataProvidersSettings[]>, default: () => [] },
  },
  setup() {
    const dataProvidersSettingsCatalogStore = useDataProvidersSettingsCatalogStore();

    const headers = ['', 'Название', 'Клиент', 'Площадка', 'Доступ', '', '']
      .map((text) => ({
        text,
        class: 'caption',
        sortable: false,
      }));

    const dataProvidersSettingsIsLoading = computed(() => dataProvidersSettingsCatalogStore.getters.isLoading);

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (isIntersecting) dataProvidersSettingsCatalogStore.actions.NEXT_PAGE();
    };

    return {
      headers,
      dataProvidersSettingsIsLoading,
      onIntersect,
    };
  },
});
