/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import userActionsCatalog from './userActions/userActionsCatalog';

const module = new Module({
  modules: {
    userActionsCatalog,
  },
});

export const useUserActionsStore = createComposable(module);

export default module;
