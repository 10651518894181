import ModuleInit from '@/models/ModuleInit';

import routes from './routes';

const moduleInit: ModuleInit = (ctx) => {
  ctx.store.dispatch('navigation/ADD_ROUTES', routes);
  ctx.router.addRoutes(routes);
};

export default moduleInit;
