import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import PluginInit from '@/models/PluginInit';

const pluginInit: PluginInit = () => {
  Vue.use(VueCompositionAPI);
};

export default pluginInit;
