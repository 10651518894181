











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    status: {
      type: String,
      default: '',
    },
  },
});
