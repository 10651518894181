import { ref, computed, Ref, ComputedRef } from '@vue/composition-api';

import { Campaign } from '@/models/Campaign';

import { useClientStore } from '@/store/client';

interface UseClientBrandCampaignListProps {
  brandId: Ref<string>;
}

interface UseClientBrandCampaignListReturn {
  activeCampaigns: ComputedRef<Campaign[]>;
  activeCampaignsIsFetched: Ref<boolean>;
  activeCampaignsIsLoading: Ref<boolean>;
  fetchActiveCampaigns: () => void;
  finishedCampaigns: ComputedRef<Campaign[]>;
  finishedCampaignsCount: ComputedRef<number>;
  finishedCampaignsIsFetched: Ref<boolean>;
  finishedCampaignsIsLoading: Ref<boolean>;
  fetchFinishedCampaigns: () => void;
  campaigns: ComputedRef<Campaign[]>;
}

export default (props: UseClientBrandCampaignListProps): UseClientBrandCampaignListReturn => {
  const { brandId } = props;

  const clientStore = useClientStore();

  const activeCampaigns = computed(() => clientStore.getters.getBrandActiveCampaigns(brandId.value));

  const activeCampaignsIsFetched = ref(false);
  const activeCampaignsIsLoading = ref(false);

  const fetchActiveCampaigns = async () => {
    if (activeCampaignsIsFetched.value || activeCampaignsIsLoading.value) return;

    activeCampaignsIsLoading.value = true;

    await clientStore.dispatch('FETCH_ACTIVE_CAMPAIGNS_BY_BRAND', brandId.value);

    activeCampaignsIsFetched.value = true;

    activeCampaignsIsLoading.value = false;
  };

  const finishedCampaigns = computed(() => clientStore.getters.getBrandFinishedCampaigns(brandId.value));
  const finishedCampaignsCount = computed(() => clientStore.getters.getBrandFinishedCampaignsCount(brandId.value));

  const finishedCampaignsIsFetched = ref(false);
  const finishedCampaignsIsLoading = ref(false);

  const fetchFinishedCampaigns = async () => {
    if (finishedCampaignsIsFetched.value || finishedCampaignsIsLoading.value) return;

    finishedCampaignsIsLoading.value = true;

    await clientStore.dispatch('FETCH_FINISHED_CAMPAIGNS_BY_BRAND', brandId.value);

    finishedCampaignsIsFetched.value = true;

    finishedCampaignsIsLoading.value = false;
  };

  const campaigns = computed(() => [
    ...activeCampaigns.value,
    ...finishedCampaigns.value,
  ]);

  return {
    activeCampaigns,
    activeCampaignsIsFetched,
    activeCampaignsIsLoading,
    fetchActiveCampaigns,
    finishedCampaigns,
    finishedCampaignsCount,
    finishedCampaignsIsFetched,
    finishedCampaignsIsLoading,
    fetchFinishedCampaigns,
    campaigns,
  };
};
