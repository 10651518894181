





























































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { DAutocomplete } from '@shared/druid-kit';

import { DataProvidersSettingsItem } from '@/models/DataProvidersSettings';

import { useDataProvidersSettingsDetailsStore } from '../../../store/dataProvidersSettings/dataProvidersSettingsDetails';

export default defineComponent({
  components: {
    DAutocomplete,
  },
  props: {
    value: { type: Object as PropType<DataProvidersSettingsItem | null>, default: null },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    existUsers: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
      loading,
    } = toRefs(props);

    const dataProvidersSettingsDetailsStore = useDataProvidersSettingsDetailsStore();

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);

    const isValid = ref(false);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updateForm = (payload: Partial<DataProvidersSettingsItem>) => {
      const newForm = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newForm);
    };

    // // // // //

    const nameRules = ref([
      (val: string) => !!val,
    ]);

    const nameIsLoading = computed(() => loading.value);
    const nameIsDisabled = computed(() => nameIsLoading.value);

    const currentName = computed({
      get() {
        return value.value?.name || '';
      },
      set(val: string) {
        updateForm({ name: val });
      },
    });

    // // // // //

    const connectorSettingsIsLoading = computed(() => loading.value);
    const connectorSettingsIsDisabled = computed(() => connectorSettingsIsLoading.value);

    const currentConnectorSettings = computed({
      get() {
        return value.value?.connectorSettings || '';
      },
      set(val: string) {
        updateForm({ connectorSettings: val });
      },
    });

    // // // // //

    const providers = computed(() => dataProvidersSettingsDetailsStore.getters.providers);
    const providersIsLoadingData = ref(false);

    const providersIsLoading = computed({
      get() {
        return providersIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        providersIsLoadingData.value = val;
      },
    });

    const providersIsDisabled = computed(() => !providers.value.length || providersIsLoading.value);

    const currentProviderId = computed({
      get() {
        return value.value?.dataProviderId || '';
      },
      set(val: string) {
        updateForm({ dataProviderId: val });
      },
    });

    const currentProvider = computed(() => providers.value.find(({ id }) => id === currentProviderId.value) ?? null);

    const fetchProviders = async () => {
      providersIsLoading.value = true;

      try {
        await dataProvidersSettingsDetailsStore.actions.FETCH_PROVIDERS();
      } finally {
        providersIsLoading.value = false;
      }
    };

    // // // // //

    const tokens = computed(() => dataProvidersSettingsDetailsStore.getters.tokens);
    const tokensIsLoadingData = ref(false);

    const tokensIsLoading = computed({
      get() {
        return tokensIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        tokensIsLoadingData.value = val;
      },
    });

    const tokensIsDisabled = computed(() => !tokens.value.length || tokensIsLoading.value || !currentProvider.value);

    const currentTokenId = computed({
      get() {
        return value.value?.tokenId || '';
      },
      set(val: string) {
        updateForm({ tokenId: val });
      },
    });

    const fetchTokens = async (providerId: string) => {
      tokensIsLoading.value = true;

      try {
        await dataProvidersSettingsDetailsStore.actions.FETCH_TOKENS(providerId);
      } finally {
        tokensIsLoading.value = false;
      }
    };

    // // // // //

    const statisticsCollectionDepths = computed(() => dataProvidersSettingsDetailsStore.getters.statisticsCollectionDepths);
    const statisticsCollectionDepthsIsLoadingData = ref(false);

    const statisticsCollectionDepthsIsLoading = computed({
      get() {
        return statisticsCollectionDepthsIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        statisticsCollectionDepthsIsLoadingData.value = val;
      },
    });

    const statisticsCollectionDepthsIsDisabled = computed(() => !statisticsCollectionDepths.value.length || statisticsCollectionDepthsIsLoading.value);

    const currentStatisticsCollectionDepth = computed({
      get() {
        return value.value?.statisticsCollectionDepth || '';
      },
      set(val: string) {
        updateForm({ statisticsCollectionDepth: val });
      },
    });

    const fetchStatisticsCollectionDepths = async (connectorId: string) => {
      statisticsCollectionDepthsIsLoadingData.value = true;

      try {
        await dataProvidersSettingsDetailsStore.actions.FETCH_STATISTICS_COLLECTION_OF_DEPTH(connectorId);
      } finally {
        statisticsCollectionDepthsIsLoadingData.value = false;
      }
    };

    // // // // // //

    const clients = computed(() => dataProvidersSettingsDetailsStore.getters.clients);
    const clientsIsLoadingData = ref(false);

    const clientsIsLoading = computed({
      get() {
        return clientsIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        clientsIsLoadingData.value = val;
      },
    });

    const clientsIsDisabled = computed(() => !clients.value.length || clientsIsLoading.value);

    const currentClientId = computed({
      get() {
        return value.value?.clientId || '';
      },
      set(val: string) {
        updateForm({ clientId: val });
      },
    });

    const currentClient = computed(() => clients.value.find(({ id }) => id === currentClientId.value) ?? null);

    const fetchClients = async () => {
      clientsIsLoadingData.value = true;

      try {
        await dataProvidersSettingsDetailsStore.actions.FETCH_CLIENTS();
      } finally {
        clientsIsLoadingData.value = false;
      }
    };

    // // // // // //

    const clientBrands = computed(() => currentClient.value?.brands ?? []);

    const currentClientBrandIds = computed({
      get() {
        return value.value?.brandIds || [];
      },
      set(val: string[]) {
        updateForm({ brandIds: val });
      },
    });

    // // // // // //

    watch(currentProviderId, async () => {
      if (!currentProvider.value) return;

      await Promise.all([
        fetchTokens(currentProvider.value.id),
        fetchStatisticsCollectionDepths(currentProvider.value.connectorId),
      ]);
    }, { immediate: true });

    const init = async () => {
      await Promise.all([
        fetchProviders(),
        fetchClients(),
      ]);
    };

    init();

    return {
      rootRef,
      isValid,
      resetValidation,
      //
      currentName,
      nameRules,
      nameIsLoading,
      nameIsDisabled,
      //
      currentConnectorSettings,
      connectorSettingsIsLoading,
      connectorSettingsIsDisabled,
      //
      currentProviderId,
      providersIsLoading,
      providersIsDisabled,
      providers,
      currentProvider,
      //
      currentTokenId,
      tokensIsLoading,
      tokensIsDisabled,
      tokens,
      //
      currentStatisticsCollectionDepth,
      statisticsCollectionDepthsIsLoading,
      statisticsCollectionDepthsIsDisabled,
      statisticsCollectionDepths,
      //
      currentClientId,
      clientsIsLoading,
      clientsIsDisabled,
      clients,
      //
      currentClientBrandIds,
      clientBrands,
      //
    };
  },
});
