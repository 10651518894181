import { AxiosResponse } from 'axios';

import { Role, RoleLight } from '@/models/Role';

import { PartialWithout } from '@/utils/type';

import httpClient from '../httpClient';

export type SetRolePayload = PartialWithout<Role, 'id'>;

export interface UpdateRolePayload {
  roleId: string;
  data: PartialWithout<Role, 'id'>;
}

export default class RolesApi {
  public static async fetchRoles(): Promise<AxiosResponse<RoleLight[]>> {
    const res = await httpClient.get<RoleLight[]>('/api/roles');

    return res;
  }

  public static async fetchRole(roleId: string): Promise<AxiosResponse<Role>> {
    const res = await httpClient.get<Role>(`/api/roles/${roleId}`);

    return res;
  }

  public static async setRole(payload: SetRolePayload): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/roles', payload);

    return res;
  }

  public static async updateRole(payload: UpdateRolePayload): Promise<AxiosResponse<''>> {
    const { roleId, data } = payload;

    const res = await httpClient.put<''>(`/api/roles/${roleId}`, data);

    return res;
  }

  public static async deleteRole(roleId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.delete<''>(`/api/roles/${roleId}`);

    return res;
  }
}
