












































































import { defineComponent, toRefs, ref, computed, watch, PropType } from '@vue/composition-api';

import BrandListItemTable from '@/components/_newDesign/Brands/BrandList/BrandListItemTable/BrandListItemTable.vue';
import BrandListItemTableShowArchiveRow from '@/components/_newDesign/Brands/BrandList/BrandListItemTable/rows/BrandListItemTableShowArchiveRow.vue';

import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

import { useClientBrandCampaignList } from '@/composables/client';

import { Brand } from '@/models/Brand';

export default defineComponent({
  components: {
    BrandListItemTable,
    BrandListItemTableShowArchiveRow,
    TableLoader,
  },
  props: {
    value: { type: Boolean, default: false },
    brand: { type: Object as PropType<Brand>, required: true },
  },
  setup(props, context) {
    const { value, brand } = toRefs(props);

    const isOpen = ref(false);

    const archiveIsOpen = ref(false);

    const brandId = computed(() => brand.value.id);
    const brandIsActive = computed(() => brand.value.active);

    const {
      activeCampaigns,
      activeCampaignsIsLoading,
      fetchActiveCampaigns,
      finishedCampaigns,
      finishedCampaignsCount,
      finishedCampaignsIsLoading,
      fetchFinishedCampaigns,
      campaigns,
    } = useClientBrandCampaignList({ brandId });

    const isLoading = computed(() => activeCampaignsIsLoading.value || finishedCampaignsIsLoading.value);

    const autoShowArchive = computed(() => !brandIsActive.value || finishedCampaignsCount.value < 2);

    const isShown = computed(() => {
      const loading = autoShowArchive.value ? isLoading.value : activeCampaignsIsLoading.value;

      return isOpen.value && !loading;
    });

    const visibleCampaigns = computed(() => {
      if (archiveIsOpen.value && !isLoading.value) return campaigns.value;

      return activeCampaigns.value;
    });

    watch(value, (val: boolean) => {
      if (isOpen.value === val) return;

      isOpen.value = val;
    }, { immediate: true });

    watch(isOpen, (val: boolean) => {
      if (value.value === val) return;

      context.emit('input', val);
    });

    watch(archiveIsOpen, (val: boolean) => {
      if (!val) return;

      fetchFinishedCampaigns();
    });

    const open = async () => {
      if (!isOpen.value) return;

      await fetchActiveCampaigns();

      if (autoShowArchive.value) {
        archiveIsOpen.value = true;
      }
    };

    const toggle = () => {
      if (isLoading.value) return;

      isOpen.value = !isOpen.value;

      open();
    };

    open();

    return {
      isOpen,
      isLoading,
      isShown,
      brandIsActive,
      archiveIsOpen,
      autoShowArchive,
      activeCampaignsIsLoading,
      finishedCampaignsIsLoading,
      finishedCampaigns,
      finishedCampaignsCount,
      fetchFinishedCampaigns,
      visibleCampaigns,
      toggle,
    };
  },
});
