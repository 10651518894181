import Clients from '@/views/Clients.vue';

import ClientCatalog from '@/views/Clients/ClientCatalog.vue';
import ClientDetails from '@/views/Clients/ClientDetails.vue';

import ClientDetailsLoader from '@/components/_newDesign/Clients/ClientDetailsLoader.vue';
import ClientCatalogLoader from '@/components/_newDesign/Clients/ClientCatalogLoader.vue';

import { AppRouteConfig } from '@/models/AppRouter';

const routeConfig: AppRouteConfig = {
  path: '/clients',
  component: Clients,
  children: [
    {
      name: 'client-catalog',
      path: '',
      component: ClientCatalog,
      meta: {
        title: 'Клиенты',
        loader: ClientCatalogLoader,
      },
    },
    {
      name: 'client-details',
      path: ':clientId',
      component: ClientDetails,
      meta: {
        title: 'Бренды и кампании',
        loader: ClientDetailsLoader,
      },
      props: true,
    },
  ],
};

export default routeConfig;
