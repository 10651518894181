import { AppRouteConfig } from '@/models/AppRouter';

import UserActions from '../views/UserActions.vue';
import UserActionsCatalog from '../views/UserActions/UserActionsCatalog.vue';
import PageCatalogLoader from '../../../components/_newDesign/common/loaders/PageCatalogLoader.vue';

const routeConfig: AppRouteConfig = {
  path: 'user-actions',
  component: UserActions,
  meta: {
    title: 'История действий',
    permission: 'admin',
    order: 5,
  },
  children: [
    {
      name: 'user-actions-catalog',
      path: '',
      component: UserActionsCatalog,
      meta: {
        title: 'История действий',
        loader: PageCatalogLoader,
        header: {
          sticky: true,
        },
      },
    },
  ],
};

export default routeConfig;
