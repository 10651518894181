/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import {
  UsersApi,
  GroupsApi,
} from '@/api';

import { UserLight } from '@/models/User';
import { Group } from '@/models/Group';

class ModuleState {
  isLoading = false;

  user: UserLight | null = null;

  groups: Group[] = [];

  groupsClientsBrands: UserLight['clients'] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get user() {
    return this.state.user;
  }

  get groups() {
    return this.state.groups;
  }

  get groupsClientsBrands() {
    return this.state.groupsClientsBrands;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_USER(payload: UserLight) {
    this.state.user = payload;
  }

  SET_GROUPS(payload: Group[]) {
    this.state.groups = payload;
  }

  SET_GROUPS_CLIENTS_BRANDS(payload: UserLight['clients']) {
    this.state.groupsClientsBrands = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_USER(userId: string) {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await UsersApi.fetchUser(userId);

      if (res.data.groups.length) {
        this.actions.FETCH_GROUPS_CLIENTS_BRANDS(res.data.groups);
      }

      this.mutations.SET_USER(res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async FETCH_GROUPS() {
    const res = await GroupsApi.fetchGroups({ limit: -1 });

    this.mutations.SET_GROUPS(res.data.list);
  }

  async FETCH_GROUPS_CLIENTS_BRANDS(payload: string[]) {
    const res = await GroupsApi.fetchClientsWithBrands(payload);

    this.mutations.SET_GROUPS_CLIENTS_BRANDS(res.data);
  }

  RESET_GROUPS_CLIENTS_BRANDS() {
    this.mutations.SET_GROUPS_CLIENTS_BRANDS([]);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useUsersDetailsStore = createComposable(module);

export default module;
