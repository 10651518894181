






























import { defineComponent, PropType, computed } from '@vue/composition-api';

import { useClientsStore } from '@/store/clients';

import ClientListItem from '@/components/_newDesign/Clients/ClientList/ClientListItem.vue';

import { Client } from '@/models/Client';

export default defineComponent({
  components: {
    ClientListItem,
  },
  props: {
    clients: { type: Array as PropType<Client[]>, default: () => [] },
  },
  setup() {
    const clientsStore = useClientsStore();

    const clientsIsLoading = computed(() => clientsStore.getters.isLoading);

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (isIntersecting) clientsStore.actions.NEXT_PAGE();
    };

    return {
      clientsIsLoading,
      onIntersect,
    };
  },
});
