import { AxiosResponse } from 'axios';

import { Category } from '@/models/Category';

import { PartialWithout } from '@/utils/type';

import httpClient from '../httpClient';

export type SetCategoryPayload = PartialWithout<Category, 'id'>;

export interface UpdateCategoryPayload {
  category: Category;
}

export default class CategoriesApi {
  public static async fetchCategories(): Promise<AxiosResponse<Category[]>> {
    const res = await httpClient.get<Category[]>('/api/categories');

    return res;
  }

  public static async fetchCategory(categoryId: string): Promise<AxiosResponse<Category>> {
    const res = await httpClient.get<Category>(`/api/categories/${categoryId}`);

    return res;
  }

  public static async fetchMetrics(): Promise<AxiosResponse<string[]>> {
    const res = await httpClient.get<string[]>('/api/categories/metrics-list');

    return res;
  }

  public static async setCategory(payload: SetCategoryPayload): Promise<AxiosResponse<string>> {
    const res = await httpClient.post<string>('/api/categories', payload);

    return res;
  }

  public static async updateCategory(payload: UpdateCategoryPayload): Promise<AxiosResponse<''>> {
    const res = await httpClient.put<''>('/api/categories', payload);

    return res;
  }

  public static async deleteCategory(categoryId: string): Promise<AxiosResponse<''>> {
    const res = await httpClient.delete<''>(`/api/categories/${categoryId}`);

    return res;
  }
}
