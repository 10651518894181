/* eslint-disable max-classes-per-file */
import Vue from 'vue';

import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, Context, createMapper, createComposable } from 'vuex-smart-module';

import { ClientsApi, CampaignsApi } from '@/api';

import clientsStore from '@/store/clients';

import { Client } from '@/models/Client';
import { Campaign } from '@/models/Campaign';

class ModuleState {
  client: Client | null = null;

  brandActiveCampaignsMap: Record<string, Campaign[]> = {};

  brandFinishedCampaignsMap: Record<string, Campaign[]> = {};

  brandFinishedCampaignsCountMap: Record<string, number> = {};
}

class ModuleGetters extends Getters<ModuleState> {
  clientsStoreContext!: Context<typeof clientsStore>;

  $init(store: Store<unknown>) {
    this.clientsStoreContext = clientsStore.context(store);
  }

  get client() {
    return this.state.client;
  }

  get getBrandActiveCampaigns() {
    return (brandId: string) => this.state.brandActiveCampaignsMap[brandId] || [];
  }

  get getBrandFinishedCampaigns() {
    return (brandId: string) => this.state.brandFinishedCampaignsMap[brandId] || [];
  }

  get getBrandFinishedCampaignsCount() {
    return (brandId: string) => this.state.brandFinishedCampaignsCountMap[brandId] || 0;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_CLIENT(payload: Client) {
    this.state.client = payload;
  }

  SET_BRAND_CAMPAIGNS(payload: { brandId: string; campaigns: Campaign[] }) {
    Vue.set(this.state.brandActiveCampaignsMap, payload.brandId, payload.campaigns);
  }

  SET_FINISHED_BRAND_CAMPAIGNS(payload: { brandId: string; campaigns: Campaign[] }) {
    const campaigns = payload.campaigns.map((campaign) => ({ ...campaign, isActive: false }));

    Vue.set(this.state.brandFinishedCampaignsMap, payload.brandId, campaigns);
  }

  SET_BRAND_FINISHED_CAMPAIGNS_COUNT(payload: { brandId: string; count: number }) {
    Vue.set(this.state.brandFinishedCampaignsCountMap, payload.brandId, payload.count);
  }

  CAMPAIGN_SET_APPROVED_STATUS(payload: { campaignId: string; value: boolean }) {
    [
      this.state.brandActiveCampaignsMap,
      this.state.brandFinishedCampaignsMap,
    ].forEach((campaignsMap) => {
      Object.values(campaignsMap).forEach((campaigns) => {
        const foundCampaign = campaigns.find((campaign) => campaign.id === payload.campaignId);

        if (!foundCampaign) return;

        foundCampaign.approved = payload.value;
      });
    });
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  clientsStoreContext!: Context<typeof clientsStore>;

  $init(store: Store<unknown>) {
    this.clientsStoreContext = clientsStore.context(store);
  }

  async FETCH_CLIENT(clientId: string) {
    const res = await ClientsApi.fetchClient(clientId);

    this.commit('SET_CLIENT', res.data);
  }

  async FETCH_ACTIVE_CAMPAIGNS_BY_BRAND(brandId: string) {
    const res = await CampaignsApi.fetchCampaignsByBrand({ brandId, params: { getFinished: false } });

    this.commit('SET_BRAND_CAMPAIGNS', {
      brandId,
      campaigns: res.data.campaigns,
    });

    this.commit('SET_BRAND_FINISHED_CAMPAIGNS_COUNT', {
      brandId,
      count: res.data.finishedCampaignsCount,
    });
  }

  async FETCH_FINISHED_CAMPAIGNS_BY_BRAND(brandId: string) {
    const res = await CampaignsApi.fetchCampaignsByBrand({ brandId, params: { getFinished: true } });

    this.commit('SET_FINISHED_BRAND_CAMPAIGNS', {
      brandId,
      campaigns: res.data.campaigns,
    });

    this.commit('SET_BRAND_FINISHED_CAMPAIGNS_COUNT', {
      brandId,
      count: res.data.finishedCampaignsCount,
    });
  }

  async CAMPAIGN_TOGGLE_APPROVED_STATUS(payload: { campaign: Campaign; value: boolean }) {
    const { campaign, value } = payload;

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Campaign',
      event_action: value ? 'Show to Client' : 'Hide from Client',
      event_label: campaign.id,
      d1d_client_id: campaign.clientId,
      d1d_client_name: campaign.clientName,
      d1d_brand_id: campaign.brandId,
      d1d_brand_name: campaign.brandName,
      d1d_campaign_id: campaign.id,
      d1d_campaign_name: campaign.name,
    });

    await CampaignsApi.updateCampaignApproved({ campaignId: campaign.id, data: value });

    this.commit('CAMPAIGN_SET_APPROVED_STATUS', { campaignId: campaign.id, value });
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const clientStoreMapper = createMapper(module);

export const useClientStore = createComposable(module);

export default module;
