



























































import { defineComponent, computed, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';
import useModals from '@/composables/useModals';

import GroupsCatalogHeader from '../../components/Groups/GroupsCatalog/GroupsCatalogHeader.vue';
import GroupsTable from '../../components/Groups/GroupsCatalog/GroupsTable/GroupsTable.vue';

import GroupsAddModal from '../../components/Groups/modals/GroupsAddModal.vue';
import GroupsItemModal from '../../components/Groups/modals/GroupsItemModal.vue';

import { useGroupsCatalogStore } from '../../store/groups/groupsCatalog';

export default defineComponent({
  components: {
    AppView,
    GroupsCatalogHeader,
    GroupsTable,
    GroupsAddModal,
    GroupsItemModal,
  },
  setup() {
    const { updateIsLoading } = useLayoutLoadingStatus();

    const {
      modalsMap,
      openModal,
    } = useModals(['add', 'item']);

    const groupsCatalogStore = useGroupsCatalogStore();

    const groups = computed(() => groupsCatalogStore.getters.groups);
    const groupsFilters = computed(() => groupsCatalogStore.getters.filters);
    const groupsFiltersChanged = computed(() => groupsCatalogStore.getters.filtersChanged);
    const groupsIsLoading = computed(() => groupsCatalogStore.getters.isLoading);

    const fetch = async () => {
      await groupsCatalogStore.actions.FETCH_GROUPS();
    };

    const init = async () => {
      updateIsLoading(true);
      groupsCatalogStore.actions.RESET_FILTERS();

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    watch(groupsFilters, () => {
      if (!groupsFiltersChanged.value) return;

      fetch();
    });

    init();

    return {
      modalsMap,
      groups,
      groupsFiltersChanged,
      groupsIsLoading,
      openModal,
    };
  },
});
