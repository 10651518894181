













































import { defineComponent, ref, computed, onMounted, nextTick, PropType, ComponentInstance, watch } from '@vue/composition-api';

import { debounce, isEqual } from 'lodash';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { MarkupLight } from '@/models/Markup';

import MarkupsTableRow from './MarkupsTableRow.vue';

import { useMarkupsStore, MarkupsSortFilters } from '../../../store';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
    MarkupsTableRow,
  },
  props: {
    markups: { type: Array as PropType<MarkupLight[]>, default: () => [] },
  },
  setup() {
    const markupStore = useMarkupsStore();

    const headers: {
      class: string;
      text: string;
      value: MarkupsSortFilters['by'];
    }[] = [
      {
        class: 'caption',
        text: 'Размещение',
        value: 'placementName',
      },
      {
        class: 'caption',
        text: 'Клиент',
        value: 'clientName',
      },
      {
        class: 'caption',
        text: 'Бренд',
        value: 'brandName',
      },
      {
        class: 'caption',
        text: 'Кампания',
        value: 'campaignName',
      },
      {
        class: 'caption',
        text: 'Площадка',
        value: 'dataProviderName',
      },
    ];

    const rootRef = ref<ComponentInstance | null>(null);
    const rootEl = ref<HTMLElement | null>(null);

    const markupsIsLoading = computed(() => markupStore.getters.isLoading);
    const pageIsFirst = computed(() => markupStore.getters.pageIsFirst);
    const pageIsLast = computed(() => markupStore.getters.pageIsLast);

    // TODO: это точно надо переписать
    // Проблема в том, что при сбросе страницы скролл враппера не изменяется и он пытается проскролиться вниз вызывая загрузку следующих страниц
    watch(markupsIsLoading, (val) => {
      if (val || !pageIsFirst.value || !rootEl.value) return;

      const wrapperEl = rootEl.value.querySelector('.v-data-table__wrapper');

      if (!wrapperEl) return;

      wrapperEl.scrollTop = 0;
    });

    onMounted(async () => {
      await nextTick();

      if (!rootRef.value) return;

      rootEl.value = rootRef.value.$el as HTMLElement;
    });

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (pageIsLast.value || markupsIsLoading.value || !isIntersecting) return;

      markupStore.dispatch('TO_NEXT_PAGE');
    };

    const options = ref<{
      sortBy: MarkupsSortFilters['by'][];
      sortDesc: boolean[];
    }>({
      sortBy: markupStore.getters.sortFilters.by ? [markupStore.getters.sortFilters.by] : [],
      sortDesc: markupStore.getters.sortFilters.desc ? [markupStore.getters.sortFilters.desc] : [],
    });

    watch(options, debounce((newVal, oldVal) => {
      if (isEqual(newVal.sortBy, oldVal.sortBy) && isEqual(newVal.sortDesc, oldVal.sortDesc)) return;

      markupStore.dispatch('UPDATE_SORT_FILTERS', {
        by: newVal.sortBy[0],
        desc: newVal.sortDesc[0],
      });
    }, 300));

    const onUpdateOptions = (payload: { sortBy: MarkupsSortFilters['by'][]; sortDesc: boolean[] }) => {
      options.value = payload;
    };

    return {
      headers,
      rootRef,
      markupsIsLoading,
      options,
      onIntersect,
      onUpdateOptions,
    };
  },
});
