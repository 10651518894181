
























































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { Group } from '@/models/Group';
import { UserClientsBrands } from '@/models/User';

import AppClientsTreeView from '@/components/_newDesign/common/inputs/AppClientsTreeView.vue';
import AppUsersAutocomplete from '@/components/_newDesign/common/inputs/AppUsersAutocomplete.vue';

export default defineComponent({
  components: {
    AppClientsTreeView,
    AppUsersAutocomplete,
  },
  props: {
    value: { type: Object as PropType<Group | null>, default: null },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    existUsers: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
      loading,
    } = toRefs(props);

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);

    const isValid = ref(false);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updateForm = (payload: Partial<Group>) => {
      const newForm = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newForm);
    };

    // // // // //

    const nameRules = ref([
      (val: string) => !!val,
    ]);

    const nameIsLoading = computed(() => loading.value);
    const nameIsDisabled = computed(() => nameIsLoading.value);

    const currentName = computed({
      get() {
        return value.value?.name || '';
      },
      set(val: string) {
        updateForm({ name: val });
      },
    });

    // // // // //

    const descriptionIsLoading = computed(() => loading.value);
    const descriptionIsDisabled = computed(() => descriptionIsLoading.value);

    const currentDescription = computed({
      get() {
        return value.value?.description || '';
      },
      set(val: string) {
        updateForm({ description: val });
      },
    });

    // // // // //

    const currentUsers = computed({
      get() {
        return value.value?.users || [];
      },
      set(val: string[]) {
        updateForm({ users: val });
      },
    });

    // // // // //

    const currentClients = computed({
      get() {
        return value.value?.clients || [];
      },
      set(val: UserClientsBrands[]) {
        updateForm({ clients: val });
      },
    });

    return {
      rootRef,
      isValid,
      resetValidation,
      //
      currentName,
      nameRules,
      nameIsLoading,
      nameIsDisabled,
      //
      currentDescription,
      descriptionIsLoading,
      descriptionIsDisabled,
      //
      currentUsers,
      //
      currentClients,
      //
    };
  },
});
