





















































import { defineComponent, PropType, toRefs, computed, ref } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
  DTooltip,
} from '@shared/druid-kit';

import modally from '@/services/modally';

import { ProviderSettings } from '@/models/ProviderSettings';

import { useProviderSettingsStore } from '../../store/index';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
    DTooltip,
  },
  props: {
    item: { type: Object as PropType<ProviderSettings>, required: true },
  },
  setup(props) {
    const providerSettingsStore = useProviderSettingsStore();

    const { item } = toRefs(props);

    const tooltipIsShown = ref(false);

    const statusIcon = computed(() => {
      const icons = {
        finishedWithError: {
          icon: 'mdi-close-circle',
          color: 'error',
          tooltipText: 'Завершена с ошибкой',
        },
        available: {
          icon: 'mdi-checkbox-marked-circle',
          color: 'success',
          tooltipText: 'Доступная настройка',
        },
        inProgress: {
          icon: 'mdi-timer-sand',
          color: 'grey',
          tooltipText: 'В процессе создания',
        },
        default: {
          icon: 'mdi-help-circle-outline',
          color: 'grey',
          tooltipText: '',
        },
      };

      const iconKey = Object.keys(icons).find((key) => !!item.value[key]) ?? 'default';

      return icons[iconKey];
    });

    const editSetting = () => {
      providerSettingsStore.dispatch('EDIT_PROVIDER_SETTINGS', item.value.id);
    };

    const deleteSetting = async () => {
      const result = await modally.show({
        type: 'confirm',
        status: 'warning',
        title: item.value.name,
        content: 'Вы действительно хотите удалить настройки площадки?',
      });

      if (!result) return;

      providerSettingsStore.dispatch('DELETE_PROVIDER_SETTINGS', item.value);
    };

    return {
      tooltipIsShown,
      statusIcon,
      editSetting,
      deleteSetting,
    };
  },
});
