









































import { defineComponent, PropType } from '@vue/composition-api';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { Campaign } from '@/models/Campaign';
import { Goal } from '@/models/Goal';

import { useCampaignEditorGoalsStore } from '@/store/campaignEditor/campaignEditorGoals';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
  },
  props: {
    goals: { type: Array as PropType<Campaign[]>, required: true },
  },
  setup() {
    const campaignEditorGoalsStore = useCampaignEditorGoalsStore();

    const headers = [
      {
        class: 'caption',
        text: 'Главная',
        value: 'mainGoal',
        width: 80,
      },
      {
        class: 'caption',
        text: 'Название',
        value: 'name',
        width: 270,
      },
      {
        class: 'caption',
        text: 'Площадка',
        value: 'providerName',
        width: 210,
      },
      {
        class: 'caption',
        text: 'Доступ',
        value: 'providerSettingsName',
        width: 300,
      },
      {
        class: 'caption',
        text: '',
        sortable: false,
      },
    ];

    const getGoalHasBeenChanged = (goal: Goal) => campaignEditorGoalsStore.getters.changedGoals.some(({ id, providerId, providerSettingsId }) => (
      id === goal.id
      && providerId === goal.providerId
      && providerSettingsId === goal.providerSettingsId
    ));

    const deleteGoal = (goal: Goal) => {
      campaignEditorGoalsStore.dispatch('DELETE_GOAL', goal);
    };

    return {
      headers,
      getGoalHasBeenChanged,
      deleteGoal,
    };
  },
});
