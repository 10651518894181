<template>
  <v-img
    v-if="img"
    class="user-icon rounded-circle"
    :src="img"
  />
  <svg
    v-else
    class="user-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <circle
      cx="50%"
      cy="50%"
      r="11"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
    />
    <text
      class="user-icon__text"
      x="50%"
      y="50%"
      dy=".36em"
      text-anchor="middle"
      font-weight="bold"
      v-text="text"
    />
  </svg>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

import store from '@/store';
import authStore from '@/store/auth';

const authStoreContext = authStore.context(store);

export default defineComponent({
  setup() {
    const img = computed(() => authStoreContext.getters.userImage);
    const text = computed(() => authStoreContext.getters.userInitials);

    return {
      img,
      text,
    };
  },
});
</script>

<style lang="scss" scoped>
.user-icon {
  &__text {
    font-size: 10px;
    line-height: 16px;
  }
}
</style>
