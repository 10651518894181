




































import { defineComponent, computed, PropType } from '@vue/composition-api';

import CampaignMetrics from '@/components/_newDesign/Campaigns/CampaignMetrics/CampaignMetrics.vue';
import CampaignChart from '@/components/_newDesign/Campaigns/CampaignChart.vue';
import CampaignCategoriesTable from '@/components/_newDesign/Campaigns/CampaignCategoriesTable/CampaignCategoriesTable.vue';

import CampaignDateFilterBtn from '@/components/_newDesign/Campaigns/buttons/CampaignDateFilterBtn.vue';
import CampaignDownloadReportBtn from '@/components/_newDesign/Campaigns/buttons/CampaignDownloadReportBtn.vue';

import { Campaign, CampaignCategory } from '@/models/Campaign';

import { useCampaignStore } from '@/store/campaign';

export default defineComponent({
  components: {
    CampaignMetrics,
    CampaignChart,
    CampaignCategoriesTable,
    CampaignDateFilterBtn,
    CampaignDownloadReportBtn,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    campaignCategories: { type: Array as PropType<CampaignCategory[]>, default: () => [] },
  },
  setup() {
    const campaignStore = useCampaignStore();

    const campaignLastUpdateDate = computed(() => campaignStore.getters.campaignLastUpdateDate);

    return {
      campaignLastUpdateDate,
    };
  },
});
