




































import { defineComponent, computed } from '@vue/composition-api';

import { debounce } from 'lodash';

import { DBtnInline } from '@shared/druid-kit';

import { usePermissionsCatalogStore } from '../../../store/permissions/permissionsCatalog';

export default defineComponent({
  components: {
    DBtnInline,
  },
  setup(props, context) {
    const permissionsCatalogStore = usePermissionsCatalogStore();

    const query = computed({
      get() {
        return permissionsCatalogStore.getters.filters.query;
      },
      set: debounce((val: string) => {
        permissionsCatalogStore.dispatch('UPDATE_FILTERS', { query: val });
      }, 300),
    });

    const openAddModal = () => {
      context.emit('open-modal', 'add');
    };

    return {
      query,
      openAddModal,
    };
  },
});
