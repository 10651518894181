











import Vue from 'vue';
import { defineComponent, ref, shallowRef, computed, watch } from '@vue/composition-api';

import {
  Chart,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import DashboardChartInfoTooltip from './tooltips/DashboardChartInfoTooltip.vue';

import DashboardColors from '../../../services/dashboardColors';

import { useDashboardStore } from '../../../store/dashboard';

Chart.register({
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  TimeScale,
  Tooltip,
  ChartDataLabels,
});

// TODO: refactor
export default defineComponent({
  setup() {
    const dashboardStore = useDashboardStore();

    const chartCanvasEl = ref<HTMLCanvasElement | null>(null);
    const chartInstance = shallowRef<Chart | null>(null);

    const statistics = computed(() => dashboardStore.getters.statistics);
    const groups = computed(() => dashboardStore.getters.groups);
    const chartData = computed(() => dashboardStore.getters.chartData);
    const currentMetric = computed(() => dashboardStore.getters.metric);

    const getChartDataColors = () => {
      const colors = DashboardColors.getColorGroups(groups.value) as string[];

      const idxByGroupMap = groups.value.reduce<Record<string, number>>((acc, group, idx) => {
        acc[group] = idx;

        return acc;
      }, {});

      return chartData.value.map((item) => colors[idxByGroupMap[item.group]]);
    };

    const createChart = () => {
      const chartCanvasElContext = chartCanvasEl.value?.getContext('2d');

      if (!chartCanvasElContext) return;

      const labels = chartData.value.map((item) => item.index);

      const datasets = [
        {
          data: chartData.value.map((item) => parseFloat(item.metric)),
          backgroundColor: getChartDataColors(),
        },
      ];

      chartInstance.value = new Chart(chartCanvasElContext, {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 20,
              bottom: 0,
            },
          },
          scales: {
            x: {
              ticks: {
                display: false,
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            y: {
              suggestedMin: 0,
              grid: {
                display: false,
                drawBorder: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
              anchor: 'end',
              align: 'start',
              offset: -20,
            },
            tooltip: {
              enabled: false,
              external({ tooltip }) {
                if (!chartInstance.value) return;

                let tooltipEl = document.getElementById('dashboard-chart__tooltip');

                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');

                  tooltipEl.id = 'dashboard-chart__tooltip';

                  chartInstance.value.canvas.parentNode?.appendChild(tooltipEl);
                }

                if (tooltip.opacity === 0) {
                  tooltipEl.style.opacity = '0';

                  return;
                }

                tooltipEl.className = '';

                if (tooltip.yAlign) {
                  tooltipEl.classList.add(tooltip.yAlign);
                }

                if (tooltip.xAlign) {
                  tooltipEl.classList.add(tooltip.xAlign);
                }

                if (tooltip.body) {
                  const statisticsDataIndex = (tooltip.title || [])[0];
                  const statisticsData = statistics.value.find((item) => item.index === parseInt(statisticsDataIndex, 10));

                  tooltipEl.innerHTML = '';

                  const TooltipComponent = Vue.extend(DashboardChartInfoTooltip);

                  const TooltipComponentInstance = new TooltipComponent({
                    propsData: {
                      metrics: statisticsData,
                    },
                  });

                  TooltipComponentInstance.$mount();

                  tooltipEl.appendChild(TooltipComponentInstance.$el);
                }

                const positionY = chartInstance.value.canvas.offsetTop;
                const positionX = chartInstance.value.canvas.offsetLeft;

                let left = positionX + tooltip.caretX;

                switch (tooltip.xAlign.toLowerCase()) {
                  case 'left':
                    left = positionX + tooltip.caretX;

                    break;
                  case 'right':
                    left = positionX + tooltip.caretX - tooltipEl.offsetWidth;

                    break;
                  case 'center':
                    left = positionX + tooltip.caretX - tooltipEl.offsetWidth / 2;

                    break;
                    // no default
                }

                let top = positionY + tooltip.caretY;

                switch (tooltip.yAlign.toLowerCase()) {
                  case 'top':
                    top = positionY + tooltip.caretY;

                    break;
                  case 'bottom':
                    top = positionY + tooltip.caretY - tooltipEl.offsetHeight;

                    break;
                  case 'center':
                    top = positionY + tooltip.caretY - tooltipEl.offsetHeight / 2;

                    break;
                    // no default
                }

                tooltipEl.style.opacity = '1';
                tooltipEl.style.left = `${left}px`;
                tooltipEl.style.top = `${top}px`;
              },
            },
          },
        },
      });
    };

    watch(chartData, () => {
      if (!chartInstance.value) return createChart();

      chartInstance.value.data.datasets[0].data.length = 0;

      chartInstance.value.data.datasets[0] = {
        data: chartData.value.map((item) => parseFloat(item.metric)),
        backgroundColor: getChartDataColors(),
      };

      chartInstance.value.data.labels = chartData.value.map((item) => item.index);

      chartInstance.value.update();
    });

    return {
      currentMetric,
      chartCanvasEl,
    };
  },
});
