


































































import { defineComponent, toRefs, ref, computed, watch, ComponentInstance } from '@vue/composition-api';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';
import AppJsonError from '@/services/errorHandler/AppJsonError';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { Permission } from '@/models/Permission';

import PermissionsItemForm from '../forms/PermissionsItemForm.vue';

import { usePermissionsCatalogStore } from '../../../store/permissions/permissionsCatalog';

export default defineComponent({
  components: {
    AppModal,
    PermissionsItemForm,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const permissionsCatalogStore = usePermissionsCatalogStore();

    const permissionFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const permissionFormData = ref<Permission | null>(null);
    const permissionFormIsValid = ref(false);

    const permissionFormResetValidation = async () => {
      if (!permissionFormRef.value) return;

      permissionFormRef.value.resetValidation();
    };

    const newPermission = computed(() => {
      if (!permissionFormData.value || !permissionFormIsValid.value) return null;

      return permissionFormData.value;
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => !newPermission.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!newPermission.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await permissionsCatalogStore.actions.CREATE_PERMISSION(newPermission.value);

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const messages = Object.values(error.response?.data || {}).reduce<string[]>((acc, val) => {
            if (Array.isArray(val)) {
              acc.push(...val);
            } else if (typeof val === 'string') {
              acc.push(val);
            }

            return acc;
          }, []);

          saveError.value = {
            title: 'Произошла ошибка',
            messages: messages.length ? messages : ['Обратитесь к администратору'],
          };
        }

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        saving.value = false;
      }
    };

    watch(value, async (val) => {
      if (!val) return;

      permissionFormData.value = null;

      permissionFormResetValidation();
    }, { immediate: true });

    return {
      permissionFormRef,
      permissionFormData,
      permissionFormIsValid,
      saving,
      saveBtnIsDisabled,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
