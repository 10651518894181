
























































































import { defineComponent, computed } from '@vue/composition-api';

import moment from 'moment';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import numatter from '@/packages/numatter';

import { useMetricsCheckStore } from '../store';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
  },
  setup() {
    const metricsCheckStore = useMetricsCheckStore();

    const total = computed(() => metricsCheckStore.getters.total);
    const totalByDataProvider = computed(() => metricsCheckStore.getters.totalByDataProvider);
    const rawTotalByDataProvider = computed(() => metricsCheckStore.getters.rawTotalByDataProvider);

    const metricsNames = computed(() => metricsCheckStore.getters.metricsNames);
    const dataProvidersNames = computed(() => metricsCheckStore.getters.dataProvidersNames);
    const markupByDataProvider = computed(() => metricsCheckStore.getters.markupByDataProvider);

    const providerHeaders = dataProvidersNames.value
      .reduce((acc: string[], name) => [...acc, `${name} (расчёт)`, name], []);

    const headers = ['', 'Размещение', ...providerHeaders]
      .map((text) => ({ text, class: 'caption', sortable: false }));

    const formatDate = (date: string) => {
      if (!date) return 'бессрочно';

      return moment(date).format('DD.MM.YYYY');
    };

    const formatPeriod = (from: string, to: string) => {
      if (!from && !to) return '—';

      return `${formatDate(from)} - ${formatDate(to)}`;
    };

    const formatMetric = (num: number) => {
      if (!num) return '—';

      return numatter.format(num, '0,0[.]00');
    };

    return {
      total,
      totalByDataProvider,
      rawTotalByDataProvider,
      markupByDataProvider,
      metricsNames,
      headers,
      dataProvidersNames,
      formatDate,
      formatPeriod,
      formatMetric,
    };
  },
});
