import { AppRouteConfig } from '@/models/AppRouter';

import Permissions from '../views/Permissions.vue';
import PermissionsCatalog from '../views/Permissions/PermissionsCatalog.vue';
import PageCatalogLoader from '../../../components/_newDesign/common/loaders/PageCatalogLoader.vue';

const routeConfig: AppRouteConfig = {
  path: 'permissions',
  component: Permissions,
  meta: {
    title: 'Разрешения',
    permission: 'admin',
    order: 3,
  },
  children: [
    {
      name: 'permissions-catalog',
      path: '',
      component: PermissionsCatalog,
      meta: {
        title: 'Разрешения',
        loader: PageCatalogLoader,
        header: {
          sticky: true,
        },
      },
    },
  ],
};

export default routeConfig;
