



















































































import { computed, defineComponent, PropType, toRefs, ref, watch } from '@vue/composition-api';

import moment from 'moment';

import { DBtnInline } from '@shared/druid-kit';

import { CampaignEditorPlacement } from '@/models/Campaign';
import { DataProvider } from '@/models/DataProvider';

import useModals from '@/composables/useModals';

import { useCampaignStore } from '@/store/campaign';
import { useCampaignEditorPlacementsStore } from '@/store/campaignEditor/campaignEditorPlacements';
import { useCampaignEditorPlacementDetailsStore } from '@/store/campaignEditor/campaignEditorPlacementDetails';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import campaignEditorPlacementsEmitter from '../campaignEditorPlacementsEmitter';

import PlacementsMarkup from '../CampaignPlacementsMarkup/PlacementsMarkup.vue';
import CampaignPlacementsCreateMarkupModal from './CampaignPlacementsCreateMarkupModal.vue';

export default defineComponent({
  components: {
    AppModal,
    DBtnInline,
    PlacementsMarkup,
    CampaignPlacementsCreateMarkupModal,
  },
  props: {
    value: { type: Boolean, default: false },
    placement: { type: Object as PropType<CampaignEditorPlacement | null>, default: null },
  },
  setup(props, context) {
    const { modalsMap, openModal } = useModals(['create']);

    const campaignStore = useCampaignStore();
    const campaignEditorPlacementsStore = useCampaignEditorPlacementsStore();
    const campaignEditorPlacementDetailsStore = useCampaignEditorPlacementDetailsStore();

    const { value, placement } = toRefs(props);

    const connectorsMenuIsOpen = ref(false);
    const isLoading = ref(false);

    const currentDate = ref(moment().format('YYYY-MM-DD'));
    const currentMultiplier = ref(1);

    const campaign = computed(() => campaignStore.getters.campaign);
    const dataProviders = computed(() => campaignEditorPlacementsStore.getters.dataProviders);
    const placementMarkups = computed(() => campaignEditorPlacementDetailsStore.getters.placementMarkups);
    const placementMarkupsIsChanged = computed(() => campaignEditorPlacementDetailsStore.getters.placementMarkupsIsChanged);

    const filteredDataProviders = computed(() => {
      const selectedDataProvidersIds = placementMarkups.value.map(({ dataProviderId }) => dataProviderId);

      return dataProviders.value.filter(({ id }) => !selectedDataProvidersIds.includes(id));
    });

    const title = computed(() => `Наценки размещения ${placement.value?.siteName || ''} (${placement.value?.danboId || ''})`);

    const openCreateModal = (dataProvider: DataProvider) => {
      if (!campaign.value || !placement.value) return;

      const newMarkup = {
        clientId: campaign.value.clientId,
        brandId: campaign.value.brandId,
        campaignId: campaign.value.id,
        placementId: placement.value.id,
        dataProviderId: dataProvider.id,
        dataProviderName: dataProvider.name,
        startDate: moment.utc(currentDate.value).toISOString(),
        value: currentMultiplier.value,
      };

      openModal({ slug: 'create', props: { markup: newMarkup } });
    };

    const close = () => {
      context.emit('input', false);
    };

    const fetch = async () => {
      if (!placement.value) return;

      isLoading.value = true;

      try {
        await campaignEditorPlacementDetailsStore.dispatch('FETCH_MARKUPS', placement.value.id);
      } finally {
        isLoading.value = false;
      }
    };

    watch(value, async (val) => {
      if (!val) {
        campaignEditorPlacementsEmitter.emit('closeMarkupsModal', {
          markupsIsChanged: placementMarkupsIsChanged.value,
        });

        campaignEditorPlacementDetailsStore.dispatch('RESET_MARKUPS');

        return;
      }

      fetch();
    });

    return {
      modalsMap,
      placementMarkups,
      connectorsMenuIsOpen,
      isLoading,
      filteredDataProviders,
      title,
      openCreateModal,
      close,
    };
  },
});
