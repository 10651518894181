



















import { defineComponent, computed, PropType } from '@vue/composition-api';

import { DTable } from '@shared/druid-kit';

import { RoleLight } from '@/models/Role';

import RolesTableRow from './RolesTableRow.vue';

import { useRolesCatalogStore } from '../../../../store/roles/rolesCatalog';

export default defineComponent({
  components: {
    DTable,
    RolesTableRow,
  },
  props: {
    roles: { type: Array as PropType<RoleLight[]>, default: () => [] },
  },
  setup() {
    const rolesCatalogStore = useRolesCatalogStore();

    const headers: {
      class: string;
      text: string;
      sortable: boolean;
    }[] = [
      {
        class: 'caption',
        text: 'Название',
        sortable: false,
      },
      {
        class: 'caption',
        text: 'Описание',
        sortable: false,
      },
      {
        class: 'caption',
        text: '',
        sortable: false,
      },
    ];

    const rolesIsLoading = computed(() => rolesCatalogStore.getters.isLoading);

    return {
      headers,
      rolesIsLoading,
    };
  },
});
