






























































import { defineComponent, PropType } from '@vue/composition-api';

import { DTable } from '@shared/druid-kit';

import MetricsTableRow from '@/components/_newDesign/common/MetricsTable/MetricsTableRow.vue';

export default defineComponent({
  components: {
    DTable,
    MetricsTableRow,
  },
  props: {
    items: { type: Array as PropType<unknown[]>, default: () => [] },
    metricCells: { type: Array as PropType<unknown[]>, default: () => [] },
  },
});
