/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import dataProvidersCatalog from './dataProviders/dataProvidersCatalog';
import dataProvidersDetails from './dataProviders/dataProvidersDetails';

const module = new Module({
  modules: {
    dataProvidersCatalog,
    dataProvidersDetails,
  },
});

export const useDataProvidersStore = createComposable(module);

export default module;
