













































import { defineComponent, PropType, toRefs, ref } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignMainSettings from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorSettings/CampaignMainSettings/CampaignMainSettings.vue';
import CampaignConversionRate from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorSettings/CampaignConversionRate/CampaignConversionRate.vue';

import CampaignEditorSettingsLoader from '@/components/_newDesign/Campaigns/CampaignEditor/loaders/CampaignEditorSettingsLoader.vue';

import { Campaign } from '@/models/Campaign';

import { useCampaignEditorSettingsStore } from '@/store/campaignEditor/campaignEditorSettings';

export default defineComponent({
  components: {
    DBtnInline,
    AppView,
    CampaignMainSettings,
    CampaignConversionRate,
    CampaignEditorSettingsLoader,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props) {
    const {
      campaign,
    } = toRefs(props);

    const campaignEditorSettingsStore = useCampaignEditorSettingsStore();

    const isLoading = ref(false);

    const fetch = async () => {
      isLoading.value = true;

      try {
        await Promise.all([
          campaignEditorSettingsStore.dispatch('FETCH_SETTINGS', { campaignId: campaign.value.id }),
          campaignEditorSettingsStore.dispatch('FETCH_CLIENT_BRANDS', { clientId: campaign.value.clientId }),
        ]);
      } finally {
        isLoading.value = false;
      }
    };

    const resetManualSettings = async () => {
      isLoading.value = true;

      try {
        await campaignEditorSettingsStore.dispatch('RESET_MANUAL_SETTINGS', { campaignId: campaign.value.id });
      } finally {
        isLoading.value = false;
      }
    };

    fetch();

    return {
      isLoading,
      resetManualSettings,
    };
  },
});
