































import { defineComponent, computed, ref } from '@vue/composition-api';

import { DBreadcrumbs } from '@shared/druid-kit';

import useMeta from '@/composables/meta/useMeta';
import { useLayoutLoadingStatus } from '@/composables/layout';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { TokenProvider } from '@/models/Token';

import { useProviderTokensStore } from '../store';

import ProviderTokensCustomModal from '../components/ProviderTokensNew/ProviderTokensCustomModal.vue';

export default defineComponent({
  components: {
    DBreadcrumbs,
    AppView,
    ProviderTokensCustomModal,
  },
  setup() {
    const providerTokensStore = useProviderTokensStore();

    const { updateIsLoading } = useLayoutLoadingStatus();

    const customTokenModaIsShown = ref(false);

    const tokenProviders = computed(() => providerTokensStore.getters.tokenProviders);

    const breadcrumbs = computed(() => [
      {
        label: 'Доступы к площадкам',
        path: '/provider-tokens',
      },
      {
        label: 'Новый токен',
        path: '/provider-tokens/new',
      },
    ]);

    useMeta({ title: 'Новый токен' });

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await providerTokensStore.dispatch('FETCH_TOKEN_PROVIDERS');
      } finally {
        updateIsLoading(false);
      }
    };

    const onProviderClick = (provider: TokenProvider) => {
      providerTokensStore.commit('SET_SELECTED_TOKEN_PROVIDER', provider);

      if (/\/tokens\/custom/i.test(provider.oAuthURL)) {
        customTokenModaIsShown.value = true;
      } else {
        window.open(provider.oAuthURL, '_blank');
      }
    };

    fetch();

    return {
      customTokenModaIsShown,
      tokenProviders,
      breadcrumbs,
      onProviderClick,
    };
  },
});
