




































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ModallyHeader',
  props: {
    title: {
      type: [String, Number],
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
  setup() {
    const statusIcons = {
      neutral: {
        icon: 'mdi-information',
        color: 'accent',
      },
      positive: {
        icon: 'mdi-check-circle',
        color: 'success',
      },
      warning: {
        icon: 'mdi-information',
        color: 'warning',
      },
      error: {
        icon: 'mdi-information',
        color: 'error',
      },
    };

    return { statusIcons };
  },
});
