/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import dataProvidersSettingsCatalog from './dataProvidersSettings/dataProvidersSettingsCatalog';
import dataProvidersSettingsDetails from './dataProvidersSettings/dataProvidersSettingsDetails';

const module = new Module({
  modules: {
    dataProvidersSettingsCatalog,
    dataProvidersSettingsDetails,
  },
});

export const useDataProvidersSettingsStore = createComposable(module);

export default module;
