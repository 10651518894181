



































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';
import AppJsonError from '@/services/errorHandler/AppJsonError';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { Permission } from '@/models/Permission';

import PermissionsItemForm from '../forms/PermissionsItemForm.vue';

import { usePermissionsCatalogStore } from '../../../store/permissions/permissionsCatalog';
import { usePermissionDetailsStore } from '../../../store/permissions/permissionDetails';

export default defineComponent({
  components: {
    AppModal,
    PermissionsItemForm,
  },
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: '' },
    permissionId: { type: String as PropType<string | null>, default: null },
  },
  setup(props, context) {
    const {
      value,
      title,
      permissionId,
    } = toRefs(props);

    const permissionsCatalogStore = usePermissionsCatalogStore();
    const permissionDetailsStore = usePermissionDetailsStore();

    const permission = computed(() => permissionDetailsStore.getters.permission);
    const permissionIsLoading = computed(() => permissionDetailsStore.getters.isLoading);

    const permissionFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const permissionFormData = ref<Permission | null>(null);
    const permissionFormIsValid = ref(false);

    const permissionReset = () => {
      permissionDetailsStore.mutations.RESET();
    };

    const permissionFormResetValidation = async () => {
      if (!permissionFormRef.value) return;

      permissionFormRef.value.resetValidation();
    };

    const newPermission = computed(() => {
      if (!permissionFormData.value || !permissionFormIsValid.value) return null;

      return permissionFormData.value;
    });

    const modalTitle = computed(() => title.value || permission.value?.name || 'Разрешение');

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => !newPermission.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!permissionId.value || !newPermission.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await permissionsCatalogStore.actions.UPDATE_PERMISSION({
          permissionId: permissionId.value,
          data: newPermission.value,
        });

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const messages = Object.values(error.response?.data || {}).reduce<string[]>((acc, val) => {
            if (Array.isArray(val)) {
              acc.push(...val);
            } else if (typeof val === 'string') {
              acc.push(val);
            }

            return acc;
          }, []);

          saveError.value = {
            title: 'Произошла ошибка',
            messages: messages.length ? messages : ['Обратитесь к администратору'],
          };
        }

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        saving.value = false;
      }
    };

    const init = async () => {
      if (!permissionId.value) return;

      await permissionDetailsStore.actions.FETCH_PERMISSION(permissionId.value);

      permissionFormData.value = permission.value;
    };

    const reset = () => {
      permissionFormData.value = null;

      permissionReset();
      permissionFormResetValidation();
    };

    watch(value, (val) => {
      if (!val) return;

      reset();
      init();
    }, { immediate: true });

    return {
      permissionIsLoading,
      permissionFormData,
      permissionFormIsValid,
      modalTitle,
      saving,
      saveBtnIsDisabled,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
