





























































import { defineComponent, toRefs, computed, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import useMeta from '@/composables/meta/useMeta';
import { useLayoutLoadingStatus } from '@/composables/layout';
import useModals from '@/composables/useModals';

import MarkupsCatalogHeader from '../components/MarkupsCatalog/MarkupsCatalogHeader.vue';
import MarkupsTable from '../components/MarkupsCatalog/MarkupsTable/MarkupsTable.vue';
import MarkupsAddModal from '../components/modals/MarkupsAddModal.vue';

import { useMarkupsStore } from '../store';

export default defineComponent({
  components: {
    AppView,
    MarkupsCatalogHeader,
    MarkupsTable,
    MarkupsAddModal,
  },
  props: {
    placementId: { type: String, default: '' },
  },
  setup(props) {
    const { placementId } = toRefs(props);

    const { updateIsLoading } = useLayoutLoadingStatus();

    const {
      modalsMap,
      openModal,
    } = useModals(['add']);

    const markupStore = useMarkupsStore();

    const byPlacement = computed(() => !!placementId.value);

    const markups = computed(() => markupStore.getters.markups);
    const markupsIsLoading = computed(() => markupStore.getters.isLoading);
    const markupsParams = computed(() => markupStore.getters.fetchParams);
    const markupsHaveBeenFiltered = computed(() => markupStore.getters.markupsHaveBeenFiltered);
    const markupsPlacement = computed(() => markupStore.getters.placement);

    const pageMeta = computed(() => ({
      title: (markupsPlacement.value ? `Наценки размещения • ${markupsPlacement.value.siteName}` : 'Наценки'),
    }));

    useMeta(pageMeta);

    const fetch = async () => {
      await markupStore.dispatch('FETCH', { placementId: placementId.value });
    };

    watch(markupsParams, () => {
      if (!markupsHaveBeenFiltered.value) return;

      fetch();
    });

    const init = async () => {
      updateIsLoading(true);
      markupStore.dispatch('RESET_FILTERS');

      try {
        if (placementId.value) {
          await markupStore.dispatch('FETCH_PLACEMENT', { placementId: placementId.value });

          if (!markupsPlacement.value?.hasMarkup) {
            openModal({ slug: 'add', props: { placementId: placementId.value } });
          }
        }

        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    init();

    return {
      modalsMap,
      byPlacement,
      markups,
      markupsIsLoading,
      markupsHaveBeenFiltered,
      openModal,
    };
  },
});
