/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import users from './users';
import roles from './roles';
import permissions from './permissions';
import groups from './groups';

const module = new Module({
  modules: {
    users,
    roles,
    permissions,
    groups,
  },
});

export const useAccessControlStore = createComposable(module);

export default module;
