

















import { defineComponent, toRefs, provide, computed, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignCategoryHeader from '@/components/_newDesign/Campaigns/CampaignCategory/CampaignCategoryHeader.vue';
import CampaignCategoryContent from '@/components/_newDesign/Campaigns/CampaignCategory/CampaignCategoryContent.vue';

import useQueryFilters from '@/composables/useQueryFilters';
import { useLayoutLoadingStatus } from '@/composables/layout';
import { useCampaignDetails } from '@/composables/campaign';
import useMeta from '@/composables/meta/useMeta';

export default defineComponent({
  components: {
    AppView,
    CampaignCategoryHeader,
    CampaignCategoryContent,
  },
  props: {
    campaignId: { type: String, required: true },
    categoryId: { type: String, required: true },
  },
  setup(props, context) {
    const { campaignId, categoryId } = toRefs(props);

    const {
      queryFilters,
      updateQueryFilters,
    } = useQueryFilters({
      filters: {
        from: undefined,
        to: undefined,
      },
    }, context);

    provide('queryFilters', queryFilters);
    provide('updateQueryFilters', updateQueryFilters);

    const {
      isLoading,
      updateIsLoading,
    } = useLayoutLoadingStatus();

    const {
      campaign,
      campaignCategories,
      fetchCampaign,
      campaignPageTitle,
    } = useCampaignDetails({
      campaignId,
      filters: queryFilters,
    });

    const category = computed(() => campaignCategories.value.find(({ id }) => id === categoryId.value));

    const pageMeta = computed(() => ({ title: campaignPageTitle.value }));

    useMeta(pageMeta);

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await fetchCampaign();
      } finally {
        updateIsLoading(false);
      }
    };

    watch(queryFilters, () => fetch());

    fetch();

    return {
      campaign,
      category,
      campaignCategories,
      isLoading,
    };
  },
});
