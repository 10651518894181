import { AxiosResponse } from 'axios';

import { ServiceType } from '@/models/ServiceType';

import httpClient from '../httpClient';

export default class ServiceTypesApi {
  public static async fetchServiceTypes(): Promise<AxiosResponse<ServiceType[]>> {
    const res = await httpClient.get<ServiceType[]>('/api/servicetypes');

    return res;
  }
}
