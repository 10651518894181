









































import { defineComponent, toRefs, ref, computed, watch, PropType } from '@vue/composition-api';

import { DSelect } from '@shared/druid-kit';

import { Campaign } from '@/models/Campaign';
import { Goal } from '@/models/Goal';

import {
  useCampaignEditorSettingsStore,
  CONVERSION_RATE_MODE_MAIN_GOAL,
  DEFAULT_CONVERSION_RATE_MODE,
} from '@/store/campaignEditor/campaignEditorSettings';

import { useCampaignEditorGoalsStore } from '@/store/campaignEditor/campaignEditorGoals';

export default defineComponent({
  components: {
    DSelect,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props) {
    const {
      campaign,
    } = toRefs(props);

    const campaignEditorSettingsStore = useCampaignEditorSettingsStore();
    const campaignEditorGoalsStore = useCampaignEditorGoalsStore();

    const goalsIsLoading = ref(false);

    const dataProviders = computed(() => campaignEditorSettingsStore.getters.dataProviderAbilities);

    const currentDataProviderId = computed({
      get() {
        return campaignEditorSettingsStore.getters.currentSettings?.dataProviderId || null;
      },
      set(val: string | null) {
        campaignEditorSettingsStore.dispatch('CHANGE_SETTINGS', { dataProviderId: val });
      },
    });

    const currentDataProvider = computed(() => dataProviders.value.find(({ dataProviderId }) => dataProviderId === currentDataProviderId.value));

    const dataProviderModes = computed(() => currentDataProvider.value?.modes || [DEFAULT_CONVERSION_RATE_MODE]);

    const currentConversionRateMode = computed({
      get() {
        return campaignEditorSettingsStore.getters.currentConversionRateMode;
      },
      set(val: string) {
        campaignEditorSettingsStore.dispatch('CHANGE_SETTINGS', { conversionRateCalculationMode: val });
      },
    });

    const currentConversionRateModeIsMainGoal = computed(() => campaignEditorSettingsStore.getters.currentConversionRateModeIsMainGoal);

    const currentMainGoal = computed(() => {
      const goal = campaignEditorSettingsStore.getters.currentMainGoal;

      if (!goal) return undefined;

      return {
        ...goal,
        slug: `${goal.providerSettingsId}:${goal.id}`,
        label: `${goal.providerSettingsName}: ${goal.name}`,
      };
    });

    const goals = computed(() => {
      const filteredGoals = campaignEditorGoalsStore.getters.goals.filter((goal) => goal.providerId === currentDataProviderId.value);

      return filteredGoals.map((goal) => ({
        ...goal,
        slug: `${goal.providerSettingsId}:${goal.id}`,
        label: `${goal.providerSettingsName}: ${goal.name}`,
      }));
    });

    const currentGoal = computed({
      get() {
        return currentMainGoal.value;
      },
      set(val: Goal | undefined) {
        if (!val) return;

        const newGoal = {
          id: val.id,
          name: val.name,
          providerId: val.providerId,
          providerName: val.providerName,
          providerSettingsId: val.providerSettingsId,
          providerSettingsName: val.providerSettingsName,
          mainGoal: true,
        };

        campaignEditorSettingsStore.dispatch('CHANGE_SETTINGS', { mainGoal: newGoal });
      },
    });

    const fetchGoalsNew = async () => {
      goalsIsLoading.value = true;

      try {
        await campaignEditorGoalsStore.dispatch('FETCH_GOALS', { campaignId: campaign.value.id });
      } finally {
        goalsIsLoading.value = false;
      }
    };

    watch(currentDataProviderId, (val) => {
      const conversionRateModeValue = val ? dataProviderModes.value[0] : DEFAULT_CONVERSION_RATE_MODE;

      currentConversionRateMode.value = conversionRateModeValue;

      if (currentConversionRateMode.value !== CONVERSION_RATE_MODE_MAIN_GOAL && currentMainGoal.value) {
        campaignEditorSettingsStore.dispatch('CHANGE_SETTINGS', { mainGoal: undefined });
      }
    });

    watch(currentConversionRateMode, (val) => {
      if (val === CONVERSION_RATE_MODE_MAIN_GOAL) {
        fetchGoalsNew();
      } else if (currentMainGoal.value) {
        campaignEditorSettingsStore.dispatch('CHANGE_SETTINGS', { mainGoal: undefined });
      }
    }, { immediate: true });

    return {
      dataProviders,
      currentDataProviderId,
      dataProviderModes,
      currentConversionRateMode,
      currentConversionRateModeIsMainGoal,
      goals,
      goalsIsLoading,
      currentGoal,
    };
  },
});
