import { AxiosResponse } from 'axios';

import { Markup, MarkupLight } from '@/models/Markup';

import httpClient from '../httpClient';

interface FetchMarkupsPayload {
  params?: {
    placementId?: string;
    filter?: string;
    page?: string | number;
    sortBy?: string;
    sortDesc?: boolean;
  };
}

export interface FetchMarkupsResponse {
  list: MarkupLight[];
  totalRows: number;
}

export interface SetMarkupPayload {
  clientId: string;
  brandId: string;
  campaignId: string;
  placementId: string;
  dataProviderId: string;
  startDate: string;
  value: number;
}

interface DeleteMarkupPayload {
  markupId: string;
  config: {
    data: string;
  };
}

export default class MarkupsApi {
  public static async fetchMarkups(payload?: FetchMarkupsPayload): Promise<AxiosResponse<FetchMarkupsResponse>> {
    const res = await httpClient.get<FetchMarkupsResponse>('/api/markups', payload);

    return res;
  }

  public static async fetchMarkup(markupId: string): Promise<AxiosResponse<Markup>> {
    const res = await httpClient.get<Markup>(`/api/markups/${markupId}`);

    return res;
  }

  public static async setMarkup(payload: SetMarkupPayload): Promise<AxiosResponse<Markup>> {
    const res = await httpClient.post<Markup>('/api/markups', payload);

    return res;
  }

  // TODO: Описать типы
  public static async updateMarkup(payload: unknown): Promise<AxiosResponse<unknown>> {
    const res = await httpClient.put<unknown>('/api/markups', payload);

    return res;
  }

  public static async deleteMarkup(payload: DeleteMarkupPayload): Promise<AxiosResponse<''>> {
    const { markupId, config } = payload;

    const res = await httpClient.delete<''>(`/api/markups/${markupId}`, config);

    return res;
  }
}
