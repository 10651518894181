<template>
  <div class="app-sidebar-nav-menu-loader">
    <div
      v-for="num in 3"
      :key="num"
      class="app-sidebar-nav-menu-loader__item"
    >
      <v-skeleton-loader
        type="heading"
        class="app-sidebar-nav-menu-loader__item-icon"
      />
      <v-skeleton-loader
        v-if="sideBarIsShown"
        type="text"
        class="app-sidebar-nav-menu-loader__item-text"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    sideBarIsShown: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.app-sidebar-nav-menu-loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    display: flex;
    align-items: baseline;
    width: 100%;
    min-height: 40px;
    padding: 0 16px;
  }

  &__item-icon {
    width: 24px;
    height: 24px;
    margin: 4px;

    ::v-deep .v-skeleton-loader__heading {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  &__item-text {
    width: 132px;
    margin-left: 16px;
  }
}
</style>
