import { AxiosResponse } from 'axios';

import { UnapprovedCampaign, UnapprovedPlacement } from '@/models/UnapprovedPlacements';
import { PlacementMetrics } from '@/models/Placement';

import httpClient from '../httpClient';

interface FetchCampaignsPayloadV2 {
  params: {
    page: number;
    searchQuery: string;
  };
}

interface FetchCampaignsResponseV2 {
  list: UnapprovedCampaign[];
  totalRows: number;
}

interface FetchPlacementsPayload {
  params: {
    searchQuery: string;
  };
}

interface FetchPlacementsByCampaignResponse {
  placements: unknown[]; // TODO: Описать тип
  archiveCount: number;
  moderateCount: number;
  shownCount: number;
  waitingCount: number;
}

interface FetchPlacementMarkupsPayload {
  placementIds: string[];
}

interface FetchPlacementMarkupsResponse {
  markups: unknown[]; // TODO: Описать тип
}

interface ChangeStatusPayload {
  ids: string[];
  status: string;
  resetCheck: boolean | null;
  pageInfo: {
    documentHostname: string;
    page: string;
    title: string;
    affiliation: string;
  } | null;
}

interface ChangeFinalStatusPayload {
  id: string;
  status: string;
  dataProviderId: string | null;
  resetCheck: boolean | null;
  pageInfo: {
    documentHostname: string;
    page: string;
    title: string;
    affiliation: string;
  } | null;
}

interface SkipMarkupPayload {
  ids: string[];
  skipMarkup: boolean;
}

export default class UnapprovedPlacementsApi {
  public static async fetchCampaignsV2(payload: FetchCampaignsPayloadV2): Promise<AxiosResponse<FetchCampaignsResponseV2>> {
    const res = await httpClient.get<FetchCampaignsResponseV2>('/api/v2/unapproved/campaigns', payload);

    return res;
  }

  public static async fetchPlacementsByCampaignV2(campaignId: string): Promise<AxiosResponse<UnapprovedPlacement[]>> {
    const res = await httpClient.get<UnapprovedPlacement[]>(`/api/v2/unapproved/placements-by-campaign/${campaignId}`);

    return res;
  }

  public static async fetchCountV2(): Promise<AxiosResponse<number>> {
    const res = await httpClient.get<number>('/api/v2/unapproved/placement-count');

    return res;
  }

  // TODO: Описать типы
  public static async fetchCampaigns(payload: FetchPlacementsPayload): Promise<AxiosResponse<unknown>> {
    const res = await httpClient.get<unknown>('/api/unapprovedPlacements', payload);

    return res;
  }

  public static async fetchPlacementsByCampaign(campaignId: string): Promise<AxiosResponse<FetchPlacementsByCampaignResponse>> {
    const res = await httpClient.get<FetchPlacementsByCampaignResponse>(`/api/unapprovedPlacements/by-campaign/${campaignId}`);

    return res;
  }

  public static async fetchCount(): Promise<AxiosResponse<number>> {
    const res = await httpClient.get<number>('/api/unapprovedPlacements/count');

    return res;
  }

  public static async fetchPlacementMarkups(payload: FetchPlacementMarkupsPayload): Promise<AxiosResponse<FetchPlacementMarkupsResponse>> {
    const res = await httpClient.post<FetchPlacementMarkupsResponse>('/api/unapprovedPlacements/markups', payload);

    return res;
  }

  public static async fetchPlacementMetrics(danboId: string): Promise<AxiosResponse<PlacementMetrics>> {
    const res = await httpClient.get<PlacementMetrics>(`/api/unapprovedPlacements/metrics/${danboId}`);

    return res;
  }

  // TODO: Описать типы
  public static async changeStatus(payload: ChangeStatusPayload): Promise<AxiosResponse<unknown>> {
    const res = await httpClient.post<unknown>('/api/unapprovedPlacements/changeStatus', payload);

    return res;
  }

  public static async changeFinalStatus(payload: ChangeFinalStatusPayload): Promise<AxiosResponse<Record<string, never>>> {
    const res = await httpClient.post<Record<string, never>>('/api/unapprovedPlacements/changeFinalStatus', payload);

    return res;
  }

  // TODO: Описать типы
  public static async skipMarkup(payload: SkipMarkupPayload): Promise<AxiosResponse<unknown>> {
    const res = await httpClient.post<unknown>('/api/unapprovedPlacements/skipMarkup', payload);

    return res;
  }
}
