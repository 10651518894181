import { AppRouteConfig } from '@/models/AppRouter';

import Maintenance from '../views/Maintenance.vue';

const routeConfig: AppRouteConfig = {
  path: 'maintenance',
  component: Maintenance,
  meta: {
    title: 'Техническое обслуживание',
    permission: 'admin',
    order: 2,
  },
};

export default routeConfig;
