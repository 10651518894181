/* eslint-disable max-classes-per-file */

import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, Context, createMapper, createComposable } from 'vuex-smart-module';

import modally from '@/services/modally';

import { CampaignEditorPlacement, CampaignPlanMetrics } from '@/models/Campaign';

import {
  TargetingGender,
  TargetingRegion,
  TargetingCappingPeriod,
  TargetingTransmittance,
  TargetingIncomeGroup,
} from '@/models/Targeting';

import { Category } from '@/models/Category';
import { DataProvider } from '@/models/DataProvider';

import { CampaignEditorApi, CategoriesApi, DataProvidersApi, TargetingsApi } from '@/api';

import isError from '@/utils/isError';
import AppJsonError from '@/services/errorHandler/AppJsonError';

import campaignStore from '../campaign';

export interface PlacementsFilters {
  filter: string;
  hidden: boolean;
}

class ModuleState {
  filters: PlacementsFilters = {
    filter: '',
    hidden: false,
  }

  campaignPlacements: CampaignEditorPlacement[] = [];

  campaignPlanMetrics: CampaignPlanMetrics = [];

  selectedPlanMetrics: CampaignPlanMetrics = [];

  campaignPlacementsForm: CampaignEditorPlacement[] = [];

  categories: Category[] = [];

  genders: TargetingGender[] = [];

  regions: TargetingRegion[] = [];

  cappingPeriods: TargetingCappingPeriod[] = [];

  transmittances: TargetingTransmittance[] = [];

  incomeGroups: TargetingIncomeGroup[] = [];

  dataProviders: DataProvider[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get campaignPlacements() {
    return this.state.campaignPlacements;
  }

  get filters() {
    return this.state.filters;
  }

  get categories() {
    return this.state.categories;
  }

  get changedPlacements() {
    return this.state.campaignPlacementsForm;
  }

  get hasBeenChanged() {
    return !!this.getters.changedPlacements.length;
  }

  get campaignPlanMetrics() {
    return this.state.campaignPlanMetrics;
  }

  get filledPlanMetrics() {
    return this.getters.campaignPlanMetrics.filter(({ name }) => this.getters.campaignPlacements.some((placement) => placement.planTotalMetrics[name]));
  }

  get selectedPlanMetrics() {
    return this.state.selectedPlanMetrics;
  }

  get visiblePlanMetrics() {
    const planMetrics = [...this.getters.filledPlanMetrics];

    this.getters.selectedPlanMetrics.forEach((planMetric) => {
      const planMetricAlreadyExists = planMetrics.some(({ name }) => name === planMetric.name);

      if (planMetricAlreadyExists) return;

      planMetrics.push(planMetric);
    });

    const planMetricsWeightsMap = this.getters.campaignPlanMetrics.reduce((map, planMetric, idx) => {
      map[planMetric.name] = idx;

      return map;
    }, {});

    return planMetrics.sort((planMetricA, planMetricB) => {
      const planMetricAWeight = planMetricsWeightsMap[planMetricA.name];
      const planMetricBWeight = planMetricsWeightsMap[planMetricB.name];

      return planMetricAWeight - planMetricBWeight;
    });
  }

  get visiblePlacements() {
    return this.getters.campaignPlacements.filter(({ hidden }) => !hidden);
  }

  get hiddenPlacements() {
    return this.getters.campaignPlacements.filter(({ hidden }) => hidden);
  }

  get dataProviders() {
    return this.state.dataProviders;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_FILTERS(payload: PlacementsFilters) {
    this.state.filters = payload;
  }

  SET_CAMPAIGN_PLACEMENTS(payload: CampaignEditorPlacement[]) {
    this.state.campaignPlacements = payload;
  }

  SET_CAMPAIGN_PLAN_METRICS(payload: CampaignPlanMetrics) {
    this.state.campaignPlanMetrics = payload;
  }

  SET_SELECTED_PLAN_METRICS(payload: CampaignPlanMetrics) {
    this.state.selectedPlanMetrics = payload;
  }

  SET_CATEGORIES(payload: Category[]) {
    this.state.categories = payload;
  }

  ADD_TO_CHANGED_PLACEMENTS(payload: CampaignEditorPlacement[]) {
    payload.forEach((newPlacement) => {
      const idx = this.state.campaignPlacementsForm.findIndex((placement) => placement.id === newPlacement.id);

      if (idx === -1) {
        this.state.campaignPlacementsForm.push(newPlacement);
      } else {
        this.state.campaignPlacementsForm.splice(idx, 1, newPlacement);
      }
    });
  }

  RESET_CHANGES() {
    this.state.campaignPlacementsForm = [];
  }

  SET_GENDERS(payload: TargetingGender[]) {
    this.state.genders = payload;
  }

  SET_REGIONS(payload: TargetingRegion[]) {
    this.state.regions = payload;
  }

  SET_CAPPING_PERIODS(payload: TargetingCappingPeriod[]) {
    this.state.cappingPeriods = payload;
  }

  SET_TRANSMITTANCES(payload: TargetingTransmittance[]) {
    this.state.transmittances = payload;
  }

  SET_INCOME_GROUPS(payload: TargetingIncomeGroup[]) {
    this.state.incomeGroups = payload;
  }

  SET_DATA_PROVIDERS(payload: DataProvider[]) {
    this.state.dataProviders = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  store!: Store<unknown>;

  campaign!: Context<typeof campaignStore>;

  $init(store: Store<unknown>) {
    this.store = store;

    this.campaign = campaignStore.context(store);
  }

  async FETCH(campaignId: string) {
    const res = await CampaignEditorApi.fetchPlacements(campaignId);

    this.commit('SET_CAMPAIGN_PLACEMENTS', res.data);
  }

  async FETCH_CAMPAIGN_PLAN_METRICS() {
    try {
      const res = await CampaignEditorApi.fetchCampaignPlanMetrics();

      this.commit('SET_CAMPAIGN_PLAN_METRICS', res.data);
    } catch (e) {
      const title = 'Не удалось получить список плановых метрик';

      modally.show({ status: 'error', title });
    }
  }

  async FETCH_CATEGORIES() {
    const res = await CategoriesApi.fetchCategories();

    this.commit('SET_CATEGORIES', res.data);
  }

  PUSH_TO_CHANGED_PLACEMENTS(payload: CampaignEditorPlacement[]) {
    this.commit('ADD_TO_CHANGED_PLACEMENTS', payload);
  }

  async SAVE(payload: { campaignId: string; options?: { withConfirm: boolean } }) {
    if (!this.getters.hasBeenChanged) return;

    const defaultOptions = {
      withConfirm: true,
    };

    const options = payload?.options ? { ...defaultOptions, ...payload.options } : defaultOptions;

    // TODO: разобраться с отправкой события в GTM

    const save = async () => {
      const data = { placements: this.getters.changedPlacements };

      try {
        await CampaignEditorApi.updatePlacements({
          campaignId: payload.campaignId,
          data,
        });

        // TODO: здесь по хорошему нужно обновлять данными из ответа, а не делать доп. запрос
        await this.dispatch('FETCH', payload.campaignId);

        this.commit('RESET_CHANGES');
      } catch (error) {
        if (!isError(error)) return;

        modally.show({
          status: 'error',
          title: 'Не удалось сохранить настройки размещений',
          content: 'Проверьте введенные данные и попытайтесь еще раз',
        });

        throw new AppJsonError({ error, options: { showingModal: false } });
      }
    };

    if (options.withConfirm) {
      const title = 'Сохранить изменения?';

      const firstChangedPlacemnents = this.getters.changedPlacements.slice(0, 10);
      const otherChangedPlacements = this.getters.changedPlacements.slice(10);
      const otherChangedPlacementsLength = otherChangedPlacements.length;

      const firstChangedPlacemnentsDonboIds = firstChangedPlacemnents.map((placement) => placement.danboId);

      const content = `Будут затронуты размещения ${firstChangedPlacemnentsDonboIds.join(', ')}${otherChangedPlacementsLength ? ` и еще ${otherChangedPlacementsLength}` : ''}`;

      const result = await modally.show({
        type: 'confirm',
        status: 'neutral',
        title,
        content,
      });

      if (!result) return;

      await save();
    } else {
      await save();
    }
  }

  async FETCH_GENDERS() {
    const res = await TargetingsApi.fetchGenders();

    this.commit('SET_GENDERS', res.data);
  }

  async FETCH_CAPPING_PERIODS() {
    const res = await TargetingsApi.fetchCappingPeriods();

    this.commit('SET_CAPPING_PERIODS', res.data);
  }

  async FETCH_TRANSMITTANCES() {
    const res = await TargetingsApi.fetchTransmittances();

    this.commit('SET_TRANSMITTANCES', res.data);
  }

  async FETCH_INCOME_GROUPS() {
    const res = await TargetingsApi.fetchIncomeGroups();

    this.commit('SET_INCOME_GROUPS', res.data);
  }

  async FETCH_REGIONS() {
    const res = await TargetingsApi.fetchRegions();

    this.commit('SET_REGIONS', res.data);
  }

  async FETCH_DATA_PROVIDERS() {
    const res = await DataProvidersApi.fetchDataProviders();

    this.commit('SET_DATA_PROVIDERS', res.data);
  }

  async FETCH_CATALOGS() {
    await Promise.all([
      this.dispatch('FETCH_CAMPAIGN_PLAN_METRICS'),
      this.dispatch('FETCH_GENDERS'),
      this.dispatch('FETCH_TRANSMITTANCES'),
      this.dispatch('FETCH_REGIONS'),
      this.dispatch('FETCH_CAPPING_PERIODS'),
      this.dispatch('FETCH_INCOME_GROUPS'),
      this.dispatch('FETCH_DATA_PROVIDERS'),
    ]);
  }

  async UPLOAD_FLIGHTS_PLAN(payload: { campaignId: string; formData: FormData }) {
    const { campaignId, formData } = payload;

    if (!campaignId) return;

    await CampaignEditorApi.uploadFlightsPlan({ campaignId, data: formData });
    await this.dispatch('FETCH', campaignId);

    this.commit('RESET_CHANGES');
  }

  async UPLOAD_PLACEMENT_PLAN(payload: { campaignId: string; formData: FormData }) {
    const { campaignId, formData } = payload;

    if (!campaignId) return;

    await CampaignEditorApi.uploadPlacementPlan({ campaignId, data: formData });
    await this.dispatch('FETCH', campaignId);

    this.commit('RESET_CHANGES');
  }

  async UPDATE_FILTERS(filters: Partial<PlacementsFilters>) {
    if (this.getters.hasBeenChanged) {
      const result = await modally.show<boolean>({
        status: 'warning',
        title: 'Есть несохраненные данные',
        type: 'confirm',
        content: 'Настройки размещений будут сброшены',
        buttons: [
          {
            props: {
              text: 'Хорошо',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary-gradient',
            },
            action: () => false,
          },
        ],
      });

      if (!result) return;

      this.commit('RESET_CHANGES');
    }

    const updatedFilters = {
      ...this.getters.filters,
      ...filters,
    };

    this.commit('SET_FILTERS', updatedFilters);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const campaignEditorPlacementsStoreMapper = createMapper(module);

export const useCampaignEditorPlacementsStore = createComposable(module);

export default module;
