import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import { preset } from '@shared/vue-cli-plugin-vuetify-preset-druid/preset';

import ProjectLogoIcon from '@/components/_newDesign/common/icons/ProjectLogo.icon.vue';
import ClientIcon from '@/components/_newDesign/common/icons/Client.icon.vue';
import PlacementsCheckIcon from '@/components/_newDesign/common/icons/PlacementsCheck.icon.vue';
import UserIcon from '@/components/_newDesign/common/icons/User.icon.vue';
import PlayIcon from '@/components/_newDesign/common/icons/Play.icon.vue';
import AppsIcon from '@/components/_newDesign/common/icons/Apps.icon.vue';
import SellIcon from '@/components/_newDesign/common/icons/Sell.icon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  theme: {
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: {
      'd1-project-logo': {
        component: ProjectLogoIcon,
      },
      'd1-client': {
        component: ClientIcon,
      },
      'd1-user': {
        component: UserIcon,
      },
      'd1-play': {
        component: PlayIcon,
      },
      'd1-apps': {
        component: AppsIcon,
      },
      'd1-sell': {
        component: SellIcon,
      },
      'd1-placements-check': {
        component: PlacementsCheckIcon,
      },
    },
  },
});
