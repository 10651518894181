
























import { defineComponent, ref } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignCategorySettings from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorCategories/CampaignCategorySettings/CampaignCategorySettings.vue';
import CampaignCategories from '@/components/_newDesign/Campaigns/CampaignEditor/CampaignEditorCategories/CampaignCategories/CampaignCategories.vue';

import CampaignEditorCategoriesLoader from '@/components/_newDesign/Campaigns/CampaignEditor/loaders/CampaignEditorCategoriesLoader.vue';

import store from '@/store';
import referenceStore from '@/store/reference';

const referenceStoreContext = referenceStore.context(store);

export default defineComponent({
  components: {
    AppView,
    CampaignCategorySettings,
    CampaignCategories,
    CampaignEditorCategoriesLoader,
  },
  setup() {
    const isLoading = ref(false);

    const fetch = async () => {
      isLoading.value = true;

      try {
        await Promise.all([
          referenceStoreContext.dispatch('FETCH_CAMPAIGN_TYPES'),
          referenceStoreContext.dispatch('FETCH_BRAND_CATEGORIES'),
        ]);
      } finally {
        isLoading.value = false;
      }
    };

    fetch();

    return { isLoading };
  },
});
