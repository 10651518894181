import { AppRouteConfig } from '@/models/AppRouter';

import AccessControl from '../views/AccessControl.vue';

import users from './users';
import groups from './groups';
import roles from './roles';
import permissions from './permissions';

const routes: AppRouteConfig[] = [
  {
    path: '/access-control',
    component: AccessControl,
    meta: {
      group: 'admin',
      title: 'Управление доступом',
      icon: 'mdi-account-multiple',
      order: 0,
      permission: 'admin',
    },
    children: [
      users,
      groups,
      roles,
      permissions,
    ],
  },
];

export default routes;
