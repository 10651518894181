import numatter, { NumatterFormatOptions, NumatterPreset } from '@/packages/numatter';

import PluginInit from '@/models/PluginInit';
import { MetricType } from '@/models/MetricType';

import metricTypesStore from '@/store/reference/metricTypes';

//
//
// // // Numatter preset generators
//

export const generatePresetFromMetricType = (metricType: MetricType): NumatterPreset => {
  const defaultFormatOptionsMap = {
    postfixes: {
      Money: 'руб.',
      Percent: '%',
    },
    outputs: {
      TimeSpan: 'time',
    },
  };

  const defaultPostfix = defaultFormatOptionsMap.postfixes[metricType.format || -1];
  const defaultOutput = defaultFormatOptionsMap.outputs[metricType.format || -1];

  const presetName = metricType.name;

  const presetOptions: NumatterFormatOptions = {
    postfix: defaultPostfix,
    output: defaultOutput,
    mantissa: metricType.accuracy,
    spaceSeparated: !!defaultPostfix,
  };

  const filledPresetOptions = numatter.clearEmptyFormatOptions(presetOptions);

  return { name: presetName, options: filledPresetOptions };
};

//
// //
//
//

const pluginInit: PluginInit = ({ store }) => {
  const metricTypesStoreContext = metricTypesStore.context(store);

  store.watch(() => metricTypesStoreContext.getters.metricTypes, () => {
    const { metricTypes } = metricTypesStoreContext.getters;

    metricTypes.forEach((metricType) => {
      const metricTypePreset = generatePresetFromMetricType(metricType);

      numatter.addPreset(metricTypePreset);
    });
  });
};

export default pluginInit;
