<template>
  <div class="provider-tokens-new-loader">
    <v-skeleton-loader
      type="heading"
      class="provider-tokens-new-loader__header"
    />

    <div class="provider-tokens-new-loader__providers">
      <v-skeleton-loader
        v-for="num in 7"
        :key="num"
        type="heading"
        class="provider-tokens-new-loader__provider"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({});
</script>

<style lang="scss" scoped>
.provider-tokens-new-loader {
  background-color: map-get($grey, 'lighten-5');

  &__header {
    display: flex;
    align-items: center;
    width: 70%;
    height: 80px;
    padding: 12px 24px;

    ::v-deep .v-skeleton-loader__heading {
      background: map-get($grey, 'lighten-1') !important;
    }
  }

  &__providers {
    padding: 64px 24px 24px 24px;
  }

  &__provider {
    width: 20%;
    padding: 18px 0;
  }
}
</style>
