import { computed, ComputedRef } from '@vue/composition-api';

import store from '@/store';
import rootUi from '@/store/root/rootUi';

const rootUiContext = rootUi.context(store);

interface UseLayoutLoadingStatusReturn {
  isLoading: ComputedRef<boolean>;
  updateIsLoading: (val: boolean) => void;
}

export default (): UseLayoutLoadingStatusReturn => {
  const isLoading = computed(() => rootUiContext.getters.isLoading);

  const updateIsLoading = (val: boolean) => rootUiContext.dispatch('UPDATE_IS_LOADING', val);

  return {
    isLoading,
    updateIsLoading,
  };
};
