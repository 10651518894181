/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import { isEqual } from 'lodash';
import Fuse from 'fuse.js';

import {
  PermissionsApi,
} from '@/api';

import { UpdatePermissionPayload } from '@/api/modules/permissions.api';

import { Permission } from '@/models/Permission';

export interface PermissionsCatalogFilters {
  query: string;
}

const initFilters = () => ({
  query: '',
});

class ModuleState {
  filters: PermissionsCatalogFilters = initFilters();

  isLoading = false;

  permissions: Permission[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get filters() {
    return this.state.filters;
  }

  get filtersAreEnabled() {
    const defaultFilters = initFilters();

    return Object.entries(this.getters.filters).some(([key, val]) => !isEqual(val, defaultFilters[key]));
  }

  get isLoading() {
    return this.state.isLoading;
  }

  private get fuseByPermissions() {
    return new Fuse(this.state.permissions, {
      keys: [
        'name',
        'description',
      ],
    });
  }

  get permissions() {
    const {
      filters,
      fuseByPermissions,
    } = this.getters;

    const { permissions } = this.state;

    if (!filters.query) return permissions;

    const fuseResult = fuseByPermissions.search(filters.query);

    return fuseResult.map(({ item }) => item);
  }

  get permissionsHaveBeenFiltered() {
    return this.getters.filtersAreEnabled;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_FILTERS(payload: PermissionsCatalogFilters) {
    this.state.filters = payload;
  }

  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_PERMISSIONS(payload: Permission[]) {
    this.state.permissions = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  UPDATE_FILTERS(payload: Partial<PermissionsCatalogFilters>) {
    const newPayload = {
      ...this.getters.filters,
      ...payload,
    };

    this.commit('SET_FILTERS', newPayload);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
  }

  async FETCH_PERMISSIONS() {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await PermissionsApi.fetchPermissions({ limit: -1 });

      this.mutations.SET_PERMISSIONS(res.data.list);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async CREATE_PERMISSION(payload: Permission) {
    await PermissionsApi.setPermission(payload);

    await this.actions.FETCH_PERMISSIONS();
  }

  async UPDATE_PERMISSION(payload: UpdatePermissionPayload) {
    await PermissionsApi.updatePermission(payload);

    await this.actions.FETCH_PERMISSIONS();
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const usePermissionsCatalogStore = createComposable(module);

export default module;
