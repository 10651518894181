





















import Vue, { VueConstructor, PropType } from 'vue';

import { HnTableCellMixin } from '@/packages/hn-table/src/mixins';

import { PlacementFlightsTableEmitter } from '../placementFlightsTableEmitter';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HnTableCellMixin>>).extend({
  name: 'PlanTableDeleteCell',
  mixins: [
    HnTableCellMixin,
  ],
  props: {
    emitter: { type: Object as PropType<PlacementFlightsTableEmitter>, required: true },
    placementPlanFlightsCount: { type: Number, default: 0 },
  },
  computed: {
    isDeletable(): boolean {
      return this.placementPlanFlightsCount > 1;
    },
  },
  methods: {
    onDeleteBtnClick() {
      this.emitter.emit('deleteFlightPlan', { row: this.row });
    },
  },
});
