







import { defineComponent, PropType } from '@vue/composition-api';

import ClientList from '@/components/_newDesign/Clients/ClientList/ClientList.vue';

import { Client } from '@/models/Client';

export default defineComponent({
  components: {
    ClientList,
  },
  props: {
    clients: { type: Array as PropType<Client[]>, required: true },
  },
});
