











import { defineComponent, computed } from '@vue/composition-api';

import store from '@/store';
import notifyStore from './store/notify.store';

import NotifyListByPosition from './components/NotifyListByPosition.vue';

const notifyStoreContext = notifyStore.context(store);

export default defineComponent({
  components: { NotifyListByPosition },
  setup() {
    const notificationsByPositionGroups = computed(() => notifyStoreContext.getters.notificationsByPositionGroups);

    return { notificationsByPositionGroups };
  },
});
