import { AxiosResponse } from 'axios';

import { Goal, GoalLight } from '@/models/Goal';

import httpClient from '../httpClient';

interface FetchGoalSettingsPayload {
  params: {
    campaignId: string;
  };
}

interface FetchSchemaPayload {
  campaignId: string;
  providerId: string;
  providerSettingsId: string;
  settings?: string;
}

interface FetchSchemaResponse {
  errors: string[];
  schema?: string;
}

interface FetchGoalsPayload {
  campaignId: string;
  providerId: string;
  providerSettingsId: string;
}

interface SetGoalsSettingsPayload {
  CampaignId: string;
  Goals: unknown[];
}

export default class GoalsSettingsApi {
  public static async fetchGoalsSettings(payload: FetchGoalSettingsPayload): Promise<AxiosResponse<Goal[]>> {
    const res = await httpClient.get<Goal[]>('/api/goalssettings', payload);

    return res;
  }

  public static async fetchSchema(payload: FetchSchemaPayload): Promise<AxiosResponse<FetchSchemaResponse>> {
    const res = await httpClient.post<FetchSchemaResponse>('/api/goalssettings/schema', payload);

    return res;
  }

  public static async fetchGoals(payload: FetchGoalsPayload): Promise<AxiosResponse<GoalLight[]>> {
    const res = await httpClient.post<GoalLight[]>('/api/goalssettings/goals', payload);

    return res;
  }

  // TODO: Описать типы
  public static async setGoalsSettings(payload: SetGoalsSettingsPayload): Promise<AxiosResponse<unknown>> {
    const res = await httpClient.post<unknown>('/api/goalssettings', payload);

    return res;
  }
}
