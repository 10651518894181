import Vue from 'vue';
import PortalVue from 'portal-vue';

import PluginInit from '@/models/PluginInit';

const pluginInit: PluginInit = () => {
  Vue.use(PortalVue);
};

export default pluginInit;
