













































import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api';

import moment from 'moment';

import numatter from '@/packages/numatter';

import plural from '@/utils/plural';

import CampaignMetricsItem from '@/components/_newDesign/Campaigns/CampaignMetrics/CampaignMetricsItem.vue';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  components: {
    CampaignMetricsItem,
  },
  props: {
    metricsData: { type: Object as PropType<Campaign>, required: true },
    metricsWithProgress: { type: Boolean, default: true },
  },
  setup(props) {
    const { metricsData, metricsWithProgress } = toRefs(props);

    const periodMetricItem = computed(() => {
      const {
        startDate,
        endDate,
      } = metricsData.value;

      const start = moment(startDate).startOf('day');
      const end = moment(endDate).endOf('day');
      const now = moment();

      const total = end.diff(start);
      const elapsed = now.diff(start);
      const remained = now.diff(end);

      const percent = total > elapsed ? (elapsed / total) * 100 : 100;

      const remainingDays = Math.floor(Math.abs(moment.duration(remained).asDays()));

      const formattedRemainingDays = percent < 100 ? `Еще ${remainingDays} ${plural(remainingDays, ['день', 'дня', 'дней'])}` : '';

      const formattedStartDate = moment.utc(startDate).format('DD.MM.YYYY');
      const formattedEndDate = moment.utc(endDate).format('DD.MM.YYYY');

      const formattedPeriod = `${formattedStartDate} – ${formattedEndDate}`;

      return {
        title: 'Период',
        subtitle: formattedRemainingDays,
        descr: formattedPeriod,
        percent,
      };
    });

    const defaultMetricItems = computed(() => {
      const metricNames = [
        'Cost',
        'Impressions',
        'Clicks',
      ];

      const titles = {
        Cost: 'Cost без АК, без НДС',
      };

      return metricNames.map((metricName) => {
        const { metricsPlan, metricsTotal } = metricsData.value;

        const plan = metricsPlan?.[metricName] || 0;
        const fact = metricsTotal?.[metricName] || 0;

        let percent = 0;

        if (plan === 0 && fact === 0) {
          percent = 0;
        } else {
          percent = plan ? (fact / plan) * 100 : 100;
        }

        const formattedPlan = numatter.format(plan, { preset: metricName });
        const formattedFact = numatter.format(fact, { preset: metricName });

        return {
          title: titles[metricName] || metricName,
          subtitle: metricsWithProgress.value ? numatter.format(percent, { postfix: '%' }) : formattedFact,
          descr: `${formattedFact} из ${formattedPlan}`,
          percent,
        };
      });
    });

    return {
      periodMetricItem,
      defaultMetricItems,
    };
  },
});
