


































































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { DAutocomplete } from '@shared/druid-kit';

import { ClientsApi, TaxonomyApi } from '@/api';
import { ClientRenamePayload } from '@/api/modules/clients.api';

import { TaxonomyClientCategory, TaxonomyBrandCategory } from '@/models/Taxonomy';
import { Client } from '@/models/Client';

export default defineComponent({
  components: {
    DAutocomplete,
  },
  props: {
    value: { type: Object as PropType<ClientRenamePayload | null>, default: null },
    valid: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);

    const isValid = ref(false);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updateSettings = (payload: Partial<ClientRenamePayload>) => {
      const newSettings = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newSettings);
    };

    // // // // //

    const clients = ref<Client[]>([]);
    const clientsIsLoading = ref(false);

    const clientsIsDisabled = computed(() => !clients.value.length || clientsIsLoading.value);

    const currentClientId = computed({
      get() {
        return value.value?.clientId || '';
      },
      set(val: string) {
        updateSettings({ clientId: val });
      },
    });

    const currentClient = computed(() => clients.value.find(({ id }) => id === currentClientId.value) ?? null);
    const currentClientName = computed(() => currentClient.value?.name || '');

    const fetchClients = async () => {
      clientsIsLoading.value = true;

      try {
        const res = await ClientsApi.fetchClients();

        clients.value = res.data;
      } finally {
        clientsIsLoading.value = false;
      }
    };

    // // // // //

    const clientNameManualIsDisabled = computed(() => clientsIsLoading.value);

    const currentClientNameManual = computed({
      get() {
        return value.value?.clientNameManual || '';
      },
      set(val: string) {
        updateSettings({ clientNameManual: val });
      },
    });

    // // // // //

    const taxonomyClientCategories = ref<TaxonomyClientCategory[]>([]);
    const taxonomyClientCategoriesIsLoading = ref(false);

    const taxonomyClientCategoriesIsDisabled = computed(() => !taxonomyClientCategories.value.length || taxonomyClientCategoriesIsLoading.value);

    const currentTaxonomyClientCategoriesIds = computed({
      get() {
        return value.value?.clientCategoriesIds || [];
      },
      set(val: string[]) {
        updateSettings({ clientCategoriesIds: val });
      },
    });

    const fetchTaxonomyClientCategories = async () => {
      taxonomyClientCategoriesIsLoading.value = true;

      try {
        const res = await TaxonomyApi.fetchClientCategories();

        taxonomyClientCategories.value = res.data;
      } finally {
        taxonomyClientCategoriesIsLoading.value = false;
      }
    };

    // // // // //

    const currentBrandId = computed({
      get() {
        return value.value?.brandId || '';
      },
      set(val: string) {
        updateSettings({ brandId: val });
      },
    });

    const currentClientBrands = computed(() => currentClient.value?.brands ?? []);
    const currentClientBrand = computed(() => currentClientBrands.value.find((brand) => brand.id === currentBrandId.value) ?? null);
    const clientBrandsIsDisabled = computed(() => clientsIsLoading.value || !currentClient.value);

    // // // // //

    const brandNameManualIsDisabled = computed(() => clientsIsLoading.value || !currentClient.value);

    const currentBrandNameManual = computed({
      get() {
        return value.value?.brandNameManual || '';
      },
      set(val: string) {
        updateSettings({ brandNameManual: val });
      },
    });

    // // // // //

    const taxonomyBrandCategories = ref<TaxonomyBrandCategory[]>([]);
    const taxonomyBrandCategoriesIsLoading = ref(false);

    const taxonomyBrandCategoriesIsDisabled = computed(() => !taxonomyBrandCategories.value.length || taxonomyBrandCategoriesIsLoading.value || !currentClient.value);

    const currentTaxonomyBrandCategoriesIds = computed({
      get() {
        return value.value?.brandCategoriesIds || [];
      },
      set(val: string[]) {
        updateSettings({ brandCategoriesIds: val });
      },
    });

    const fetchTaxonomyBrandCategories = async () => {
      taxonomyBrandCategoriesIsLoading.value = true;

      try {
        const res = await TaxonomyApi.fetchBrandCategories();

        taxonomyBrandCategories.value = res.data;
      } finally {
        taxonomyBrandCategoriesIsLoading.value = false;
      }
    };

    // // // // //

    watch(currentClientId, () => {
      if (!currentClient.value) return;

      updateSettings({
        clientNameManual: currentClient.value.name,
        clientCategoriesIds: currentClient.value.categoriesIds,
        brandId: '',
        brandNameManual: '',
        brandCategoriesIds: [],
      });
    });

    watch(currentBrandId, () => {
      if (!currentClientBrand.value) return;

      updateSettings({
        brandNameManual: currentClientBrand.value.name,
        brandCategoriesIds: currentClientBrand.value.categoriesIds,
      });
    });

    const init = async () => {
      await Promise.all([
        fetchClients(),
        fetchTaxonomyClientCategories(),
        fetchTaxonomyBrandCategories(),
      ]);
    };

    init();

    return {
      rootRef,
      isValid,
      resetValidation,
      //
      currentClientNameManual,
      clientNameManualIsDisabled,
      //
      clients,
      currentClient,
      currentClientId,
      currentClientName,
      clientsIsLoading,
      clientsIsDisabled,
      //
      taxonomyClientCategories,
      currentTaxonomyClientCategoriesIds,
      taxonomyClientCategoriesIsLoading,
      taxonomyClientCategoriesIsDisabled,
      //
      currentBrandId,
      currentClientBrands,
      clientBrandsIsDisabled,
      //
      brandNameManualIsDisabled,
      currentBrandNameManual,
      //
      taxonomyBrandCategories,
      taxonomyBrandCategoriesIsLoading,
      taxonomyBrandCategoriesIsDisabled,
      currentTaxonomyBrandCategoriesIds,
    };
  },
});
