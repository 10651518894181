
import Vue, { VueConstructor } from 'vue';
import { Ref } from '@vue/composition-api';

import { BaseEditorComponent } from '@handsontable/vue';
import Handsontable from 'handsontable/base';

import { HnTableChangedCells } from '../types';

import HnTableCellWrapper, { HnTableCellWrapperProps } from '../components/HnTableCellWrapper.vue';

interface HnTableCellWrapperInject {
  hotTableChangedCells: Ref<HnTableChangedCells>;
}

export default (Vue as VueConstructor<Vue & BaseEditorComponent & HnTableCellWrapperInject>).extend({
  components: {
    HnTableCellWrapper,
  },
  extends: BaseEditorComponent,
  inject: [
    'hotTableChangedCells',
  ],
  props: {
    textAlign: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    isFirstCol: { type: Boolean, default: false },
    isLastCol: { type: Boolean, default: false },
  },
  data() {
    return {
      TD: null as HTMLTableCellElement | null,
      hotInstance: null as Handsontable | null,
      cellProperties: null as Handsontable.CellProperties | null,
      originalValue: null as unknown | null,
      value: null as unknown | null,
      row: -1,
      col: -1,
      prop: '',
      isRenderer: false,
      isEditor: false,
      isVisible: false,
      editorStyle: {
        left: '0',
        top: '0',
        width: '0',
        height: '0',
      },
      //
      editorInputFocusActionName: 'focus',
      //
      cellRow: -1,
      cellIsInvalid: false,
    };
  },
  computed: {
    hnTbaleCellWrapperProps(): HnTableCellWrapperProps {
      return {
        ...this.$attrs,
        prop: this.prop,
        isRenderer: this.isRenderer,
        isEditor: this.isEditor,
        isVisible: this.isVisible,
        //
        style: this.isEditor && this.isVisible ? this.editorStyle : undefined,
        textAlign: this.textAlign,
        readonly: this.readonly,
        //
        cellRow: this.cellRow,
        invalid: this.cellIsInvalid,
        //
        isFirstCol: this.isFirstCol,
        isLastCol: this.isLastCol,
      };
    },
    cellHasBeenEdited(): boolean {
      return this.hotTableChangedCells.value.some((cell) => cell.row === this.cellRow && cell.prop === this.prop);
    },
  },
  watch: {
    hotInstance: {
      async handler() {
        await this.$nextTick();

        if (!this.hotInstance || this.hotInstance.isDestroyed) return;

        this.setCellRowWatch();
        this.setCellIsInvalidWatch();
      },
      immediate: true,
    },
  },
  methods: {
    setCellRowWatch() {
      const callback = () => {
        this.cellRow = this.cellProperties.row ?? -1;
      };

      this.hotInstance?.removeHook('afterRender', callback);

      this.hotInstance?.addHook('afterRender', callback);
    },
    setCellIsInvalidWatch() {
      const callback = () => {
        this.cellIsInvalid = !(this.cellProperties?.valid ?? true);
      };

      this.hotInstance?.removeHook('afterValidate', callback);

      this.hotInstance?.addHook('afterValidate', callback);
    },
    //
    prepare(row: number, col: number, prop: string | number, TD: HTMLTableCellElement, originalValue: unknown, cellProperties: Handsontable.CellProperties) {
      // We'll need to call the `prepare` method from
      // the `BaseEditorComponent` class, as it provides
      // the component with the information needed to use the editor
      // (hotInstance, row, col, prop, TD, originalValue, cellProperties)
      //
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (BaseEditorComponent as any).options.methods.prepare.call(this, row, col, prop, TD, originalValue, cellProperties);

      const appEl = document.getElementById('app');

      if (appEl && !appEl.contains(this.$el)) appEl.appendChild(this.$el);

      const tdPosition = TD.getBoundingClientRect();

      // As the `prepare` method is triggered after selecting
      // any cell, we're updating the styles for the editor element,
      // so it shows up in the correct position.
      this.editorStyle.left = `${tdPosition.left + window.pageXOffset}px`;
      this.editorStyle.top = `${tdPosition.top + window.pageYOffset}px`;
      this.editorStyle.width = `${tdPosition.width}px`;
      this.editorStyle.height = `${tdPosition.height}px`;
    },
    beginEditing(...args: unknown[]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (BaseEditorComponent as any).options.methods.beginEditing.call(this, ...args);

      this.focus();
    },
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    async focus() {
      await this.$nextTick();

      const input = this.$refs.editorInput as HTMLElement;

      if (!input) return;

      const inputFocusAction = input[this.editorInputFocusActionName];

      if (typeof inputFocusAction !== 'function') return;

      input[this.editorInputFocusActionName]();
    },
    setValue(value: unknown | null) {
      this.value = value;
    },
    getValue() {
      return this.value;
    },
  },
});
