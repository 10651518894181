/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import {
  RolesApi,
  ServiceTypesApi,
  OrganizationsApi,
} from '@/api';

import { Role } from '@/models/Role';
import { ServiceType } from '@/models/ServiceType';
import { Organization } from '@/models/Organization';

class ModuleState {
  isLoading = false;

  role: Role | null = null;

  serviceTypes: ServiceType[] = [];

  serviceOrganizations: Organization[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get role() {
    return this.state.role;
  }

  get serviceTypes() {
    return this.state.serviceTypes;
  }

  get serviceOrganizations() {
    return this.state.serviceOrganizations;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_ROLE(payload: Role) {
    this.state.role = payload;
  }

  SET_SERVICE_TYPES(payload: ServiceType[]) {
    this.state.serviceTypes = payload;
  }

  SET_SERVICE_ORGANIZATIONS(payload: Organization[]) {
    this.state.serviceOrganizations = payload;
  }

  RESET_ROLE() {
    const defaultState = new ModuleState();

    this.state.isLoading = defaultState.isLoading;
    this.state.role = defaultState.role;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_ROLE(roleId: string) {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await RolesApi.fetchRole(roleId);

      this.mutations.SET_ROLE(res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async FETCH_SERVICE_TYPES() {
    const res = await ServiceTypesApi.fetchServiceTypes();

    this.mutations.SET_SERVICE_TYPES(res.data);
  }

  async FETCH_SERVICE_ORGANIZATIONS() {
    const res = await OrganizationsApi.fetchServiceOrganizations();

    this.mutations.SET_SERVICE_ORGANIZATIONS(res.data);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useRoleDetailsStore = createComposable(module);

export default module;
