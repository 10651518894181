import { useOidc, oidcToRefs } from '@shared/vue-oidc';

import { setBaseUrl, setRequestInterceptor } from '@/api/httpClient';

import PluginInit from '@/models/PluginInit';

const pluginInit: PluginInit = ({ configuration }) => {
  const oidc = useOidc();

  let baseUrl = '/';

  if (configuration.backendUrl) {
    baseUrl = configuration.backendUrl;
  }

  setBaseUrl(baseUrl);

  setRequestInterceptor((request) => {
    const { oidcAccessToken } = oidcToRefs(oidc);

    if (request.headers && oidcAccessToken.value) {
      request.headers.Authorization = `Bearer ${oidcAccessToken.value}`;
    }

    return request;
  });
};

export default pluginInit;
