import { render, staticRenderFns } from "./UserActionsTable.vue?vue&type=template&id=8507a20c&scoped=true&"
import script from "./UserActionsTable.vue?vue&type=script&lang=ts&"
export * from "./UserActionsTable.vue?vue&type=script&lang=ts&"
import style0 from "./UserActionsTable.vue?vue&type=style&index=0&id=8507a20c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8507a20c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})


/* vuetify-loader */
import installDirectives from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
