/* eslint-disable max-classes-per-file */
import Vue from 'vue';

import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { UnapprovedPlacementsApi } from '@/api';

import { UnapprovedCampaign, UnapprovedPlacement } from '@/models/UnapprovedPlacements';

interface UnapprovedPlacementsFilters {
  query: string;
  page: number;
}

const initFilters = () => ({
  query: '',
  page: 1,
});

class ModuleState {
  campaigns: UnapprovedCampaign[] = [];

  campaignPlacementsMap: Record<string, UnapprovedPlacement[]> = {};

  filters: UnapprovedPlacementsFilters = initFilters();

  filtersChanged = false;

  isLoading = false;
}

class ModuleGetters extends Getters<ModuleState> {
  get campaigns() {
    return this.state.campaigns;
  }

  get getCampaignPlacements() {
    return (campaignId: string) => this.state.campaignPlacementsMap[campaignId] || [];
  }

  get filters() {
    return this.state.filters;
  }

  get filtersChanged() {
    return this.state.filtersChanged;
  }

  get isLoading() {
    return this.state.isLoading;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_CAMPAIGNS(payload: UnapprovedCampaign[]) {
    this.state.campaigns = payload;
  }

  ADD_CAMPAIGNS(payload: UnapprovedCampaign[]) {
    this.state.campaigns = [...this.state.campaigns, ...payload];
  }

  SET_CAMPAIGN_PLACEMENTS(payload: { campaignId: string; placements: UnapprovedPlacement[] }) {
    Vue.set(this.state.campaignPlacementsMap, payload.campaignId, payload.placements);
  }

  SET_FILTERS(payload: UnapprovedPlacementsFilters) {
    this.state.filters = payload;
  }

  SET_FILTERS_CHANGED(payload: boolean) {
    this.state.filtersChanged = payload;
  }

  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_CAMPAIGNS() {
    const { query, page } = this.getters.filters;

    this.commit('SET_IS_LOADING', true);

    try {
      const res = await UnapprovedPlacementsApi.fetchCampaignsV2({ params: { searchQuery: query, page } });

      if (page === 1) {
        this.commit('SET_CAMPAIGNS', res.data.list);
      } else {
        this.commit('ADD_CAMPAIGNS', res.data.list);
      }
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async FETCH_PLACEMENTS_BY_CAMPAIGN(campaignId: string) {
    const res = await UnapprovedPlacementsApi.fetchPlacementsByCampaignV2(campaignId);

    this.commit('SET_CAMPAIGN_PLACEMENTS', {
      campaignId,
      placements: res.data,
    });
  }

  UPDATE_FILTERS(filters: Partial<UnapprovedPlacementsFilters>) {
    const page = filters.page || 1;

    const newFilters = {
      ...this.getters.filters,
      ...filters,
      page,
    };

    this.commit('SET_FILTERS', newFilters);
    this.commit('SET_FILTERS_CHANGED', true);
  }

  NEXT_PAGE() {
    this.dispatch('UPDATE_FILTERS', {
      page: this.getters.filters.page + 1,
    });
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
    this.commit('SET_FILTERS_CHANGED', false);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useUnapprovedPlacementsStore = createComposable(module);
export const unapprovedPlacementsStoreMapper = createMapper(module);

export default module;
