










import { defineComponent, toRefs, inject, ref, computed, PropType, Ref } from '@vue/composition-api';

import moment from 'moment';

import { DDatePeriodPickerInline } from '@shared/druid-kit';

import { Campaign } from '@/models/Campaign';

export default defineComponent({
  components: {
    DDatePeriodPickerInline,
  },
  props: {
    btnClass: { type: String, default: '' },
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props) {
    const { campaign } = toRefs(props);

    const queryFilters = inject<Ref<{ from?: string; to?: string }>>('queryFilters', ref({}));
    const updateQueryFilters = inject<(value: { [key: string]: string }) => void>('updateQueryFilters');

    const campaignStartDate = computed(() => moment(campaign.value.startDate).format('YYYY-MM-DD'));
    const campaignEndDate = computed(() => moment(campaign.value.endDate).format('YYYY-MM-DD'));

    const campaignFilters = computed({
      get() {
        return {
          from: queryFilters.value?.from || campaignStartDate.value,
          to: queryFilters.value?.to || campaignEndDate.value,
        };
      },
      set(val: { [key: string]: string }) {
        if (updateQueryFilters) updateQueryFilters(val);
      },
    });

    const yesterday = moment().add(-1, 'days').startOf('day').format('YYYY-MM-DD');

    const presets = ['yesterday', 'last-7-days', 'last-30-days', 'last-week', 'last-month'];

    const customPresets = computed(() => [
      {
        label: 'За всю кампанию',
        icon: 'mdi-calendar-week',
        value: {
          from: '',
          to: '',
        },
      },
      {
        label: 'С начала по вчера',
        icon: 'mdi-calendar-week',
        value: {
          from: campaignStartDate.value,
          to: yesterday,
        },
      },
    ]);

    return {
      presets,
      customPresets,
      campaignFilters,
    };
  },
});
