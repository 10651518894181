





























import { defineComponent, toRefs, PropType, computed } from '@vue/composition-api';

import moment from 'moment';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import modally from '@/services/modally';

import { Token } from '@/models/Token';

import { useProviderTokensStore } from '../../../store/index';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    item: { type: Object as PropType<Token>, required: true },
  },
  setup(props) {
    const {
      item,
    } = toRefs(props);

    const providerTokensStore = useProviderTokensStore();

    const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');

    const creationDate = computed(() => formatDate(item.value.createdOn));
    const expirationDate = computed(() => formatDate(item.value.expirationDate));

    const deleteToken = async () => {
      const result = await modally.show({
        type: 'confirm',
        status: 'warning',
        title: item.value.name,
        content: 'Вы действительно хотите удалить доступ?',
      });

      if (!result) return;

      await providerTokensStore.dispatch('DELETE_TOKEN', {
        tokenId: item.value.id,
        tokenProviderId: item.value.tokenProviderId,
      });
    };

    return {
      creationDate,
      expirationDate,
      deleteToken,
    };
  },
});
