































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { DAutocomplete } from '@shared/druid-kit';

import AppBrandCategoriesTreeView from '@/components/_newDesign/common/inputs/AppBrandCategoriesTreeView.vue';

import { TaxonomyApi } from '@/api';

import { SetCampaignCategoriesPayload } from '@/api/modules/campaigns.api';

import { TaxonomyClientCategory, TaxonomyBrandCategory } from '@/models/Taxonomy';

import { CampaignType } from '@/models/Campaign';

export default defineComponent({
  components: {
    DAutocomplete,
    AppBrandCategoriesTreeView,
  },
  props: {
    value: { type: Object as PropType<SetCampaignCategoriesPayload['data'] | null>, default: null },
    valid: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);

    const isValid = ref(false);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updateSettings = (payload: Partial<SetCampaignCategoriesPayload['data']>) => {
      const newSettings = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newSettings);
    };

    // // // // //

    const taxonomyClientCategoriesIdsRules = ref([
      (val: string[]) => !!val.length,
    ]);

    const taxonomyClientCategories = ref<TaxonomyClientCategory[]>([]);
    const taxonomyClientCategoriesIsLoading = ref(false);

    const taxonomyClientCategoriesIsDisabled = computed(() => !taxonomyClientCategories.value.length || taxonomyClientCategoriesIsLoading.value);

    const currentTaxonomyClientCategoriesIds = computed({
      get() {
        return value.value?.clientCategoriesIds || [];
      },
      set(val: string[]) {
        updateSettings({ clientCategoriesIds: val });
      },
    });

    const fetchTaxonomyClientCategories = async () => {
      taxonomyClientCategoriesIsLoading.value = true;

      try {
        const res = await TaxonomyApi.fetchClientCategories();

        taxonomyClientCategories.value = res.data;
      } finally {
        taxonomyClientCategoriesIsLoading.value = false;
      }
    };

    // // // // //

    const taxonomyBrandCategoriesIdsRules = ref([
      (val: string[]) => !!val.length,
    ]);

    const taxonomyBrandCategories = ref<TaxonomyBrandCategory[]>([]);
    const taxonomyBrandCategoriesIsLoading = ref(false);

    const taxonomyBrandCategoriesIsDisabled = computed(() => !taxonomyBrandCategories.value.length || taxonomyBrandCategoriesIsLoading.value);

    const currentTaxonomyBrandCategoriesIds = computed({
      get() {
        return value.value?.brandCategoriesIds || [];
      },
      set(val: string[]) {
        updateSettings({ brandCategoriesIds: val });
      },
    });

    const fetchTaxonomyBrandCategories = async () => {
      taxonomyBrandCategoriesIsLoading.value = true;

      try {
        const res = await TaxonomyApi.fetchBrandCategories();

        taxonomyBrandCategories.value = res.data;
      } finally {
        taxonomyBrandCategoriesIsLoading.value = false;
      }
    };

    // // // // //

    const taxonomyCampaignTypeIdRules = ref([
      (val: string) => !!val,
    ]);

    const taxonomyCampaignTypes = ref<CampaignType[]>([]);
    const taxonomyCampaignTypesIsLoading = ref(false);

    const taxonomyCampaignTypesIsDisabled = computed(() => !taxonomyCampaignTypes.value.length || taxonomyCampaignTypesIsLoading.value);

    const currentTaxonomyCampaignTypeId = computed({
      get() {
        return value.value?.campaignTypeId || '';
      },
      set(val: string) {
        updateSettings({ campaignTypeId: val });
      },
    });

    const fetchTaxonomyCampaignTypes = async () => {
      taxonomyCampaignTypesIsLoading.value = true;

      try {
        const res = await TaxonomyApi.fetchCampaignTypes();

        taxonomyCampaignTypes.value = res.data;
      } finally {
        taxonomyCampaignTypesIsLoading.value = false;
      }
    };

    // // // // //

    const currentTaxonomyCampaignCategoriesIds = computed({
      get() {
        return value.value?.campaignCategoriesIds || [];
      },
      set(val: string[]) {
        updateSettings({ campaignCategoriesIds: val });
      },
    });

    // // // // //

    const init = async () => {
      await Promise.all([
        fetchTaxonomyClientCategories(),
        fetchTaxonomyBrandCategories(),
        fetchTaxonomyCampaignTypes(),
      ]);
    };

    init();

    return {
      rootRef,
      isValid,
      resetValidation,
      //
      taxonomyClientCategories,
      taxonomyClientCategoriesIdsRules,
      currentTaxonomyClientCategoriesIds,
      taxonomyClientCategoriesIsLoading,
      taxonomyClientCategoriesIsDisabled,
      //
      taxonomyBrandCategories,
      taxonomyBrandCategoriesIdsRules,
      taxonomyBrandCategoriesIsLoading,
      taxonomyBrandCategoriesIsDisabled,
      currentTaxonomyBrandCategoriesIds,
      //
      taxonomyCampaignTypes,
      taxonomyCampaignTypeIdRules,
      taxonomyCampaignTypesIsLoading,
      taxonomyCampaignTypesIsDisabled,
      currentTaxonomyCampaignTypeId,
      //
      currentTaxonomyCampaignCategoriesIds,
    };
  },
});
