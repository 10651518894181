import Vue from 'vue';

import confique from '@/packages/confique';

const configuration = {
  commitSha: '$VUE_APP_CI_COMMIT_SHA',
  backendUrl: '$VUE_APP_BACKEND_URL',
  handsontableLicenseKey: '$VUE_APP_HANDSONTABLE_LICENSE_KEY',
};

const confiqueInstance = confique<typeof configuration>(configuration);

export type Configuration = typeof confiqueInstance;

Vue.configuration = confiqueInstance;
Vue.prototype.$configuration = confiqueInstance;

export default confiqueInstance;
