




import { defineComponent, computed, watch } from '@vue/composition-api';

import useRouter from '@/packages/hooks/useRouter';
import useRoute from '@/packages/hooks/useRoute';

import AppView from '@/components/_newDesign/common/AppView.vue';

import store from '@/store';
import authStore from '@/store/auth';

const authStoreContext = authStore.context(store);

export default defineComponent({
  components: {
    AppView,
  },
  setup(props, context) {
    const router = useRouter(context);
    const route = useRoute(context);

    const user = computed(() => authStoreContext.getters.user);
    const sourceUrl = computed(() => (route.value.query.source_url || '/') as string);

    watch(user, (val) => {
      if (!val) return;

      router.replace(sourceUrl.value, () => { /* empty */ }); // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
    }, { immediate: true });
  },
});
