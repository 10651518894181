











































import { defineComponent, toRefs, ref, computed, PropType } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import SearchBreadcrumbs from '../SearchBreadcrumbs.vue';
import SearchListItem from './SearchListItem.vue';

import { useSearchStore } from '../../store';

import { SearchResultListItem } from '../../models';

export default defineComponent({
  components: {
    DBtnInline,
    SearchBreadcrumbs,
    SearchListItem,
  },
  props: {
    title: { type: String, default: '' },
    items: { type: Array as PropType<SearchResultListItem[]>, default: () => [] },
  },
  setup(props) {
    const { items } = toRefs(props);

    const searchStore = useSearchStore();

    const limit = ref(5);
    const allItemsIsShown = ref(false);

    const searchResultContainsOnlyOneItem = computed(() => searchStore.getters.resultContainsOnlyOneItem);
    const btnToggleAllItemsIsShown = computed(() => !allItemsIsShown.value && limit.value < items.value.length);

    const visibleItems = computed(() => {
      if (allItemsIsShown.value) return items.value;

      return items.value.slice(0, limit.value);
    });

    const firstVisibleItem = computed(() => visibleItems.value[0]);

    const breadcrumbsIsShown = computed(() => {
      if (!firstVisibleItem.value) return false;

      return !!firstVisibleItem.value.breadcrumbs?.length && searchResultContainsOnlyOneItem.value;
    });

    const toggleAllItems = () => {
      allItemsIsShown.value = !allItemsIsShown.value;
    };

    return {
      btnToggleAllItemsIsShown,
      allItemsIsShown,
      visibleItems,
      firstVisibleItem,
      breadcrumbsIsShown,
      toggleAllItems,
    };
  },
});
