

































import { defineComponent, computed } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import useRoute from '@/packages/hooks/useRoute';

import store from '@/store';
import navigationUiStore from '@/store/navigation/navigationUi';

const navigationUiStoreContext = navigationUiStore.context(store);

export default defineComponent({
  components: {
    DBtnInline,
  },
  setup(props, context) {
    const route = useRoute(context);

    const footerMinHeight = computed(() => navigationUiStoreContext.getters.footerMinHeight);
    const linksOnRight = computed(() => !!route.value.meta?.footer?.linksOnRight);

    return {
      footerMinHeight,
      linksOnRight,
    };
  },
});
