























import { defineComponent, computed, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';

import DashboardHeader from '../components/Dashboard/DashboardHeader.vue';
import DashboardChart from '../components/Dashboard/DashboardChart/DashboardChart.vue';
import DashboardLabels from '../components/Dashboard/DashboardLabels.vue';

import { useDashboardStore } from '../store/dashboard';

export default defineComponent({
  components: {
    AppView,
    DashboardHeader,
    DashboardChart,
    DashboardLabels,
  },
  setup() {
    const { updateIsLoading } = useLayoutLoadingStatus();

    const dashboardStore = useDashboardStore();

    const statisticsFilters = computed(() => dashboardStore.getters.filters);

    const fetch = async () => {
      await dashboardStore.actions.FETCH_STATISTICS();
    };

    watch(statisticsFilters, () => {
      fetch();
    });

    const init = async () => {
      updateIsLoading(true);

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    init();
  },
});
