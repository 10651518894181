













import { defineComponent, toRefs, ref, computed, PropType } from '@vue/composition-api';

import { DAutocomplete } from '@shared/druid-kit';

import { UsersApi } from '@/api';

import { UserLight } from '@/models/User';

export default defineComponent({
  components: {
    DAutocomplete,
  },
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'name',
    },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const users = ref<UserLight[]>([]);
    const isLoading = ref(false);

    const selectedUsers = computed({
      get() {
        return value.value;
      },
      set(val) {
        context.emit('input', val);
      },
    });

    const isDisabled = computed(() => !users.value.length || isLoading.value);

    const init = async () => {
      isLoading.value = true;

      try {
        const res = await UsersApi.fetchUsers({ limit: -1 });

        users.value = res.data.list;
      } finally {
        isLoading.value = false;
      }
    };

    init();

    return {
      selectedUsers,
      users,
      isLoading,
      isDisabled,
    };
  },
});
