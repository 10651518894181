




























































































































import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';

import {
  DAccordion,
  DBtnUpload,
} from '@shared/druid-kit';

import { FactUploadApi } from '@/api';

import isAxiosError from '@/utils/isAxiosError';
import isError from '@/utils/isError';

import AppJsonError from '@/services/errorHandler/AppJsonError';

export default defineComponent({
  components: {
    DAccordion,
    DBtnUpload,
  },
  setup() {
    const isLoading = ref(false);
    const uploadIsSuccess = ref(false);
    const uploadIsError = ref(false);

    const errors = ref([]);

    const resetUploadStatuses = () => {
      uploadIsSuccess.value = false;
      uploadIsError.value = false;
    };

    const onUploadFactBtnClick = () => {
      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Fact',
        event_action: 'Upload',
        event_label: 'Selecting a file',
      });
    };

    const uploadFact = async (formData: FormData) => {
      resetUploadStatuses();

      isLoading.value = true;

      try {
        await FactUploadApi.uploadFile(formData);

        uploadIsSuccess.value = true;

        Vue.gtm.push({
          event: 'user_action',
          event_category: 'Fact',
          event_action: 'Upload',
          event_label: 'Success',
        });
      } catch (error) {
        uploadIsError.value = true;

        Vue.gtm.push({
          event: 'user_action',
          event_category: 'Fact',
          event_action: 'Upload',
          event_label: 'Fail',
        });

        if (!isError(error)) return;

        if (isAxiosError(error) && error.response?.data.errors) {
          errors.value = error.response.data.errors;
        } else {
          throw new AppJsonError({ error, options: { showingModal: false } });
        }
      } finally {
        isLoading.value = false;
      }
    };

    const problemItems = [
      {
        title: 'Ошибки в заголовках — некорректная таблица',
        content: 'Это значит, что надо использовать шаблон: нельзя менять название колонок',
      },
      {
        title: 'Ошибки формата',
        content: 'Это значит, что если вы используйте формат данных "Дата" экселя 15.12.2018, а у вас написано "15 декабря 18" => эксель не знает, что это дата => мы тоже.',
      },
      {
        title: 'Ошибки неполных данных. Например, отсутствуют Clicks',
        content: 'Это значит, что надо заполнить эти ячейки — они обязательны для заполнения.',
      },
      {
        title: 'Ошибки данных',
        content: `
        <ul>
          <li>Повторяющиеся записи по DANBOID и дате — значение для даты и одного DANBOID должно быть одно. Просуммируйте самостоятельно.</li>
          <li>Пересекающиеся периоды во вкладке Month — обнаружены пересекающиеся интервалы дат для DANBOID — можно склеить интервалы дат и просуммировать значения по одному DANBOID => увеличить интервал в строке с DANBOID, вторую (или все остальные) строку — удалить.</li>
          <li>Пересекающиеся данные на листах — если лист месячных данных заполнен И заполнен лист дневных данных по одним и тем же данным, мы считаем, что данные наиболее полные по дням (если они покрывают период за месяц). В остальных случаях отдаём ошибку.</li>
        </ul>
        `,
      },
      {
        title: 'Топ ошибок для самопроверки',
        /* eslint-disable global-require */
        content: `
        <ul>
          <li>Объединенные ячейки</li>
          <li>Неправильные заголовки</li>
          <li>Дата старта позже даты окончания</li>
          <li>Формулы в ячейках (отправляйте файлы без формул, чтобы наверняка)</li>
          <li>Большое количество знаков после запятой</li>
          <li>
            Проблемы с форматом данных
            <ul>
              <li>если в ячейке стоит формат даты, должен остаться формат даты (всё привязано к вашим локальным настройкам),</li>
              <li>если стоит формат цифры — оставляем цифру (смотрите за разделителями дробных значений: запятые или точки в зависимости от локальных настроек вашего экселя),</li>
              <li>
                <div class="mb-2">
                  настройка "Задать указанную точность", в параметрах Excel должна быть отжата:
                </div>
                <img src="${require('../assets/img/excel_settings.png')}" alt="excel_settings" width="840"/>
              </li>
            </ul>
          </li>
        </ul>
        `,
        /* eslint-enable */
      },
    ];

    return {
      isLoading,
      onUploadFactBtnClick,
      uploadFact,
      uploadIsSuccess,
      uploadIsError,
      errors,
      problemItems,
    };
  },
});
