import { AxiosResponse } from 'axios';

import { httpClient } from '@/api';

type DownloadFileOptions = {
  url: string;
  name?: string;
  params?: Record<string, unknown>;
} | {
  name?: string;
  fetcher: () => AxiosResponse<Blob>;
};

const downloadFile = async (options: DownloadFileOptions): Promise<void> => {
  const fetcher = 'fetcher' in options ? options.fetcher : () => httpClient.get<Blob>(options.url, {
    responseType: 'blob',
    params: options.params,
  });

  const res = await fetcher();

  const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
  const fileLink = document.createElement('a');

  const fileName = options.name || res.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/"/g, '');

  fileLink.href = fileUrl;
  fileLink.setAttribute('download', fileName);

  document.body.appendChild(fileLink);

  fileLink.click();
  fileLink.remove();

  window.URL.revokeObjectURL(fileUrl);
};

export default downloadFile;
