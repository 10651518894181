

























import Vue from 'vue';
import { defineComponent, computed, PropType, toRefs } from '@vue/composition-api';

import MetricsTable from '@/components/_newDesign/common/MetricsTable/MetricsTable.vue';

import { MetricCell } from '@/components/_newDesign/common/MetricsTable/MetricsTableRow.vue';

import CampaignPlacementsTableRow from '@/components/_newDesign/Campaigns/CampaignPlacementsTable/CampaignPlacementsTableRow.vue';

import { Placement } from '@/models/Placement';

import { useMetricTypesStore } from '@/store/reference/metricTypes';

export default defineComponent({
  components: {
    MetricsTable,
    CampaignPlacementsTableRow,
  },
  props: {
    placements: { type: Array as PropType<Placement[]>, default: () => [] },
    metrics: { type: Array as PropType<string[]>, default: () => ['Cost', 'Impressions', 'Clicks', 'CTR', 'CR'] },
  },
  setup(props) {
    const {
      placements,
      metrics,
    } = toRefs(props);

    const metricTypesStore = useMetricTypesStore();

    const metricCells = computed<MetricCell[]>(() => metrics.value.map((metricName) => {
      const metric = metricTypesStore.getters.getMetricType(metricName);

      return {
        metric: {
          name: metricName,
        },
        planInsteadProgress: metric?.format === 'Percent',
        onlyFact: !metric?.canBePlanned,
        disabledTooltip: !metric?.canBePlanned,
      };
    }));

    const headers = computed(() => [
      {
        class: '',
        text: '',
        value: 'name',
        sortable: false,
      },
      ...Vue.acl.check('markups_edit') ? [
        {
          text: '',
          value: '',
          sortable: false,
        },
      ] : [],
      {
        text: '',
        value: '',
        sortable: false,
      },
      {
        text: '',
        value: '',
        sortable: false,
      },
      ...metricCells.value.map((metricCell) => ({
        class: 'caption',
        text: metricCell.metric.name,
        value: `metricsTotal.${metricCell.metric.name}`,
        sortable: false,
      })),
    ]);

    const activePlacements = computed(() => placements.value.filter(({ state }) => state.code !== 'finished'));
    const activePlacementsCount = computed(() => activePlacements.value.length);

    return {
      metricCells,
      headers,
      activePlacementsCount,
    };
  },
});
