




































import Vue from 'vue';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';

import { DSelectInline } from '@shared/druid-kit';

import useRouter from '@/packages/hooks/useRouter';
import { useClientsStore } from '@/store/clients';

import { FetchClientsV2Payload } from '@/api/modules/clients.api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import ClientCatalogContent from '@/components/_newDesign/Clients/ClientCatalog/ClientCatalogContent.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';
import { useClientPaths } from '@/composables/client';

export default defineComponent({
  components: {
    DSelectInline,
    AppView,
    ClientCatalogContent,
  },
  setup(props, context) {
    const router = useRouter(context);

    const clientsStore = useClientsStore();

    const { updateIsLoading } = useLayoutLoadingStatus();

    const clientsFiltersStateOptions: { text: string; value: string }[] = [
      { text: 'Все клиенты', value: 'all' },
      { text: 'Только архивные клиенты', value: 'archive' },
      { text: 'С активными кампаниями', value: 'active' },
    ];

    const clients = computed(() => clientsStore.getters.clients);
    const clientsIsLoading = computed(() => clientsStore.getters.isLoading);
    const clientsFilters = computed(() => clientsStore.getters.filters);
    const clientsFiltersChanged = computed(() => clientsStore.getters.filtersChanged);

    const activeState = computed({
      get() {
        return clientsStore.getters.filters.state;
      },
      set(val: FetchClientsV2Payload['state']) {
        clientsStore.actions.UPDATE_FILTERS({ state: val });
      },
    });

    const fetchClients = async () => {
      await clientsStore.actions.FETCH_CLIENTS();
    };

    const resetClientsFilters = () => {
      clientsStore.actions.RESET_FILTERS();
    };

    const init = async () => {
      updateIsLoading(true);

      resetClientsFilters();

      try {
        await fetchClients();
      } finally {
        updateIsLoading(false);
      }
    };

    watch(clients, (val) => {
      if (val.length !== 1) return;

      const { path } = useClientPaths({ client: ref(val[0]) });

      router.push(path.value);
    });

    watch(clientsFilters, () => {
      if (!clientsFiltersChanged.value) return;

      fetchClients();
    });

    watch(activeState, (val) => {
      const eventLabels = {
        all: 'All clients',
        archive: 'Archive clients only',
        active: 'With active campaigns',
      } as const;

      Vue.gtm.push({
        event: 'user_action',
        event_category: 'Campaign List',
        event_action: 'Click on clients filtering',
        event_label: eventLabels[val],
      });
    });

    init();

    return {
      activeState,
      clients,
      clientsIsLoading,
      clientsFiltersChanged,
      clientsFiltersStateOptions,
    };
  },
});
