





























import { defineComponent, toRefs, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    categoryHasBeenChanged: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      categoryHasBeenChanged,
      saving,
    } = toRefs(props);

    const saveBtnDisabled = computed(() => !saving.value && categoryHasBeenChanged.value);
    const closeBtnDisabled = computed(() => saving.value);

    const save = () => {
      context.emit('save');
    };

    const cancel = () => {
      context.emit('cancel');
    };

    return {
      saveBtnDisabled,
      closeBtnDisabled,
      cancel,
      save,
    };
  },
});
