


























































































import { defineComponent, ref, computed } from '@vue/composition-api';

import { DSelect, DSelectInline } from '@shared/druid-kit';

import { useDashboardStore, GROUP_BY_OPTIONS_LIST, HISTOGRAM_METRICS, NamedOption, GroupOption } from '../../store/dashboard';

export default defineComponent({
  components: {
    DSelect,
    DSelectInline,
  },
  setup() {
    const dashboardStore = useDashboardStore();

    const statisticsIsLoading = computed(() => dashboardStore.getters.isLoading);
    const statisticsFiltersData = computed(() => dashboardStore.getters.filtersData);
    const statisticsFilters = computed(() => dashboardStore.getters.filters);

    const emptyGroup = ref<GroupOption>({ value: 0, name: 'нет', prop: '' });

    // // //

    const metric = computed(() => dashboardStore.getters.metric);
    const metrics = ref<NamedOption[]>([...HISTOGRAM_METRICS]);

    const currentMetricIdx = computed({
      get() {
        const metricIdx = metrics.value.findIndex(({ name }) => name === metric.value.name) || 0;

        return metricIdx;
      },
      set(val: number) {
        const currentMetric = metrics.value[val];

        dashboardStore.actions.UPDATE_METRIC(currentMetric);
      },
    });

    // // //

    const groups = ref<GroupOption[]>([emptyGroup.value, ...GROUP_BY_OPTIONS_LIST]);

    const currentGroup = computed({
      get() {
        return statisticsFilters.value.groupDataBy;
      },
      set(val: string | number | null) {
        dashboardStore.actions.UPDATE_FILTERS({ groupDataBy: val });
      },
    });

    // // //

    const colorGroups = ref<GroupOption[]>([...GROUP_BY_OPTIONS_LIST]);

    const currentColorGroup = computed({
      get() {
        return dashboardStore.getters.colorGroup;
      },
      set(val: GroupOption) {
        dashboardStore.actions.UPDATE_COLOR_GROUP(val);
      },
    });

    const colorGroupsIsDisabled = computed(() => !!currentGroup.value || statisticsIsLoading.value);

    const updateFilter = (payload: { slug: string; value: string }) => {
      dashboardStore.actions.UPDATE_FILTERS({ [payload.slug]: payload.value });
    };

    // const resetFilters = () => {
    //   dashboardStore.actions.RESET_FILTERS();
    // };

    return {
      statisticsIsLoading,
      statisticsFilters,
      statisticsFiltersData,
      //
      metrics,
      currentMetricIdx,
      //
      groups,
      currentGroup,
      //
      colorGroups,
      currentColorGroup,
      colorGroupsIsDisabled,
      //
      updateFilter,
      // resetFilters,
    };
  },
});
