import { AxiosResponse } from 'axios';

import { Permission } from '@/models/Permission';

import { PartialWithout } from '@/utils/type';

import httpClient from '../httpClient';

interface FetchPermissionsPayload {
  page?: number;
  limit?: number;
  filter?: string;
}

interface FetchPermissionsResponse {
  list: Permission[];
  totalRows: number;
}

export interface UpdatePermissionPayload {
  permissionId: string;
  data: PartialWithout<Permission, 'id'>;
}

export default class PermissionsApi {
  public static async fetchPermissions(payload: FetchPermissionsPayload): Promise<AxiosResponse<FetchPermissionsResponse>> {
    const res = await httpClient.post<FetchPermissionsResponse>('/api/permissions/find', payload);

    return res;
  }

  public static async fetchPermission(permissionId: string): Promise<AxiosResponse<Permission>> {
    const res = await httpClient.get<Permission>(`/api/permissions/${permissionId}`);

    return res;
  }

  public static async setPermission(payload: Permission): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/permissions', payload);

    return res;
  }

  public static async updatePermission(payload: UpdatePermissionPayload): Promise<AxiosResponse<''>> {
    const { permissionId, data } = payload;

    const res = await httpClient.put<''>(`/api/permissions/${permissionId}`, data);

    return res;
  }
}
