import hasOwnProperty from '@/utils/hasOwnProperty';

import { AppJsonErrorPayload } from './types';

export default class AppJsonError extends Error {
  constructor(payload: AppJsonErrorPayload) {
    const error = {
      name: payload.error.name,
      stack: payload.error.stack,
      message: payload.error.message,
    };

    if (payload.error && typeof payload.error === 'object' && hasOwnProperty(payload.error, 'toJSON')) {
      delete payload.error.toJSON;
    }

    super(JSON.stringify({
      error,
      options: payload.options,
      data: payload.error,
    }));

    this.name = 'JSON Error';

    if (typeof payload.error.stack === 'string') {
      this.stack = payload.error.stack;
    }
  }
}
