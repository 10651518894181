





























































import Vue from 'vue';

import { defineComponent, ref, computed, PropType, toRefs, onMounted, watch, nextTick } from '@vue/composition-api';

import goTo from 'vuetify/es5/services/goto';

import useRoute from '@/packages/hooks/useRoute';

import MetricsTable from '@/components/_newDesign/common/MetricsTable/MetricsTable.vue';

import CampaignCategoriesTableRow from '@/components/_newDesign/Campaigns/CampaignCategoriesTable/CampaignCategoriesTableRow.vue';
import CampaignPlacementsTableRow from '@/components/_newDesign/Campaigns/CampaignPlacementsTable/CampaignPlacementsTableRow.vue';

import CampaignCategoriesOrderModal from '@/components/_newDesign/Campaigns/modals/CampaignCategoriesOrderModal.vue';
import CampaignPlacementsOrderModal from '@/components/_newDesign/Campaigns/modals/CampaignPlacementsOrderModal.vue';

import { Campaign, CampaignCategory } from '@/models/Campaign';

export default defineComponent({
  components: {
    MetricsTable,
    CampaignCategoriesTableRow,
    CampaignPlacementsTableRow,
    CampaignCategoriesOrderModal,
    CampaignPlacementsOrderModal,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    campaignCategories: { type: Array as PropType<CampaignCategory[]>, default: () => [] },
  },
  setup(props, context) {
    const route = useRoute(context);
    const expanded = ref<unknown[]>([]);

    const { campaignCategories } = toRefs(props);

    const categoryIdx = computed(() => campaignCategories.value
      .findIndex(({ placements }) => placements.some(({ danboId }) => danboId === route.value.query.danboId)));

    const metricCells = [
      {
        metric: {
          name: 'Cost',
        },
      },
      {
        metric: {
          name: 'Impressions',
        },
      },
      {
        metric: {
          name: 'Clicks',
        },
      },
      {
        metric: {
          name: 'CTR',
        },
        planInsteadProgress: true,
      },
      {
        metric: {
          name: 'CR',
        },
        onlyFact: true,
      },
    ].map((metricCell) => ({
      ...metricCell,
      total: true,
      progressProps: {
        color: 'primary darken-1',
      },
    }));

    const headers = computed(() => [
      {
        class: '',
        text: '',
        value: 'name',
        sortable: false,
      },
      ...(Vue.acl.check('markups_edit') ? [
        {
          text: '',
          value: '',
          sortable: false,
        },
      ] : []),
      {
        text: '',
        value: '',
        sortable: false,
      },
      {
        text: '',
        value: '',
        sortable: false,
      },
      ...metricCells.map((metricCell) => ({
        class: 'caption',
        text: metricCell.metric.name,
        value: `metricsTotal.${metricCell.metric.name}`,
        sortable: false,
      })),
    ]);

    const modalSlugs = [
      'categoriesOrder',
      'placementsOrder',
    ] as const;

    type ModalSlug = typeof modalSlugs[number];

    const modalsMap = ref<Record<ModalSlug, { isShown: boolean, props?: Record<string, unknown> }>>({
      categoriesOrder: {
        isShown: false,
      },
      placementsOrder: {
        isShown: false,
      },
    });

    const openModal = (payload: { slug: ModalSlug, props?: Record<string, unknown> }) => {
      modalsMap.value[payload.slug].props = payload.props;
      modalsMap.value[payload.slug].isShown = true;
    };

    const openCategoriesOrderModal = () => {
      openModal({ slug: 'categoriesOrder' });
    };

    const updateExpanded = (val: unknown[]) => {
      expanded.value = val;
    };

    const scrollToPlacement = async () => {
      if (!route.value.query.danboId || !expanded.value.length) return;

      await nextTick();

      goTo(`#placement-danbo-id-${route.value.query.danboId}`);
    };

    watch(campaignCategories, (val) => {
      if (categoryIdx.value !== -1) {
        expanded.value = [val[categoryIdx.value]];
      } else {
        expanded.value = val.length === 1 ? [val[0]] : expanded.value;
      }
    }, { immediate: true });

    onMounted(() => {
      scrollToPlacement();
    });

    return {
      updateExpanded,
      expanded,
      metricCells,
      headers,
      modalsMap,
      openModal,
      openCategoriesOrderModal,
    };
  },
});
