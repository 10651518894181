


















































import { defineComponent, computed } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';
import useModals from '@/composables/useModals';

import DataProvidersCatalogHeader from '../../components/DataProviders/DataProvidersCatalog/DataProvidersCatalogHeader.vue';
import DataProvidersTable from '../../components/DataProviders/DataProvidersCatalog/DataProvidersTable/DataProvidersTable.vue';

import DataProvidersAddModal from '../../components/DataProviders/modals/DataProvidersAddModal.vue';
import DataProvidersItemModal from '../../components/DataProviders/modals/DataProvidersItemModal.vue';

import { useDataProvidersCatalogStore } from '../../store/dataProviders/dataProvidersCatalog';

export default defineComponent({
  components: {
    AppView,
    DataProvidersCatalogHeader,
    DataProvidersTable,
    DataProvidersAddModal,
    DataProvidersItemModal,
  },
  setup() {
    const { updateIsLoading } = useLayoutLoadingStatus();

    const {
      modalsMap,
      openModal,
    } = useModals(['add', 'item']);

    const dataProvidersCatalogStore = useDataProvidersCatalogStore();

    const dataProviders = computed(() => dataProvidersCatalogStore.getters.dataProviders);
    const dataProvidersIsLoading = computed(() => dataProvidersCatalogStore.getters.isLoading);

    const fetch = async () => {
      await dataProvidersCatalogStore.actions.FETCH_DATA_PROVIDERS();
    };

    const init = async () => {
      updateIsLoading(true);

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    init();

    return {
      modalsMap,
      dataProviders,
      dataProvidersIsLoading,
      openModal,
    };
  },
});
