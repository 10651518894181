/* eslint-disable max-classes-per-file */

import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import { isEqual, orderBy } from 'lodash';

import {
  BenchmarkBoardApi,
} from '@/api';

import { FetchStatisticsPayload } from '@/api/modules/benchmarkBoard.api';

import { BenchmarkBoardStatisticsGroup } from '@/models/BenchmarkBoard';

// TODO: refactor

export const enum GroupByOptions {
  // none = 0,
  dataProvider = 1,
  agency = 2,
  client = 3,
  brand = 4,
  siteName = 5,
  adPlace = 6,
  capping = 7,
  timing = 8,
  geo = 9,
  transmittance = 10,
  audience = 11,
  interests = 12
}

export interface NamedOption {
  value: string | number;
  name: string;
}

export interface GroupOption extends NamedOption {
  prop: string;
}

export interface HistogramGroupMetrics extends BenchmarkBoardStatisticsGroup {
  index: number;
}

type DashboardFilterData = Record<'adPlace' | 'dataProviderName' | 'clientId' | 'brandName' | 'capping' | 'timing' | 'geo' | 'transmittance' | 'audience' | 'siteName', {
  slug: string;
  label: string;
  items: {
    value: string | number | null;
    text: string | number;
  }[];
}>;

export type StatisticsFilters = Record<keyof FetchStatisticsPayload['params'], FetchStatisticsPayload['params'][keyof FetchStatisticsPayload['params']]>;

type StatisticsFiltersValuesMap = Record<keyof StatisticsFilters, FetchStatisticsPayload['params'][keyof FetchStatisticsPayload['params']]>;

export const GROUP_BY_OPTIONS_LIST: GroupOption[] = [
  {
    value: GroupByOptions.dataProvider,
    name: 'источник данных',
    prop: 'dataProviderName',
  },
  {
    value: GroupByOptions.client,
    name: 'клиент',
    prop: 'clientName',
  },
  {
    value: GroupByOptions.brand,
    name: 'бренд',
    prop: 'brandName',
  },
  {
    value: GroupByOptions.capping,
    name: 'частота',
    prop: 'capping',
  },
  {
    value: GroupByOptions.timing,
    name: 'продолжительность',
    prop: 'timing',
  },
  {
    value: GroupByOptions.geo,
    name: 'гео',
    prop: 'geo',
  },
  {
    value: GroupByOptions.transmittance,
    name: 'пропускаемость',
    prop: 'transmittance',
  },
  {
    value: GroupByOptions.audience,
    name: 'аудитория',
    prop: 'audience',
  },
  {
    value: GroupByOptions.siteName,
    name: 'площадка',
    prop: 'siteName',
  },
  {
    value: GroupByOptions.adPlace,
    name: 'формат',
    prop: 'adPlace',
  },
];

export const HISTOGRAM_METRICS: NamedOption[] = [
  {
    value: 'viewability',
    name: 'Viewability %',
  },
  {
    value: 'ctr',
    name: 'CTR %',
  },
  {
    value: 'vtR25',
    name: 'VTR 25%',
  },
  {
    value: 'vtR50',
    name: 'VTR 50%',
  },
  {
    value: 'vtR75',
    name: 'VTR 75%',
  },
  {
    value: 'vtR100',
    name: 'VTR 100%',
  },
  {
    value: 'mobile',
    name: 'Mobile %',
  },
];

class ModuleState {
  filtersData: DashboardFilterData | null = null;

  filters: StatisticsFilters = {
    dataProviderName: null,
    clientId: null,
    brandName: null,
    capping: null,
    timing: null,
    geo: null,
    transmittance: null,
    audience: null,
    siteName: null,
    adPlace: null,
    groupDataBy: 0,
  };

  isLoading = false;

  metric: NamedOption | null = null;

  statistics: BenchmarkBoardStatisticsGroup[] = [];

  colorGroup: GroupOption | null = null;

  group: GroupOption | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get filtersData() {
    return this.state.filtersData;
  }

  get filters() {
    return this.state.filters;
  }

  get filtersAreEnabled() {
    const defaultFilters = new ModuleState().filters;

    return Object.entries(this.getters.filters).some(([key, val]) => {
      const defaultValue = defaultFilters[key];

      return !isEqual(val, defaultValue);
    });
  }

  get metric() {
    return this.state.metric || HISTOGRAM_METRICS[0];
  }

  get colorGroup() {
    return this.state.colorGroup || GROUP_BY_OPTIONS_LIST.find((item) => item.value === GroupByOptions.adPlace) as GroupOption;
  }

  get statistics() {
    const currentMetric = this.getters.metric.value;

    return this.state.statistics
      .filter((data) => parseFloat(data[currentMetric]))
      .map((data, idx) => {
        const index = idx;

        return {
          index,
          ...data,
        };
      });
  }

  get groups() {
    const currentColorGroup = this.getters.colorGroup;

    if (!currentColorGroup) return [];

    return orderBy([...new Set(this.getters.statistics.map((item) => item[currentColorGroup.prop]))]);
  }

  get chartData() {
    const currentColorGroup = this.getters.colorGroup;

    if (!currentColorGroup) return [];

    const formatMetric = (metric: number) => (metric * 100).toFixed(2);

    const groupsDataMap = this.getters.groups.reduce<Record<string, HistogramGroupMetrics[]>>((acc, group) => {
      acc[group] = [];

      return acc;
    }, {});

    this.getters.statistics.forEach((item) => {
      const itemColorGroup = item[currentColorGroup.prop] as string; // temp

      groupsDataMap[itemColorGroup].push(item);
    });

    const currentMetric = this.getters.metric.value;

    return Object.entries(groupsDataMap).reduce<{ index: number; group: string; metric: string }[]>((acc, [group, data]) => {
      const groupData = orderBy(data.map((item) => {
        const formattedMetric = formatMetric(item[currentMetric]);

        return {
          index: item.index,
          group,
          metric: formattedMetric,
        };
      }), (item) => item.metric);

      acc.push(...groupData);

      return acc;
    }, []);
  }

  get statisticsFetchParams() {
    return Object.entries(this.getters.filters).reduce((acc, [key, val]) => {
      acc[key] = val;

      return acc;
    }, {} as StatisticsFiltersValuesMap);
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_FILTERS_DATA(payload: DashboardFilterData) {
    this.state.filtersData = payload;
  }

  SET_FILTERS(payload: StatisticsFilters) {
    this.state.filters = payload;
  }

  SET_METRIC(payload: NamedOption) {
    this.state.metric = payload;
  }

  SET_COLOR_GROUP(payload: GroupOption) {
    this.state.colorGroup = payload;
  }

  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_STATISTICS(payload: BenchmarkBoardStatisticsGroup[]) {
    this.state.statistics = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  INIT_FILTERS_DATA() {
    if (this.getters.filtersAreEnabled) return;

    const filtersMeta = [
      {
        label: 'Источник данных',
        itemValue: 'dataProviderName',
        itemText: 'dataProviderName',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Клиент',
        itemValue: 'clientId',
        itemText: 'clientName',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Бренд',
        itemValue: 'brandName',
        itemText: 'brandName',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Частота',
        itemValue: 'capping',
        itemText: 'capping',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Продолжительность',
        itemValue: 'timing',
        itemText: 'timing',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Гео',
        itemValue: 'geo',
        itemText: 'geo',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Пропускаемость',
        itemValue: 'transmittance',
        itemText: 'transmittance',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Аудитория',
        itemValue: 'audience',
        itemText: 'audience',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Площадка',
        itemValue: 'siteName',
        itemText: 'siteName',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      {
        label: 'Формат',
        itemValue: 'adPlace',
        itemText: 'adPlace',
        allItem: {
          value: null,
          text: 'Все',
        },
      },
      // {
      //   itemValue: 'groupDataBy',
      //   itemText: 'groupDataBy',
      // },
    ] as const;

    const filtersData = filtersMeta.reduce((acc, filterMeta) => {
      acc[filterMeta.itemValue] = {
        slug: filterMeta.itemValue,
        label: filterMeta.label,
        items: [
          filterMeta.allItem,
        ],
      };

      return acc;
    }, {} as Record<typeof filtersMeta[number]['itemValue'], {
      slug: string;
      label: string;
      items: {
        value: string | number | null;
        text: string | number;
      }[];
    }>);

    const cache = {};

    this.getters.statistics.forEach((item) => {
      filtersMeta.forEach((filterMeta) => {
        if (!cache[filterMeta.itemValue]) {
          cache[filterMeta.itemValue] = {};
        }

        const value = item[filterMeta.itemValue];
        const text = item[filterMeta.itemText];

        const itemIsIncluded = cache[filterMeta.itemValue][value] === text;

        if (itemIsIncluded) return;

        cache[filterMeta.itemValue][value] = text;

        filtersData[filterMeta.itemValue].items.push({
          value,
          text,
        });
      });
    });

    this.commit('SET_FILTERS_DATA', filtersData);
  }

  INIT_COLOR_GROUP() {
    const defaultFilters = new ModuleState().filters;

    if (this.getters.filters.groupDataBy === defaultFilters.groupDataBy) return;

    const newColorGroup = GROUP_BY_OPTIONS_LIST.find((item) => item.value === this.getters.filters.groupDataBy) as GroupOption;

    this.actions.UPDATE_COLOR_GROUP(newColorGroup);
  }

  UPDATE_FILTERS(payload: Partial<StatisticsFiltersValuesMap>) {
    const newFilters = Object.entries(payload).reduce((acc, [key, val]) => {
      acc[key] = val;

      return acc;
    }, { ...this.getters.filters });

    this.commit('SET_FILTERS', newFilters);
  }

  UPDATE_METRIC(payload: NamedOption) {
    this.commit('SET_METRIC', payload);
  }

  UPDATE_COLOR_GROUP(payload: GroupOption) {
    this.commit('SET_COLOR_GROUP', payload);
  }

  async FETCH_STATISTICS() {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await BenchmarkBoardApi.fetchStatistics({ params: this.getters.statisticsFetchParams });

      this.commit('SET_STATISTICS', res.data);

      await Promise.all([
        this.dispatch('INIT_FILTERS_DATA'),
        this.dispatch('INIT_COLOR_GROUP'),
      ]);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  // RESET_METRIC() {
  //   this.dispatch('UPDATE_METRIC', HISTOGRAM_METRICS[0]);
  // }

  // RESET_COLOR_GROUP() {
  //   this.dispatch('UPDATE_COLOR_GROUP', null);
  // }

  // RESET_FILTERS() {
  //   const defaultFilters = new ModuleState().filters;

  //   this.dispatch('UPDATE_FILTERS', defaultFilters);

  //   this.dispatch('RESET_METRIC');
  //   this.dispatch('RESET_COLOR_GROUP');
  // }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useDashboardStore = createComposable(module);

export default module;
