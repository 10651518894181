






import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import { DBreadcrumbs } from '@shared/druid-kit';

import { Markup } from '@/models/Markup';

export default defineComponent({
  components: {
    DBreadcrumbs,
  },
  props: {
    markup: { type: Object as PropType<Markup>, required: true },
  },
  setup(props) {
    const { markup } = toRefs(props);

    const breadcrumbs = computed(() => [
      {
        label: 'Наценки',
        path: '/markups',
      },
      {
        label: markup.value.placementName,
        path: `/markups/${markup.value.id}`,
      },
    ]);

    return {
      breadcrumbs,
    };
  },
});
