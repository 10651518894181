



































import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import moment from 'moment';

import { DBtnInline } from '@shared/druid-kit';

import { Markup } from '@/models/Markup';

export default defineComponent({
  components: {
    DBtnInline,
  },
  props: {
    markup: { type: Object as PropType<Markup>, required: true },
  },
  setup(props) {
    const { markup } = toRefs(props);

    const fields = computed(() => [
      {
        label: 'Клиент',
        to: `/clients/${markup.value.clientId}`,
        text: markup.value.clientName,
      },
      {
        label: 'Бренд',
        to: `/clients/${markup.value.clientId}/?brandId=${markup.value.brandId}`,
        text: markup.value.brandName,
      },
      {
        label: 'Кампания',
        to: `/campaigns/${markup.value.campaignId}`,
        text: markup.value.campaignName,
      },
      {
        label: 'Площадка',
        text: markup.value.dataProviderName,
      },
      {
        label: 'Плановый период',
        text: `${moment(markup.value.placementStartDate).format('DD.MM.YYYY')} — ${moment(markup.value.placementEndDate).format('DD.MM.YYYY')}`,
      },
      {
        label: 'Период сбора данных',
        text: `${moment(markup.value.statisticsMinDate).format('DD.MM.YYYY')} — ${moment(markup.value.statisticsMaxDate).format('DD.MM.YYYY')}`,
      },
    ]);

    return {
      fields,
    };
  },
});
