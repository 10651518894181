/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import moment from 'moment';
import { isEqual } from 'lodash';

import {
  UserActionsApi,
} from '@/api';

import { FetchUserActionsPayload, FetchUserActionsResponse } from '@/api/modules/userActions.api';

import { UserAction } from '@/models/User';

export type USerActionsCatalogFilters = FetchUserActionsPayload['params'];

const initFilters = () => ({
  filter: '',
  from: moment().add(-1, 'months').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  page: 1,
});

class ModuleState {
  filters: USerActionsCatalogFilters = initFilters();

  isLoading = false;

  userActions: UserAction[] = [];

  userActionsTotalCount = 0;
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get filters() {
    return this.state.filters;
  }

  get filtersAreEnabled() {
    const defaultFilters = initFilters();

    return Object.entries(this.getters.filters).some(([key, val]) => !isEqual(val, defaultFilters[key]));
  }

  get userActions() {
    return this.state.userActions;
  }

  get userActionsTotalCount() {
    return this.state.userActionsTotalCount;
  }

  get userActionsHaveBeenFiltered() {
    return this.getters.filtersAreEnabled;
  }

  get page() {
    return this.state.filters.page;
  }

  get maxPage() {
    return Math.ceil(this.getters.userActionsTotalCount / 50);
  }

  get pageIsFirst() {
    return this.getters.page <= 1;
  }

  get pageIsLast() {
    return this.getters.page >= this.getters.maxPage;
  }

  get nextPage() {
    if (this.getters.pageIsLast) return this.getters.page;

    return this.getters.page + 1;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_FILTERS(payload: USerActionsCatalogFilters) {
    this.state.filters = payload;
  }

  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_USER_ACTIONS(payload: FetchUserActionsResponse) {
    this.state.userActions = payload.list;
    this.state.userActionsTotalCount = payload.totalRows;
  }

  ADD_USER_ACTIONS(payload: FetchUserActionsResponse) {
    this.state.userActions = [...this.state.userActions, ...payload.list];
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  UPDATE_FILTERS(payload: Partial<USerActionsCatalogFilters>) {
    const page = payload.page || 1;

    const newPayload = {
      ...this.getters.filters,
      ...payload,
      page,
    };

    this.commit('SET_FILTERS', newPayload);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
  }

  async FETCH_USER_ACTIONS() {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await UserActionsApi.fetchUserActions({ params: this.getters.filters });

      if (this.getters.pageIsFirst) {
        this.commit('SET_USER_ACTIONS', res.data);
      } else {
        this.commit('ADD_USER_ACTIONS', res.data);
      }
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async TO_NEXT_PAGE() {
    await this.dispatch('UPDATE_FILTERS', { page: this.getters.nextPage });
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useUserActionsCatalogStore = createComposable(module);

export default module;
