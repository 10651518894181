/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import rolesCatalog from './roles/rolesCatalog';
import roleDetails from './roles/roleDetails';

const module = new Module({
  modules: {
    rolesCatalog,
    roleDetails,
  },
});

export const useRolesStore = createComposable(module);

export default module;
