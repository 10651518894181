




















import { defineComponent, PropType, nextTick, onMounted } from '@vue/composition-api';

import goTo from 'vuetify/lib/services/goto';

import useRoute from '@/packages/hooks/useRoute';

import { DTable } from '@shared/druid-kit';

import { UnapprovedPlacement } from '@/models/UnapprovedPlacements';

import UnapprovedPlacementsTableRow from './UnapprovedPlacementsTableRow.vue';

export default defineComponent({
  components: {
    DTable,
    UnapprovedPlacementsTableRow,
  },
  props: {
    placements: { type: Array as PropType<UnapprovedPlacement[]>, default: () => [] },
  },
  setup(props, context) {
    const route = useRoute(context);

    const scrollToPlacement = async () => {
      if (!route.value.query.search) return;

      const scrollToId = `placement-danbo-id-${route.value.query.search}`;
      const scrollToEl = document.getElementById(scrollToId);

      if (!scrollToEl) return;

      await nextTick();

      goTo(`#${scrollToId}`);
    };

    onMounted(() => {
      scrollToPlacement();
    });
  },
});
