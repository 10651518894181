/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import { DataProvidersApi } from '@/api';

import { DataProvider, DataProviderSite, DataProviderType } from '@/models/DataProvider';

class ModuleState {
  isLoading = false;

  dataProvider: DataProvider | null = null;

  sites: DataProviderSite[] = [];

  types: DataProviderType[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get dataProvider() {
    return this.state.dataProvider;
  }

  get sites() {
    return this.state.sites;
  }

  get types() {
    return this.state.types;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_DATA_PROVIDER(payload: DataProvider) {
    this.state.dataProvider = payload;
  }

  SET_SITES(payload: DataProviderSite[]) {
    this.state.sites = payload;
  }

  SET_TYPES(payload: DataProviderType[]) {
    this.state.types = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_DATA_PROVIDER(providerId: string) {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await DataProvidersApi.fetchDataProvider(providerId);

      this.commit('SET_DATA_PROVIDER', res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async FETCH_SITES() {
    const res = await DataProvidersApi.fetchSites();

    this.commit('SET_SITES', res.data);
  }

  async FETCH_TYPES() {
    const res = await DataProvidersApi.fetchTypes();

    this.commit('SET_TYPES', res.data);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useDataProvidersDetailsStore = createComposable(module);

export default module;
