import { AxiosResponse } from 'axios';

import { Organization } from '@/models/Organization';

import httpClient from '../httpClient';

export default class OrganizationsApi {
  public static async fetchServiceOrganizations(): Promise<AxiosResponse<Organization[]>> {
    const res = await httpClient.get<Organization[]>('/api/organizations/service');

    return res;
  }
}
