

































































import { defineComponent, PropType, toRefs, ref, computed, watch } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { useCampaignEditorPlacementsStore } from '@/store/campaignEditor/campaignEditorPlacements';
import { MetricTypeLight } from '@/models/MetricType';

export default defineComponent({
  components: {
    AppModal,
  },
  props: {
    value: { type: Boolean, default: false },
    filledPlanMetrics: { type: Array as PropType<MetricTypeLight[]>, default: () => [] },
    visiblePlanMetrics: { type: Array as PropType<MetricTypeLight[]>, default: () => [] },
  },
  setup(props, context) {
    const {
      value,
      filledPlanMetrics,
      visiblePlanMetrics,
    } = toRefs(props);

    const campaignEditorPlacementsStore = useCampaignEditorPlacementsStore();

    const selectedPlanMetricsNames = ref<string[]>([]);

    const isLoading = ref(false);

    const campaignPlanMetrics = computed(() => campaignEditorPlacementsStore.getters.campaignPlanMetrics);

    const planMetricsOptions = computed(() => campaignPlanMetrics.value.map((planMetric) => ({
      text: planMetric.displayName,
      value: planMetric.name,
      disabled: filledPlanMetrics.value.some(({ name }) => name === planMetric.name),
    })));

    watch(value, (val: boolean) => {
      if (!val) return;

      selectedPlanMetricsNames.value = visiblePlanMetrics.value.map(({ name }) => name);
    });

    const close = () => {
      context.emit('input', false);
    };

    const save = () => {
      const planMetric = campaignPlanMetrics.value.filter(({ name }) => selectedPlanMetricsNames.value.includes(name));

      campaignEditorPlacementsStore.commit('SET_SELECTED_PLAN_METRICS', planMetric);

      close();
    };

    return {
      isLoading,
      selectedPlanMetricsNames,
      planMetricsOptions,
      save,
    };
  },
});
