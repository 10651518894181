





























import { defineComponent, toRefs, ref, PropType, computed } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import modally from '@/services/modally';

import { Category } from '@/models/Category';

import { useCategoriesCatalogStore } from '../../../../store/categories/categoriesCatalog';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    category: { type: Object as PropType<Category>, required: true },
  },
  setup(props) {
    const {
      category,
    } = toRefs(props);

    const categoriesCatalogStore = useCategoriesCatalogStore();

    const deletion = ref(false);

    const displayableMetricsText = computed(() => category.value.displayableMetrics.join(', '));

    const deleteCategory = async () => {
      const result = await modally.show({
        title: 'Удалить категорию?',
        content: `Категория ${category.value.name} будет удалена.`,
        status: 'warning',
        buttons: [
          {
            props: {
              text: 'Удалить',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary',
            },
            action: () => false,
          },
        ],
      });

      if (!result) return;

      deletion.value = true;

      try {
        await categoriesCatalogStore.actions.DELETE_CATEGORY(category.value.id);
      } finally {
        deletion.value = false;
      }
    };

    return {
      deletion,
      deleteCategory,
      displayableMetricsText,
    };
  },
});
