/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import { DataProvidersSettingsApi } from '@/api';

import { DataProvidersSettingsItem, DataProvidersSettingsToken } from '@/models/DataProvidersSettings';
import { Client } from '@/models/Client';
import { Provider } from '@/models/ProviderSettings';

class ModuleState {
  isLoading = false;

  providerSettings: DataProvidersSettingsItem | null = null;

  providers: Provider[] = [];

  tokens: DataProvidersSettingsToken[] = [];

  statisticsCollectionDepths: string[] = [];

  clients: Client[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get providerSettings() {
    return this.state.providerSettings;
  }

  get providers() {
    return this.state.providers;
  }

  get tokens() {
    return this.state.tokens;
  }

  get statisticsCollectionDepths() {
    return this.state.statisticsCollectionDepths;
  }

  get clients() {
    return this.state.clients;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_PROVIDER_SETTINGS(payload: DataProvidersSettingsItem) {
    this.state.providerSettings = payload;
  }

  SET_PROVIDERS(payload: Provider[]) {
    this.state.providers = payload;
  }

  SET_TOKENS(payload: DataProvidersSettingsToken[]) {
    this.state.tokens = payload;
  }

  SET_STATISTICS_COLLECTION_OF_DEPTH(payload: string[]) {
    this.state.statisticsCollectionDepths = payload;
  }

  SET_CLIENTS(payload: Client[]) {
    this.state.clients = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_PROVIDER_SETTINGS(settingsId: string) {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await DataProvidersSettingsApi.fetchProvider(settingsId);

      this.mutations.SET_PROVIDER_SETTINGS(res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async FETCH_PROVIDERS() {
    const res = await DataProvidersSettingsApi.fetchProviders();

    this.mutations.SET_PROVIDERS(res.data);
  }

  async FETCH_TOKENS(providerId: string) {
    const res = await DataProvidersSettingsApi.fetchTokens({ params: { id: providerId } });

    this.mutations.SET_TOKENS(res.data);
  }

  async FETCH_STATISTICS_COLLECTION_OF_DEPTH(connectorId: string) {
    const res = await DataProvidersSettingsApi.fetchStatisticsCollectionDepths({ params: { id: connectorId } });

    this.mutations.SET_STATISTICS_COLLECTION_OF_DEPTH(res.data);
  }

  async FETCH_CLIENTS() {
    const res = await DataProvidersSettingsApi.fetchClients();

    this.mutations.SET_CLIENTS(res.data);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useDataProvidersSettingsDetailsStore = createComposable(module);

export default module;
