






































import { defineComponent, computed } from '@vue/composition-api';

import ListLoader from '@/components/_newDesign/common/loaders/ListLoader.vue';

import SearchList from './SearchList/SearchList.vue';

import { useSearchStore } from '../store';

export default defineComponent({
  components: {
    SearchList,
    ListLoader,
  },
  setup() {
    const searchStore = useSearchStore();

    const searchResultIsExist = computed(() => searchStore.getters.resultIsExist);
    const searchResultIsEmpty = computed(() => searchStore.getters.resultIsEmpty);
    const searchCategories = computed(() => searchStore.getters.categories);
    const searchIsLoading = computed(() => searchStore.getters.isLoading);

    return {
      searchResultIsExist,
      searchResultIsEmpty,
      searchCategories,
      searchIsLoading,
    };
  },
});
