



















































import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api';

import { DTable } from '@shared/druid-kit';

import { Category } from '@/models/Category';

import useModals from '@/composables/useModals';

import CategoryRulesTableRow from './CategoryRulesTableRow.vue';
import CategoriesAddRuleModal from '../modals/CategoriesAddRuleModal.vue';

export default defineComponent({
  components: {
    DTable,
    CategoryRulesTableRow,
    CategoriesAddRuleModal,
  },
  props: {
    value: { type: Array as PropType<Category['rules']>, required: true },
    loading: { type: Boolean, default: false },
    title: { type: String, default: '' },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const {
      modalsMap,
      openModal,
    } = useModals(['add']);

    const headers = ['Level 1', 'Level 2', 'Device', 'Service Type', '']
      .map((headerText) => ({
        text: headerText,
        class: 'caption',
        sortable: false,
      }));

    const rules = computed({
      get() {
        return value.value;
      },
      set(val: Category['rules']) {
        context.emit('input', val);
      },
    });

    const deleteRule = (idx: number) => {
      rules.value = value.value.filter((rule, ruleIdx) => ruleIdx !== idx);
    };

    const addRule = (newRule: Category['rules'][number]) => {
      rules.value = [...value.value, newRule];
    };

    return {
      headers,
      rules,
      modalsMap,
      openModal,
      deleteRule,
      addRule,
    };
  },
});
