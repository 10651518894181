



























































import { defineComponent, computed } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';
import useModals from '@/composables/useModals';

import RolesCatalogHeader from '../../components/Roles/RolesCatalog/RolesCatalogHeader.vue';
import RolesTable from '../../components/Roles/RolesCatalog/RolesTable/RolesTable.vue';

import RolesAddModal from '../../components/Roles/modals/RolesAddModal.vue';
import RolesItemModal from '../../components/Roles/modals/RolesItemModal.vue';

import { useRolesCatalogStore } from '../../store/roles/rolesCatalog';

export default defineComponent({
  components: {
    AppView,
    RolesCatalogHeader,
    RolesTable,
    RolesAddModal,
    RolesItemModal,
  },
  setup() {
    const { updateIsLoading } = useLayoutLoadingStatus();

    const {
      modalsMap,
      openModal,
    } = useModals(['add', 'item']);

    const rolesCatalogStore = useRolesCatalogStore();

    const roles = computed(() => rolesCatalogStore.getters.roles);
    const rolesIsLoading = computed(() => rolesCatalogStore.getters.isLoading);
    const rolesHaveBeenFiltered = computed(() => rolesCatalogStore.getters.rolesHaveBeenFiltered);

    const fetch = async () => {
      await rolesCatalogStore.actions.FETCH_ROLES();
    };

    const init = async () => {
      updateIsLoading(true);

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    init();

    return {
      modalsMap,
      roles,
      rolesIsLoading,
      rolesHaveBeenFiltered,
      openModal,
    };
  },
});
