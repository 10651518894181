import Vue from 'vue';
import VueRouter from 'vue-router';

import Auth from '@/views/Auth.vue';

import AccessDenied from '@/views/AccessDenied.vue';
import NotFound from '@/views/NotFound.vue';

import clients from '@/router/clients';

import campaigns from '@/router/campaigns';

import store from '@/store';
import authStore from '@/store/auth';
import navigationStore from '@/store/navigation';

import { AppRouteConfig } from '@/models/AppRouter';

const authStoreContext = authStore.context(store);
const navigationStoreContext = navigationStore.context(store);

Vue.use(VueRouter);

const routes: AppRouteConfig[] = [
  {
    path: '/',
    redirect: '/clients',
  },
  {
    path: '/auth',
    component: Auth,
  },
  clients,
  campaigns,
  {
    path: '/wiki', // доступно для Front и BU
    meta: {
      title: 'Руководство пользователя',
      permission: 'view_unapproved_campaigns',
      order: 4,
    },
    beforeEnter: () => window.open('https://guide.danbo.digital/'),
  },
  {
    path: '/help', // доступно для Front и BU
    meta: {
      title: 'Обратная связь',
      permission: 'view_unapproved_campaigns',
      order: 5,
    },
    beforeEnter() {
      const { userEmail } = authStoreContext.getters;

      const pathToForm = `https://docs.google.com/forms/d/e/1FAIpQLSeOdrVX7TX5TBPW3Ch5j91BtWj4uqxW3o73R-iYj2YKbWzIzw/viewform?usp=pp_url&entry.1615998704=${userEmail}`;

      window.open(pathToForm);
    },
  },
  {
    path: '/apps',
    meta: {
      title: 'Приложения',
    },
    beforeEnter: () => window.open('https://sso.okkam.group'),
  },
  {
    path: '/logout',
    meta: {
      title: 'Выход',
    },
    async beforeEnter() {
      await authStoreContext.dispatch('SIGN_OUT');
    },
  },
  {
    path: '/access-denied',
    component: AccessDenied,
    meta: {
      permission: 'any',
      header: {
        visible: false,
      },
      footer: {
        visible: false,
      },
    },
  },
  {
    path: '/not-found',
    component: NotFound,
    meta: {
      permission: 'any',
      header: {
        visible: false,
      },
      footer: {
        visible: false,
      },
    },
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      permission: 'any',
      header: {
        visible: false,
      },
      footer: {
        visible: false,
      },
    },
  },
];

store.dispatch('navigation/ADD_ROUTES', routes);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    if (to.meta.scrollPosition) return to.meta.scrollPosition;

    if (from.meta.scrollPosition) return from.meta.scrollPosition;

    return { x: 0, y: 0 };
  },
});

router.afterEach((to, from) => {
  navigationStoreContext.commit('SET_PREV_ROUTE', from);

  const user = store.getters['auth/user'];

  if (user) {
    router.app.$gtm.push({
      event: 'pageview',
      url: to.fullPath,
      d1d_client_id: to.params.clientId,
      d1d_campaign_id: to.params.campaignId,
      d1d_danbo_id: to.params.danboId,
    });
  }
});

export default router;
