

























import { defineComponent, toRefs, computed, provide, watch } from '@vue/composition-api';

import useRoute from '@/packages/hooks/useRoute';

import AppView from '@/components/_newDesign/common/AppView.vue';

import CampaignAdObjectHeader from '@/components/_newDesign/Campaigns/CampaignAdObject/CampaignAdObjectHeader.vue';
import CampaignAdObjectContent from '@/components/_newDesign/Campaigns/CampaignAdObject/CampaignAdObjectContent.vue';

import useQueryFilters from '@/composables/useQueryFilters';
import { useLayoutLoadingStatus } from '@/composables/layout';
import { useCampaignDetails } from '@/composables/campaign';
import useMeta from '@/composables/meta/useMeta';

import { useCampaignStore } from '@/store/campaign';

export default defineComponent({
  components: {
    CampaignAdObjectHeader,
    CampaignAdObjectContent,
    AppView,
  },
  props: {
    campaignId: { type: String, required: true },
    danboId: { type: String, required: true },
    connectorId: { type: String, required: true },
    level: { type: String, required: true },
    type: { type: String, required: true },
    onSiteId: { type: String, required: true },
  },
  setup(props, context) {
    const route = useRoute(context);

    const {
      campaignId,
      danboId,
      connectorId,
      level,
      type,
      onSiteId,
    } = toRefs(props);

    const campaignStore = useCampaignStore();

    const {
      queryFilters,
      updateQueryFilters,
    } = useQueryFilters({
      filters: {
        from: undefined,
        to: undefined,
      },
    }, context);

    provide('queryFilters', queryFilters);
    provide('updateQueryFilters', updateQueryFilters);

    const {
      updateIsLoading,
      isLoading,
    } = useLayoutLoadingStatus();

    const {
      campaign,
      campaignCategories,
      fetchCampaign,
      campaignPageTitle,
    } = useCampaignDetails({ campaignId });

    const adObject = computed(() => campaignStore.getters.campaignAdObject);

    const campaignCategory = computed(() => campaignCategories.value
      .filter(({ placements }) => placements.find((placement) => placement.danboId === danboId.value))[0] ?? null);

    const pageMeta = computed(() => ({ title: campaignPageTitle.value }));

    useMeta(pageMeta);

    const fetch = async () => {
      updateIsLoading(true);

      try {
        await Promise.all([
          fetchCampaign(),
          campaignStore.dispatch('FETCH_CAMPAIGN_AD_OBJECT', {
            campaignId: campaignId.value,
            danboId: danboId.value,
            connectorId: connectorId.value,
            level: level.value,
            type: type.value,
            onSiteId: onSiteId.value,
            params: queryFilters.value,
          }),
        ]);
      } finally {
        updateIsLoading(false);
      }
    };

    watch(() => route.value, () => fetch());

    fetch();

    return {
      campaign,
      campaignCategory,
      adObject,
      isLoading,
    };
  },
});
