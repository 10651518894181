




























import Vue from 'vue';
import { defineComponent, ref, toRefs, PropType } from '@vue/composition-api';

import { DBtnUpload } from '@shared/druid-kit';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { Campaign } from '@/models/Campaign';

import { useCampaignEditorPlacementsStore } from '@/store/campaignEditor/campaignEditorPlacements';

import modally from '@/services/modally';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';
import AppJsonError from '@/services/errorHandler/AppJsonError';

export default defineComponent({
  components: {
    DBtnUpload,
    AppModal,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const { campaign } = toRefs(props);

    const campaignEditorPlacementsStore = useCampaignEditorPlacementsStore();

    const reports = ref([
      {
        slug: 'placements',
        action: 'UPLOAD_PLACEMENT_PLAN',
        name: 'Загрузить планы',
        gtm: {
          eventAction: 'Upload MediaPlan',
        },
      },
      {
        slug: 'flights',
        action: 'UPLOAD_FLIGHTS_PLAN',
        name: 'Загрузить флайты',
        gtm: {
          eventAction: 'Upload Flights',
        },
      },
    ] as const);

    type Report = typeof reports.value[number];

    const reportsLoadingsMap = ref(reports.value.reduce((map, report) => {
      map[report.slug] = false;

      return map;
    }, {} as Record<Report['slug'], boolean>));

    const close = () => {
      context.emit('input', false);
    };

    const uploadFiles = async (formData: FormData, report: Report) => {
      reportsLoadingsMap.value[report.slug] = true;

      try {
        await campaignEditorPlacementsStore.dispatch(report.action, { campaignId: campaign.value.id, formData });

        Vue.gtm.push({
          event: 'user_action',
          event_category: 'Campaign Editor',
          event_action: report.gtm.eventAction,
          event_label: 'Success',
          d1d_client_id: campaign.value.clientId,
          d1d_client_name: campaign.value.clientName,
          d1d_brand_id: campaign.value.brandId,
          d1d_brand_name: campaign.value.brandName,
          d1d_campaign_id: campaign.value.id,
          d1d_campaign_name: campaign.value.name,
        });

        close();
      } catch (e) {
        Vue.gtm.push({
          event: 'user_action',
          event_category: 'Campaign Editor',
          event_action: report.gtm.eventAction,
          event_label: 'Failed',
          d1d_client_id: campaign.value.clientId,
          d1d_client_name: campaign.value.clientName,
          d1d_brand_id: campaign.value.brandId,
          d1d_brand_name: campaign.value.brandName,
          d1d_campaign_id: campaign.value.id,
          d1d_campaign_name: campaign.value.name,
        });

        if (!isError(e)) return;

        if (isAxiosError(e)) {
          const response = e.response?.data;

          if ((typeof response) !== 'string' && !Array.isArray(response)) return;

          const errors = Array.isArray(response) ? response : [response];

          modally.show({ title: 'Произошла ошибка', content: errors.join(', '), status: 'error' });
        } else {
          throw new AppJsonError({ error: e, options: { showingModal: false } });
        }
      } finally {
        reportsLoadingsMap.value[report.slug] = false;
      }
    };

    return {
      reports,
      uploadFiles,
      reportsLoadingsMap,
    };
  },
});
