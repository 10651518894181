






















































































import { defineComponent, computed } from '@vue/composition-api';

import { DTooltip } from '@shared/druid-kit';

import AppSideBarClient from '@/components/_newDesign/App/AppSideBar/AppSideBarClient.vue';
import AppSideBarMenuItem from '@/components/_newDesign/App/AppSideBar/AppSideBarMenuItem.vue';
import AppSidebarNavMenuLoader from '@/components/_newDesign/App/AppSideBar/AppSidebarNavMenuLoader.vue';

import store from '@/store';
import { useAuthStore } from '@/store/auth';
import navigationStore from '@/store/navigation';

const navigationStoreContext = navigationStore.context(store);

export default defineComponent({
  components: {
    DTooltip,
    AppSideBarClient,
    AppSideBarMenuItem,
    AppSidebarNavMenuLoader,
  },
  props: {
    sideBarIsShown: { type: Boolean, default: false },
  },
  setup() {
    const authStore = useAuthStore();

    const user = computed(() => authStore.getters.user);
    const menu = computed(() => navigationStoreContext.getters.menu);

    return {
      user,
      menu,
    };
  },
});
