/* eslint-disable max-classes-per-file */

import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, Context, createMapper } from 'vuex-smart-module';

import { TaxonomyApi } from '@/api';

import { CampaignType } from '@/models/Campaign';
import { TaxonomyBrandCategory } from '@/models/Taxonomy';

import metricTypes from '@/store/reference/metricTypes';

class ModuleState {
  campaignTypes: CampaignType[] = [];

  brandCategories: TaxonomyBrandCategory[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  modules!: {
    metricTypes: Context<typeof metricTypes>;
  };

  $init(store: Store<unknown>) {
    this.modules = {
      metricTypes: metricTypes.context(store),
    };
  }

  get metricTypes() {
    return this.modules.metricTypes.state.metricTypes;
  }

  get campaignTypes() {
    return this.state.campaignTypes;
  }

  get brandCategories() {
    return this.state.brandCategories;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_CAMPAIGN_TYPES(payload: CampaignType[]) {
    this.state.campaignTypes = payload;
  }

  SET_BRAND_CATEGORIES(payload: TaxonomyBrandCategory[]) {
    this.state.brandCategories = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_CAMPAIGN_TYPES() {
    const res = await TaxonomyApi.fetchCampaignTypes();

    this.commit('SET_CAMPAIGN_TYPES', res.data);
  }

  async FETCH_BRAND_CATEGORIES() {
    const res = await TaxonomyApi.fetchBrandCategories();

    this.commit('SET_BRAND_CATEGORIES', res.data);
  }
}

const module = new Module({
  modules: {
    metricTypes,
  },
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const referenceStoreMapper = createMapper(module);

export default module;
