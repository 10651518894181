/* eslint-disable max-classes-per-file */

import { groupBy } from 'lodash';

import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

import { NotifyItem, NotifyItemPushOptions, NotifyItemId } from '@/services/notify/types';

import { NOTIFY_ITEM_POSITION_DEFAULT } from '../constants';

class ModuleState {
  notifications: NotifyItem[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get notificationsByPositionGroups() {
    const groupedByPosition = groupBy(this.state.notifications, 'position');

    return Object.entries(groupedByPosition).map(([key, value]) => ({
      position: key.split(/,| /),
      list: value,
    }));
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  PUSH_NOTIFICATION(payload: NotifyItem) {
    this.state.notifications.push(payload);
  }

  REMOVE_NOTIFICATION(notificationId: NotifyItemId) {
    this.state.notifications = this.state.notifications.filter(({ id }) => id !== notificationId);
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  PUSH_NOTIFICATION(payload: NotifyItemPushOptions) {
    this.commit('PUSH_NOTIFICATION', {
      position: NOTIFY_ITEM_POSITION_DEFAULT,
      ...payload,
      id: (Math.random().toString(36) + Date.now().toString(36)).substr(2),
    });
  }

  REMOVE_NOTIFICATION(notificationId: NotifyItemId) {
    this.commit('REMOVE_NOTIFICATION', notificationId);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const notifyStoreMapper = createMapper(module);

export default module;
