
































































































import { defineComponent, PropType, toRefs, ref, computed } from '@vue/composition-api';

import {
  DBtnInline,
  DInputInline,
  DRadioInline,
  DSelectInline,
} from '@shared/druid-kit';

import { Campaign, CampaignEditorPlacementDetails } from '@/models/Campaign';

import { useCampaignEditorPlacementDetailsStore } from '@/store/campaignEditor/campaignEditorPlacementDetails';

export default defineComponent({
  components: {
    DBtnInline,
    DRadioInline,
    DSelectInline,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    placement: { type: Object as PropType<CampaignEditorPlacementDetails>, required: true },
    currentPlacement: { type: Object as PropType<CampaignEditorPlacementDetails>, required: true },
  },
  setup(props) {
    const {
      campaign,
      placement,
      currentPlacement,
    } = toRefs(props);

    const campaignEditorPlacementDetailsStore = useCampaignEditorPlacementDetailsStore();

    const referenceDataIsLoading = ref(false);

    const categorySelectOptions = computed(() => campaignEditorPlacementDetailsStore
      .state
      .categories
      .map(({ id, name }) => ({ value: id, text: name })));

    const dataProviderSelectOptions = computed(() => {
      const options: { value: string | null; text: string }[] = campaignEditorPlacementDetailsStore
        .state
        .dataProviders
        .filter(({ name }) => placement.value.metricsDataProviders.includes(name))
        .map(({ id, name }) => ({ value: id, text: name }));

      options.unshift({ value: null, text: 'Сумма по площадкам' });

      return options;
    });

    const placementManualStateSelectOptions = computed(() => {
      const options: { value: number | null; text: string }[] = campaignEditorPlacementDetailsStore
        .state
        .placementManualStates
        .map(({ id, name }) => ({ value: id, text: name }));

      options.unshift({ value: null, text: 'Статус по-умолчанию' });

      return options;
    });

    const changePlacement = (payload: Partial<CampaignEditorPlacementDetails>) => {
      campaignEditorPlacementDetailsStore.dispatch('CHANGE_PLACEMENT_FORM', payload);
    };

    const placementManualState = computed({
      get() {
        return placement.value.manualState ?? null;
      },
      set(val: number | null) {
        changePlacement({ manualState: val ?? undefined });
      },
    });

    const placementStatus = computed(() => {
      const textMap = {
        default: 'Размещение сейчас активно',
        1: 'Размещение на паузе',
        2: 'Размещение идет',
        3: 'Размещение завершено',
      };

      const iconsMap = {
        default: '$d1-play',
        1: 'mdi-timer-sand',
        2: '$d1-play',
        3: 'mdi-check',
      };

      const text = textMap[placementManualState.value || 'default'];
      const icon = iconsMap[placementManualState.value || 'default'];

      return {
        text,
        icon,
      };
    });

    const placementFieldsColumns = computed(() => [
      [

        {
          label: 'Danboid',
          value: currentPlacement.value.danboId,
        },
        {
          label: 'Сайт',
          value: currentPlacement.value.siteName,
          props: {
            label: 'Не выбран',
          },
          listeners: {
            input(val: string) {
              changePlacement({ siteName: val });
            },
          },
          component: DInputInline,
        },
        {
          label: 'Место',
          value: currentPlacement.value.adPlace,
          props: {
            label: 'Не выбрано',
          },
          listeners: {
            input(val: string) {
              changePlacement({ adPlace: val });
            },
          },
          component: DInputInline,
        },
        {
          label: 'Секция',
          value: currentPlacement.value.section,
          props: {
            label: 'Не выбрана',
          },
          listeners: {
            input(val: string) {
              changePlacement({ section: val });
            },
          },
          component: DInputInline,
        },
        {
          label: 'Подсекция',
          value: currentPlacement.value.subsection,
          props: {
            label: 'Не выбрана',
          },
          listeners: {
            input(val: string) {
              changePlacement({ subsection: val });
            },
          },
          component: DInputInline,
        },
        {
          label: 'Категория',
          value: currentPlacement.value.category.categoryId,
          props: {
            items: categorySelectOptions.value,
            loading: referenceDataIsLoading.value,
            disabled: referenceDataIsLoading.value,
            returnObject: true,
          },
          listeners: {
            input(payload: { value: string, text: string }) {
              changePlacement({ category: { categoryId: payload.value, categoryName: payload.text } });
            },
          },
          component: DSelectInline,
        },
      ],
      [

        {
          label: 'Таргетинги',
          value: currentPlacement.value.comment,
          placeholder: '—',
        },
        {
          label: 'Данные плана',
          value: currentPlacement.value.getPlanFrom1C ?? false,
          props: {
            items: [
              { value: false, text: 'Заданы вручную' },
              { value: true, text: 'Взять из 1С' },
            ],
          },
          listeners: {
            async change(val: boolean) {
              if (!val) return changePlacement({ getPlanFrom1C: val });

              await campaignEditorPlacementDetailsStore.dispatch('FETCH_PLACEMENT_FROM_1C', {
                campaignId: campaign.value.id,
                placementDanboId: currentPlacement.value.danboId,
              });
            },
          },
          component: DRadioInline,
          stub: placement.value.hidden ? 'Скрыто' : '',
        },
        {
          label: 'Показ данных',
          value: currentPlacement.value?.cutOffMetrics ?? false,
          props: {
            items: [
              { value: true, text: 'За плановый период' },
              { value: false, text: 'За весь период' },
            ],
          },
          listeners: {
            input(val: boolean) {
              changePlacement({ cutOffMetrics: val });
            },
          },
          component: DSelectInline,
          stub: placement.value.hidden ? 'Скрыто' : '',
        },
        {
          label: 'Источник данных',
          value: currentPlacement.value.preferredDataProviderId,
          props: {
            items: dataProviderSelectOptions.value,
            loading: referenceDataIsLoading.value,
            disabled: referenceDataIsLoading.value,
          },
          listeners: {
            input(val: string) {
              changePlacement({ preferredDataProviderId: val });
            },
          },
          component: DSelectInline,
        },
        {
          label: 'Данные плана размещения',
          value: currentPlacement.value.hidden,
          props: {
            items: [
              { value: true, text: 'Скрыты от всех' },
              { value: false, text: 'Видны вам и клиенту' },
            ],
          },
          slots: {
            appendItem: {
              icon: 'mdi-information-outline',
              content: 'Эта настройка управляет видимостью размещения на вкладке\nнастроек размещения План и на странице Кампании с данными\nплана для вас и клиента.\nФакт размещения можно скрыть/показать для клиента\nна странице Кампании.',
            },
          },
          listeners: {
            input(val: boolean) {
              changePlacement({ hidden: val });
            },
          },
          component: DSelectInline,
        },
      ],
    ]);

    const fetchReferenceData = async () => {
      referenceDataIsLoading.value = true;

      try {
        await Promise.all([
          campaignEditorPlacementDetailsStore.dispatch('FETCH_CATEGORIES'),
          campaignEditorPlacementDetailsStore.dispatch('FETCH_DATA_PROVIDERS'),
          campaignEditorPlacementDetailsStore.dispatch('FETCH_PLACEMENT_MANUAL_STATES'),
        ]);
      } finally {
        referenceDataIsLoading.value = false;
      }
    };

    fetchReferenceData();

    return {
      referenceDataIsLoading,
      placementManualState,
      placementStatus,
      placementManualStateSelectOptions,
      placementFieldsColumns,
    };
  },
});
