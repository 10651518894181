import Vue from 'vue';

import gtmService, { GtmInstanceName, GtmData } from '@/services/gtm';

import PluginInit from '@/models/PluginInit';
import Gtm from '@/models/Gtm';

import store from '@/store';
import authStore from '@/store/auth';

const authStoreContext = authStore.context(store);

const pluginInit: PluginInit = () => {
  gtmService();

  const gtm: Gtm = {
    normalizeData(payload: Partial<GtmData>) {
      const defaultValuesMap: GtmData = {
        d1d_user_id: '-',
        d1d_user_access: [],
        //
        d1d_client_id: '-',
        d1d_client_name: '-',
        //
        d1d_brand_id: '-',
        d1d_brand_name: '-',
        //
        d1d_campaign_id: '-',
        d1d_campaign_name: '-',
        //
        d1d_danbo_id: '-',
      };

      const { user } = authStoreContext.getters;

      const data = {
        d1d_user_id: user?.id || defaultValuesMap.d1d_user_id,
        d1d_user_access: user?.roles || defaultValuesMap.d1d_user_access,
        ...payload,
      };

      const normalizedData = Object.entries(data).reduce<GtmData>((acc, [key, val]) => {
        const trimValue = (value: unknown | string | string[]) => {
          if (!value) return;

          if (typeof value === 'string') {
            return value.trim();
          }

          if (Array.isArray(value)) {
            return value.map((param) => (typeof param === 'string' ? param.trim() : param));
          }
        };

        acc[key] = trimValue(val) || trimValue(defaultValuesMap[key]) || '-';

        return acc;
      }, { ...defaultValuesMap });

      return normalizedData;
    },
    push(payload) {
      const name: GtmInstanceName = 'dataLayer';

      const dataLayer = window[name];

      if (!dataLayer) return;

      const data = this.normalizeData(payload);

      dataLayer.push(data);
    },
  };

  Vue.gtm = gtm;
  Vue.prototype.$gtm = gtm;
};

export default pluginInit;
