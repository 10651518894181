import { VueConstructor } from 'vue';

import Handsontable from 'handsontable';
import moment from 'moment';

import HnTableDateCell from '../components/cells/HnTableDateCell.vue';
import HnTableSelectCell from '../components/cells/HnTableSelectCell.vue';
import HnTableStubCell from '../components/cells/HnTableStubCell.vue';

const hnTableColumnCustomCellTypesMap = {
  stub: 'stub',
} as const;

const hnTableColumnCellTypesMap = {
  text: 'text',
  number: 'numeric',
  date: 'date',
  select: 'dropdown',
  checkbox: 'checkbox',
  ...hnTableColumnCustomCellTypesMap,
} as const;

const hnTableColumnCellTypes = Object.values(hnTableColumnCellTypesMap);

Object.values(hnTableColumnCustomCellTypesMap).forEach((type) => {
  Handsontable.cellTypes.registerCellType(type, {});
});

type HnTableColumnCellType = typeof hnTableColumnCellTypes[number];

type HnTableColumnOptionsHeader = {
  className?: string;
  style?: string;
  text?: string;
};

type HnTableColumnOptionsSettings = {
  type?: HnTableColumnCellType;
  width?: number;
  className?: string;
  readOnly?: boolean;
  disableVisualSelection?: boolean;
  skipColumnOnPaste?: boolean;
  editor?: boolean;
  placeholder?: string;
  allowInvalid?: boolean;
  allowEmpty?: boolean;
  numericFormat?: {
    pattern?: string | {
      mantissa?: number;
    };
  };
  dateFormat?: string;
  validator?: { $gte?: string; $lte?: string } | ((value: string | number, callback: (value: boolean) => void) => void);
};

type HnTableColumnOptionsProps = Record<string, unknown>;

type HnTableColumnOptions = {
  title: string;
  data: string;
  header?: HnTableColumnOptionsHeader;
  settings: HnTableColumnOptionsSettings;
  props?: HnTableColumnOptionsProps;
  rendererComponent?: VueConstructor;
  editorComponent?: VueConstructor;
};

interface HnTableColumnCell {
  type: HnTableColumnCellType;
  header?: HnTableColumnOptionsHeader;
  settings?: HnTableColumnOptionsSettings;
  settingsToProps?: {
    setting: keyof HnTableColumnOptionsSettings;
    prop: keyof HnTableColumnOptionsProps;
  }[];
  rendererComponent?: VueConstructor;
  editorComponent?: VueConstructor;
}

const hnTableColumnCellText: HnTableColumnCell = {
  type: hnTableColumnCellTypesMap.text,
};

const hnTableColumnCellDefault = hnTableColumnCellText;

const hnTableColumnCells: HnTableColumnCell[] = [
  hnTableColumnCellDefault,
  {
    type: hnTableColumnCellTypesMap.number,
  },
  {
    type: hnTableColumnCellTypesMap.date,
    settings: {
      dateFormat: 'YYYY-MM-DDTHH:mm:ss[Z]',
    },
    settingsToProps: [
      {
        setting: 'dateFormat',
        prop: 'format',
      },
    ],
    rendererComponent: HnTableDateCell,
    editorComponent: HnTableDateCell,
  },
  {
    type: hnTableColumnCellTypesMap.select,
    rendererComponent: HnTableSelectCell,
    editorComponent: HnTableSelectCell,
  },
  {
    type: hnTableColumnCellTypesMap.stub,
    settings: {
      readOnly: true,
      disableVisualSelection: true,
      skipColumnOnPaste: true,
    },
    rendererComponent: HnTableStubCell,
  },
];

const getColumnCell = (type?: HnTableColumnCellType) => hnTableColumnCells.find((cell) => cell.type === type) || hnTableColumnCellDefault;

const getColumnHeader = (cell: HnTableColumnCell, options: HnTableColumnOptions) => {
  const cellHeader = cell.header;

  return {
    ...cellHeader,
    ...options.header,
  };
};

const getColumnSettings = (cell: HnTableColumnCell, options: HnTableColumnOptions) => {
  const cellSettings = cell.settings;

  return {
    placeholder: '—',
    ...cellSettings,
    ...options.settings,
  };
};

const getColumnProps = (cell: HnTableColumnCell, options: HnTableColumnOptions) => {
  const settingsToProps = cell.settingsToProps || [];

  const providedProps = settingsToProps.reduce((obj, { setting, prop }) => {
    const val = options.settings[setting];

    if (val === undefined) return obj;

    obj[prop] = val;

    return obj;
  }, {} as Record<typeof settingsToProps[number]['prop'], HnTableColumnOptionsSettings[keyof HnTableColumnOptionsSettings]>);

  return {
    ...providedProps,
    ...options.props,
    readonly: options.settings.readOnly,
  };
};

const getColumnComponents = (cell: HnTableColumnCell, options: HnTableColumnOptions) => {
  const cellRendererComponent = cell.rendererComponent;
  const cellEditorComponent = cell.editorComponent;

  return {
    renderer: options.rendererComponent || cellRendererComponent,
    editor: options.editorComponent || cellEditorComponent,
  };
};

type HnTableColumnGenerators = Record<'default', (options: HnTableColumnOptions) => HnTableColumnOptions> & Partial<Record<HnTableColumnCellType, (options: HnTableColumnOptions) => HnTableColumnOptions>>;

const hnTableColumnGenerators: HnTableColumnGenerators = {
  default: (options) => {
    const cell = getColumnCell(options.settings.type);

    const header = getColumnHeader(cell, options);
    const settings = getColumnSettings(cell, options);
    const props = getColumnProps(cell, options);
    const components = getColumnComponents(cell, options);

    return {
      title: options.title,
      data: options.data,
      header,
      settings,
      props,
      rendererComponent: components.renderer,
      editorComponent: components.editor,
    };
  },
  date: (options) => {
    const cell = getColumnCell(options.settings.type);

    const header = getColumnHeader(cell, options);
    const settings = getColumnSettings(cell, options);

    if (settings.validator && typeof settings.validator !== 'function') {
      const validatorOptions = settings.validator;

      // Тут важно не потерять this
      // Функция выполняется внутри Handsontable, поэтому this будет равен Handsontable.CellMeta
      settings.validator = function (this: Handsontable.CellMeta, value, callback) {
        const { instance, visualRow } = this;

        let status = moment(value).isValid();

        if (!status) return callback(status);

        const gteValue = validatorOptions.$gte ? instance.getDataAtRowProp(visualRow, validatorOptions.$gte) : null;
        const lteValue = validatorOptions.$lte ? instance.getDataAtRowProp(visualRow, validatorOptions.$lte) : null;

        const gteStatus = gteValue ? moment(value).isSameOrAfter(moment(gteValue), 'day') : true;
        const lteStatus = lteValue ? moment(value).isSameOrBefore(moment(lteValue), 'day') : true;

        status = gteStatus && lteStatus;

        callback(status);
      };
    }

    const props = getColumnProps(cell, options);
    const components = getColumnComponents(cell, options);

    return {
      title: options.title,
      data: options.data,
      header,
      settings,
      props,
      rendererComponent: components.renderer,
      editorComponent: components.editor,
    };
  },
};

export default class HnTableColumn {
  title = '';

  data = '';

  header: HnTableColumnOptionsHeader;

  settings: HnTableColumnOptionsSettings;

  props: HnTableColumnOptionsProps;

  rendererComponent?: VueConstructor;

  editorComponent?: VueConstructor;

  constructor(payload: HnTableColumnOptions) {
    const options = { ...payload };

    const generator = hnTableColumnGenerators[options.settings.type || ''] || hnTableColumnGenerators.default;

    const column = generator(options);

    this.title = column.title;
    this.data = column.data;
    this.header = column.header;
    this.settings = column.settings;
    this.props = column.props;
    this.rendererComponent = column.rendererComponent;
    this.editorComponent = column.editorComponent;
  }
}
