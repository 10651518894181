import { render, staticRenderFns } from "./CampaignEditorSettingsLoader.vue?vue&type=template&id=6b881c75&scoped=true&"
import script from "./CampaignEditorSettingsLoader.vue?vue&type=script&lang=js&"
export * from "./CampaignEditorSettingsLoader.vue?vue&type=script&lang=js&"
import style0 from "./CampaignEditorSettingsLoader.vue?vue&type=style&index=0&id=6b881c75&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b881c75",
  null
  
)

export default component.exports