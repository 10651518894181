/* eslint-disable max-classes-per-file */

import Vue from 'vue';
import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, Context, createMapper } from 'vuex-smart-module';

import { ClientLike } from '@/models/Client';

import clientStore from '@/store/client';
import campaignStore from '@/store/campaign';

class ModuleState {
  currentClient: ClientLike | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  clientStoreContext!: Context<typeof clientStore>;

  campaignStoreContext!: Context<typeof campaignStore>;

  get currentClient() {
    return this.state.currentClient;
  }

  get currentClientId() {
    return this.getters.currentClient?.id;
  }

  get currentClientName() {
    return this.getters.currentClient?.name || '';
  }

  get currentClientPath() {
    return this.getters.currentClientId ? `/clients/${this.getters.currentClientId}` : '';
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_CURRENT_CLIENT(payload: ClientLike) {
    this.state.currentClient = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  $init(store: Store<unknown>) {
    const clientStoreContext = clientStore.context(store);
    const campaignStoreContext = campaignStore.context(store);

    const initWatchers = async () => {
      await Vue.nextTick();

      store.watch(() => clientStoreContext.getters.client, (val) => {
        if (!val) return;

        this.dispatch('CHANGE_CURRENT_CLIENT', {
          id: val.id,
          name: val.name,
        });
      });

      store.watch(() => campaignStoreContext.getters.campaign, (val) => {
        if (!val) return;

        this.dispatch('CHANGE_CURRENT_CLIENT', {
          id: val.clientId,
          name: val.clientName,
        });
      });
    };

    initWatchers();
  }

  CHANGE_CURRENT_CLIENT(payload: ClientLike) {
    this.commit('SET_CURRENT_CLIENT', payload);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const navigationCurrentClientStoreMapper = createMapper(module);

export default module;
