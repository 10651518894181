import { registerModule } from 'vuex-smart-module';

import ModuleInit from '@/models/ModuleInit';

import store from './store';
import routes from './routes/index';

const moduleInit: ModuleInit = (ctx) => {
  registerModule(ctx.store, ['metricsCheck'], 'metricsCheck', store);

  ctx.store.dispatch('navigation/ADD_ROUTES', routes);
  ctx.router.addRoutes(routes);
};

export default moduleInit;
