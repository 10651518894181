import { Route, RawLocation } from 'vue-router';
import { AppRouteConfig } from '@/models/AppRouter';

import MetricsCheck from '../views/MetricsCheck.vue';

const routes: AppRouteConfig[] = [
  {
    path: '/MetricsCheck',
    redirect(to: Route): RawLocation {
      const {
        d,
        ...otherQuery
      } = to.query;

      return {
        path: '/metrics-check',
        query: {
          danboId: d,
          ...otherQuery,
        },
      };
    },
  },
  {
    path: '/metrics-check',
    component: MetricsCheck,
    meta: {
      group: 'main',
      title: 'Источники факта',
      icon: 'mdi-source-branch',
      order: 5,
      permission: 'upload_fact',
      header: {
        sticky: true,
      },
    },
    props: true,
  },
];

export default routes;
