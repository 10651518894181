


































































import { defineComponent, toRefs, ref, computed, watch, ComponentInstance } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import AppJsonError from '@/services/errorHandler/AppJsonError';

import { UserLight } from '@/models/User';

import { PartialWithout } from '@/utils/type';
import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { useUsersCatalogStore } from '../../../store/users/usersCatalog';
import { useUsersDetailsStore } from '../../../store/users/userDetails';

import UsersForm from '../forms/UsersForm.vue';

export default defineComponent({
  components: {
    AppModal,
    UsersForm,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const usersCatalogStore = useUsersCatalogStore();
    const usersDetailsStore = useUsersDetailsStore();

    const userFormRef = ref<ComponentInstance & { resetValidation(): void, collapseClientsTree(): void } | null>(null);
    const userFormData = ref<PartialWithout<UserLight, 'id'> | null>(null);
    const userFormIsValid = ref(false);

    const userFormResetValidation = async () => {
      if (!userFormRef.value) return;

      userFormRef.value.resetValidation();
    };

    const userFormCollapseClientsTree = () => {
      if (!userFormRef.value) return;

      userFormRef.value.collapseClientsTree();
    };

    const newUser = computed(() => {
      if (!userFormData.value || !userFormIsValid.value) return null;

      return userFormData.value;
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => !newUser.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!newUser.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await usersCatalogStore.actions.CREATE_USER(newUser.value);

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const messages = Object.values(error.response?.data?.errors || {})
            .reduce<string[]>((acc, val) => {
              if (Array.isArray(val)) {
                acc.push(...val);
              } else if (typeof val === 'string') {
                acc.push(val);
              }

              return acc;
            }, []);

          saveError.value = {
            title: 'Произошла ошибка',
            messages: messages.length ? messages : ['Обратитесь к администратору'],
          };
        }

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        saving.value = false;
      }
    };

    watch(value, async (val) => {
      if (!val) {
        userFormCollapseClientsTree();

        usersDetailsStore.actions.RESET_GROUPS_CLIENTS_BRANDS();

        return;
      }

      userFormData.value = null;

      userFormResetValidation();
    }, { immediate: true });

    return {
      userFormRef,
      userFormData,
      userFormIsValid,
      saving,
      saveBtnIsDisabled,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
