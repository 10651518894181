









import { defineComponent } from '@vue/composition-api';

import { DAccordion } from '@shared/druid-kit';

export default defineComponent({
  components: {
    DAccordion,
  },
  setup() {
    const items = [
      /* eslint-disable global-require */
      {
        title: 'Как посмотреть данные только из размещения или только из загруженного факта',
        content: `
        <div>
          <div class="mb-2">В Настройках кампании → вкладка Размещения нужно вместо Сумма по площадкам выбрать Fact (это данные загруженного файла) или площадку.</div>
          <img class="mb-6" src="${require('../assets/img/data_provider_1.png')}" alt="excel_settings" width="960"/>
          <div class="mb-4">Или на странице размещения (размещение можно найти по danboid в поиске) можно изменить этот же параметр.</div>
          <img class="mb-6" src="${require('../assets/img/data_provider_2.png')}" alt="excel_settings" width="960"/>
        </div>
        `,
      },
      {
        title: 'Если данные не сходятся',
        content: `
        <div>
          <div class="mb-2">Вероятно, данные из загруженного файла суммируются с данными площадки.</div>
          <div class="mb-2">Выберите Fact как источник данных в Настройках кампании    → Размещения в столбце Источник данных.</div>
          <img class="mb-6" src="${require('../assets/img/data_provider_1.png')}" alt="excel_settings" width="960"/>
          <div class="mb-4">Или на странице размещения (размещение можно найти по danboid в поиске) можно изменить этот же параметр.</div>
          <img class="mb-6" src="${require('../assets/img/data_provider_2.png')}" alt="excel_settings" width="960"/>
        </div>
        `,
      },
      /* eslint-enable */
    ];

    return {
      items,
    };
  },
});
