






































import { defineComponent, inject, computed } from '@vue/composition-api';

import store from '@/store';
import navigationCurrentClient from '@/store/navigation/navigationCurrentClient';

const navigationCurrentClientContext = navigationCurrentClient.context(store);

export default defineComponent({
  setup() {
    const clientId = computed(() => navigationCurrentClientContext.getters.currentClientId);
    const clientName = computed(() => navigationCurrentClientContext.getters.currentClientName);

    const isActive = inject('appSideBarMenuItemIsActive', false);

    const limit = 4;
    const width = 54;
    const height = 24;

    const widthMax = width;
    const widthMin = 28;

    const charWidth = width / 3;

    const text = computed(() => (clientName.value.slice(0, limit) || '').trim());

    const rectWidth = computed(() => {
      const val = [
        widthMin,
        36,
        46,
        widthMax,
      ][text.value.length - 1] || text.value.length * charWidth;

      if (val > widthMax) return widthMax;

      if (val < widthMin) return widthMin;

      return val;
    });

    const rectHeight = height;
    const rectX = computed(() => ((width - rectWidth.value) / 2));
    const rectRX = rectHeight / 2;
    const rectRY = rectHeight / 2;

    return {
      clientId,
      clientName,
      isActive,
      text,
      width,
      height,
      rectWidth,
      rectHeight,
      rectX,
      rectRX,
      rectRY,
    };
  },
});
