/* eslint-disable max-classes-per-file */

import Vue from 'vue';

import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { CampaignEditorPlacementDetails } from '@/models/Campaign';
import { Markup, MarkupLight } from '@/models/Markup';

import { CampaignEditorApi, CategoriesApi, DataProvidersApi, MarkupsApi, PlacementsApi } from '@/api';

import { SetMarkupPayload } from '@/api/modules/markups.api';
import { Category } from '@/models/Category';
import { DataProvider } from '@/models/DataProvider';
import { PlacementManualState } from '@/models/Placement';

class ModuleState {
  placement: null | CampaignEditorPlacementDetails = null;

  placementForm: null | CampaignEditorPlacementDetails = null;

  placementMarkups: MarkupLight[] = [];

  markupsWithCostMap: Record<string, Markup> = {};

  placementMarkupsIsChanged = false;

  categories: Category[] = [];

  dataProviders: DataProvider[] = [];

  placementManualStates: PlacementManualState[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get placement() {
    return this.state.placement;
  }

  get placementForm() {
    return this.state.placementForm;
  }

  get currentPlacement() {
    return this.getters.placementForm || this.getters.placement;
  }

  get placementHasBeenChanged() {
    return !!this.getters.placementForm;
  }

  get placementMarkups() {
    return this.state.placementMarkups;
  }

  get markupsWithCostMap() {
    return this.state.markupsWithCostMap;
  }

  get getMarkupWithCost() {
    return (markupId: string) => this.state.markupsWithCostMap[markupId] ?? null;
  }

  get placementMarkupsIsChanged() {
    return this.state.placementMarkupsIsChanged;
  }

  get categories() {
    return this.state.categories;
  }

  get dataProviders() {
    return this.state.dataProviders;
  }

  get placementManualStates() {
    return this.state.placementManualStates;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_PLACEMENT(payload: CampaignEditorPlacementDetails) {
    this.state.placement = payload;
  }

  SET_PLACEMENT_FORM(payload: CampaignEditorPlacementDetails) {
    this.state.placementForm = payload;
  }

  RESET_PLACEMENTS_FORM() {
    this.state.placementForm = null;
  }

  SET_MARKUPS(payload: MarkupLight[]) {
    this.state.placementMarkups = payload;
  }

  SET_MARKUPS_WITH_COST(payload: { markupId: string; markup: Markup }) {
    Vue.set(this.state.markupsWithCostMap, payload.markupId, payload.markup);
  }

  SET_MAPRKUPS_IS_CHANGED(payload: boolean) {
    this.state.placementMarkupsIsChanged = payload;
  }

  RESET_MARKUPS_WITH_COST() {
    this.state.markupsWithCostMap = {};
  }

  SET_CATEGORIES(payload: Category[]) {
    this.state.categories = payload;
  }

  SET_DATA_PROVIDERS(payload: DataProvider[]) {
    this.state.dataProviders = payload;
  }

  SET_PLACEMENT_MANUAL_STATES(payload: PlacementManualState[]) {
    this.state.placementManualStates = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_PLACEMENT(payload: { campaignId: string; placementDanboId: string }) {
    const res = await CampaignEditorApi.fetchPlacement(payload);

    this.commit('SET_PLACEMENT', res.data);
  }

  async FETCH_PLACEMENT_FROM_1C(payload: { campaignId: string; placementDanboId: string }) {
    const params = { use1CPlan: true };

    const res = await CampaignEditorApi.fetchPlacement({ ...payload, params });

    this.commit('SET_PLACEMENT_FORM', res.data);
  }

  async SAVE_PLACEMENT(payload: { campaignId: string }) {
    if (!this.getters.currentPlacement) return;

    await CampaignEditorApi.updatePlacement({ ...payload, data: { placement: this.getters.currentPlacement } });

    this.commit('RESET_PLACEMENTS_FORM');
  }

  async SHOW_PLACEMENT(payload: { campaignId: string; placementDanboId: string }) {
    if (!this.getters.currentPlacement) return;

    const newPlacement = { ...this.getters.currentPlacement, hidden: false };

    await this.dispatch('CHANGE_PLACEMENT_FORM', newPlacement);

    await this.dispatch('SAVE_PLACEMENT', payload);
    await this.dispatch('FETCH_PLACEMENT', payload);
  }

  CHANGE_PLACEMENT_FORM(payload: Partial<CampaignEditorPlacementDetails>) {
    if (!this.getters.currentPlacement) return;

    const updatedPlacement = { ...this.getters.currentPlacement, ...payload };

    this.commit('SET_PLACEMENT_FORM', updatedPlacement);
  }

  async FETCH_MARKUPS(placementId: string) {
    const res = await MarkupsApi.fetchMarkups({ params: { placementId } });

    this.commit('SET_MARKUPS', res.data.list);
  }

  async ADD_PLACEMENT_MARKUP(payload: SetMarkupPayload) {
    await MarkupsApi.setMarkup(payload);
    await this.dispatch('FETCH_MARKUPS', payload.placementId);

    this.commit('SET_MAPRKUPS_IS_CHANGED', true);
  }

  RESET_MARKUPS() {
    this.commit('SET_MARKUPS', []);
    this.commit('RESET_MARKUPS_WITH_COST', []);
    this.commit('SET_MAPRKUPS_IS_CHANGED', false);
  }

  async FETCH_MARKUP(markupId: string) {
    const res = await MarkupsApi.fetchMarkup(markupId);

    this.commit('SET_MARKUPS_WITH_COST', { markupId, markup: res.data });
  }

  async ADD_MARKUP_PERIOD(payload: { markupId: string; data: SetMarkupPayload }) {
    const { markupId, data } = payload;

    await MarkupsApi.setMarkup(data);
    await this.dispatch('FETCH_MARKUP', markupId);

    this.commit('SET_MAPRKUPS_IS_CHANGED', true);
  }

  async DELETE_MARKUP_PERIOD(payload: { markupId: string; index: number | string }) {
    const { markupId, index } = payload;

    await MarkupsApi.deleteMarkup({ markupId, config: { data: `${index}` } });
    await this.dispatch('FETCH_MARKUP', markupId);

    this.commit('SET_MAPRKUPS_IS_CHANGED', true);
  }

  async DELETE_MARKUP(payload: { placementId: string; markupId: string }) {
    const { placementId, markupId } = payload;

    await MarkupsApi.deleteMarkup({ markupId, config: { data: '' } });
    await this.dispatch('FETCH_MARKUPS', placementId);

    this.commit('SET_MAPRKUPS_IS_CHANGED', true);
  }

  async FETCH_CATEGORIES() {
    const res = await CategoriesApi.fetchCategories();

    this.commit('SET_CATEGORIES', res.data);
  }

  async FETCH_DATA_PROVIDERS() {
    const res = await DataProvidersApi.fetchDataProviders();

    this.commit('SET_DATA_PROVIDERS', res.data);
  }

  async FETCH_PLACEMENT_MANUAL_STATES() {
    const res = await PlacementsApi.fetchManualStates();

    this.commit('SET_PLACEMENT_MANUAL_STATES', res.data);
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const campaignEditorPlacementDetailsStoreMapper = createMapper(module);

export const useCampaignEditorPlacementDetailsStore = createComposable(module);

export default module;
