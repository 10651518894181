/* eslint-disable max-classes-per-file */
import Vue from 'vue';
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import moment from 'moment';

import { Markup } from '@/models/Markup';

import { MarkupsApi } from '@/api';

class ModuleState {
  markupIsLoading = false;

  markup: Markup | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  get markupIsLoading() {
    return this.state.markupIsLoading;
  }

  get markup() {
    return this.state.markup;
  }

  get markupPeriods() {
    return this.getters.markup?.markupsWithCost || [];
  }

  get maxMarkupPeriodStartDate() {
    const markupPeriodsStartDates = this.getters.markupPeriods.map((period) => moment(period.startDate));

    return moment.utc(moment.max(markupPeriodsStartDates)).format('YYYY-MM-DDTHH:mm:ss[Z]');
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_MARKUP_IS_LOADING(value: boolean) {
    this.state.markupIsLoading = value;
  }

  SET_MARKUP(payload: Markup) {
    this.state.markup = payload;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_MARKUP(payload: { markupId: string }) {
    this.commit('SET_MARKUP_IS_LOADING', true);

    try {
      const res = await MarkupsApi.fetchMarkup(payload.markupId);

      this.commit('SET_MARKUP', res.data);
    } finally {
      this.commit('SET_MARKUP_IS_LOADING', false);
    }
  }

  async ADD_MARKUP_PERIOD(payload: { startDate: string; multiplier: number }) {
    const { markup } = this.getters;

    if (!markup) return;

    const newMarkup = {
      clientId: markup.clientId,
      brandId: markup.brandId,
      campaignId: markup.campaignId,
      placementId: markup.placementId,
      dataProviderId: markup.dataProviderId,
      startDate: payload.startDate,
      value: payload.multiplier,
    };

    await MarkupsApi.setMarkup(newMarkup);

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Markup',
      event_action: 'Change Markup',
      event_label: `${markup.dataProviderName} | ${markup.danboId}`,
      d1d_client_id: markup.clientId,
      d1d_client_name: markup.clientName,
      d1d_brand_id: markup.brandId,
      d1d_brand_name: markup.brandName,
      d1d_campaign_id: markup.campaignId,
      d1d_campaign_name: markup.campaignName,
      d1d_danbo_id: markup.danboId,
    });

    await this.dispatch('FETCH_MARKUP', { markupId: markup.id });
  }

  async DELETE_MARKUP_PERIOD(payload: { index: number | string }) {
    const { markup } = this.getters;

    if (!markup) return;

    await MarkupsApi.deleteMarkup({
      markupId: markup.id,
      config: {
        data: `${payload.index}`,
      },
    });

    await this.dispatch('FETCH_MARKUP', { markupId: markup.id });
  }

  async DELETE_MARKUP() {
    const { markup } = this.getters;

    if (!markup) return;

    await MarkupsApi.deleteMarkup({
      markupId: markup.id,
      config: {
        data: '',
      },
    });

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Markup',
      event_action: 'Delete Markup',
      event_label: `${markup.dataProviderName} | ${markup.danboId}`,
      d1d_client_id: markup.clientId,
      d1d_client_name: markup.clientName,
      d1d_brand_id: markup.brandId,
      d1d_brand_name: markup.brandName,
      d1d_campaign_id: markup.campaignId,
      d1d_campaign_name: markup.campaignName,
      d1d_danbo_id: markup.danboId,
    });
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useMarkupDetailsStore = createComposable(module);

export default module;
