var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{staticClass:"client-list-item px-6",attrs:{"to":_vm.path,"ripple":false}},[_c('v-list-item-content',{staticClass:"py-2"},[_c('v-list-item-title',{staticClass:"client-list-item__title mb-1 subtitle-3",domProps:{"textContent":_vm._s(_vm.title)}}),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"client-list-item__subtitle body-2",domProps:{"textContent":_vm._s(_vm.subtitle)}})],1),_vm._v(" "),_c('v-list-item-action',{staticClass:"client-list-item__action align-self-stretch pl-6 ma-0",on:{"click":function($event){$event.preventDefault();if($event.target !== $event.currentTarget){ return null; }}}},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"d-flex align-center mr-8"},[_c('DTooltip',{attrs:{"center":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var btnHover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":btnHover ? 'primary' : '',"to":_vm.unapprovedPlacementsPath,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("\n                  mdi-checkbox-multiple-marked-outline\n                ")])],1)]}}],null,true)})]}}])},[_vm._v(" "),_c('span',[_vm._v("Непроверенные размещения")])])],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center"},[_c('DTooltip',{attrs:{"min-width":"308","center":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.favoriteStatusIsLoading,"color":"primary","icon":""},on:{"click":_vm.toggleFavoriteStatus}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("\n                "+_vm._s(_vm.client.isFavourite ? 'mdi-star' : 'mdi-star-outline')+"\n              ")])],1)]}}])},[_vm._v(" "),_c('div',{staticClass:"caption"},[(_vm.client.isFavourite)?[_vm._v("\n              Клиент добавлен в избранное, нажмите,"),_c('br'),_vm._v("\n              чтобы убрать\n            ")]:[_vm._v("\n              Добавьте в избранное,"),_c('br'),_vm._v("\n              чтобы видеть этого клиента наверху списка\n            ")]],2)])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }