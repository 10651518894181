import { AppRouteConfig } from '@/models/AppRouter';

import ProviderTokens from '../views/ProviderTokens.vue';
import ProviderTokensList from '../views/ProviderTokensList.vue';
import ProviderTokensNew from '../views/ProviderTokensNew.vue';
import ProviderTokensCallback from '../views/ProviderTokensCallback.vue';

import ProviderTokensListLoader from '../components/ProviderTokensList/ProviderTokensListLoader.vue';
import ProviderTokensNewLoader from '../components/ProviderTokensNew/ProviderTokensNewLoader.vue';

const routes: AppRouteConfig[] = [
  {
    path: '/Home/TokenCallback',
    redirect: '/provider-tokens/callback',
  },
  {
    path: '/provider-tokens',
    component: ProviderTokens,
    meta: {
      group: 'main',
      title: 'Доступы к площадкам',
      icon: 'mdi-layers-outline',
      order: 1,
      permission: 'provider-settings_edit',
    },
    props: true,
    children: [
      {
        path: '',
        component: ProviderTokensList,
        meta: {
          title: 'Доступы к площадкам',
          permission: 'provider-settings_edit',
          loader: ProviderTokensListLoader,
          header: {
            sticky: true,
          },
        },
        props: true,
      },
      {
        path: 'new',
        component: ProviderTokensNew,
        meta: {
          permission: 'provider-settings_edit',
          loader: ProviderTokensNewLoader,
        },
        props: true,
      },
      {
        path: 'callback',
        component: ProviderTokensCallback,
        meta: {
          permission: 'provider-settings_edit',
        },
        props: true,
      },
    ],
  },
];

export default routes;
