/* eslint-disable max-classes-per-file */
import Vue from 'vue';

import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import moment from 'moment';
import lodash from 'lodash';

import { ClientsApi, CampaignsApi } from '@/api';

import { FetchPlacementPayload, FetchAdObjectPayload, FetchAdObjectWithLevelPayload, SetCampaignCategoriesPayload } from '@/api/modules/campaigns.api';

import { Client } from '@/models/Client';

import {
  Campaign,
  CampaignCategory,
  CampaignPlacement,
  CampaignConnector,
  CampaignAdObject,
} from '@/models/Campaign';

class ModuleState {
  campaign: Campaign | null = null;

  client: Client | null = null;

  campaignUpdate: Campaign | null = null;

  categories: CampaignCategory[] = [];

  campaignPlacement: CampaignPlacement | null = null;

  campaignConnector: CampaignConnector | null = null;

  campaignAdObject: CampaignAdObject | null = null;
}

class ModuleGetters extends Getters<ModuleState> {
  get campaign() {
    return this.state.campaign;
  }

  get client() {
    return this.state.client;
  }

  get campaignUpdate() {
    return this.state.campaignUpdate;
  }

  get currentCampaign() {
    return this.getters.campaignUpdate || this.getters.campaign;
  }

  get campaignCategories() {
    return this.state.categories;
  }

  get campaignPlacement() {
    return this.state.campaignPlacement;
  }

  get campaignConnector() {
    return this.state.campaignConnector;
  }

  get campaignAdObject() {
    return this.state.campaignAdObject;
  }

  get clientBrand() {
    return this.getters.client?.brands.find((brand) => brand.id === this.getters.campaign?.brandId) ?? null;
  }

  get campaignLastUpdateDate() {
    const updatedPlacements = this.getters.campaignCategories
      .flatMap((category) => category.placements)
      .filter((placement) => placement.metricsUpdatedAt);

    const lastUpdatedPlacement = lodash.maxBy(updatedPlacements, (placement) => moment(placement.metricsUpdatedAt));

    const lastUpdateDate = lastUpdatedPlacement?.metricsUpdatedAt || this.getters.campaign?.startDate || '';

    if (!lastUpdateDate) return '';

    return moment.utc(lastUpdateDate).format('DD.MM.YYYY HH:mm');
  }

  get campaignHasBeenChanged() {
    return !!this.getters.campaignUpdate;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_CAMPAIGN(payload: Campaign) {
    this.state.campaign = payload;
  }

  SET_CLIENT(payload: Client) {
    this.state.client = payload;
  }

  SET_CAMPAIGN_UPDATE(payload: Campaign) {
    this.state.campaignUpdate = payload;
  }

  CAMPAIGN_SET_APPROVED_STATUS(value: boolean) {
    if (!this.state.campaign) return;

    Vue.set(this.state.campaign, 'approved', value);
  }

  SET_CAMPAIGN_CATEGORIES(payload: CampaignCategory[]) {
    this.state.categories = payload;
  }

  SET_CAMPAIGN_PLACEMENT(payload: CampaignPlacement) {
    this.state.campaignPlacement = payload;
  }

  SET_CAMPAIGN_CONNECTOR(payload: CampaignConnector) {
    this.state.campaignConnector = payload;
  }

  SET_CAMPAIGN_AD_OBJECT(payload: CampaignAdObject) {
    this.state.campaignAdObject = payload;
  }

  RESET_CAMPAIGN_UPDATE() {
    this.state.campaignUpdate = null;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_CAMPAIGN(payload: { campaignId: string; filters?: { from?: string; to?: string }; options?: { withCategories: boolean }}) {
    const { campaignId, filters } = payload;

    const options = payload.options ?? {
      withCategories: false,
    };

    if (options.withCategories) {
      const res = await CampaignsApi.fetchCampaign({
        campaignId,
        params: filters,
      });

      this.commit('SET_CAMPAIGN', res.data.campaign);
      this.commit('SET_CAMPAIGN_CATEGORIES', res.data.categories);
    } else {
      const res = await CampaignsApi.fetchCampaignInfo({ campaignId });

      this.commit('SET_CAMPAIGN', res.data);
    }
  }

  async FETCH_CLIENT(clientId: string) {
    const res = await ClientsApi.fetchClient(clientId);

    this.commit('SET_CLIENT', res.data);
  }

  async CAMPAIGN_TOGGLE_APPROVED_STATUS(value: boolean) {
    const { campaign } = this.getters;

    if (!campaign) return;

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Campaign',
      event_action: value ? 'Show to Client' : 'Hide from Client',
      event_label: campaign.id,
      d1d_client_id: campaign.clientId,
      d1d_client_name: campaign.clientName,
      d1d_brand_id: campaign.brandId,
      d1d_brand_name: campaign.brandName,
      d1d_campaign_id: campaign.id,
      d1d_campaign_name: campaign.name,
    });

    await CampaignsApi.updateCampaignApproved({ campaignId: campaign.id, data: value });

    this.commit('CAMPAIGN_SET_APPROVED_STATUS', value);
  }

  UPDATE_CAMPAIGN(payload: Partial<Campaign>) {
    if (!this.getters.currentCampaign) return;

    const newCampaign = { ...this.getters.currentCampaign, ...payload };

    this.commit('SET_CAMPAIGN', newCampaign);
  }

  CHANGE_CAMPAIGN(payload: Partial<Campaign>) {
    if (!this.getters.currentCampaign) return;

    const newCampaign = { ...this.getters.currentCampaign, ...payload };

    this.commit('SET_CAMPAIGN_UPDATE', newCampaign);
  }

  async UPDATE_CAMPAIGN_CATEGORIES_ORDER(payload: CampaignCategory[]) {
    if (!this.state.campaign) return;

    const data = {
      CategoriesPlacements: payload.map((category) => ({
        CategoryId: category.id,
        PlacementsIds: category.placements.map((placement) => placement.id),
      })),
    };

    await CampaignsApi.updateCampaignCategoriesOrder({ campaignId: this.state.campaign.id, data });

    this.commit('SET_CAMPAIGN_CATEGORIES', payload);
  }

  async SET_CAMPAIGN_CATEGORIES(payload: SetCampaignCategoriesPayload) {
    await CampaignsApi.setCampaignCategories(payload);
  }

  async UPDATE_CAMPAIGN_PLACEMENTS_ORDER(payload: CampaignCategory) {
    if (!this.state.campaign) return;

    const categories = this.getters.campaignCategories.map((category) => ({
      ...category,
      placements: category.id === payload.id ? payload.placements : category.placements,
    }));

    const data = {
      CategoriesPlacements: categories.map((category) => ({
        CategoryId: category.id,
        PlacementsIds: category.placements.map((placement) => placement.id),
      })),
    };

    await CampaignsApi.updateCampaignCategoriesOrder({ campaignId: this.state.campaign.id, data });

    this.commit('SET_CAMPAIGN_CATEGORIES', categories);
  }

  async FETCH_CAMPAIGN_PLACEMENT(payload: FetchPlacementPayload) {
    const res = await CampaignsApi.fetchPlacement(payload);

    this.commit('SET_CAMPAIGN_PLACEMENT', res.data);
  }

  async FETCH_CAMPAIGN_CONNECTOR(payload: FetchAdObjectPayload) {
    const res = await CampaignsApi.fetchAdObject(payload);

    this.commit('SET_CAMPAIGN_CONNECTOR', res.data);
  }

  async FETCH_CAMPAIGN_AD_OBJECT(payload: FetchAdObjectWithLevelPayload) {
    const res = await CampaignsApi.fetchAdObjectWithLevel(payload);

    this.commit('SET_CAMPAIGN_AD_OBJECT', res.data);
  }

  async SAVE_CAMPAIGN_ACTIVE_STATUS() {
    const campaignId = this.getters.campaign?.id || null;

    if (!campaignId || typeof this.getters.campaignUpdate?.isActive !== 'boolean') return;

    await CampaignsApi.updateCampaignActiveStatus({
      campaignId,
      data: this.getters.campaignUpdate.isActive,
    });
  }

  async SAVE_CAMPAIGN() {
    const { campaign, campaignHasBeenChanged } = this.getters;

    if (!campaign || !campaignHasBeenChanged) return;

    await this.dispatch('SAVE_CAMPAIGN_ACTIVE_STATUS');

    await this.dispatch('FETCH_CAMPAIGN', { campaignId: campaign.id });

    this.commit('RESET_CAMPAIGN_UPDATE');
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const campaignStoreMapper = createMapper(module);

export const useCampaignStore = createComposable(module);

export default module;
