


























import { defineComponent, toRefs, computed, PropType, ref } from '@vue/composition-api';

import { DTableRow } from '@shared/druid-kit';

import MetricsTableMetricCell from '@/components/_newDesign/common/MetricsTable/cells/MetricsTableMetricCell.vue';

export interface MetricCell {
  metric: {
    name: string
  };
  planInsteadProgress: boolean;
  onlyFact: boolean;
  disabledTooltip: boolean;
}

export default defineComponent({
  components: {
    DTableRow,
    MetricsTableMetricCell,
  },
  props: {
    metricsPlan: { type: Object as PropType<Record<string, number>>, default: () => ({}) },
    metricsTotal: { type: Object as PropType<Record<string, number>>, default: () => ({}) },
    metricCells: { type: Array as PropType<MetricCell[]>, default: () => [] },
  },
  setup(props) {
    const { metricsPlan, metricsTotal, metricCells } = toRefs(props);

    const isIntersect = ref(false);

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      isIntersect.value = isIntersecting;
    };

    const filledMetricCells = computed(() => metricCells.value.map((metricCell) => {
      const plan = metricsPlan?.value[metricCell.metric.name] || 0;
      const fact = metricsTotal?.value[metricCell.metric.name] || 0;

      return {
        ...metricCell,
        metric: {
          ...metricCell.metric,
          plan,
          fact,
        },
      };
    }));

    return {
      filledMetricCells,
      isIntersect,
      onIntersect,
    };
  },
});
