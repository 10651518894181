










































import Vue from 'vue';

import {
  defineComponent,
  PropType,
  toRefs,
  provide,
  ref,
  computed,
  watch,
  onMounted,
  nextTick,
  ComponentInstance,
  onBeforeUnmount,
} from '@vue/composition-api';

import Handsontable from 'handsontable';
import { HotTable, HotColumn } from '@handsontable/vue';
import { get, cloneDeep } from 'lodash';

import useConfiguration from '@/packages/hooks/useConfiguration';

import HnTableColumn from '../models/HnTableColumn';

import { HnTableChangedCells } from '../types';

import { HnTableCellClassName } from '../utils';

interface HTableSettings extends Handsontable.GridSettings {
  fixedColumnsRight?: number;
  fixedColumnsRightWithBorder?: boolean;
}

export default defineComponent({
  components: {
    HotTable,
    HotColumn,
  },
  props: {
    data: { type: Array as PropType<Handsontable.CellValue[][] | Handsontable.RowObject[]>, default: () => [] },
    columns: { type: Array as PropType<HnTableColumn[]>, default: () => [] },
    settings: { type: Object as PropType<HTableSettings>, default: () => ({}) },
    dataHasBeenChanged: { type: Boolean, default: false },
    columnsPresetsMap: { type: Object as PropType<Record<string, HnTableColumn[]>>, default: () => ({}) },
    columnsPresetsSlugs: { type: Array as PropType<string[]>, default: () => [] },
  },
  setup(props, context) {
    const {
      data,
      columns,
      settings,
      dataHasBeenChanged,
      columnsPresetsMap,
      columnsPresetsSlugs,
    } = toRefs(props);

    const configuration = useConfiguration(context);

    const hotTableKey = ref(1);

    const rootEl = ref<HTMLElement | null>(null);
    const hotTableComponent = ref<ComponentInstance | null>(null);

    const hotTableInitialized = ref(false);

    const hotTableHeight = ref<(() => number | string) | string | number | undefined>(undefined);

    const hotTableColumnSortingConfig = ref<Handsontable.plugins.ColumnSorting.Config | null>(null);

    const hotTableChangedCells = ref<HnTableChangedCells>([]);

    provide('hotTableChangedCells', hotTableChangedCells);

    const hotInstance = computed(() => {
      const instance = get(hotTableComponent.value, 'hotInstance');

      if (!instance) return undefined;

      return instance as Handsontable;
    });

    const headers = computed(() => columns.value.map((column) => ({
      text: column.title,
      ...column.header,
    })));

    const hotData = ref<Handsontable.CellValue[][] | Handsontable.RowObject[]>([]);

    const cellsWidthsMap = ref<Record<string, number>>({});

    const hiddenColumnsData = ref<number[]>([]);

    const hotHeaders = computed(() => headers.value.map((header) => header.text));

    const hotColumns = computed(() => columns.value);

    const hotTableViewportColumnRenderingOffset = computed(() => {
      if (settings.value?.fixedColumnsLeft || settings.value?.fixedColumnsRight) return hotColumns.value.length;

      return settings.value?.viewportColumnRenderingOffset || 'auto';
    });

    const hiddenColumns = computed({
      get() {
        const currentHotInstance = hotInstance.value;

        if (!currentHotInstance) return hiddenColumnsData.value;

        const disabledPresets = Object.keys(columnsPresetsMap.value).filter((key) => !columnsPresetsSlugs.value.includes(key));

        const colProps = disabledPresets.reduce<string[]>((acc, slug) => {
          acc.push(...columnsPresetsMap.value[slug].map((col) => col.data));

          return acc;
        }, []);

        const hiddenColIndexes = colProps.map((prop) => currentHotInstance.propToCol(prop));

        return [...new Set([
          ...hiddenColumnsData.value,
          ...hiddenColIndexes,
        ])];
      },
      set(val: number[]) {
        hiddenColumnsData.value = val;
      },
    });

    const setHotData = () => {
      hotData.value = cloneDeep(data.value);
    };

    const refreshHotData = () => {
      hotData.value = [...hotData.value];
    };

    const updateHotData = async () => {
      await nextTick();

      hotInstance.value?.loadData(hotData.value);
    };

    const hotTableCellsHighlightEnable = async () => {
      await nextTick();

      const localHotInstance = hotInstance.value;

      if (!localHotInstance) return;

      hotTableChangedCells.value.forEach((cell) => {
        const hnTableCellClassName = new HnTableCellClassName({ table: localHotInstance, coords: cell });

        hnTableCellClassName.add('hn-table__cell--edited');
      });

      localHotInstance.render();
    };

    const hotTableCellsHighlightDisable = async () => {
      await nextTick();

      const localHotInstance = hotInstance.value;

      if (!localHotInstance) return;

      hotTableChangedCells.value.forEach((cell) => {
        const hnTableCellClassName = new HnTableCellClassName({ table: localHotInstance, coords: cell });

        hnTableCellClassName.remove('hn-table__cell--edited');
      });

      hotTableChangedCells.value = [];

      localHotInstance.render();
    };

    watch(data, () => {
      // TODO: Разобраться в чем дело
      // Нужен либо setTimeout либо два (!) nextTick, чтобы Handsontable отобразил данные на странице редактирования размещения (флайтов)
      setTimeout(() => {
        setHotData();
      }, 30);
    }, { immediate: true });

    watch(dataHasBeenChanged, (value) => {
      if (value) return;

      hotTableCellsHighlightDisable();
    });

    watch(hotInstance, async (value) => {
      if (!value) return;

      context.emit('initialized', value);
    });

    watch(hotData, () => {
      updateHotData();

      hotTableCellsHighlightEnable();
    }, { immediate: true });

    watch(hotColumns, async () => {
      refreshHotData(); // костыль для обновления колонок

      hotTableKey.value += 1;
    }, { immediate: true });

    watch(hotTableChangedCells, () => {
      hotTableCellsHighlightEnable();
    });

    watch(hiddenColumns, (val, prevVal) => {
      const currentHotInstance = hotInstance.value;

      if (!currentHotInstance) return;

      const hiddenColumnsPlugin = currentHotInstance.getPlugin('hiddenColumns');

      hiddenColumnsPlugin.showColumns(prevVal);
      hiddenColumnsPlugin.hideColumns(val);

      currentHotInstance.render();
    });

    const setHotTableHeight = async () => {
      await nextTick();

      if (['firefox', 'safari'].includes(Vue.device.browser.slug) && settings.value.height === '100%') {
        const parentEl = rootEl.value?.parentNode;

        if (parentEl instanceof HTMLElement) {
          const parentElClientRect = parentEl.getBoundingClientRect();
          const parentElHeight = parentElClientRect.height;

          hotTableHeight.value = parentElHeight;
        }
      } else {
        hotTableHeight.value = settings.value.height ?? 'auto';
      }
    };

    onMounted(async () => {
      await setHotTableHeight();

      await nextTick();

      hotTableInitialized.value = true;
    });

    const getFixedCellsWidthsMap = () => {
      const { fixedColumnsLeft, fixedColumnsRight } = settings.value;

      const columnsLength = hotColumns.value.length;
      const rightColumnsRange = fixedColumnsRight ? columnsLength - 1 - fixedColumnsRight : null;

      let leftPosition = 0;
      let rightPosition = 0;

      return Object.keys(cellsWidthsMap.value).reduce<Record<number, number>>((acc, key) => {
        const cellIdx = parseInt(key, 10);

        if (fixedColumnsLeft && cellIdx < fixedColumnsLeft) {
          leftPosition += cellsWidthsMap.value?.[cellIdx - 1] || 0;

          acc[cellIdx] = leftPosition;
        }

        if (fixedColumnsRight && cellIdx < fixedColumnsRight) {
          rightPosition += cellsWidthsMap.value?.[columnsLength - cellIdx] || 0;
        }

        if (rightColumnsRange && cellIdx > rightColumnsRange) {
          acc[cellIdx] = rightPosition;

          rightPosition -= cellsWidthsMap.value?.[cellIdx + 1] || 0;
        }

        return acc;
      }, {});
    };

    const addFixedCellStyles = (cellEl: HTMLTableCellElement, cellIdx: number) => {
      const fixedCellsWidthsMap = getFixedCellsWidthsMap();

      if (!(cellIdx in fixedCellsWidthsMap)) return;

      const { fixedColumnsLeft, fixedColumnsRight, fixedColumnsRightWithBorder } = settings.value;

      const direction = fixedColumnsLeft && cellIdx < fixedColumnsLeft ? 'left' : 'right';
      const position = fixedCellsWidthsMap[cellIdx];

      cellEl.style.position = 'sticky';
      cellEl.style.zIndex = '1';

      const maxIdx = Object.keys(fixedCellsWidthsMap).reduce((idxA, idxB) => Math.max(idxA, parseInt(idxB, 10)), 0);

      if (fixedColumnsRight && fixedColumnsRightWithBorder && cellIdx === (maxIdx - fixedColumnsRight + 1)) {
        cellEl.style.borderLeft = '1px solid #9E9E9E';
      }

      cellEl.style[direction] = `${position}px`;
    };

    const generateCellsWidthsMap = () => {
      if (!hotInstance.value) return;

      const tableData = hotInstance.value.getData();

      tableData.forEach((row, rowIdx) => {
        row.forEach((cellValue: unknown, cellIdx: number) => {
          if (!hotInstance.value) return;

          const cellVisualIdx = hotInstance.value.toVisualColumn(cellIdx);
          const cell = hotInstance.value.getCell(rowIdx, cellVisualIdx);

          if (!cell) return;

          cellsWidthsMap.value[cellVisualIdx] = cell.scrollWidth;
        });
      });
    };

    const hnTableValidateCells = () => {
      const localHotInstance = hotInstance.value;

      if (!localHotInstance || localHotInstance.isDestroyed) return;

      localHotInstance.validateCells();
    };

    const afterLoadData: Handsontable.GridSettings['afterLoadData'] = async (...args) => {
      await nextTick();

      const localHotInstance = hotInstance.value;

      if (!localHotInstance) return;

      const columnSorting = localHotInstance.getPlugin('columnSorting');

      if (hotTableColumnSortingConfig.value) columnSorting.sort(hotTableColumnSortingConfig.value);

      if (settings.value.afterLoadData) settings.value.afterLoadData(...args);
    };

    const afterGetColHeader: Handsontable.GridSettings['afterGetColHeader'] = (...args) => {
      const [col, th] = args;

      const columnHeader = headers.value[col];

      if (typeof columnHeader === 'string') return;

      const className = columnHeader?.className;
      const style = columnHeader?.style;

      if (className) {
        th.classList.add(className);
      }

      if (style) {
        th.style.cssText = style;
      }

      addFixedCellStyles(th, col);

      if (settings.value.afterGetColHeader) settings.value.afterGetColHeader(...args);
    };

    const afterColumnSort: Handsontable.GridSettings['afterColumnSort'] = (...args) => {
      const [_currentSortConfig, destinationSortConfigs] = args;

      [hotTableColumnSortingConfig.value] = destinationSortConfigs;

      if (settings.value.afterColumnSort) settings.value.afterColumnSort(...args);
    };

    const beforePaste: Handsontable.GridSettings['beforePaste'] = (...args) => {
      const [pasteData, coords] = args;

      let currentRow = coords[0].startRow;
      let currentCol = coords[0].startCol;

      pasteData.forEach((rowValue, rowIdx) => {
        currentCol = coords[0].startCol;

        pasteData[rowIdx].forEach((cellValue, cellIdx) => {
          const cellType = hotInstance.value?.getCellMeta(currentRow, currentCol).type;

          currentCol += 1;

          if (cellType !== 'numeric') return;

          const value = parseFloat(cellValue.replace(',', '.')) || '';

          if (value === '') return;

          pasteData[rowIdx][cellIdx] = value;
        });

        currentRow += 1;
      });

      if (settings.value.beforePaste) settings.value.beforePaste(...args);
    };

    const beforeChange: Handsontable.GridSettings['beforeChange'] = (...args) => {
      const [changes, source] = args;

      const filteredChanges = changes.filter(([_row, _prop, oldValue, newValue]) => {
        const valuesAreEqual = `${oldValue}` === `${newValue}`;
        const leastOneValueIsFilled = !!oldValue || !!newValue;

        return !valuesAreEqual && leastOneValueIsFilled;
      });

      if (!filteredChanges.length && source === 'edit') return false;

      if (settings.value.beforeChange) settings.value.beforeChange(...args);
    };

    const afterChange: Handsontable.GridSettings['afterChange'] = async (...args) => {
      await nextTick();

      const localHotInstance = hotInstance.value;

      if (!localHotInstance) return;

      const [changes] = args;

      if (changes) {
        changes.forEach(([row, prop]) => hotTableChangedCells.value.push({ row, col: localHotInstance.propToCol(prop), prop }));
      }

      if (settings.value.afterChange) settings.value.afterChange(...args);
    };

    const afterRender: Handsontable.GridSettings['afterRender'] = (...args) => {
      const [isForced] = args;

      if (!isForced) return;

      hnTableValidateCells();

      generateCellsWidthsMap();

      if (settings.value.afterRender) settings.value.afterRender(...args);
    };

    const afterRenderer: Handsontable.GridSettings['afterRenderer'] = (...args) => {
      const td = args[0];
      const column = args[2];

      addFixedCellStyles(td, column);

      if (settings.value.afterRenderer) settings.value.afterRenderer(...args);
    };

    const hotSettings = computed(() => ({
      columnSorting: true,
      ...settings.value,
      licenseKey: configuration.value.handsontableLicenseKey,
      colHeaders: hotHeaders.value,
      height: hotTableHeight.value,
      viewportColumnRenderingOffset: hotTableViewportColumnRenderingOffset.value,
      autoRowSize: false,
      autoColumnSize: false,
      fixedColumnsLeft: 0,
      currentRowClassName: 'hn-table__row--current',
      invalidCellClassName: 'hn-table__cell--invalid',
      readOnlyCellClassName: 'hn-table__cell--readonly',
      afterLoadData,
      afterGetColHeader,
      afterColumnSort,
      beforePaste,
      beforeChange,
      afterChange,
      afterRender,
      afterRenderer,
      hiddenColumns: {
        columns: hiddenColumns.value,
      },
    }));

    onBeforeUnmount(() => {
      const localHotInstance = hotInstance.value;

      if (!localHotInstance) return;

      localHotInstance.destroy();
    });

    return {
      hotTableKey,
      rootEl,
      hotTableComponent,
      hotTableInitialized,
      hotInstance,
      hotSettings,
      hotColumns,
    };
  },
});
