












































































import { defineComponent, toRefs, ref, computed, watch, ComponentInstance } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import AppJsonError from '@/services/errorHandler/AppJsonError';

import { PartialWithout } from '@/utils/type';
import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { UsersFormData } from '../../../models/users';

import { useUsersCatalogStore } from '../../../store/users/usersCatalog';
import { useUsersDetailsStore } from '../../../store/users/userDetails';

import UsersForm from '../forms/UsersForm.vue';

export default defineComponent({
  components: {
    AppModal,
    UsersForm,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const usersCatalogStore = useUsersCatalogStore();
    const usersDetailsStore = useUsersDetailsStore();

    const usersFormRef = ref<ComponentInstance & { resetValidation(): void, collapseClientsTree(): void } | null>(null);
    const usersFormData = ref<PartialWithout<UsersFormData, 'id'> | null>(null);
    const usersFormIsValid = ref(false);

    const userFormResetValidation = async () => {
      if (!usersFormRef.value) return;

      usersFormRef.value.resetValidation();
    };

    const userFormCollapseClientsTree = () => {
      if (!usersFormRef.value) return;

      usersFormRef.value.collapseClientsTree();
    };

    const newUsers = computed(() => {
      if (!usersFormData.value || !usersFormIsValid.value) return null;

      return usersFormData.value;
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => !newUsers.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!newUsers.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await usersCatalogStore.actions.ADD_EXISTING_USERS({
          ...newUsers.value,
          emails: newUsers.value.emails || [],
        });

        close();
      } catch (error) {
        if (!isError(error)) return;

        if (isAxiosError(error)) {
          const messages = Object.values(error.response?.data?.errors || {})
            .reduce<string[]>((acc, val) => {
              if (Array.isArray(val)) {
                acc.push(...val);
              } else if (typeof val === 'string') {
                acc.push(val);
              }

              return acc;
            }, []);

          saveError.value = {
            title: 'Произошла ошибка',
            messages: messages.length ? messages : ['Обратитесь к администратору'],
          };
        }

        throw new AppJsonError({ error, options: { showingModal: false } });
      } finally {
        saving.value = false;
      }
    };

    watch(value, async (val) => {
      if (!val) {
        userFormCollapseClientsTree();

        usersDetailsStore.actions.RESET_GROUPS_CLIENTS_BRANDS();

        return;
      }

      usersFormData.value = null;

      userFormResetValidation();
    }, { immediate: true });

    return {
      usersFormRef,
      usersFormData,
      usersFormIsValid,
      saving,
      saveBtnIsDisabled,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
