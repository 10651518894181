<template>
  <div class="campaign-editor-settings-loader">
    <ListLoader class="campaign-editor-settings-loader__list" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import ListLoader from '@/components/_newDesign/common/loaders/ListLoader.vue';

export default defineComponent({
  components: {
    ListLoader,
  },
});
</script>

<style lang="scss" scoped>
.campaign-editor-settings-loader {
  padding: 12px 24px;

  &__list {
    width: 20%;
  }
}
</style>
