











































import { defineComponent, ref, computed, watch, onMounted, nextTick, PropType, ComponentInstance } from '@vue/composition-api';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { UserAction } from '@/models/User';

import UserActionsTableRow from './UserActionsTableRow.vue';

import { useUserActionsCatalogStore } from '../../../../store/userActions/userActionsCatalog';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
    UserActionsTableRow,
  },
  props: {
    userActions: { type: Array as PropType<UserAction[]>, default: () => [] },
  },
  setup() {
    const userActionsCatalogStore = useUserActionsCatalogStore();

    const headers = [
      {
        class: 'caption',
        text: 'Объект',
        sortable: false,
      },
      {
        class: 'caption',
        text: 'Действие',
        sortable: false,
      },
      {
        class: 'caption',
        text: 'Когда и кем сделано',
        sortable: false,
      },
    ];

    const rootRef = ref<ComponentInstance | null>(null);
    const rootEl = ref<HTMLElement | null>(null);

    const userActionsIsLoading = computed(() => userActionsCatalogStore.getters.isLoading);
    const pageIsFirst = computed(() => userActionsCatalogStore.getters.pageIsFirst);
    const pageIsLast = computed(() => userActionsCatalogStore.getters.pageIsLast);

    // TODO: это точно надо переписать
    // Проблема в том, что при сбросе страницы скролл враппера не изменяется и он пытается проскролиться вниз вызывая загрузку следующих страниц
    watch(userActionsIsLoading, (val) => {
      if (val || !pageIsFirst.value || !rootEl.value) return;

      const wrapperEl = rootEl.value.querySelector('.v-data-table__wrapper');

      if (!wrapperEl) return;

      wrapperEl.scrollTop = 0;
    });

    onMounted(async () => {
      await nextTick();

      if (!rootRef.value) return;

      rootEl.value = rootRef.value.$el as HTMLElement;
    });

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (pageIsLast.value || userActionsIsLoading.value || !isIntersecting) return;

      userActionsCatalogStore.dispatch('TO_NEXT_PAGE');
    };

    return {
      headers,
      rootRef,
      userActionsIsLoading,
      onIntersect,
    };
  },
});
