/* eslint-disable @typescript-eslint/no-explicit-any */

import { interpolateViridis } from 'd3-scale-chromatic';

// TODO: refactor

export default class DashboardColors {
  public static getColorGroups(groups: any[]): any[] {
    // TODO D3.interpolateViridis вынести в параметр colorScale
    return this.interpolateColors(groups.length, interpolateViridis, {
      colorStart: 0,
      colorEnd: 1,
      useEndAsStart: false,
    });
  }

  private static interpolateColors(dataLength: number, colorScale: (t) => void, colorRangeInfo: any): any[] {
    const { colorStart, colorEnd } = colorRangeInfo;
    const intervalSize = (colorEnd - colorStart) / dataLength;
    const colorArray: any[] = [];

    for (let i = 0; i < dataLength; i += 1) {
      const colorPoint = this.calculatePoint(i, intervalSize, colorRangeInfo);

      colorArray.push(colorScale(colorPoint));
    }

    return colorArray;
  }

  private static calculatePoint(i: number, intervalSize: any, colorRangeInfo: any): any {
    const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;

    return (useEndAsStart
      ? (colorEnd - (i * intervalSize))
      : (colorStart + (i * intervalSize)));
  }
}
