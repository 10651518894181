






























import Vue from 'vue';
import { defineComponent, PropType, toRefs, provide, ref, computed, onMounted, nextTick, ComponentInstance } from '@vue/composition-api';
import { AppRouteConfigMetaIcon } from '@/models/AppRouter';

export default defineComponent({
  props: {
    title: { type: String, default: '' },
    icon: { type: [Object, String] as PropType<AppRouteConfigMetaIcon>, default: '' },
    textClass: { type: String, default: 'caption' },
    active: { type: Boolean, default: false },
    gtmIsActive: { type: Boolean, default: true },
  },
  setup(props, context) {
    const {
      title,
      active,
      gtmIsActive,
      icon,
    } = toRefs(props);

    const rootRef = ref<ComponentInstance | null>(null);
    const rootEl = ref<HTMLElement | null>(null);

    const rootClasses = computed(() => {
      const root = 'app-side-bar-menu-item';

      const map = {
        root,
        active: `${root}--active`,
      };

      return {
        map,
        list: [
          map.root,
          active.value ? map.active : '',
        ],
      };
    });

    const listeners = computed(() => {
      const onClick = (e: unknown) => {
        Vue.gtm.push({
          event: 'user_action',
          event_category: 'Campaign List',
          event_action: 'Menu',
          event_label: title.value,
        });

        context.emit('click', e);
      };

      return {
        ...context.listeners,
        ...(gtmIsActive.value ? { click: onClick } : {}),
      };
    });

    const isActive = ref(false);

    provide('appSideBarMenuItemIsActive', isActive);

    const iconName = computed(() => (typeof icon.value === 'object' ? icon.value.name : icon.value));

    const iconNameComputed = computed(() => (iconName.value || 'mdi-circle'));

    const iconSizeComputed = computed(() => {
      if (!iconName.value) return 8;

      return typeof icon.value === 'object' ? icon.value.size : undefined || 24;
    });

    const avatarSizeComputed = computed(() => (iconName.value ? 32 : 8));

    const updateActiveStatusViaRootElClassList = (el: HTMLElement) => {
      isActive.value = el.classList.contains(rootClasses.value.map.active);
    };

    onMounted(async () => {
      await nextTick();

      if (!rootRef.value) return;

      rootEl.value = rootRef.value.$el as HTMLElement;

      if (!rootEl.value) return;

      updateActiveStatusViaRootElClassList(rootEl.value);
    });

    const onMutateRootEl = async () => {
      if (!rootEl.value) return;

      updateActiveStatusViaRootElClassList(rootEl.value);
    };

    return {
      rootClasses,
      rootRef,
      listeners,
      avatarSizeComputed,
      iconNameComputed,
      iconSizeComputed,
      onMutateRootEl,
    };
  },
});
