



































import { computed, defineComponent, watch } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import useRoute from '@/packages/hooks/useRoute';

import { useLayoutLoadingStatus } from '@/composables/layout';

import { useUnapprovedPlacementsStore } from '../store';

import UnapprovedPlacementsHeader from '../components/UnapprovedPlacementsHeader.vue';
import UnapprovedPlacementsContent from '../components/UnapprovedPlacementsContent.vue';

export default defineComponent({
  components: {
    AppView,
    UnapprovedPlacementsHeader,
    UnapprovedPlacementsContent,
  },
  setup(props, context) {
    const route = useRoute(context);

    const unapprovedPlacementsStore = useUnapprovedPlacementsStore();

    const { updateIsLoading } = useLayoutLoadingStatus();

    const unapprovedCampaigns = computed(() => unapprovedPlacementsStore.getters.campaigns);
    const unapprovedCampaignsFilters = computed(() => unapprovedPlacementsStore.getters.filters);
    const unapprovedCampaignsFiltersChanged = computed(() => unapprovedPlacementsStore.getters.filtersChanged);
    const isLoading = computed(() => unapprovedPlacementsStore.getters.isLoading);

    const searchQueryIsChanged = computed(() => !!route.value.query.search);

    const fetch = async () => {
      await unapprovedPlacementsStore.actions.FETCH_CAMPAIGNS();
    };

    const init = async () => {
      if (searchQueryIsChanged.value) return;

      updateIsLoading(true);
      unapprovedPlacementsStore.actions.RESET_FILTERS();

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    watch(unapprovedCampaignsFilters, () => {
      if (!unapprovedCampaignsFiltersChanged.value) return;

      fetch();
    });

    init();

    return {
      unapprovedCampaigns,
      unapprovedCampaignsFiltersChanged,
      isLoading,
    };
  },
});
