import Vue from 'vue';
import Bowser from 'bowser';

import PluginInit from '@/models/PluginInit';

export interface Device {
  os: {
    slug: string;
    name: string;
  };
  browser: {
    slug: string;
    name: string;
  };
}

const pluginInit: PluginInit = () => {
  const bowserParser = Bowser.getParser(window.navigator.userAgent);

  const device = {
    os: {
      get slug() {
        return bowserParser.getOSName(true);
      },
      get name() {
        return bowserParser.getOSName();
      },
    },
    browser: {
      get slug() {
        return bowserParser.getBrowserName(true);
      },
      get name() {
        return bowserParser.getBrowserName();
      },
    },
  };

  Vue.device = device;
  Vue.prototype.$device = device;
};

export default pluginInit;
