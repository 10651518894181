













































import modally from '@/services/modally';
import notify from '@/services/notify';

import numatter from '@/packages/numatter';

import { Markup } from '@/models/Markup';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import { useCampaignEditorPlacementDetailsStore } from '@/store/campaignEditor/campaignEditorPlacementDetails';

import { computed, defineComponent, PropType, ref, toRefs } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
  DBtnInline,
} from '@shared/druid-kit';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
    DBtnInline,
  },
  props: {
    markup: { type: Object as PropType<Markup | null>, default: null },
  },
  setup(props, context) {
    const { markup } = toRefs(props);

    const campaignEditorPlacementStore = useCampaignEditorPlacementDetailsStore();

    const deleting = ref(false);

    const markupsWithCost = computed(() => markup.value?.markupsWithCost || []);

    const calculatedCost = computed(() => {
      const result = markupsWithCost.value.reduce<number>((acc, { cost }) => acc + cost, 0);

      return numatter.format(result, '0,0[.]00');
    });

    const calculatedRawCost = computed(() => {
      const result = markupsWithCost.value.reduce<number>((acc, { rawCost }) => acc + rawCost, 0);

      return numatter.format(result, '0,0[.]00');
    });

    const deleteMarkup = async () => {
      const result = await modally.show({
        title: 'Удалить все периоды?',
        content: 'Наценка будет удалена.\nКоэффицент 1 будет использоваться по умолчанию\nна весь период размещения.',
        buttons: [
          {
            props: {
              text: 'Удалить все',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary',
            },
            action: () => false,
          },
        ],
      });

      if (!markup.value || !result) return;

      deleting.value = true;

      try {
        await campaignEditorPlacementStore.dispatch('DELETE_MARKUP', {
          placementId: markup.value.placementId,
          markupId: markup.value.id,
        });

        notify.push({
          text: `Наценка для ${markup.value.dataProviderName} удалена`,
          position: ['bottom', 'center'],
          status: 'positive',
        });
      } catch (e) {
        if (!isError(e) || !isAxiosError(e)) return;

        const message = e.response?.data?.message;

        if (message) {
          modally.show({ status: 'error', title: 'Ошибка при удалении наценки', content: message });
        } else {
          throw e;
        }
      } finally {
        deleting.value = false;
      }
    };

    const openAddModal = () => {
      context.emit('open-add-modal');
    };

    return {
      calculatedCost,
      calculatedRawCost,
      deleteMarkup,
      openAddModal,
    };
  },
});
