
























import { defineComponent, PropType } from '@vue/composition-api';

import { SearchBreadcrumb } from '../models';

export default defineComponent({
  props: {
    breadcrumbs: { type: Array as PropType<SearchBreadcrumb[]>, default: () => [] },
  },
});
