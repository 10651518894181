const parsenv = (env: string) => {
  const isBoolean = (value: string) => value === 'true' || value === 'false';

  const isNumeric = (value: string) => !Number.isNaN(+value) && !Number.isNaN(parseFloat(value));

  const envIsBoolean = isBoolean(env);
  const envIsNum = isNumeric(env);

  if (envIsNum || envIsBoolean) return JSON.parse(env);

  return env;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const confique = <T>(config: any): T => new Proxy(config, {
  get(target, prop) {
    prop = String(prop);

    if (!(prop in target)) throw new Error(`Config: There is no key named "${prop}"`);

    let value = target[prop];

    if (value === null || value === undefined) throw new Error(`Config: Value for "${prop}" is not defined`);

    if (typeof value === 'string' && value.startsWith('$VUE_APP_')) {
      // value was not replaced, it seems we are in development.
      // remove $ and get current value from process.env
      const envName = value.substring(1);

      value = process.env[envName];

      if (value === null || value === undefined) throw new Error(`Config: Environment variable "${envName}" is not defined`);
    }

    // value was already replaced, it seems we are in production.

    const parsedValue = parsenv(value);

    return parsedValue;
  },
});

export default confique;
