<template>
  <div class="list-loader">
    <v-skeleton-loader
      v-if="!withoutTitle"
      type="text"
      class="list-loader__title list-loader__item"
    />
    <v-skeleton-loader
      v-for="num in rows"
      :key="num"
      type="text"
      class="list-loader__item"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    withoutTitle: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
  },
});
</script>

<style lang="scss" scoped>
.list-loader {
  display: flex;
  flex-direction: column;

  &--in-row {
    flex-direction: row;
  }

  &__title {
    ::v-deep .v-skeleton-loader__text {
      background: map-get($grey, 'lighten-1') !important;
    }
  }

  &__item {
    width: 100%;
    margin-right: 0;

    &--in-row {
      margin-right: 24px;
    }
  }

  ::v-deep .v-skeleton-loader__text {
    margin-bottom: 32px;
  }
}
</style>
