



























import { defineComponent, toRefs, ref, PropType } from '@vue/composition-api';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import modally from '@/services/modally';

import { RoleLight } from '@/models/Role';

import { useRolesCatalogStore } from '../../../../store/roles/rolesCatalog';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    role: { type: Object as PropType<RoleLight>, required: true },
  },
  setup(props, context) {
    const {
      role,
    } = toRefs(props);

    const rolesCatalogStore = useRolesCatalogStore();

    const openRole = () => {
      context.emit('open-modal', {
        slug: 'item',
        props: {
          title: role.value.name,
          roleId: role.value.id,
        },
      });
    };

    const deletion = ref(false);

    const deleteRole = async () => {
      const result = await modally.show({
        title: 'Удалить роль?',
        content: `Роль ${role.value.name} будет удалена.`,
        buttons: [
          {
            props: {
              text: 'Удалить',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary',
            },
            action: () => false,
          },
        ],
      });

      if (!result) return;

      deletion.value = true;

      try {
        await rolesCatalogStore.actions.DELETE_ROLE(role.value.id);
      } finally {
        deletion.value = false;
      }
    };

    return {
      openRole,
      deletion,
      deleteRole,
    };
  },
});
