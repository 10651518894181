/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createComposable } from 'vuex-smart-module';

import { CategoriesApi } from '@/api';

import { SetCategoryPayload, UpdateCategoryPayload } from '@/api/modules/categories.api';

import { Category } from '@/models/Category';

class ModuleState {
  isLoading = false;

  categories: Category[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get isLoading() {
    return this.state.isLoading;
  }

  get categories() {
    return this.state.categories;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }

  SET_CATEGORIES(payload: Category[]) {
    this.state.categories = payload;
  }

  REMOVE_CATEGORY(categoryId: string) {
    this.state.categories = this.state.categories.filter(({ id }) => id !== categoryId);
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_CATEGORIES() {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await CategoriesApi.fetchCategories();

      this.commit('SET_CATEGORIES', res.data);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async DELETE_CATEGORY(categoryId: string) {
    await CategoriesApi.deleteCategory(categoryId);

    this.commit('REMOVE_CATEGORY', categoryId);
  }

  async CREATE_CATEGORY(payload: SetCategoryPayload) {
    await CategoriesApi.setCategory(payload);

    await this.dispatch('FETCH_CATEGORIES');
  }

  async UPDATE_CATEGORY(payload: UpdateCategoryPayload) {
    await CategoriesApi.updateCategory(payload);

    await this.dispatch('FETCH_CATEGORIES');
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useCategoriesCatalogStore = createComposable(module);

export default module;
