




















































import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api';

import {
  DRadioInline,
  DSelect,
} from '@shared/druid-kit';

import { Campaign } from '@/models/Campaign';

import { useCampaignStore } from '@/store/campaign';
import { useCampaignEditorSettingsStore } from '@/store/campaignEditor/campaignEditorSettings';

export default defineComponent({
  components: {
    DRadioInline,
    DSelect,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
  },
  setup(props) {
    const {
      campaign,
    } = toRefs(props);

    const campaignStore = useCampaignStore();
    const campaignEditorSettingsStore = useCampaignEditorSettingsStore();

    const clientBrands = computed(() => campaignEditorSettingsStore.getters.clientBrands);

    const campaignName = computed({
      get() {
        return campaignEditorSettingsStore.getters.currentSettings?.name || '';
      },
      set(val: string) {
        campaignEditorSettingsStore.dispatch('CHANGE_SETTINGS', { name: val });
      },
    });

    const campaignBrand = computed({
      get() {
        return campaignEditorSettingsStore.getters.currentSettings?.brandId || null;
      },
      set(val: string | null) {
        if (!val) return;

        campaignEditorSettingsStore.dispatch('CHANGE_SETTINGS', { brandId: val });
      },
    });

    const campaignProjectCode = computed(() => campaign.value.projectCode || 'Не привязан');

    const campaignIsActive = computed({
      get() {
        return campaignStore.getters.currentCampaign?.isActive || false;
      },
      set(val: boolean) {
        campaignStore.dispatch('CHANGE_CAMPAIGN', { isActive: val });
      },
    });

    const campaignIsActiveOptions = computed(() => [
      { value: true, text: campaignIsActive.value ? 'Запущена' : 'Запустить' },
      { value: false, text: campaignIsActive.value ? 'Остановить' : 'Остановлена' },
    ]);

    return {
      clientBrands,
      campaignIsActiveOptions,
      campaignName,
      campaignBrand,
      campaignProjectCode,
      campaignIsActive,
    };
  },
});
