import { AxiosResponse } from 'axios';

import { Token, TokenProvider } from '@/models/Token';

import httpClient from '../httpClient';

interface FetchTokensPayload {
  params?: {
    filter?: string;
    page?: string | number;
  };
}

interface FetchTokensResponse {
  list: Token[];
  totalRows: number;
}

interface AddTokenPayload {
  name: string;
  tokenProviderId: string;
  tokenJson: string;
}

type AddTokenResponse = Omit<Token, 'providerName'>;

export default class TokensApi {
  public static async fetchTokens(payload: FetchTokensPayload): Promise<AxiosResponse<FetchTokensResponse>> {
    const res = await httpClient.get<FetchTokensResponse>('/api/tokens', payload);

    return res;
  }

  public static async fetchConnectorTokens(connectorId: string): Promise<AxiosResponse<Token[]>> {
    const res = await httpClient.get<Token[]>('/api/tokens', { params: { connectorId } });

    return res;
  }

  public static async fetchTokenProviders(): Promise<AxiosResponse<TokenProvider[]>> {
    const res = await httpClient.get<TokenProvider[]>('/api/tokens/tokenproviders');

    return res;
  }

  public static async addToken(payload: AddTokenPayload): Promise<AxiosResponse<AddTokenResponse>> {
    const res = await httpClient.post<AddTokenResponse>('/api/tokens', payload);

    return res;
  }

  public static async createToken(payload: { code: string; tokenProviderId: string }): Promise<AxiosResponse<AddTokenResponse>> {
    const formData = new FormData();

    formData.append('code', payload.code);
    formData.append('tokenProviderId', payload.tokenProviderId);

    const res = await httpClient.post<AddTokenResponse>('/api/tokens/oauth', formData, { headers: { 'Content-type': 'application/x-www-form-urlencoded' } });

    return res;
  }

  public static async deleteToken(tokenId: string): Promise<AxiosResponse<unknown>> {
    const res = await httpClient.delete<unknown>(`/api/tokens/${tokenId}`);

    return res;
  }
}
