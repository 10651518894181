































































import { defineComponent, ref, PropType } from '@vue/composition-api';

import {
  DBtnInline,
  DTable,
} from '@shared/druid-kit';

import useRouter from '@/packages/hooks/useRouter';

import modally from '@/services/modally';
import notify from '@/services/notify';

import { Markup } from '@/models/Markup';

import isError from '@/utils/isError';
import isAxiosError from '@/utils/isAxiosError';

import MarkupPeriodsTableRow from './MarkupPeriodsTableRow.vue';

import MarkupPeriodsTableAddModal from './modals/MarkupPeriodsTableAddModal.vue';

import { useMarkupDetailsStore } from '../../../store/markupDetails';

export default defineComponent({
  components: {
    DBtnInline,
    DTable,
    MarkupPeriodsTableRow,
    MarkupPeriodsTableAddModal,
  },
  props: {
    markup: { type: Object as PropType<Markup>, required: true },
  },
  setup(props, context) {
    const router = useRouter(context);

    const markupDetailsStore = useMarkupDetailsStore();

    const headers = [
      {
        class: 'caption',
        text: 'Дата начала',
        value: 'startDate',
      },
      {
        class: 'caption',
        text: 'Коэффицент',
        value: 'value',
      },
      {
        class: 'caption',
        text: 'Бюджет без наценки',
        value: 'rawCost',
      },
      {
        class: 'caption',
        text: 'Бюджет с наценкой',
        value: 'cost',
      },
      {
        sortable: false,
      },
    ];

    const modalsMap = ref({
      add: false,
    });

    const openModal = (modalSlug: keyof typeof modalsMap.value) => {
      modalsMap.value[modalSlug] = true;
    };

    const openAddModal = () => {
      openModal('add');
    };

    const deleting = ref(false);

    const deleteMarkup = async () => {
      const result = await modally.show({
        title: 'Удалить все периоды?',
        content: 'Наценка будет удалена.\nКоэффицент 1 будет использоваться по умолчанию\nна весь период размещения.',
        buttons: [
          {
            props: {
              text: 'Удалить все',
              color: 'warning',
              outlined: true,
            },
            action: () => true,
          },
          {
            props: {
              text: 'Отмена',
              color: 'primary',
            },
            action: () => false,
          },
        ],
      });

      if (!result) return;

      deleting.value = true;

      try {
        await markupDetailsStore.dispatch('DELETE_MARKUP');

        const onPush = () => notify.push({
          text: 'Наценка удалена',
          position: ['bottom', 'center'],
          status: 'positive',
        });

        router.push('/markups', onPush, onPush);
      } catch (e) {
        if (!isError(e) || !isAxiosError(e)) return;

        const message = e.response?.data?.message;

        if (message) {
          modally.show({ status: 'error', title: 'Ошибка при удалении наценки', content: message });
        } else {
          throw e;
        }
      } finally {
        deleting.value = false;
      }
    };

    return {
      headers,
      modalsMap,
      openAddModal,
      deleteMarkup,
    };
  },
});
