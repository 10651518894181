


















import { defineComponent, PropType } from '@vue/composition-api';

import { DTable } from '@shared/druid-kit';

import { DataProvider } from '@/models/DataProvider';

import DataProvidersTableRow from './DataProvidersTableRow.vue';

export default defineComponent({
  components: {
    DTable,
    DataProvidersTableRow,
  },
  props: {
    dataProviders: { type: Array as PropType<DataProvider[]>, default: () => [] },
  },
  setup() {
    const headers = ['Name', 'ConnectorId', '']
      .map((text) => ({
        text,
        class: 'caption',
        sortable: false,
      }));

    return {
      headers,
    };
  },
});
