





































import { defineComponent, computed } from '@vue/composition-api';

import { debounce } from 'lodash';

import {
  DBtnInline,
  DSelectInline,
} from '@shared/druid-kit';

import { useProviderSettingsStore } from '../store';

export default defineComponent({
  components: {
    DBtnInline,
    DSelectInline,
  },
  setup() {
    const providerSettingsStore = useProviderSettingsStore();

    const filter = computed({
      get() {
        return providerSettingsStore.getters.filters.failedOnly;
      },
      set(val: boolean) {
        providerSettingsStore.dispatch('UPDATE_FILTERS', { failedOnly: val });
      },
    });

    const query = computed({
      get() {
        return providerSettingsStore.getters.filters.query;
      },
      set: debounce((val: string) => {
        providerSettingsStore.dispatch('UPDATE_FILTERS', { query: val });
      }, 300),
    });

    const filterItems = computed(() => [
      { text: 'Все настройки', value: false },
      { text: 'Только проблемные', value: true },
    ]);

    const createSetting = () => {
      providerSettingsStore.dispatch('CREATE_PROVIDER_SETTINGS');
    };

    return {
      filter,
      filterItems,
      createSetting,
      query,
    };
  },
});
