/* eslint-disable max-classes-per-file */
import { Module, createComposable } from 'vuex-smart-module';

import usersCatalog from './users/usersCatalog';
import userDetails from './users/userDetails';

const module = new Module({
  modules: {
    usersCatalog,
    userDetails,
  },
});

export const useUsersStore = createComposable(module);

export default module;
