



























import { defineComponent, computed } from '@vue/composition-api';

import useRoute from '@/packages/hooks/useRoute';

import store from '@/store';
import navigationUiStore from '@/store/navigation/navigationUi';

const navigationUiStoreContext = navigationUiStore.context(store);

export default defineComponent({
  setup(props, context) {
    const route = useRoute(context);

    const headerMinHeight = computed(() => navigationUiStoreContext.getters.headerMinHeight);

    const title = computed(() => route.value.meta.title);

    const headerIsSticky = computed(() => route.value.meta?.header?.sticky);

    return {
      headerMinHeight,
      title,
      headerIsSticky,
    };
  },
});
