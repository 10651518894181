


















import { defineComponent } from '@vue/composition-api';

import { DTableCell } from '@shared/druid-kit';

export default defineComponent({
  components: {
    DTableCell,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    approvedBy: {
      type: String,
      default: '',
    },
  },
});
