


















import { defineComponent, PropType, toRefs, computed, watch, ref } from '@vue/composition-api';

import Handsontable from 'handsontable';
import { cloneDeep } from 'lodash';

import { HnTable } from '@/packages/hn-table/src/components';
import { HnTableColumn } from '@/packages/hn-table/src/models';
import { HnTableSelectCellItems } from '@/packages/hn-table/src/types';

import { useAuthStore } from '@/store/auth';
import { useMetricTypesStore } from '@/store/reference/metricTypes';
import { useCampaignEditorPlacementsStore } from '@/store/campaignEditor/campaignEditorPlacements';

import { Campaign, CampaignEditorPlacement } from '@/models/Campaign';
import { MetricTypeLight } from '@/models/MetricType';

import campaignEditorPlacementsEmitter from '../campaignEditorPlacementsEmitter';

import PlacementsTableInfoCell from './cells/PlacementsTableInfoCell.vue';
import PlacementsTableSourceCell from './cells/PlacementsTableSourceCell.vue';
import PlacementsTableMarkupsCell from './cells/PlacementsTableMarkupsCell.vue';
import PlacementsTableMetricCell from './cells/PlacementsTableMetricCell.vue';
import PlacementsTableApprovedCell from './cells/PlacementsTableApprovedCell.vue';

let H_TABLE_INSTANCE: Handsontable | null = null;

const validateTargetingAge = (value: string | number, callback: (val: boolean) => void) => callback(value >= 12 && value <= 64);

export default defineComponent({
  components: {
    HnTable,
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    campaignPlacements: { type: Array as PropType<CampaignEditorPlacement[]>, required: true },
    campaignPlanMetrics: { type: Array as PropType<MetricTypeLight[]>, required: true },
    placementsHasBeenChanged: { type: Boolean, default: false },
    columnsPresetsSlugs: { type: Array as PropType<string[]>, default: () => [] },
  },
  setup(props, context) {
    const {
      campaign,
      campaignPlacements,
      campaignPlanMetrics,
      placementsHasBeenChanged,
      columnsPresetsSlugs,
    } = toRefs(props);

    const authStore = useAuthStore();
    const metricTypesStore = useMetricTypesStore();
    const campaignEditorPlacementsStore = useCampaignEditorPlacementsStore();

    const tableEl = ref<HTMLElement | null>(null);

    const userIsDp = computed(() => authStore.getters.userIsDp);
    const userIsBu = computed(() => authStore.getters.userIsBu);

    const planIsShown = computed(() => columnsPresetsSlugs.value.includes('planMetrics'));
    const factIsShown = computed(() => columnsPresetsSlugs.value.includes('factMetrics'));

    const cuurentColumnsPresetsSlugs = computed(() => {
      const newSlugs = columnsPresetsSlugs.value.filter((slug) => slug !== 'planMetrics' && slug !== 'factMetrics');

      if (planIsShown.value || factIsShown.value) {
        newSlugs.push('metrics');
      }

      return newSlugs;
    });

    const categorySelectOptions = computed<HnTableSelectCellItems>(() => campaignEditorPlacementsStore
      .getters
      .categories
      .map(({ id, name }) => ({ value: id, text: name })));

    const genders = computed(() => campaignEditorPlacementsStore.state.genders);
    const transmittances = computed(() => campaignEditorPlacementsStore.state.transmittances);
    const regions = computed(() => campaignEditorPlacementsStore.state.regions);
    const cappingPeriods = computed(() => campaignEditorPlacementsStore.state.cappingPeriods);
    const incomeGroups = computed(() => campaignEditorPlacementsStore.state.incomeGroups);

    const dataProviderSelectOptions = computed<HnTableSelectCellItems>(() => {
      const { dataProviders } = campaignEditorPlacementsStore.getters;

      return (cell) => {
        const rowData = cell.hotInstance?.getSourceDataAtRow(cell.row);

        if (!rowData || Array.isArray(rowData)) return [];

        return [
          { value: null, text: 'Сумма по площадкам' },
          ...dataProviders
            .filter(({ id, name }) => id === rowData.preferredDataProviderId || rowData.metricsDataProviders.includes(name))
            .map(({ id, name }) => ({ value: id, text: name })),
        ];
      };
    });

    const columnsPresetsMap = computed(() => ({
      settings: [
        new HnTableColumn({
          data: 'vat',
          title: 'НДС',
          header: {
            className: 'htRight',
          },
          settings: {
            type: 'numeric',
            width: 54,
            numericFormat: {
              pattern: '0{%}',
            },
          },
        }),
        new HnTableColumn({
          data: 'startDate',
          title: 'Старт',
          header: {
            className: 'htRight',
          },
          settings: {
            type: 'date',
            width: 94,
            validator: { $lte: 'endDate' },
          },
          props: {
            textAlign: 'right',
          },
        }),
        new HnTableColumn({
          title: '',
          data: 'settings-stub',
          settings: {
            type: 'stub',
            width: 32,
          },
          props: {
            text: '—',
          },
        }),
        new HnTableColumn({
          title: 'Конец',
          data: 'endDate',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'date',
            width: 94,
            validator: { $gte: 'startDate' },
          },
          props: {
            textAlign: 'left',
          },
        }),
        new HnTableColumn({
          title: 'Тип закупки',
          data: 'placementType',
          header: {
            className: 'htRight',
          },
          settings: {
            className: 'htRight',
            width: 86,
            readOnly: true,
          },
        }),
        new HnTableColumn({
          title: 'Категория',
          data: 'category.categoryId',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'dropdown',
            width: 154,
          },
          props: {
            items: categorySelectOptions.value,
            textAlign: 'left',
          },
        }),
        new HnTableColumn({
          title: 'Тип сервиса',
          data: 'serviceTypeFullPath',
          header: {
            className: 'htLeft',
          },
          settings: {
            className: 'htLeft',
            width: 182,
            readOnly: true,
          },
        }),
        ...(userIsBu.value ? [
          new HnTableColumn({
            title: 'Наценки',
            data: 'markupsCount',
            settings: {
              width: 76,
              readOnly: true,
            },
            props: {
              campaignPlacements: campaignPlacements.value,
              textAlign: 'center',
              emitter: campaignEditorPlacementsEmitter,
            },
            rendererComponent: PlacementsTableMarkupsCell,
          }),
        ] : []),
        new HnTableColumn({
          title: 'Сумма с наценкой',
          data: 'totalCost',
          header: {
            className: 'htRight',
          },
          settings: {
            type: 'numeric',
            className: 'htRight',
            width: 120,
            placeholder: '—',
            readOnly: true,
            numericFormat: {
              pattern: {
                mantissa: 2,
              },
            },
          },
        }),
      ],
      targetings: [
        new HnTableColumn({
          title: 'Регион',
          data: 'targeting.geo',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'dropdown',
            width: 222,
          },
          props: {
            items: regions.value,
            textAlign: 'left',
            itemText: 'name',
            itemValue: 'code',
            multiple: true,
          },
        }),
        new HnTableColumn({
          title: 'Пол',
          data: 'targeting.gender',
          header: {
            className: 'htRight',
          },
          settings: {
            type: 'dropdown',
            width: 60,
          },
          props: {
            items: genders.value,
            textAlign: 'right',
            itemText: 'name',
            itemValue: 'code',
          },
        }),
        new HnTableColumn({
          data: 'targeting.ageFrom',
          title: 'Возраст от',
          header: {
            className: 'htRight',
          },
          settings: {
            type: 'numeric',
            validator: validateTargetingAge,
            allowInvalid: false,
            width: 80,
            placeholder: '—',
            className: 'htRight',
          },
        }),
        new HnTableColumn({
          title: '',
          data: 'targetings-stub',
          settings: {
            type: 'stub',
            width: 32,
          },
          props: {
            text: '—',
          },
        }),
        new HnTableColumn({
          data: 'targeting.ageTo',
          title: 'Возраст до',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'numeric',
            validator: validateTargetingAge,
            allowInvalid: false,
            width: 80,
            placeholder: '—',
            className: 'htLeft',
          },
        }),
        new HnTableColumn({
          title: 'Пропускаемость',
          data: 'targeting.transmittance',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'dropdown',
            width: 120,
          },
          props: {
            items: transmittances.value,
            textAlign: 'left',
            itemText: 'name',
            itemValue: 'code',
          },
        }),
        new HnTableColumn({
          data: 'targeting.interests',
          title: 'Таргетинг по интересам',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'text',
            width: 154,
            placeholder: '—',
            className: 'htLeft',
          },
        }),
        new HnTableColumn({
          title: 'Период ограничения по частоте',
          data: 'targeting.cappingPeriod',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'dropdown',
            width: 154,
          },
          props: {
            items: cappingPeriods.value,
            textAlign: 'left',
            itemText: 'name',
            itemValue: 'code',
          },
        }),
        new HnTableColumn({
          data: 'targeting.capping',
          title: 'Ограничение по частоте',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'numeric',
            width: 110,
            placeholder: '—',
            className: 'htLeft',
          },
        }),
        new HnTableColumn({
          data: 'targeting.timing',
          title: 'Хронометраж',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'numeric',
            width: 110,
            placeholder: '—',
            className: 'htLeft',
          },
        }),
        new HnTableColumn({
          title: 'Тип дохода',
          data: 'targeting.income',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'dropdown',
            width: 86,
          },
          props: {
            items: incomeGroups.value,
            textAlign: 'left',
            itemText: 'name',
            itemValue: 'code',
          },
        }),
      ],
      metrics: [
        new HnTableColumn({
          title: 'Данные плана',
          data: 'getPlanFrom1C',
          header: {
            className: 'htRight',
          },
          settings: {
            width: 80,
            readOnly: true,
          },
          props: {
            textAlign: 'right',
          },
          rendererComponent: PlacementsTableSourceCell,
        }),
        new HnTableColumn({
          title: 'Источник данных',
          data: 'preferredDataProviderId',
          header: {
            className: 'htLeft',
          },
          settings: {
            type: 'dropdown',
            width: 182,
          },
          props: {
            items: dataProviderSelectOptions.value,
            textAlign: 'left',
          },
        }),
        ...campaignPlanMetrics.value.map((planMetric) => {
          const metricTypeAccuracy = metricTypesStore.getters.getMetricTypeProp(planMetric.name, 'accuracy') || 0;

          return new HnTableColumn({
            title: planMetric.displayName,
            data: `planTotalMetrics.${planMetric.name}`,
            header: {
              className: 'htRight',
            },
            settings: {
              type: 'numeric',
              width: 168,
              className: 'htRight',
              placeholder: '—',
              allowInvalid: false,
              allowEmpty: true,
              readOnly: !planIsShown.value && factIsShown.value,
            },
            props: {
              metricName: planMetric.name,
              campaignPlacements: campaignPlacements.value,
              format: {
                mantissa: metricTypeAccuracy,
              },
              planIsShown: planIsShown.value,
              factIsShown: factIsShown.value,
            },
            rendererComponent: PlacementsTableMetricCell,
          });
        }),
      ],
    }));

    const hnTableColumns = computed<HnTableColumn[]>(() => [
      new HnTableColumn({
        title: 'Размещение',
        data: 'hidden',
        header: {
          className: 'htLeft',
        },
        settings: {
          width: 320,
          readOnly: true,
        },
        props: {
          campaign: campaign.value,
          campaignPlacements: campaignPlacements.value,
        },
        rendererComponent: PlacementsTableInfoCell,
      }),
      ...columnsPresetsMap.value.settings,
      ...columnsPresetsMap.value.targetings,
      ...columnsPresetsMap.value.metrics,
      new HnTableColumn({
        title: 'DP',
        data: 'check.approvedByPlanning',
        settings: {
          width: 60,
          readOnly: !userIsDp.value,
        },
        props: {
          textAlign: 'center',
          campaignPlacements: campaignPlacements.value,
          tooltipText: [
            [true, 'Сделать непроверенным Digital Planer’ом'],
            [false, 'Отметить размещение как проверенное Digital Planer’ом'],
          ],
        },
        rendererComponent: PlacementsTableApprovedCell,
      }),
      new HnTableColumn({
        title: 'BU',
        data: 'check.approvedByBuying',
        settings: {
          width: 80,
          readOnly: !userIsBu.value,
        },
        props: {
          textAlign: 'center',
          campaignPlacements: campaignPlacements.value,
          tooltipText: [
            [true, 'Сделать непроверенным Buying Unit’ом'],
            [false, 'Отметить размещение как проверенное Buying Unit’ом'],
          ],
        },
        rendererComponent: PlacementsTableApprovedCell,
      }),
    ]);

    const beforeChange = (changes: Handsontable.CellChange[], source: Handsontable.ChangeSource) => {
      const localHnTableInstance = H_TABLE_INSTANCE;

      if (!localHnTableInstance || !changes || source === 'loadData') return;

      const changesWithoutGetPlanFrom1C = changes.filter(([_row, prop]) => prop !== 'getPlanFrom1C');
      const changedRowsWithoutGetPlanFrom1C = changesWithoutGetPlanFrom1C.map(([row]) => row);
      const uniqueChangedRowsWithoutGetPlanFrom1C = [...new Set(changedRowsWithoutGetPlanFrom1C)];

      uniqueChangedRowsWithoutGetPlanFrom1C.forEach((row) => {
        const oldValue = localHnTableInstance.getDataAtRowProp(row, 'getPlanFrom1C');

        if (oldValue) changes.push([row, 'getPlanFrom1C', oldValue, false]);
      });
    };

    const afterChange = (changes: Handsontable.CellChange[], source: Handsontable.ChangeSource) => {
      const localHnTableInstance = H_TABLE_INSTANCE;

      if (!localHnTableInstance || source === 'loadData') return;

      const sourceData = cloneDeep(localHnTableInstance.getSourceData()) as CampaignEditorPlacement[];

      const changedRows = changes.map(([row]) => row);
      const uniqueChangedRows = [...new Set(changedRows)];

      const changedPlacements = uniqueChangedRows.map((row) => {
        const physicalRow = localHnTableInstance.rowIndexMapper.getPhysicalFromVisualIndex(row) as number;

        return sourceData[physicalRow];
      });

      context.emit('change', changedPlacements);
    };

    const hnTableSettings = computed(() => ({
      height: '100%',
      beforeChange,
      afterChange,
      fixedColumnsLeft: 1,
      fixedColumnsRight: 2,
      fixedColumnsRightWithBorder: columnsPresetsSlugs.value.some((slug) => slug !== 'settings'),
    }));

    const onHnTableInitialized = (localHnTableInstance: Handsontable) => {
      H_TABLE_INSTANCE = localHnTableInstance;
    };

    watch(placementsHasBeenChanged, (val) => {
      const localHnTableInstance = H_TABLE_INSTANCE;

      if (!localHnTableInstance || !tableEl.value) return;

      const { clientHeight } = tableEl.value;
      const tableHeight = val ? clientHeight - 40 : clientHeight + 40;

      tableEl.value.style.height = `${tableHeight}px`;

      localHnTableInstance.refreshDimensions();
    }, { immediate: true });

    return {
      cuurentColumnsPresetsSlugs,
      hnTableColumns,
      hnTableSettings,
      onHnTableInitialized,
      columnsPresetsMap,
      tableEl,
    };
  },
});
