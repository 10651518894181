import { AxiosResponse } from 'axios';

import { MaintenanceClient } from '@/models/Maintenance';

import httpClient from '../httpClient';

export default class MaintenanceApi {
  public static async updateCampaignMetrics(payload: MaintenanceClient[]): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/maintenance/update-campaigns-metrics', payload);

    return res;
  }

  public static async updatePlacementMetricDataproviders(payload: MaintenanceClient[]): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/maintenance/update-placements-metrics-dataprovider', payload);

    return res;
  }

  public static async recalculatePlacementMetrics(payload: MaintenanceClient[]): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/maintenance/recalculate-placements-metrics', payload);

    return res;
  }

  public static async updatePlacementMarkups(payload: MaintenanceClient[]): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/maintenance/update-placements-by-markups', payload);

    return res;
  }

  public static async recalculatePlacementFlights(payload: MaintenanceClient[]): Promise<AxiosResponse<''>> {
    const res = await httpClient.post<''>('/api/maintenance/recalculate-placement-flights', payload);

    return res;
  }
}
