import { AppRouteConfig } from '@/models/AppRouter';

import DataProvidersSettings from '../views/DataProvidersSettings.vue';
import DataProvidersSettingsCatalog from '../views/DataProvidersSettings/DataProvidersSettingsCatalog.vue';
import PageCatalogLoader from '../../../components/_newDesign/common/loaders/PageCatalogLoader.vue';

const routeConfig: AppRouteConfig = {
  path: 'data-providers-settings',
  component: DataProvidersSettings,
  meta: {
    title: 'Настройки провайдеров',
    permission: 'admin',
    order: 1,
  },
  children: [
    {
      name: 'data-providers-settings-catalog',
      path: '',
      component: DataProvidersSettingsCatalog,
      meta: {
        title: 'Настройки провайдеров',
        loader: PageCatalogLoader,
        header: {
          sticky: true,
        },
      },
    },
  ],
};

export default routeConfig;
