import { AxiosResponse } from 'axios';

import httpClient from '../httpClient';

interface SearchResultClient {
  id: string;
  name: string;
}

interface SearchResultBrand {
  id: string;
  name: string;
  clientId: string;
  clientName: string;
}

interface SearchResultCampaign {
  id: string;
  name: string;
  clientId: string;
  clientName: string;
  brandId: string;
  brandName: string;
  isActive: boolean;
  state: {
    id: string;
    name: string;
    code: 'paused' | 'active' | 'finished';
  };
  approved: boolean;
}

interface SearchResultPlacement {
  id: string;
  name: string;
  danboId: string;
  clientId: string;
  clientName: string;
  brandId: string;
  brandName: string;
  campaignId: string;
  campaignName: string;
  state: {
    id: string;
    name: string;
    code: 'default' | 'paused' | 'running' | 'finished';
  };
  approved: boolean;
}

export interface SearchResult {
  isDanboResult: boolean;
  result: {
    clients: SearchResultClient[];
    brands: SearchResultBrand[];
    campaigns: SearchResultCampaign[];
    placements: SearchResultPlacement[];
  };
}

interface SearchPayload {
  keyWord: string;
}

export default class SearchApi {
  public static async search(payload: SearchPayload): Promise<AxiosResponse<SearchResult>> {
    const res = await httpClient.get<SearchResult>('/api/search', { params: payload });

    return res;
  }
}
