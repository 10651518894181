






























import Vue from 'vue';
import { defineComponent, computed } from '@vue/composition-api';

import AppSideBarLogo from '@/components/_newDesign/App/AppSideBar/AppSideBarLogo.vue';
import AppSideBarNavMenu from '@/components/_newDesign/App/AppSideBar/AppSideBarNavMenu.vue';
import AppSideBarUserMenu from '@/components/_newDesign/App/AppSideBar/AppSideBarUserMenu.vue';
import AppSideBarMinimizer from '@/components/_newDesign/App/AppSideBar/AppSideBarMinimizer.vue';

import store from '@/store';
import navigationUiStore from '@/store/navigation/navigationUi';

const navigationUiStoreContext = navigationUiStore.context(store);

export default defineComponent({
  components: {
    AppSideBarLogo,
    AppSideBarNavMenu,
    AppSideBarUserMenu,
    AppSideBarMinimizer,
  },
  setup() {
    const sideBarWidth = computed(() => navigationUiStoreContext.getters.sideBarWidth);

    const sideBarMinWidth = computed(() => navigationUiStoreContext.getters.sideBarMinWidth);

    const sideBarMinimaized = computed({
      get() {
        return navigationUiStoreContext.getters.sideBarMinimaized;
      },
      set(val: boolean) {
        navigationUiStoreContext.dispatch('CHANGE_SIDE_BAR_MINIMAIZED', val);
      },
    });

    const rootClasses = computed(() => {
      const base = 'app-side-bar';

      return [
        base,
        Vue.device.os.slug === 'windows' ? `${base}--custom-scrollbar` : '',
      ];
    });

    return {
      rootClasses,
      sideBarWidth,
      sideBarMinWidth,
      sideBarMinimaized,
    };
  },
});
