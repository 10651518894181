import Vue, { ComponentOptions, AsyncComponent, VueConstructor } from 'vue';
import { RouteConfig, Route } from 'vue-router';

export const appRouteConfigMetaGroupSlugs = [
  'main',
  'admin',
] as const;

export const appRouteConfigMetaGroupTitles = [
  '',
  'Администратор',
] as const;

export const appRouteConfigMetaGroupShortTitles = [
  '',
  'Админ',
] as const;

type AppRouteConfigMetaGroupSlugs = typeof appRouteConfigMetaGroupSlugs;

type AppRouteConfigMetaGroupTitles = typeof appRouteConfigMetaGroupTitles;

type AppRouteConfigMetaGroupShortTitles = typeof appRouteConfigMetaGroupShortTitles;

export type AppRouteConfigMetaGroupSlug = AppRouteConfigMetaGroupSlugs[number];

export type AppRouteConfigMetaGroupTitle = AppRouteConfigMetaGroupTitles[number];

export type AppRouteConfigMetaGroupShortTitle = AppRouteConfigMetaGroupShortTitles[number];

export type AppRouteConfigMetaIcon = string | {
  name: string;
  size?: string | number;
  custom?: boolean;
};

type AppRouteConfigMetaHeader = {
  visible?: boolean;
  sticky?: boolean;
};

type AppRouteConfigMetaFooter = {
  visible?: boolean;
  linksOnRight?: boolean;
};

export interface AppRouteConfigMeta {
  isPublic?: boolean;
  layout?: string;
  group?: AppRouteConfigMetaGroupSlug;
  title?: string;
  icon?: AppRouteConfigMetaIcon;
  order?: number;
  permission?: string;
  loader?: VueConstructor;
  header?: AppRouteConfigMetaHeader;
  footer?: AppRouteConfigMetaFooter;
}

export interface AppRoute extends Omit<Route, 'meta'> {
  meta?: AppRouteConfigMeta;
}

type AppRouteConfigComponent = ComponentOptions<Vue> | typeof Vue | AsyncComponent;

type AppRouteConfigProps = boolean | Record<string, unknown> | ((route: AppRoute) => Record<string, unknown>);

export interface AppRouteConfigSingleView<T> extends Omit<RouteConfig, 'meta' | 'children' | 'component'> {
  meta?: AppRouteConfigMeta;
  component?: AppRouteConfigComponent;
  children?: T[];
  props?: AppRouteConfigProps;
}

export interface AppRouteConfigMultipleViews<T> extends Omit<RouteConfig, 'meta' | 'children' | 'components'> {
  meta?: AppRouteConfigMeta;
  components?: { [key: string]: AppRouteConfigComponent };
  children?: T[];
  props?: { [key: string]: AppRouteConfigProps };
}

export type AppRouteConfig = AppRouteConfigSingleView<AppRouteConfig> | AppRouteConfigMultipleViews<AppRouteConfig>;
