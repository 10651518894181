<template>
  <div class="category-details-loader">
    <v-skeleton-loader
      type="heading"
      class="category-details-loader__header"
    />
    <div class="category-details-loader__row">
      <div class="category-details-loader__col category-details-loader__col--white">
        <ListLoader
          without-title
          :rows="3"
          class="mb-6"
        />
        <ListLoader
          without-title
          :rows="5"
        />
      </div>
      <div class="category-details-loader__col">
        <v-skeleton-loader
          type="text"
          class="category-details-loader__table-title"
        />
        <TableLoader :columns="4" />
      </div>
      <div class="category-details-loader__col">
        <v-skeleton-loader
          type="text"
          class="category-details-loader__table-title"
        />
        <TableLoader :columns="4" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import ListLoader from '@/components/_newDesign/common/loaders/ListLoader.vue';
import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

export default defineComponent({
  components: {
    ListLoader,
    TableLoader,
  },
});
</script>

<style lang="scss" scoped>
.category-details-loader {
  background-color: map-get($grey, 'lighten-5');

  &__header {
    display: flex;
    align-items: center;
    width: 70%;
    height: 80px;
    padding: 12px 24px;

    ::v-deep .v-skeleton-loader__heading {
      background: map-get($grey, 'lighten-1') !important;
    }
  }

  &__row {
    display: flex;
  }

  &__col {
    width: 100%;
    padding: 24px;

    &--white {
      background-color: map-get($shades, 'white');
    }
  }

  &__table-title {
    width: 60%;

    ::v-deep .v-skeleton-loader__text {
      margin-bottom: 32px !important;
      background: map-get($grey, 'lighten-1') !important;
    }
  }

  &__table {
    display: block;
    width: 100%;
    padding-right: 24px;

    &:last-child {
      padding-right: 0;
    }
  }
}
</style>
