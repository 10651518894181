















import { defineComponent, PropType } from '@vue/composition-api';

import { DTableRow, DTableCellSpacer } from '@shared/druid-kit';

import { UnapprovedPlacement } from '@/models/UnapprovedPlacements';

import UnapprovedPlacementsTableInfoCell from './cells/UnapprovedPlacementsTableInfoCell.vue';
import UnapprovedPlacementsTableCheckCell from './cells/UnapprovedPlacementsTableCheckCell.vue';

export default defineComponent({
  components: {
    DTableRow,
    DTableCellSpacer,
    UnapprovedPlacementsTableInfoCell,
    UnapprovedPlacementsTableCheckCell,
  },
  props: {
    placement: {
      type: Object as PropType<UnapprovedPlacement>,
      required: true,
    },
  },
});
