











































import { defineComponent, PropType, toRefs, ref, watch, computed } from '@vue/composition-api';

import { Markup } from '@/models/Markup';

import { useCampaignEditorPlacementDetailsStore } from '@/store/campaignEditor/campaignEditorPlacementDetails';

import TableLoader from '@/components/_newDesign/common/loaders/TableLoader.vue';

import MarkupsPeriodsTable from './MarkupPeriodsTable/MarkupsPeriodsTable.vue';

export default defineComponent({
  components: {
    TableLoader,
    MarkupsPeriodsTable,
  },
  props: {
    value: { type: Boolean, default: false },
    markup: { type: Object as PropType<Markup>, required: true },
  },
  setup(props, context) {
    const {
      value,
      markup,
    } = toRefs(props);

    const campaignEditorPlacementStore = useCampaignEditorPlacementDetailsStore();

    const isOpen = ref(false);
    const isLoading = ref(false);

    const markupsDataProvider = computed(() => campaignEditorPlacementStore.getters.getMarkupWithCost(markup.value.id));
    const isShown = computed(() => isOpen.value && !isLoading.value);

    const fetch = async () => {
      isLoading.value = true;

      try {
        await campaignEditorPlacementStore.dispatch('FETCH_MARKUP', markup.value.id);
      } finally {
        isLoading.value = false;
      }
    };

    const open = async () => {
      if (!isOpen.value) return;

      await fetch();
    };

    watch(value, (val: boolean) => {
      if (isOpen.value === val) return;

      isOpen.value = val;

      open();
    }, { immediate: true });

    watch(isOpen, (val: boolean) => {
      if (value.value === val) return;

      context.emit('input', val);
    });

    const toggle = () => {
      if (isLoading.value) return;

      isOpen.value = !isOpen.value;

      open();
    };

    return {
      isOpen,
      isLoading,
      isShown,
      toggle,
      markupsDataProvider,
    };
  },
});
