import { computed, Ref, ComputedRef } from '@vue/composition-api';

import { Client, ClientBrand } from '@/models/Client';

interface UseClientBrandsProps {
  client: Ref<Client>;
}

interface UseClientBrandsReturn {
  activeBrands: ComputedRef<ClientBrand[]>;
  archiveBrands: ComputedRef<ClientBrand[]>;
  activeBrandsCount: ComputedRef<number>;
  archiveBrandsCount: ComputedRef<number>;
}

export default (props: UseClientBrandsProps): UseClientBrandsReturn => {
  const { client } = props;

  const activeBrands = computed(() => client.value.brands.filter((brand) => brand.active));

  const archiveBrands = computed(() => client.value.brands.filter((brand) => !brand.active));

  const activeBrandsCount = computed(() => activeBrands.value.length);

  const archiveBrandsCount = computed(() => archiveBrands.value.length);

  return {
    activeBrands,
    archiveBrands,
    activeBrandsCount,
    archiveBrandsCount,
  };
};
