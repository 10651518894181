


































































import { defineComponent, toRefs, ref, computed, watch, ComponentInstance } from '@vue/composition-api';

import AppModal from '@/components/_newDesign/common/AppModal.vue';

import { Role } from '@/models/Role';

import { PartialWithout } from '@/utils/type';

import RolesItemForm from '../forms/RolesItemForm.vue';

import { useRolesCatalogStore } from '../../../store/roles/rolesCatalog';

export default defineComponent({
  components: {
    AppModal,
    RolesItemForm,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
    } = toRefs(props);

    const rolesCatalogStore = useRolesCatalogStore();

    const roleFormRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);
    const roleFormData = ref<PartialWithout<Role, 'id'> | null>(null);
    const roleFormIsValid = ref(false);

    const roleFormResetValidation = async () => {
      if (!roleFormRef.value) return;

      roleFormRef.value.resetValidation();
    };

    const newRole = computed(() => {
      if (!roleFormData.value || !roleFormIsValid.value) return null;

      return roleFormData.value;
    });

    const saving = ref(false);
    const saveBtnIsDisabled = computed(() => !newRole.value);
    const saveError = ref<{ title: string; messages: string[] } | null>(null);

    const closeBtnIsDisabled = computed(() => saving.value);

    const close = () => {
      context.emit('input', false);
    };

    const save = async () => {
      if (!newRole.value) return;

      saving.value = true;
      saveError.value = null;

      try {
        await rolesCatalogStore.actions.CREATE_ROLE(newRole.value);

        close();
      } finally {
        saving.value = false;
      }
    };

    watch(value, async (val) => {
      if (!val) return;

      roleFormData.value = null;

      roleFormResetValidation();
    }, { immediate: true });

    return {
      roleFormRef,
      roleFormData,
      roleFormIsValid,
      saving,
      saveBtnIsDisabled,
      closeBtnIsDisabled,
      saveError,
      save,
    };
  },
});
