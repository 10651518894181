










































import { defineComponent, toRefs, ref, computed, PropType } from '@vue/composition-api';

import store from '@/store';

import notifyStore from '../store/notify.store';

import { NotifyItem, NotifyItemButton } from '../types';

const notifyStoreContext = notifyStore.context(store);

export default defineComponent({
  props: {
    notification: {
      type: Object as PropType<NotifyItem>,
      required: true,
    },
  },
  setup(props) {
    const { notification } = toRefs(props);

    const loadingButtonIdx = ref(-1);

    const timeout = computed(() => notification.value.timeout || 6000);
    const closeBtnContent = computed(() => notification.value?.closeBtnContent ?? { icon: 'mdi-close' });

    const closeBtnProps = computed(() => ({
      icon: !!closeBtnContent.value?.icon,
      text: !!closeBtnContent.value?.text,
    }));

    const remove = () => {
      notifyStoreContext.dispatch('REMOVE_NOTIFICATION', notification.value.id);
    };

    const isShown = computed({
      get() {
        return true;
      },
      set(value) {
        if (!value) remove();
      },
    });

    const color = computed(() => {
      const colors = {
        neutral: 'accent',
        positive: 'success',
        warning: 'warning',
        error: 'error',
      };

      return colors[notification.value.status || ''];
    });

    const normalizedButtons = computed(() => {
      const buttons = notification.value?.buttons;

      if (!buttons) return [];

      const someButtonsIsLoading = loadingButtonIdx.value < buttons.length && loadingButtonIdx.value !== -1;

      return buttons.map((button, idx) => {
        const buttonIsLoading = idx === loadingButtonIdx.value;

        return {
          ...button,
          props: {
            ...button.props,
            loading: buttonIsLoading,
            disabled: someButtonsIsLoading && !buttonIsLoading,
          },
        };
      });
    });

    const onClickBtn = async (payload: { button: NotifyItemButton; index: number }) => {
      if (!payload.button?.action) return;

      loadingButtonIdx.value = payload.index;

      try {
        await payload.button.action({ close: remove });
      } finally {
        loadingButtonIdx.value = -1;
      }
    };

    return {
      isShown,
      timeout,
      remove,
      color,
      closeBtnProps,
      closeBtnContent,
      normalizedButtons,
      onClickBtn,
    };
  },
});
