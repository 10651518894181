































































import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import moment from 'moment';

import {
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { UserAction, UserActionChange } from '@/models/User';

export default defineComponent({
  components: {
    DTableCell,
    DTableRow,
  },
  props: {
    userAction: { type: Object as PropType<UserAction>, required: true },
  },
  setup(props) {
    const {
      userAction,
    } = toRefs(props);

    const date = computed(() => moment(userAction.value.timestamp).format('DD.MM.YYYY'));
    const time = computed(() => moment(userAction.value.timestamp).format('HH:mm:ss'));

    const pathToUser = computed(() => `/access-control/users/?current-user-id=${userAction.value.user.id}`);

    const pathToObject = computed(() => {
      const pathGeneratorsMap: Record<string, (action: UserAction) => string | undefined> = {
        Campaign(action) {
          return `/campaigns/${action.objectId}`;
        },
        Placement(action) {
          if (!action.campaignId) return;

          return `/campaigns/${action.campaignId}/placements/${action.objectId}`;
        },
        Markup(action) {
          return `/markups/${action.objectId}`;
        },
      };

      const pathGenerator = pathGeneratorsMap[userAction.value.objectName];

      return pathGenerator ? pathGenerator(userAction.value) : undefined;
    });

    const obejectName = computed(() => {
      const obejectNameTranslations = {
        Campaign: 'Кампания',
        Placement: 'Размещение',
        Markup: 'Наценка',
        Token: 'Доступ',
        DataProviderSettings: 'Настройки провайдера',
      };

      return obejectNameTranslations?.[userAction.value.objectName] || userAction.value.objectName;
    });

    const actionIcon = computed(() => {
      switch (userAction.value.actionName) {
        case 'Добавление':
          return 'mdi-plus';

        case 'Изменение':
          return 'mdi-pencil';

        case 'Удаление':
          return 'mdi-delete-outline';

        default:
          return '';
      }
    });

    const generateChange = (payload: { name: string; change: UserActionChange }) => {
      const { name, change } = payload;

      const isEmpty = (str?: string) => !str || str.length === 0;

      if (name === 'Добавление') {
        return {
          text: change.name,
          newValue: change.newValue,
        };
      }

      if (name === 'Удаление') {
        return {
          text: change.name,
          oldValue: change.oldValue,
        };
      }

      if (isEmpty(change.oldValue)) {
        return {
          text: `Добавлено ${change.name}`,
          newValue: change.newValue,
        };
      }

      if (isEmpty(change.newValue)) {
        return {
          text: `Удалено ${change.name}`,
          oldValue: change.oldValue,
        };
      }

      return {
        text: change.name,
        newValue: change?.newValue || null,
        oldValue: change?.oldValue || null,
      };
    };

    const normalizedChanges = computed(() => userAction.value.changes
      .map((change) => generateChange({ name: userAction.value.actionName, change })));

    return {
      date,
      time,
      obejectName,
      pathToUser,
      pathToObject,
      generateChange,
      actionIcon,
      normalizedChanges,
    };
  },
});
