

























import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { orderBy, isEqual } from 'lodash';

import {
  DSelectInline,
  DTreeView,
} from '@shared/druid-kit';

import { ClientsApi } from '@/api';

import { Client } from '@/models/Client';

interface ClientWithBrands {
  id: string;
  brands: string[];
}

export default defineComponent({
  components: {
    DSelectInline,
    DTreeView,
  },
  props: {
    value: { type: Array as PropType<ClientWithBrands[]>, default: () => [] },
    preselectedClients: { type: Array as PropType<ClientWithBrands[]>, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
      preselectedClients,
      loading,
    } = toRefs(props);

    const clientsTreeRef = ref<ComponentInstance & { collapse(): void } | null>(null);
    const isLoadingData = ref(false);

    const isLoading = computed({
      get() {
        return loading.value || isLoadingData.value;
      },
      set(val: boolean) {
        isLoadingData.value = val;
      },
    });

    const clients = ref<Client[]>([]);

    const selectedNodeIds = ref<string[]>([]);

    const preselectedClientsComputed = computed(() => preselectedClients.value.map((client) => ({
      ...client,
      brands: client.brands.length ? client.brands : (clients.value.find(({ id }) => id === client.id)?.brands || []).map((brand) => brand.id),
    })));

    const selectedClientsMap = computed(() => {
      if (!clients.value.length) return {};

      return value.value.reduce<Record<string, string>>((acc, client) => {
        client.brands.forEach((brandId) => {
          const newBrandId = `${client.id}_${brandId}`;

          acc[newBrandId] = newBrandId;
        });

        return acc;
      }, {});
    });

    const preselectedClientsMap = computed(() => {
      if (!clients.value.length) return {};

      return preselectedClientsComputed.value.reduce<Record<string, string>>((acc, client) => {
        client.brands.forEach((brandId) => {
          const newBrandId = `${client.id}_${brandId}`;

          acc[newBrandId] = newBrandId;
        });

        return acc;
      }, {});
    });

    const selectedNodeIdsMap = computed(() => selectedNodeIds.value.reduce<Record<string, string>>((acc, selectedNodeId) => {
      acc[selectedNodeId] = selectedNodeId;

      return acc;
    }, {}));

    const sortOptions: { text: string; value: string }[] = [
      { text: 'По алфавиту', value: 'alphabet' },
      { text: 'По выбранным', value: 'checked' },
    ];

    const sortFilter = ref<'alphabet' | 'checked'>('checked');

    const sortMethod = computed(() => {
      const methods: Record<typeof sortFilter.value, (list: Client[]) => Client[]> = {
        alphabet(list) {
          return orderBy(list, 'name', 'asc');
        },
        checked(list) {
          const preSortedList = methods.alphabet(list);

          return orderBy(preSortedList, (client) => {
            const isSelected = client.brands.some((brand) => {
              const brandId = `${client.id}_${brand.id}`;

              return !!selectedNodeIdsMap.value[brandId];
            });

            return isSelected ? 1 : 0;
          }, 'desc');
        },
      };

      return methods[sortFilter.value];
    });

    const sortClients = (list: Client[]) => (sortMethod.value ? sortMethod.value(list) : list);

    watch(sortFilter, () => {
      clients.value = sortClients(clients.value);
    });

    const nodes = computed(() => {
      const list = clients.value.map((client) => {
        const disabled = preselectedClientsMap.value[client.id];

        const brands = client.brands.map((brand) => {
          const brandId = `${client.id}_${brand.id}`;

          return {
            id: brandId,
            name: brand.name,
            disabled: !!preselectedClientsMap.value[brandId],
          };
        });

        return {
          id: client.id,
          name: client.name,
          children: brands,
          disabled: !!(disabled || brands.every((brand) => brand.disabled)),
        };
      });

      return list;
    });

    watch([selectedClientsMap, preselectedClientsMap], ([selectedVal, preselectedVal], [_prevSelectedVal, prevPreselectedVal]) => {
      let resultMap = { ...selectedVal };

      Object.values(prevPreselectedVal).forEach((val) => {
        delete resultMap[val];
      });

      resultMap = {
        ...resultMap,
        ...preselectedVal,
      };

      if (isEqual(resultMap, selectedNodeIdsMap.value)) return;

      selectedNodeIds.value = Object.values(resultMap);

      if (sortFilter.value === 'checked' && (Object.keys(selectedVal).length || Object.keys(preselectedVal).length)) {
        clients.value = sortClients(clients.value);
      }
    });

    watch(selectedNodeIdsMap, (val, prevVal) => {
      if (isEqual(prevVal, val) || isEqual(val, selectedClientsMap.value)) return;

      const resultMap = Object.values(val).reduce((acc, id) => {
        const [clientId, brandId] = id.split('_');

        if (!acc[clientId]) {
          acc[clientId] = {
            id: clientId,
            brands: [],
          };
        }

        if (clientId && brandId) {
          acc[clientId].brands.push(brandId);
        }

        return acc;
      }, {});

      const result = Object.values(resultMap);

      context.emit('input', result);
    });

    const init = async () => {
      isLoading.value = true;

      try {
        const res = await ClientsApi.fetchClients();

        clients.value = sortClients(res.data);
      } finally {
        isLoading.value = false;
      }
    };

    const collapseTree = () => {
      if (!clientsTreeRef.value) return;

      clientsTreeRef.value.collapse();
    };

    init();

    return {
      isLoading,
      sortOptions,
      sortFilter,
      clients,
      nodes,
      selectedNodeIds,
      clientsTreeRef,
      collapseTree,
    };
  },
});
