




























import { defineComponent, ref, PropType } from '@vue/composition-api';

interface TabsItem {
  name: string;
  type: string;
  items: Record<string, unknown>[];
}

export default defineComponent({
  props: {
    tabs: { type: Array as PropType<TabsItem[]>, default: () => [] },
  },
  setup() {
    const activeTabIdx = ref(0);

    return { activeTabIdx };
  },
});
