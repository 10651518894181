var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DTable',_vm._b({staticClass:"metrics-table",attrs:{"items":_vm.items,"items-per-page":_vm.items.length},on:{"update:expanded":function($event){return _vm.$emit('update:expanded', $event)}},scopedSlots:_vm._u([(_vm.$slots['header-name'])?{key:"header-name",fn:function(){return [_vm._t("header-name")]},proxy:true}:null,{key:"row",fn:function(ref){
var index = ref.index;
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(index)?_c('tr',{staticStyle:{"height":"8px"}}):_vm._e(),_vm._v(" "),_vm._t("row",function(){return [_c('MetricsTableRow',{attrs:{"metrics-plan":item.metricsPlan,"metrics-total":item.metricsTotal,"metric-cells":_vm.metricCells},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._t("prepend-row",null,{"item":item,"isExpanded":isExpanded,"expand":expand})]},proxy:true},{key:"append",fn:function(){return [_vm._t("append-row",null,{"item":item})]},proxy:true}],null,true)})]},{"item":item,"isExpanded":isExpanded,"expand":expand})]}},{key:"expanded-row",fn:function(ref){
var item = ref.item;
return [_vm._t("expanded-row",null,{"item":item})]}},{key:"body-append",fn:function(){return [_vm._t("body-append")]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'DTable',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }