/* eslint-disable max-classes-per-file */

import Vue from 'vue';
import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, Context, createMapper, createComposable } from 'vuex-smart-module';

import { Brand } from '@/models/Brand';
import { CampaignSettings } from '@/models/Campaign';
import { GoalLight } from '@/models/Goal';

import {
  ClientsApi,
  CampaignsApi,
  CampaignEditorApi,
  GoalsSettingsApi,
} from '@/api';

import campaignStore from '../campaign';

export const CONVERSION_RATE_MODE_GOAL_SUM = 'GoalsSum';
export const CONVERSION_RATE_MODE_MAIN_GOAL = 'MainGoal';

export const DEFAULT_CONVERSION_RATE_MODE = CONVERSION_RATE_MODE_GOAL_SUM;

class ModuleState {
  settings: CampaignSettings['settings'] | null = null;

  settingsUpdate: CampaignSettings['settings'] | null = null;

  dataProviderAbilities: CampaignSettings['dataProviderAbilities'] = [];

  clientBrands: Brand[] = [];

  goals: GoalLight[] = [];
}

class ModuleGetters extends Getters<ModuleState> {
  get settings() {
    return this.state.settings;
  }

  get settingsUpdate() {
    return this.state.settingsUpdate;
  }

  get currentSettings() {
    return this.getters.settingsUpdate || this.getters.settings;
  }

  get dataProviderAbilities() {
    return this.state.dataProviderAbilities;
  }

  get clientBrands() {
    return this.state.clientBrands;
  }

  get goals() {
    return this.state.goals;
  }

  get hasBeenChanged() {
    return !!this.getters.settingsUpdate;
  }

  get currentConversionRateMode() {
    return this.getters.currentSettings?.conversionRateCalculationMode || '';
  }

  get currentConversionRateModeIsMainGoal() {
    return this.getters.currentConversionRateMode === CONVERSION_RATE_MODE_MAIN_GOAL;
  }

  get currentMainGoal() {
    return this.getters.currentSettings?.mainGoal;
  }

  get error() {
    if (!this.getters.currentSettings) return;

    const rules = [
      {
        check: () => {
          const { currentConversionRateMode } = this.getters;

          return !currentConversionRateMode;
        },
        error: { message: 'Не выбрана метрика для подсчета' },
      },
      {
        check: () => {
          const {
            currentConversionRateModeIsMainGoal,
            currentMainGoal,
          } = this.getters;

          return currentConversionRateModeIsMainGoal && !currentMainGoal?.id;
        },
        error: { message: 'Не выбрана главная цель' },
      },
    ];

    const ruleWithError = rules.find(({ check }) => check());
    const error = ruleWithError?.error;

    if (!error) return;

    return { title: 'Основные настройки', ...error };
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_SETTINGS(payload: CampaignSettings['settings']) {
    this.state.settings = payload;
  }

  SET_DATA_PROVIDER_ABILITIES(payload: CampaignSettings['dataProviderAbilities']) {
    this.state.dataProviderAbilities = payload;
  }

  SET_SETTINGS_UPDATE(payload: CampaignSettings['settings']) {
    this.state.settingsUpdate = payload;
  }

  SET_CLIENT_BRANDS(payload: Brand[]) {
    this.state.clientBrands = payload;
  }

  SET_GOALS(payload: GoalLight[]) {
    this.state.goals = payload;
  }

  RESET_CHANGES() {
    this.state.settingsUpdate = null;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  store!: Store<unknown>;

  campaign!: Context<typeof campaignStore>;

  $init(store: Store<unknown>) {
    this.store = store;

    this.campaign = campaignStore.context(store);
  }

  async FETCH_SETTINGS(payload: { campaignId: string }) {
    const { campaignId } = payload;

    const res = await CampaignEditorApi.fetchCampaignSettings(campaignId);

    this.commit('SET_SETTINGS', res.data.settings);
    this.commit('SET_DATA_PROVIDER_ABILITIES', res.data.dataProviderAbilities);
  }

  async FETCH_CLIENT_BRANDS(payload: { clientId: string }) {
    const { clientId } = payload;

    const res = await ClientsApi.fetchClientBrands(clientId);

    this.commit('SET_CLIENT_BRANDS', res.data);
  }

  async FETCH_GOALS(payload: { campaignId: string; providerId: string; providerSettingsId: string }) {
    try {
      const res = await GoalsSettingsApi.fetchGoals(payload);

      this.commit('SET_GOALS', res.data);
    } catch (e) {
      this.commit('SET_GOALS', []);
    }
  }

  CHANGE_SETTINGS(payload: Partial<CampaignSettings['settings']>) {
    if (!this.getters.currentSettings) return;

    const newSettings = { ...this.getters.currentSettings, ...payload };

    this.commit('SET_SETTINGS_UPDATE', newSettings);
  }

  async SAVE(payload: { campaignId: string }) {
    const { campaignId } = payload;

    if (!this.getters.settingsUpdate || this.getters.error) return;

    // Мммм, костыли костылики, так надо потому что получаемый формат настроек и формат для обновления абсолютно разные
    // TODO: когда то это надо будет переделать
    const data: Parameters<typeof CampaignsApi.updateCampaignSettings>[0]['data'] & {
      name?: string;
      brandId?: string;
      calculationSettings?: {
        includeAC: boolean;
        includeVAT: boolean;
      };
    } = {
      ...this.getters.settingsUpdate,
      ...this.getters.settingsUpdate.calculationSettings,
      campaignNameManual: this.getters.settingsUpdate.name,
      brandIdManual: this.getters.settingsUpdate.brandId,
    };

    delete data.calculationSettings;
    delete data.name;
    delete data.brandId;

    await CampaignsApi.updateCampaignSettings({ campaignId, data });

    const { campaign } = this.campaign.getters;

    Vue.gtm.push({
      event: 'user_action',
      event_category: 'Campaign Editor',
      event_action: 'Edit Settings',
      event_label: 'Save',
      d1d_client_id: campaign?.clientId,
      d1d_client_name: campaign?.clientName,
      d1d_brand_id: campaign?.brandId,
      d1d_brand_name: campaign?.brandName,
      d1d_campaign_id: campaign?.id,
      d1d_campaign_name: campaign?.name,
    });

    await this.dispatch('FETCH_SETTINGS', payload);

    this.commit('RESET_CHANGES');
  }

  async RESET_MANUAL_SETTINGS(payload: { campaignId: string }) {
    const { campaignId } = payload;

    await CampaignsApi.removeCampaignManualSettings({ campaignId, data: {} });

    await this.dispatch('FETCH_SETTINGS', payload);

    this.commit('RESET_CHANGES');
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const campaignEditorSettingsStoreMapper = createMapper(module);

export const useCampaignEditorSettingsStore = createComposable(module);

export default module;
