import Vue from 'vue';
import { createOidc } from '@shared/vue-oidc';

import PluginInit from '@/models/PluginInit';

const { origin } = window.location;

export const OIDC_SIGN_IN_URI = '/signin-oidc';
export const OIDC_SIGN_OUT_URI = '/signout-oidc';

export const OIDC_SILENT_SIGN_IN_URI = '/signin-oidc-silent';

export const oidc = createOidc({
  authority: 'https://sso.okkam.group',
  client_id: 'd1d.web.front',
  response_type: 'code',
  scope: 'openid offline_access profile dan.profile userService.manage_users proxyService.api d1d.benchmarks.api d1d.mediascope.api d1d.users.api d1d.web.api',

  redirect_uri: `${origin}${OIDC_SIGN_IN_URI}`,
  post_logout_redirect_uri: `${origin}${OIDC_SIGN_OUT_URI}`,

  silent_redirect_uri: `${origin}${OIDC_SILENT_SIGN_IN_URI}`,
});

const pluginInit: PluginInit = ({ router }) => {
  oidc.useDefaultRoutes(router);

  Vue.use(oidc);
};

export default pluginInit;
