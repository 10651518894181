





















import { defineComponent, computed } from '@vue/composition-api';

import DashboardColors from '../../services/dashboardColors';

import { useDashboardStore } from '../../store/dashboard';

export default defineComponent({
  setup() {
    const dashboardStore = useDashboardStore();

    const groups = computed(() => dashboardStore.getters.groups);

    const items = computed(() => {
      const colors = DashboardColors.getColorGroups(groups.value);

      return groups.value.map((group, idx) => {
        const groupColor = colors[idx];

        return {
          text: group,
          color: groupColor,
        };
      });
    });

    return {
      items,
    };
  },
});
