import { AxiosResponse } from 'axios';

import { BenchmarkBoardStatisticsGroup } from '@/models/BenchmarkBoard';

import httpClient from '../httpClient';

export interface FetchStatisticsPayload {
  params: {
    dataProviderName: string | number | null;
    clientId: string | number | null;
    brandName: string | number | null;
    capping: string | number | null;
    timing: string | number | null;
    geo: string | number | null;
    transmittance: string | number | null;
    audience: string | number | null;
    siteName: string | number | null;
    adPlace: string | number | null;
    groupDataBy: string | number | null;
  };
}

export default class BenchmarkBoardApi {
  public static async fetchStatistics(payload: FetchStatisticsPayload): Promise<AxiosResponse<BenchmarkBoardStatisticsGroup[]>> {
    const res = await httpClient.get<BenchmarkBoardStatisticsGroup[]>('/api/benchmark-board/statistics', payload);

    return res;
  }
}
