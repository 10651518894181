



























import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api';

import { Campaign, CampaignEditorPlacementDetails } from '@/models/Campaign';

export default defineComponent({
  components: {
  },
  props: {
    campaign: { type: Object as PropType<Campaign>, required: true },
    placement: { type: Object as PropType<CampaignEditorPlacementDetails>, required: true },
  },
  setup(props) {
    const {
      campaign,
      placement,
    } = toRefs(props);

    const breadcrumbs = computed(() => [
      {
        label: campaign.value.brandName,
        to: `/clients/${campaign.value.clientId}/?brandId=${campaign.value.brandId}`,
      },
      {
        label: campaign.value.name,
        to: `/campaigns/${campaign.value.id}`,
      },
      {
        label: 'Настройки кампании',
        to: { name: 'campaign-editor' },
      },
      {
        label: `${placement.value.siteName || 'Без имени'}`,
        to: { name: 'campaign-placemet-editor' },
      },
    ]);

    return {
      breadcrumbs,
    };
  },
});
