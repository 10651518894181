import initScript from './initScript';

/* eslint-disable camelcase */
export interface GtmData {
  d1d_user_id: string;
  d1d_user_access: string[];
  //
  d1d_client_id: string;
  d1d_client_name: string;
  //
  d1d_brand_id: string;
  d1d_brand_name: string;
  //
  d1d_campaign_id: string;
  d1d_campaign_name: string;
  //
  d1d_danbo_id: string;
  [key: string]: unknown;
}
/* eslint-enable camelcase */

export const gtmInstances = [
  {
    id: 'GTM-NHR22N8',
    name: 'dataLayer',
  },
];

export type GtmInstanceName = typeof gtmInstances[number]['name'];

export default (): void => {
  initScript(gtmInstances);
};
