// TODO: описать типы и порефакторить, тут точно можно обойтись без lodash

import { forEach, isObject } from 'lodash';

const hexToRgb = (h) => {
  let r = '0';
  let g = '0';
  let b = '0';

  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }

  return `${+r}, ${+g}, ${+b}`;
};

const generateRgbVar = (name, color) => {
  const colorRgb = hexToRgb(color);

  if (!colorRgb.includes('NaN')) {
    document.documentElement.style.setProperty(`--v-${name}-rgb`, colorRgb);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createRgbVarsForThemes = (themes: any): void => {
  forEach(themes, (theme) => {
    forEach(theme, (colors, key) => {
      if (isObject(colors)) {
        forEach(colors, (color, colorKey) => {
          generateRgbVar(`${key}-${colorKey}`, color);
        });
      } else {
        generateRgbVar(key, colors);
      }
    });
  });
};

export default createRgbVarsForThemes;
