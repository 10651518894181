












































































































import { defineComponent, toRefs, ref, computed, watch, PropType, ComponentInstance } from '@vue/composition-api';

import { DAutocomplete } from '@shared/druid-kit';

import { DataProvider } from '@/models/DataProvider';

import { useDataProvidersDetailsStore } from '../../../store/dataProviders/dataProvidersDetails';

export default defineComponent({
  components: {
    DAutocomplete,
  },
  props: {
    value: { type: Object as PropType<DataProvider | null>, default: null },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  setup(props, context) {
    const {
      value,
      loading,
    } = toRefs(props);

    const dataProvidersDetailsStore = useDataProvidersDetailsStore();

    const rootRef = ref<ComponentInstance & { resetValidation(): void } | null>(null);

    const isValid = ref(false);

    watch(isValid, (val) => {
      context.emit('update:valid', val);
    }, { immediate: true });

    const resetValidation = () => {
      if (!rootRef.value) return;

      rootRef.value.resetValidation();
    };

    const updateDataProvider = (payload: Partial<DataProvider>) => {
      const newDataProvider = {
        ...value.value,
        ...payload,
      };

      context.emit('input', newDataProvider);
    };

    // // // // //

    const nameRules = ref([
      (val: string) => !!val,
    ]);

    const nameIsLoading = computed(() => loading.value);
    const nameIsDisabled = computed(() => nameIsLoading.value);

    const currentName = computed({
      get() {
        return value.value?.name || '';
      },
      set(val: string) {
        updateDataProvider({ name: val });
      },
    });

    // // // // //

    const connectorIdRules = ref([
      (val: string) => !val || /^([0-9a-f]{8})-(([0-9a-f]{4}-){3})([0-9a-f]{12})$/i.test(val),
    ]);

    const connectorIdIsLoading = computed(() => loading.value);
    const connectorIdIsDisabled = computed(() => connectorIdIsLoading.value);

    const currentConnectorId = computed({
      get() {
        return value.value?.connectorId || '';
      },
      set(val: string) {
        updateDataProvider({ connectorId: val });
      },
    });

    // // // // //

    const priorityIsLoading = computed(() => loading.value);
    const priorityIsDisabled = computed(() => priorityIsLoading.value);

    const currentPriority = computed({
      get() {
        return value.value?.priority || 0;
      },
      set(val: number) {
        updateDataProvider({ priority: val });
      },
    });

    // // // // //

    const typeRules = ref([
      (val: DataProvider['type']) => !!val,
    ]);

    const types = computed(() => dataProvidersDetailsStore.getters.types);
    const typesIsLoadingData = ref(false);

    const typesIsLoading = computed({
      get() {
        return typesIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        typesIsLoadingData.value = val;
      },
    });

    const typesIsDisabled = computed(() => !types.value.length || typesIsLoading.value);

    const currentType = computed({
      get() {
        return value.value?.type || null;
      },
      set(val: DataProvider['type'] | null) {
        if (!val) return;

        updateDataProvider({ type: val });
      },
    });

    const fetchTypes = async () => {
      typesIsLoading.value = true;

      try {
        await dataProvidersDetailsStore.actions.FETCH_TYPES();
      } finally {
        typesIsLoading.value = false;
      }
    };

    // // // // //

    const sites = computed(() => dataProvidersDetailsStore.getters.sites);
    const sitesIsLoadingData = ref(false);

    const sitesIsLoading = computed({
      get() {
        return sitesIsLoadingData.value || loading.value;
      },
      set(val: boolean) {
        sitesIsLoadingData.value = val;
      },
    });

    const sitesIsDisabled = computed(() => !sites.value.length || sitesIsLoading.value);

    const currentSitesIds = computed({
      get() {
        return value.value?.sites || [];
      },
      set(val: string[]) {
        updateDataProvider({ sites: val });
      },
    });

    const fetchSites = async () => {
      sitesIsLoading.value = true;

      try {
        await dataProvidersDetailsStore.actions.FETCH_SITES();
      } finally {
        sitesIsLoading.value = false;
      }
    };

    // // // // //

    const isTokenRequiredIsLoading = computed(() => loading.value);
    const isTokenRequiredIsDisabled = computed(() => isTokenRequiredIsLoading.value);

    const currentIsTokenRequired = computed({
      get() {
        return value.value?.isTokenRequired || false;
      },
      set(val: boolean) {
        updateDataProvider({ isTokenRequired: val });
      },
    });

    // // // // //

    const supportsGoalsIsLoading = computed(() => loading.value);
    const supportsGoalsIsDisabled = computed(() => supportsGoalsIsLoading.value);

    const currentSupportsGoals = computed({
      get() {
        return value.value?.supportsGoals || false;
      },
      set(val: boolean) {
        updateDataProvider({ supportsGoals: val });
      },
    });

    // // // // //

    const supportsTriggersIsLoading = computed(() => loading.value);
    const supportsTriggersIsDisabled = computed(() => supportsTriggersIsLoading.value);

    const currentSupportsTriggers = computed({
      get() {
        return value.value?.supportsTriggers || false;
      },
      set(val: boolean) {
        updateDataProvider({ supportsTriggers: val });
      },
    });

    // // // // //

    const init = async () => {
      await Promise.all([
        fetchTypes(),
        fetchSites(),
      ]);
    };

    init();

    return {
      rootRef,
      isValid,
      resetValidation,
      //
      nameRules,
      currentName,
      nameIsLoading,
      nameIsDisabled,
      //
      connectorIdRules,
      currentConnectorId,
      connectorIdIsLoading,
      connectorIdIsDisabled,
      //
      currentPriority,
      priorityIsLoading,
      priorityIsDisabled,
      //
      sites,
      currentSitesIds,
      sitesIsLoading,
      sitesIsDisabled,
      //
      typeRules,
      currentType,
      types,
      typesIsLoading,
      typesIsDisabled,
      //
      currentIsTokenRequired,
      isTokenRequiredIsLoading,
      isTokenRequiredIsDisabled,
      //
      currentSupportsGoals,
      supportsGoalsIsLoading,
      supportsGoalsIsDisabled,
      //
      currentSupportsTriggers,
      supportsTriggersIsLoading,
      supportsTriggersIsDisabled,
    };
  },
});
