











































import { defineComponent, computed, PropType } from '@vue/composition-api';

import {
  DTable,
  DTableCell,
  DTableRow,
} from '@shared/druid-kit';

import { User } from '@/models/User';

import UersTableRow from './UersTableRow.vue';

import { useUsersCatalogStore } from '../../../../store/users/usersCatalog';

export default defineComponent({
  components: {
    DTable,
    DTableCell,
    DTableRow,
    UersTableRow,
  },
  props: {
    users: { type: Array as PropType<User[]>, default: () => [] },
  },
  setup() {
    const usersCatalogStore = useUsersCatalogStore();

    const headers = ['Имя', 'Эл. почта', '']
      .map((text) => ({
        text,
        class: 'caption',
        sortable: false,
      }));

    const usersIsLoading = computed(() => usersCatalogStore.getters.isLoading);

    const onIntersect = (entries: unknown, observer: unknown, isIntersecting: boolean) => {
      if (isIntersecting) usersCatalogStore.actions.NEXT_PAGE();
    };

    return {
      headers,
      usersIsLoading,
      onIntersect,
    };
  },
});
