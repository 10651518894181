





















import { defineComponent, toRefs, computed } from '@vue/composition-api';

import AppView from '@/components/_newDesign/common/AppView.vue';

import useMeta from '@/composables/meta/useMeta';
import { useLayoutLoadingStatus } from '@/composables/layout';

import MarkupDetailsHeader from '../components/MarkupDetails/MarkupDetailsHeader.vue';
import MarkupDetailsInfo from '../components/MarkupDetails/MarkupDetailsInfo.vue';

import MarkupPeriodsTable from '../components/MarkupDetails/MarkupPeriodsTable/MarkupsPeriodsTable.vue';

import { useMarkupDetailsStore } from '../store/markupDetails';

export default defineComponent({
  components: {
    AppView,
    MarkupDetailsHeader,
    MarkupDetailsInfo,
    MarkupPeriodsTable,
  },
  props: {
    markupId: { type: String, required: true },
  },
  setup(props) {
    const { markupId } = toRefs(props);

    const {
      isLoading,
      updateIsLoading,
    } = useLayoutLoadingStatus();

    const markupDetailsStore = useMarkupDetailsStore();

    const markup = computed(() => markupDetailsStore.getters.markup);

    const pageMeta = computed(() => ({ title: markup.value ? `Наценки • ${markup.value.placementName}` : 'Наценки' }));

    useMeta(pageMeta);

    const init = async () => {
      updateIsLoading(true);

      try {
        await markupDetailsStore.dispatch('FETCH_MARKUP', { markupId: markupId.value });
      } finally {
        updateIsLoading(false);
      }
    };

    init();

    return {
      isLoading,
      markup,
    };
  },
});
