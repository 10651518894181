
































































import { defineComponent, computed, watch } from '@vue/composition-api';

import useRoute from '@/packages/hooks/useRoute';

import AppView from '@/components/_newDesign/common/AppView.vue';

import { useLayoutLoadingStatus } from '@/composables/layout';
import useModals from '@/composables/useModals';

import UsersCatalogHeader from '../../components/Users/UsersCatalog/UsersCatalogHeader.vue';
import UsersTable from '../../components/Users/UsersCatalog/UsersTable/UsersTable.vue';

import UsersAddModal from '../../components/Users/modals/UsersAddModal.vue';
import UsersAddExistModal from '../../components/Users/modals/UsersAddExistModal.vue';
import UsersItemModal from '../../components/Users/modals/UsersItemModal.vue';
import UsersAccessRightsModal from '../../components/Users/modals/UsersAccessRightsModal.vue';

import { useUsersCatalogStore } from '../../store/users/usersCatalog';

export default defineComponent({
  components: {
    AppView,
    UsersCatalogHeader,
    UsersTable,
    UsersAddModal,
    UsersAddExistModal,
    UsersItemModal,
    UsersAccessRightsModal,
  },
  setup(props, context) {
    const route = useRoute(context);

    const { updateIsLoading } = useLayoutLoadingStatus();

    const {
      modalsMap,
      openModal,
    } = useModals(['add', 'addExist', 'item', 'accessRights']);

    const usersCatalogStore = useUsersCatalogStore();

    const users = computed(() => usersCatalogStore.getters.users);
    const usersFilters = computed(() => usersCatalogStore.getters.filters);
    const usersFiltersChanged = computed(() => usersCatalogStore.getters.filtersChanged);
    const usersIsLoading = computed(() => usersCatalogStore.getters.isLoading);

    const fetch = async () => {
      await usersCatalogStore.actions.FETCH_USERS();
    };

    const init = async () => {
      updateIsLoading(true);
      usersCatalogStore.actions.RESET_FILTERS();

      try {
        await fetch();
      } finally {
        updateIsLoading(false);
      }
    };

    watch(route, () => {
      if (route.value.query?.['current-user-id']) {
        openModal({
          slug: 'item',
          props: { userId: route.value.query['current-user-id'] },
        });
      }
    }, { immediate: true });

    watch(usersFilters, () => {
      if (!usersFiltersChanged.value) return;

      fetch();
    });

    init();

    return {
      modalsMap,
      users,
      usersFiltersChanged,
      usersIsLoading,
      openModal,
    };
  },
});
