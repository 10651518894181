
































import { defineComponent, computed } from '@vue/composition-api';

import { debounce } from 'lodash';

import { DBtnInline } from '@shared/druid-kit';

import { useProviderTokensStore } from '../../store';

export default defineComponent({
  components: {
    DBtnInline,
  },
  setup() {
    const providerTokensStore = useProviderTokensStore();

    const query = computed({
      get() {
        return providerTokensStore.getters.filters.query;
      },
      set: debounce((val: string) => {
        providerTokensStore.dispatch('UPDATE_FILTERS', { query: val });
      }, 300),
    });

    return { query };
  },
});
